// import { fromJS } from 'immutable';
// import { CREATEACCOUNTACTION_SUCCESS, CREATEACCOUNTACTION_FAILURE } from "../constants/Action";
import { createSlice } from '@reduxjs/toolkit';



const initialState ={
    setCreateAccountData: {}
};



const CreateAccountReducer = createSlice({
    name: 'CreateAccountReducer',
    initialState,
    reducers: {
        setCreateAccount(state, action) {
        return { ...state, setCreateAccountData: action.payload }
      },
    //   TermConcat(state, action) {
    //     return { ...state, termArray: action.payload }
    //   },
  
    },
   
  
  
  
  })
  
  export const { setCreateAccount } = CreateAccountReducer.actions
  export default CreateAccountReducer.reducer