
import React, { useState, useRef } from "react";
import {
    Text,
    TouchableOpacity,
    View,
    Dimensions,
    ScrollView, TextInput, Image
} from "react-native";
import { Icon, Button } from "react-native-elements";
import { Hoverable } from "react-native-web-hooks";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import CreateCommentStyle from './Style/CreateCommentStyle';
import ButtonStyle from "../constants/ButtonStyle";

import appolloClient from "../client";
import { ContentReportMutation } from '../graphqlSchema/FeedMutation';
import { showReportUserModal } from '../reducers/AdminTrueFalseReducer';


const ReportUserModal = props => {

    let [reportMsg, setreportMsg] = useState('')
    let [showCaption, setshowCaption] = useState('')
    let [clickedReport, setclickedReport] = useState('')
    let [errorMsg, seterrorMsg] = useState('')

    const reportContent = (reportType) => {
        if (reportMsg == '') {
            seterrorMsg('Please share your reason')
        } else if (clickedReport == '') {
            seterrorMsg('Please select one emoji')
        } else {
            seterrorMsg('')
            appolloClient
                .query({
                    query: ContentReportMutation,
                    variables: {
                        user_id: props.reportUser,
                        report_type: clickedReport,
                        message:reportMsg
                    },
                    fetchPolicy: "no-cache",
                })
                .then(async (res) => {
                    props.showReportUserModal(false, '')
                    console.log(res.data, 'res-------')

                    if (res.data.report_create.status.status == 'OK') {
                        alert('you have succesfully submitted the report')
                    }
                })
        }

    }

    return (
        <View
            style={{
                width: "100%",
                height: Dimensions.get('window').width >= 750 ? 480 : 500,
                backgroundColor: '#fff'
            }}
        >
            <Icon
                name="close" size={18} type="font-awesome"
                iconStyle={{ marginRight: 15, alignSelf: 'flex-end', marginTop: 20 }}
                onPress={() => {
                    props.showReportUserModal(false, '')
                }}

            />
            <TextInput multiline={true} numberOfLines={4}
                onChangeText={(text) => setreportMsg(text)}
                style={{ borderColor: '#d3d3d3', borderWidth: 1, borderRadius: 8, padding: 10, marginTop: 20, width: '90%', marginLeft: '5%' }}
                value={reportMsg}
                placeholder="Share your reason" />

            <View style={{ flexDirection: 'row', justifyContent: 'space-evenly', width: '80%', marginLeft: '10%', marginTop: 20 }}>

                <TouchableOpacity
                    onMouseEnter={() => setshowCaption("Flag for Removal")}
                    onMouseLeave={() => setshowCaption("")}

                    onPress={() => setclickedReport('SPAM')}
                    style={{ borderColor: clickedReport == 'SPAM' ? '#de5246' : '#fff', borderWidth: 2 }}

                // onMouseLeave={() => this.setState({ showCaption: "" })}
                >
                    <Icon
                        color={'#de5246'}
                        name={"flag"}
                        type="font-awesome"
                        size={25}
                        iconStyle={{ alignSelf: "center" }}
                        containerStyle={{ alignSelf: "center" }}
                    />
                </TouchableOpacity>
                <TouchableOpacity
                    onMouseEnter={() => setshowCaption("Low Quality")}
                    onMouseLeave={() => setshowCaption("")}
                    onPress={() => setclickedReport('LOW_QUALITY')}
                    style={{ borderColor: clickedReport == 'LOW_QUALITY' ? '#de5246' : '#fff', borderWidth: 2 }}

                >
                    <Image
                        source={require("../assets/image/bugIcon.png")}
                        style={{
                            width: 18,
                            height: 18,
                            // marginLeft: 10
                        }}
                    />
                </TouchableOpacity>
                <TouchableOpacity
                    onMouseEnter={() => setshowCaption("Off-Topic")}
                    onMouseLeave={() => setshowCaption("")}

                    onPress={() => setclickedReport('HATE')}
                    style={{ borderColor: clickedReport == 'HATE' ? '#de5246' : '#fff', borderWidth: 2 }}


                // onMouseLeave={() => this.setState({ showCaption: "" })}
                >
                    <Image
                        source={require("../assets/image/hate.png")}
                        style={{
                            width: 22,
                            height: 22,
                        }}
                    />
                </TouchableOpacity>
                {/* <TouchableOpacity
                    onMouseEnter={() => setshowCaption("Disagree")}
                    onMouseLeave={() => setshowCaption("")}

                    onPress={() => setclickedReport('OTHER')}
                    style={{ borderColor: clickedReport == 'OTHER' ? '#de5246' : '#fff', borderWidth: 2 }}

                >
                    <Icon
                        color={'#ffce44'}
                        name={"thumbs-down"}
                        type="font-awesome"
                        size={24}
                        iconStyle={{ alignSelf: "center" }}
                        containerStyle={{ alignSelf: "center" }}
                    />
                </TouchableOpacity> */}
            </View>
            <Text style={{ textAlign: 'center', paddingVertical: 10, fontWeight: 'bold', color: showCaption.length > 0 ? '#D3B036' : '#000' }}>{showCaption}  </Text>
            <Text style={{ textAlign: 'center', paddingVertical: 10, color: 'red' }}>{errorMsg}  </Text>

            <Button
                title="Submit"
                titleStyle={ButtonStyle.titleStyle}
                buttonStyle={ButtonStyle.backgroundStyle}
                containerStyle={[ButtonStyle.containerStyle,
                    //  { marginTop:userData.length>0 ? 200:0, marginBottom: 50 }
                ]}
                onPress={() => {
                    reportContent()
                }}
            />

        </View >
    );
};

const mapStateToProps = state => (
    {
        reportUser: state.AdminTrueFalseReducer.userId,
    });
const mapDispatchToProps = dispatch => ({

    showReportUserModal: (reportUserModal, userId) => dispatch(showReportUserModal({ type: "reportUser", payload: { reportUserModal, userId } }))


});

// const PostShareModelWrapper = graphql(PostShareMutation, {
//   name: "Post",
//   options: { fetchPolicy: "no-cache" }
// })(PostShareModel);



export default compose(connect(mapStateToProps, mapDispatchToProps))(
    ReportUserModal
);