import React, { lazy, createRef, Suspense } from "react";

import {
  Dimensions,
  Platform,
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  FlatList,
} from "react-native";
import { Icon } from "react-native-elements";
import { heightPercentageToDP as hp } from "react-native-responsive-screen";
import { connect } from "react-redux";
import { compose } from "react-recompose";
// import { listClikMembers } from "../actionCreator/ClikMembersAction";
import { listClikUserRequest } from "../actionCreator/ClikUserRequestAction";
import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";
import { setPostCommentDetails } from "../actionCreator/PostCommentDetailsAction";
import { setPostDetails } from "../actionCreator/PostDetailsAction";
import { getTrendingCliksProfileDetails } from "../actionCreator/TrendingCliksProfileAction";
import { getTrendingTopicsProfileDetails } from "../actionCreator/TrendingTopicsProfileAction";
import appolloClient from "../client";
import ConstantColors from "../constants/Colors";
import ConstantFontFamily from "../constants/FontFamily";
import {
  SearchClikMutation,
  SearchEveryThingMutation,
  SearchFeedMutation,
  SearchTopicMutation,
  SearchUserMutation,
} from "../graphqlSchema/SearchMutation";
import {
  SearchClikVariables,
  SearchEveryVariables,
  SearchFeedVariables,
  SearchTopicVariables,
  SearchUserVariables,
} from "../graphqlSchema/SearchVariables";
// import NavigationService from "../library/NavigationService";
import ShadowSkeleton from "../components/ShadowSkeleton";
import ButtonStyle from "../constants/ButtonStyle";
import { clikMemberSuccess } from "../reducers/TrendingCliksProfileReducer";

// import CreateCommentCard from "../components/CreateCommentCard";
import {
  feedFluctuation,
  clikHover,
  isloading,
  screen,
} from "../reducers/action/Completed";
// import { retry } from "../library/Helper";

// const CommentDetailScreen = lazy(() => import("./CommentDetailScreen"))
import CommentDetailScreen from "./CommentDetailScreen";

// const BottomScreen = lazy(() => import("../components/BottomScreen"))
import FeedList from "../components/FeedList";
// const FeedList = lazy(() => import("../components/FeedList"))
// const LeftPanel = lazy(() =>
//   retry(() => import("../components/LeftPanel"))
// );
import LeftPanel from "../components/LeftPanel";
import Header from "../components/Header";

let feedID = [];

class SearchScreen extends React.PureComponent {
  flatListRef = createRef();
  constructor(props) {
    super(props);
    this.state = {
      term: "",
      PostList: [],
      ClikList: [],
      TopicList: [],
      UserList: [],
      FeedList: [],
      SuggestList: [],
      TopicSuggestList: [],
      ClikSuggestList: [],
      UserSuggestList: [],
      FeedSuggestList: [],
      SelectFilterItem: "",
      //  this.props.navigation
      //   .getParam("type", "NO-ID")
      //   .toUpperCase(),
      selectedItems: [],
      selection: {
        start: 1,
        end: 0,
      },
      index: this.getIndex(),
      showTips: true,
      activeFeed: -1,
      activeId: "",
      activeTitle: "",
      feedY: 0,
      searched: false,
      termTitle: this.props.term,
      termWebTitle: this.props.termWeb,
      isLoading: true,
      commentDelay: false,
    };
    this.nameInput = React.createRef();
  }

  getIndex = () => {
    // console.log(this.props.navigation,'getCurrentRoute');
    // switch (this.props.navigation.getParent("type", "NO-ID").toUpperCase()) {
    //   case "TOPICS":
    //     return 1;
    //   case "CLIKS":
    //     return 2;
    //   case "USERS":
    //     return 3;
    //   case "FEEDS":
    //     return 4;
    //   default:
    //     return 0;
    // }
  };

  componentDidUpdate = (prevProps, prevState) => {
    // if (
    //   prevProps.navigation.getParam("type", "NO-ID") !==
    //   this.props.navigation.getParam("type", "NO-ID")
    // ) {
    //   this.setState({
    //     SelectFilterItem: this.props.navigation
    //       .getParam("type", "NO-ID")
    //       .toUpperCase(),
    //     index: this.getIndex(),
    //   });
    // }

    if (prevProps.searchQuery != this.props.searchQuery) {
      console.log("gitting");
      this.getSuggestion(this.props.term);
    }
    if (prevProps.term != this.props.term) {
      // console.log(this.props.term,'this.props.term');
      this.getRespectiveSuggestions(this.props.term);
      // this.getSuggestion(this.props.term);
    }
    if (prevProps.keyEvent != this.props.keyEvent) {
      if (this.props.keyEvent === 13) {
        this.setState({
          TopicSuggestList: [],
          ClikSuggestList: [],
          UserSuggestList: [],
          FeedSuggestList: [],
        });
        this.getSuggestion(this.props.term);
      }
    }

    if (prevProps.termWeb != this.props.termWeb) {
      this.getRespectiveSuggestions(this.props.termWeb);
    }
    if (prevProps.keyEventWeb != this.props.keyEventWeb) {
      if (this.props.keyEventWeb === 13) {
        this.setState({
          TopicSuggestList: [],
          ClikSuggestList: [],
          UserSuggestList: [],
          FeedSuggestList: [],
        });
        this.getSuggestion(this.props.termWeb);
      }
    }
    if (prevState.termWebTitle != this.state.termWebTitle) {
      this.setState({ termWebTitle: this.state.termWebTitle });
      this.getRespectiveSuggestions(this.props.termWeb);
    }
    if (prevState.termTitle != this.state.termTitle) {
      this.setState({ termTitle: this.state.termTitle });
      this.getRespectiveSuggestions(this.props.term);
    }

    // if (this.state.PostList.length == 0) {
    //   this.props.setPostCommentReset({
    //     payload: [],
    //     postId: "",
    //     title: "",
    //     loading: true,
    //   });
    // }
  };

  getSuggestion = (value) => {
    this.setState({
      isLoading: true,
      showTips: true,
    });
    let topics = [];
    let cliks = [];
    let users = [];
    let feeds = [];
    let terms = [];
    if (Platform.OS == "web") {
      var TotalTerm = value.split(" ");
    } else {
      var TotalTerm = value !== undefined ? value.split(" ")[0].trim() : "";
    }

    for (var i = 0; i < TotalTerm.length; i++) {
      if (TotalTerm[i].startsWith("#") == true) {
        cliks.push(TotalTerm[i].replace("#", ""));
      } else if (TotalTerm[i].startsWith("/") == true) {
        topics.push(TotalTerm[i].replace("/", ""));
      } else if (TotalTerm[i].startsWith("@") == true) {
        users.push(TotalTerm[i].replace("@", ""));
      } else if (TotalTerm[i].startsWith("%") == true) {
        feeds.push(TotalTerm[i].replace("%", "").replace("-", " "));
      } else {
        terms.push(TotalTerm[i]);
      }
    }
    this.props.isloading(true);
    SearchEveryVariables.variables.terms = terms;
    appolloClient
      .query({
        query: SearchEveryThingMutation,
        ...SearchEveryVariables,
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        this.setState(
          {
            showTips: false,
            ClikList: res?.data?.search?.everything?.posts?.edges,
            PostList: res?.data?.search?.everything?.posts?.edges,
            TopicList: res?.data?.search?.everything?.posts?.edges,
            UserList: res?.data?.search?.everything?.posts?.edges,
            FeedList: res?.data?.search?.everything?.posts?.edges,
            isLoading: false,
          },
          () => {
            this.flatListRef.current &&
              this.flatListRef.current.scrollToOffset({
                x: 0,
                y: 0,
                animated: true,
              });
            setTimeout(() => {
              this.props.setPostCommentDetails({
                id:
                  res?.data?.search?.everything?.posts?.edges?.length > 0 &&
                  res?.data?.search?.everything?.posts?.edges[0].node.id,
                title:
                  res?.data?.search?.everything?.posts?.edges?.length > 0 &&
                  res.data.search.everything.posts.edges[0].node.title,
              });
              this.props.setUpdatedPostId(
                res?.data?.search?.everything?.posts?.edges?.length > 0 &&
                  res.data.search.everything.posts.edges[0].node.id
              );
              this.props.isloading(false);
            }, 2000);
          }
        );
      });
  };

  getRespectiveSuggestions = async (value) => {
    if (Platform.OS == "web") {
      var TotalTerm = value.split(" ");
    } else {
      var TotalTerm = value !== undefined ? value.split(" ")[0].trim() : "";
    }
    for (var i = 0; i < TotalTerm.length; i++) {
      if (TotalTerm[i].startsWith("#") == true || this.state.index == 1) {
        await this.setState({
          TopicSuggestList: [],
          UserSuggestList: [],
          FeedSuggestList: [],
        });
        await this.getClikSuggestion(TotalTerm[i].replace("#", ""));
      } else if (
        TotalTerm[i].startsWith("/") == true ||
        this.state.index == 2
      ) {
        await this.setState({
          ClikSuggestList: [],
          UserSuggestList: [],
          FeedSuggestList: [],
        });
        await this.getTopicSuggestion(TotalTerm[i].replace("/", ""));
      } else if (
        TotalTerm[i].startsWith("@") == true ||
        this.state.index == 3
      ) {
        await this.setState({
          TopicSuggestList: [],
          ClikSuggestList: [],
          FeedSuggestList: [],
        });
        await this.getUserSuggestion(TotalTerm[i].replace("@", ""));
      } else if (
        TotalTerm[i].startsWith("%") == true ||
        this.state.index == 4
      ) {
        await this.setState({
          TopicSuggestList: [],
          ClikSuggestList: [],
          UserSuggestList: [],
        });
        await this.getFeedSuggestion(TotalTerm[i].replace("%", ""));
      } else {
        await this.setState({
          TopicSuggestList: [],
          ClikSuggestList: [],
          UserSuggestList: [],
          FeedSuggestList: [],
        });
      }
    }
  };

  getTopicSuggestion = (value) => {
    SearchTopicVariables.variables.prefix = value;
    appolloClient
      .query({
        query: SearchTopicMutation,
        ...SearchTopicVariables,
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        let data = res.data.search.topics;
        this.setState({
          TopicSuggestList: data,
          ClikSuggestList: [],
          UserSuggestList: [],
          FeedSuggestList: [],
        });
      });
  };

  getClikSuggestion = (value) => {
    SearchClikVariables.variables.prefix = value;
    appolloClient
      .query({
        query: SearchClikMutation,
        ...SearchClikVariables,
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        let data = res.data.search.cliks;
        this.setState({
          ClikSuggestList: data,
          TopicSuggestList: [],
          UserSuggestList: [],
          FeedSuggestList: [],
        });
      });
  };

  getUserSuggestion = (value) => {
    SearchUserVariables.variables.prefix = value;
    appolloClient
      .query({
        query: SearchUserMutation,
        ...SearchUserVariables,
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        let data = res.data.search.users;
        this.setState({
          UserSuggestList: data,
          TopicSuggestList: [],
          ClikSuggestList: [],
          FeedSuggestList: [],
        });
      });
  };

  getFeedSuggestion = (value) => {
    SearchFeedVariables.variables.prefix = value.replace("-", " ");
    appolloClient
      .query({
        query: SearchFeedMutation,
        ...SearchFeedVariables,
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        let data = res.data.search.feeds;
        this.setState({
          FeedSuggestList: data,
          TopicSuggestList: [],
          ClikSuggestList: [],
          UserSuggestList: [],
        });
      });
  };

  _renderItem = (item) => {
    var row = item.item;
    this.getfeedID("Search" + row.id);
    return (
      <FeedList
        loginModalStatusEventParent={null}
        item={{ item: { node: row.node } }}
        data={this.state.PostList}
        navigation={this.props.navigation}
        ViewMode={"Default"}
        highlight={this.makeHighlight}
        ActiveTab="Search"
        onLoadingComment={this.onLoadingComment}
      />
    );
  };

  getUnique = (array) => {
    var uniqueArray = [];
    for (let i = 0; i < array.length; i++) {
      if (uniqueArray.indexOf(array[i]) === -1) {
        uniqueArray.push(array[i]);
      }
    }
    return uniqueArray;
  };

  getfeedID = async (id) => {
    let data = feedID;
    await data.push(id);
    feedID = data;
  };

  makeHighlight = async (id, title) => {
    let newId = id.search("Search") != -1 ? id : "Search" + id;
    await this.setState({
      activeFeed: this.getUnique(feedID).indexOf(newId),
      activeId: id,
      activeTitle: title,
    });
    this.setBorderColor();
  };

  setBorderColor = async () => {
    for (let i = 0; i < this.getUnique(feedID).length; i++) {
      let data = document.getElementById(this.getUnique(feedID)[i]);
      if (data != null && Dimensions.get("window").width >= 1000) {
        document.getElementById(this.getUnique(feedID)[i]).style.borderColor =
          i == this.state.activeFeed ? ConstantColors.blueColor : "#c5c5c5";
        if (i == this.state.activeFeed) {
          document.getElementById(this.getUnique(feedID)[i]).click();
        }
      }
    }
  };

  listScroll = (value) => {
    this.setState({
      feedY: value,
    });
  };

  onLoadingComment = () => {
    this.setState({ commentDelay: true });
    if (this.props.PostCommentDetails) {
      setTimeout(() => {
        this.setState({ commentDelay: false });
      }, 500);
    }
  };

  componentDidMount() {
    // console.log(this.props.navigation.getCurrentRoute,'getCurrentRoute');
    let pathname =
      location && location.search && location.search.replace("?query=", "");
    if (pathname != "") {
      this.getRespectiveSuggestions(pathname);
      this.getSuggestion(pathname);
    }
    if (this.props.termWeb && this.props.termWeb.length > 0) {
      this.getRespectiveSuggestions(this.props.termWeb);
      this.getSuggestion(this.props.termWeb);
    }
    if (this.props.term && this.props.term.length > 0) {
      this.getRespectiveSuggestions(this.props.term);
      this.getSuggestion(this.props.term);
    }
    this.props.screen("search");
  }

  render() {
    console.log(this.props.term, this.props.termWeb, "111111111111111");
    return (
      <View
        style={{
          flex: 1,
          width: "100%",
        }}
      >
        {/* {Dimensions.get("window").width <= 750 && (
          <View
            style={{
              width: "100%",
              flexDirection: "row",
              backgroundColor: "#000",
              height: 42,
              alignItems: "center",
            }}
          >
            <TouchableOpacity
              style={[ButtonStyle.headerBackStyle, { marginRight: 25 }]}
              onPress={() => {
                this.props.setSearchBarStatus(false);
                this.props.searchOpenBarStatus(false);
                let nav = this.props.navigation.dangerouslyGetParent().state;
                if (nav.routes.length > 1) {
                  this.props.navigation.goBack();
                  return;
                } else {
                  this.props.navigation.navigate("home");
                }
              }}
            >
              <Icon
                color={"#fff"}
                name="angle-left"
                type="font-awesome"
                size={40}
              />
            </TouchableOpacity>
            <View
              style={{
                backgroundColor: "#fff",
                width:
                  Dimensions.get("window").width <= 750
                    ? Dimensions.get("window").width - 70
                    : Dimensions.get("window").width >= 750 &&
                      Dimensions.get("window").width <= 1200
                      ? Dimensions.get("window").width - 100
                      : 445,
                height: 34,
                borderRadius: 0,
                flexDirection: "row",
                borderColor: "#388bfd",
                borderWidth: 0,
                borderRadius: 6,
                justifyContent: "space-between",
                alignItems: "center",
                paddingHorizontal: "2%",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  width: "90%",
                  alignItems: "center",
                }}
              >
                <Icon
                  iconStyle={{ marginRight: "auto" }}
                  name="search"
                  color={this.state.boxColor == "#fff" ? "#333333" : "#717071"}
                  size={18}
                  type="font-awesome"
                />
                <TextInput
                  style={{
                    width: "90%",
                    paddingLeft: 15,
                    height: 32,
                    borderColor: "#388bfd",
                    borderWidth: 0,
                    borderRadius: 6,
                    backgroundColor: "#fff",
                    outline: "none",
                    color: "#000",
                    fontSize: 14,
                    fontFamily: ConstantFontFamily.defaultFont,
                  }}
                  ref={this.props.nameInput}
                  placeholder="Search Weclikd"
                  onChangeText={(term) => {
                    this.setState({ term: term });
                    this.props.setTermWeb(this.state.term);
                  }}
                  value={
                    this.props.termWeb ? this.props.termWeb : this.state.term
                  }
                  onSubmitEditing={() => {
                    this.getSuggestion(this.state.term);
                    this.props.setTermWeb(this.state.term);
                    this.props.setTerm(this.state.term);
                  }}
                  onFocus={() =>
                    this.setState({
                      boxColor: "#fff",
                    })
                  }
                  returnKeyType={"search"}
                />
              </View>

              {Dimensions.get("window").width <= 1200 && (
                <Icon
                  name="times"
                  onPress={() => {
                    this.props.setTerm("");
                    this.props.setTermWeb("");
                    this.setState({ term: "" });
                  }}
                  size={20}
                  type="font-awesome"
                />
              )}
            </View>
          </View>
        )} */}
        {/* <View
          style={{
            flexDirection: "row",
            width: "100%",
          }}
        > */}
        <View
          style={[
            this.props.windowSize.width > 1200
              ? ButtonStyle.threeColStruc
              : null,
            {
              // flexDirection: "row",
              width: "100%",
              // justifyContent: "center",
              backgroundColor: ConstantColors.whiteBackground,
              height: "100%",
            },
          ]}
        >
          {this.props.windowSize.width > 1600 ? (
            <LeftPanel
              // ref={navigatorRef => {
              //   NavigationService.setTopLevelNavigator(navigatorRef);
              // }}
              navigation={this.props.navigation}
            />
          ) : null}
          {/* {Dimensions.get('window').width <= 750 &&
          <Header
            title="Search"
            navigation={this.props.navigation}
            // leftPanelModalFunc={this.props.leftPanelModalFunc}
          />
        } */}
          {
            <ScrollView
              showsVerticalScrollIndicator={false}
              contentContainerStyle={{
                height: hp("100%") - 100,
                width: this.props.windowSize?.width > 1200 ? 600 : "100%",
              }}
              style={[
                {
                  height: hp("100%"),

                  paddingBottom: 100,
                  borderLeftWidth: 1,
                  borderRightWidth: 1,
                  borderColor: "#d3d3d3",
                  flexGrow: 0,
                  // marginLeft:10
                },
              ]}
            >
              {this.state.showTips || this.state.isLoading ? (
                <ShadowSkeleton />
              ) : (
                this.state.PostList.length == 0 &&
                this.props.term == "" &&
                this.props.termWeb ==
                  ""(
                    <View
                      style={{
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: 20,
                      }}
                    >
                      <Icon
                        color={"#000"}
                        iconStyle={{
                          color: "#fff",
                          justifyContent: "center",
                          alignItems: "center",
                          alignSelf: "center",
                        }}
                        reverse
                        name="file"
                        type="font-awesome"
                        size={20}
                        containerStyle={{
                          alignSelf: "center",
                        }}
                      />
                      <Text
                        style={{
                          fontSize: 14,
                          fontWeight: "bold",
                          fontFamily: ConstantFontFamily.defaultFont,
                          color: "#000",
                          alignSelf: "center",
                        }}
                      >
                        No Search Results
                      </Text>
                    </View>
                  )
              )}
              <FlatList
                ref={this.flatListRef}
                data={this.state.PostList}
                renderItem={this._renderItem}
                showsVerticalScrollIndicator={false}
                scrollEnabled={true}
                scrollEventThrottle={16}
                onEndReachedThreshold={0.001}
                initialNumToRender={10}
                removeClippedSubviews={true}
              />
            </ScrollView>
          }
          {Platform.OS == "web" && Dimensions.get("window").width >= 750 ? (
            <View
              style={[
                {
                  width: this.props.windowSize?.width > 1200 ? 600 : 500,

                  marginBottom: 5,
                  marginRight:
                    Dimensions.get("window").width >= 750 &&
                    Dimensions.get("window").width <= 1200
                      ? 5
                      : 15,
                  display:
                    Dimensions.get("window").width >= 750 &&
                    Platform.OS == "web"
                      ? "flex"
                      : "none",
                  // marginLeft: 10,
                  height: Dimensions.get("window").height - 45,
                },
              ]}
            >
              <View style={{ width: "92%", marginLeft: "4%" }}>
                <CommentDetailScreen
                  navigation={this.props.navigation}
                  postId={
                    this.props.PostDetails && this.props.PostDetails.node?.id
                  }
                  listScroll={this.listScroll}
                />
              </View>

              {/* {(this.props.show == true || this.props.windowSize.width >= 1200) && this.props.isLaoding == false ?
                  <View style={{
                    width: Platform.OS == "web" &&
                      this.props.windowSize.width >= 750 && this.props.windowSize.width <= 1200
                      ? '100%' : Platform.OS == "web" && this.props.windowSize.width >= 1200 ? '100%'
                        : "100%", zIndex: 0, position: 'absolute', bottom: 10,
                  }}>
                    <CreateCommentCard
                      onClose={this.onClose}
                      parent_content_id={
                        this.props.PostId &&
                        this.props.PostId.replace("Trending", "")
                          .replace("New", "")
                          .replace("Discussion", "")
                          .replace("Search", "")
                      }
                      clickList={
                        this.props.PostDetails
                          ? this.props.PostDetails
                          : null
                      }
                      initial="main"
                      topComment={this.props.PostDetails && this.props.PostDetails.node}
                      navigation={this.props.navigation}
                      title={this.props.Title}
                      type={this.props.type}
                      commentRef={this.commentRef}
                    />
                  </View> : null
                } */}
            </View>
          ) : null}
        </View>
        {/* </View> */}
        {/* {Dimensions.get("window").width <= 750 && (
          
            <BottomScreen navigation={NavigationService} />
          
        )} */}
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginStatus: state.UserReducer.loginStatus,
    getUserFollowTopicList: state.LoginUserDetailsReducer.userFollowTopicsList
      ? state.LoginUserDetailsReducer.userFollowTopicsList
      : [],
    getUserFollowCliksList: state.LoginUserDetailsReducer.userFollowCliksList
      ? state.LoginUserDetailsReducer.userFollowCliksList
      : [],
    getUserFollowUserList: state.LoginUserDetailsReducer.userFollowUserList
      ? state.LoginUserDetailsReducer.userFollowUserList
      : [],
    getUserFollowFeedList: state.LoginUserDetailsReducer.userFollowFeedList
      ? state.LoginUserDetailsReducer.userFollowFeedList
      : [],
    PostDetails: state.PostDetailsReducer.PostDetails,
    term: state.ScreenLoadingReducer.setTerm,
    keyEvent: state.AdminTrueFalseReducer.setKeyEvent,
    termWeb: state.ScreenLoadingReducer.setTermWeb,
    keyEventWeb: state.ScreenLoadingReducer.setKeyEventWeb,
    windowSize: state.windowResizeReducer.windowResize,
    // screenName: state.screenNameReducer.screen,
    // isLaoding: state.isLoadingReducer.isloading,
    // show: state.showDiscussionReducer.show,
    windowSize: state.windowResizeReducer.windowResize,
    searchQuery: state.AdminTrueFalseReducer.searchQuery,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setPostDetails: (payload) => dispatch(setPostDetails(payload)),
  topicId: (payload) => dispatch(getTrendingTopicsProfileDetails(payload)),
  ClikId: (payload) => dispatch(getTrendingCliksProfileDetails(payload)),
  setClikUserRequest: (payload) => dispatch(listClikUserRequest(payload)),
  setClikMembers: (payload) => dispatch(clikMemberSuccess(payload)),
  setLoginModalStatus: (payload) => dispatch(setLOGINMODALACTION(payload)),
  setPostCommentDetails: (payload) => dispatch(setPostCommentDetails(payload)),
  setTerm: (payload) => dispatch({ type: "SET_TERM", payload }),
  setTermWeb: (payload) => dispatch({ type: "SET_TERM_WEB", payload }),
  // setPostCommentReset: (payload) =>
  //   dispatch({ type: "POSTCOMMENTDETAILS_RESET", payload }),
  setSearchBarStatus: (payload) =>
    dispatch({ type: "SEARCH_BAR_STATUS", payload }),
  searchOpenBarStatus: (payload) =>
    dispatch({ type: "SEARCHBAR_STATUS", payload }),
  setUpdatedPostId: (payload) =>
    dispatch({ type: "SET_UPDATED_POSTID", payload }),
  isloading: (payload) => dispatch(isloading(payload)),
  screen: (payload) => dispatch(screen(payload)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SearchScreen
);
