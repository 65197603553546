import React from 'react';
import { ActivityIndicator, View } from 'react-native';

const LoaderComponent = (props) => {
	return (
		<View style={{
			flex: 1,
			justifyContent: 'center',
			alignItems: 'center',
			zIndex: 1000,
			opacity: 1
		}}>
			<ActivityIndicator size="large" color="#000" />
		</View>
	)
}

export default LoaderComponent;