import { createSlice } from '@reduxjs/toolkit';
// import { CLIKS_PROFILE_DETAILS_FAILURE, CLIKS_PROFILE_DETAILS_SUCCESS } from "../constants/Action";




const initialState = {
    postShareStatus: false,
    postShareId: ""
};

const PostShareReducer = createSlice({
    name: 'PostShareReducer',
    initialState,
    reducers: {
        setPostShareModel(state, action) {
            return { ...state, postShareId: action.payload.id,postShareStatus:action.payload.status }
        },
        TermConcat(state, action) {
            return { ...state, termArray: action.payload }
        },

    },




})

export const { setPostShareModel } = PostShareReducer.actions
export default PostShareReducer.reducer

// // import { fromJS } from 'immutable';

// const initialState = {
//     postShareStatus: false,
//     postShareId:""
// };

// export default function PostShareReducer(state = initialState, action) {
//     const { type, payload } = action;
//     switch (type) {
//         case "POSTSHARESTATUS":
//             return state
//                 .set('postShareStatus', payload);
//         case "SHAREDPOSTID":
//             return state.set("postShareId",payload)
//     }
//     return state;
// }
