import { takeEvery, put } from 'redux-saga/effects';
import { HASSCROLLEDACTION } from "../constants/Action";
import { hasscrolledAdctionSuccess, hasscrolledAdctionFailure } from '../reducers/HasScrolledReducer';

export function* HasScrolledMiddleware({ payload }) {
    if (payload != null) {
        yield put(hasscrolledAdctionSuccess(payload));
    } else {
        yield put(hasscrolledAdctionFailure(payload));
    }
}

export default function* HasScrolledMiddlewareWatcher() {
    yield takeEvery(HASSCROLLEDACTION, HasScrolledMiddleware);
}