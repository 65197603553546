import React, { Component } from "react";
import {
  Animated,
  Dimensions,
  Image,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { Icon } from "react-native-elements";
import { Hoverable } from "react-native-web-hooks";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import HomeStyle from "../components/Style/HomeStyle";
import ConstantColors from "../constants/Colors";
import ConstantFontFamily from "../constants/FontFamily";
// import { generate } from 'build-number-generator'
import { screen } from "../reducers/action/Completed";
import ButtonStyle from "../constants/ButtonStyle";
import LeftPanel from "../components/LeftPanel";

class FAQScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      headingName: "",
    };
  }

  async componentDidMount() {
    this.setState({
      data: [
        {
          title: "About",
          data: [1, 2, 3, 4, 5, 6],
        },
        {
          title: "Basics",
          data: ["A", "B", "C", "D", "E"],
        },
        {
          title: "Membership",
          data: ["A", "B", "C", "D", "E"],
        },
        {
          title: "Monetization",
          data: ["A", "B", "C", "D", "E"],
        },
        {
          title: "Contact",
          data: ["A", "B", "C", "D", "E"],
        },
        {
          title: "Beta testers",
          data: ["A", "B", "C", "D", "E"],
        },
      ],
    });
    this.props.screen("faq");
  }

  _renderItem = (item) => {
    switch (item) {
      case "Beta testers":
        return (
          <View
            style={[
              {
                padding: 25,
                marginTop: Dimensions.get("window").width <= 1200 ? 0 : 15,
                marginLeft: Dimensions.get("window").width <= 1200 ? 0 : 15,
              },
            ]}
          >
            <Text style={styles.titleTextStyle}>Beta Testers</Text>
            <Text style={styles.textStyle}>
              We host a constant, open beta to try out new features and would
              love to have your feedback and for you to share our app via word
              of mouth! If your quality suggestions and feedback are implemented
              and/or each member you invite, we award you with gold membership
              each month.
            </Text>
          </View>
        );
      case "Contact":
        return (
          <View
            style={[
              {
                padding: 25,
                marginTop: Dimensions.get("window").width <= 1200 ? 0 : 15,
                marginLeft: Dimensions.get("window").width <= 1200 ? 0 : 15,
              },
            ]}
          >
            <Text style={styles.titleTextStyle}>Contact</Text>
            <Text style={styles.textStyle}>
              Mail us anytime and reach our executive office directly at
              info@weclikd.com. We’re always looking forward to and actively
              reaching out to members, content leaders and new potential
              collabs. Please feel free to reach out to our CEO on Linkedin or
              follow @weclikd on Twitter.
            </Text>
          </View>
        );
      case "Monetization":
        return (
          <View
            style={[
              {
                padding: 25,
                marginTop: Dimensions.get("window").width <= 1200 ? 0 : 15,
                marginLeft: Dimensions.get("window").width <= 1200 ? 0 : 15,
              },
            ]}
          >
            <Text style={styles.titleTextStyle}>Monetization/Hearts</Text>
            <Text style={styles.textStyle}>
              The core of #weclikd is you are investing in yourself while using
              our platform to learn. We gather the intellectual content of other
              social platforms, blogs, and thought leaders across the web and
              rely on our users to complement the posts with quality
              discussions.
            </Text>
            <Text style={styles.textStyle}>
              There are four heart tiers, red, silver, gold, and blue.
            </Text>
            <View
              style={{
                paddingBottom: 15,
              }}
            >
              <Text style={styles.textStyle}>
                These hearts serve multiple purposes.
              </Text>
              <View style={{ flexDirection: "row", alignItems: "flex-start" }}>
                <Text style={styles.bulletStyle}>{"\u2022"} </Text>
                <Text style={styles.bulletTextStyle}>
                  {" "}
                  Bookmark the content. Posts with hearts will appear in your
                  “Bookmarks” section of your home feed.
                </Text>
              </View>
              <View style={{ flexDirection: "row", alignItems: "flex-start" }}>
                <Text style={styles.bulletStyle}>{"\u2022"} </Text>
                <Text
                  style={[
                    styles.bulletTextStyle,
                    { alignSelf: "flex-start", paddingTop: 6 },
                  ]}
                >
                  Your vote in the quality of the content. Blue is the highest
                  quality, which means you have learned something valuable. Red
                  should be used to express support for the content, but it was
                  not something you learned. Silver & Gold are somewhere in
                  between red and blue hearts.
                </Text>
              </View>
              <View style={{ flexDirection: "row", alignItems: "flex-start" }}>
                <Text style={styles.bulletStyle}>{"\u2022"} </Text>
                <Text
                  style={[
                    styles.bulletTextStyle,
                    { alignSelf: "flex-start", paddingTop: 6 },
                  ]}
                >
                  Award the author with a portion of your paid subscription.
                  Currently, only comments are monetized, not posts.
                </Text>
              </View>
            </View>
            <Text style={[styles.textStyle, { fontWeight: "bold" }]}>
              For instance, given Alice (you) is willing to create x and Bob is
              agreed upon payment for her content.
            </Text>
          </View>
        );
      case "Extras":
        return (
          <View
            style={[
              {
                padding: 25,
                marginTop: Dimensions.get("window").width <= 1200 ? 0 : 15,
                marginLeft: Dimensions.get("window").width <= 1200 ? 0 : 15,
              },
            ]}
          >
            <Text style={styles.titleTextStyle}>Extras</Text>
            <Text style={[styles.textStyle, { textAlign: "center" }]}>
              Find extra boosts, merch, widgets, and limited edition items
              here!!
            </Text>
          </View>
        );
      case "Your profile":
        return (
          <View
            style={[
              {
                padding: 25,
                marginTop: Dimensions.get("window").width <= 1200 ? 0 : 15,
                marginLeft: Dimensions.get("window").width <= 1200 ? 0 : 15,
              },
            ]}
          >
            <Text style={styles.titleTextStyle}>Your profile</Text>
            <Text style={styles.textStyle}>
              Your profile is a personal safe space where you can check your
              analytics via earnings, edit settings and how you appear to the
              world wide web. It is a space to tell others what you are
              passionate about.
            </Text>
            <View>
              <View style={{ flexDirection: "row" }}>
                <Text style={styles.bulletStyle}>{"\u2022"} </Text>
                <Text style={styles.bulletTextStyle}>
                  Analytics: optimize content promotion and earnings
                </Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text style={styles.bulletStyle}>{"\u2022"} </Text>
                <Text style={[styles.bulletTextStyle, { paddingTop: 6 }]}>
                  {" "}
                  Settings: edit your subscription options, notifications, and
                  earn free membership and parks when you invite a friend to our
                  platform
                </Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text style={styles.bulletStyle}>{"\u2022"} </Text>
                <Text style={styles.bulletTextStyle}>
                  {" "}
                  Edit: Change your profile
                </Text>
              </View>
            </View>
            <Text style={styles.textStyle}>
              Each user has their own feed, where you can see their trending
              content, new content, and also any common posts that you have
              discussed with the user.
            </Text>
          </View>
        );
      case "Membership":
        return (
          <View
            style={[
              {
                padding: 25,
                marginTop: Dimensions.get("window").width <= 1200 ? 0 : 15,
                marginLeft: Dimensions.get("window").width <= 1200 ? 0 : 15,
              },
            ]}
          >
            <Text style={styles.titleTextStyle}>Membership</Text>
            <Text style={styles.textStyle}>
              Basic membership is free. However for the added premium features,
              the ability to fully monetize your earnings potential and for the
              hosting of your servers, we charge a base of $5.00 per month.
            </Text>
            <Text style={styles.textStyle}>
              Additional packages to earn or invest in content will be released
              on a rolling out and seasonal basis. Future packages may allow
              users to invest and generate revenue by not only contributing
              valuable content but also via liking. This is to build the
              community of #weclikd itself upon celebration of thoughtful
              holidays and the meaning of days marked within our time. ((More
              details apply.))
            </Text>

            <View>
              {/* <Text style={styles.textStyle}>Additional packages to earn or invest in content will be released on a rolling out and seasonal basis. Future packages may allow users to invest and generate revenue by not only contributing valuable content but also via liking. This is to build the community of #weclikd itself upon celebration of thoughtful holidays and the meaning of days marked within our time. ((More details apply.))</Text> */}
            </View>
            {/* <View>
            <Text style={[styles.textStyle, { paddingBottom: 10, marginTop: 10 }]}>Premium membership includes : </Text>

            <View>
              <View style={{ flexDirection: 'row', }}><Text style={styles.bulletStyle}>{'\u2022'}{' '}</Text><Text style={styles.bulletTextStyle}> private cliks and monetizing</Text></View>
              <View style={{ flexDirection: 'row', }}><Text style={styles.bulletStyle}>{'\u2022'}{' '}</Text><Text style={[styles.bulletTextStyle, { paddingTop: 6 }]}> editing posts within 5 minutes</Text></View>
              <View style={{ flexDirection: 'row', }}><Text style={styles.bulletStyle}>{'\u2022'}{' '}</Text><Text style={styles.bulletTextStyle}> tagging up to 10 users in posts </Text></View>
              <View style={{ flexDirection: 'row', }}><Text style={styles.bulletStyle}>{'\u2022'}{' '}</Text><Text style={styles.bulletTextStyle}> seeing who viewed your profile </Text></View>
              <View style={{ flexDirection: 'row', }}><Text style={styles.bulletStyle}>{'\u2022'}{' '}</Text><Text style={styles.bulletTextStyle}> index and personalize your feeds </Text></View>
            </View>
          </View> */}
          </View>
        );
      case "Basics":
        return (
          <View
            style={[
              {
                padding: 25,
                marginTop: Dimensions.get("window").width <= 1200 ? 0 : 15,
                marginLeft: Dimensions.get("window").width <= 1200 ? 0 : 15,
              },
            ]}
          >
            <Text style={styles.titleTextStyle}>Basics</Text>
            <Text style={styles.textStyle}>
              Home is where you can view the most popular trending feeds, newly
              indexed content, and your bookmarks that you liked, discussed, and
              indexed in.
            </Text>
            <Text style={styles.textStyle}>
              The + bar on the right hand corner by notifications is where you
              can index your own links.
            </Text>
            <Text style={styles.textStyle}>
              On the left hand side, you can explore new feeds and connect with
              other users.
            </Text>
          </View>
        );
      case "Values":
        return (
          <View
            style={[
              {
                padding: 25,
                marginTop: Dimensions.get("window").width <= 1200 ? 0 : 15,
                marginLeft: Dimensions.get("window").width <= 1200 ? 0 : 15,
              },
            ]}
          >
            <Text style={styles.titleTextStyle}>Values</Text>

            <Text style={styles.textStyle}>
              A Space for the Intellectually Curious. Weclikd facilitates
              discourse. We cogently discuss ideas, and do not attack people,
              cultures, or events.
            </Text>
            <Text style={styles.textStyle}>
              Comments must be thoughtful and original.
            </Text>
            <Text style={styles.textStyle}>
              Our core values are quality, authenticity, and integrity. We hold
              the right to not support discourse of certain topics.
            </Text>
            <Text style={styles.textStyle}>
              Violation of principles results in forfeiture of content,
              earnings, and accounts.
            </Text>
            <Text style={styles.textStyle}>
              Privacy and security are utmost important to us; we do not sell
              ads or personal info.
            </Text>
            <Text style={styles.textStyle}>We C. We Likd. #Weclikd.</Text>
            {/* <Text style={styles.textStyle}>Weclikd inc. is a platform to help you monetize and promote quality intellectual discourse across the web. We ascertain to help you discover novel ideas with our topics and quality content across the web. Our goal is to amplify your voice across the web and recapture the loss value of your time.</Text> */}

            {/* <View>
            <Text style={styles.textStyle}>Users on our platform can:</Text>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}><Text style={styles.bulletStyle}>{'\u2022'}{' '}</Text><Text style={styles.bulletTextStyle}> Endorse quality discussions with your subscription</Text></View>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}><Text style={styles.bulletStyle}>{'\u2022'}{' '}</Text><Text style={styles.bulletTextStyle}> Get visibility & traffic to your community and/or site</Text></View>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}><Text style={styles.bulletStyle}>{'\u2022'}{' '}</Text><Text style={styles.bulletTextStyle}> Monetize off your insightful comments</Text></View>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}><Text style={styles.bulletStyle}>{'\u2022'}{' '}</Text><Text style={styles.bulletTextStyle}> Most of all, have fun learning with other users</Text></View>
          </View> */}
          </View>
        );
      case "Topics & cliks":
        return (
          <View
            style={[
              {
                padding: 25,
                marginTop: Dimensions.get("window").width <= 1200 ? 0 : 15,
                marginLeft: Dimensions.get("window").width <= 1200 ? 0 : 15,
              },
            ]}
          >
            <Text style={styles.titleTextStyle}>Topics </Text>
            <Text style={styles.textStyle}>
              Topics relate to a specific subject. It is open to all - no
              community owns a specific topic. Topics are organized in a
              tree-like structure. Users with enough reputation can propose new
              topics and can also volunteer to moderate.
            </Text>
            <Text style={styles.titleTextStyle}>Cliks </Text>
            <Text style={styles.textStyle}>
              Cliks are groups of people with a common background or shared
              interest. There is an application process to create a clik to
              prevent hate groups and also to prevent similar cliks from being
              created. All cliks are invite only and they may require some
              minimum qualifications or degrees to join. While both topics and
              cliks can be followed by anyone, they serve different purposes.
              Anyone can follow and discuss posts in /astrophysics, but the clik
              #astrophysicsphd may have their own locked esoteric discussions
              about posts in /astrophysics or other related fields.{" "}
            </Text>
            <Text style={styles.textStyle}>
              You may click the “+” icon to find new topics, cliks, users, or
              feeds to follow. Unlike other platforms that boost content and ads
              based off of popularity and who you follow, we improve quality
              experience through relevant topics, groups, and content.{" "}
            </Text>
            <Text style={styles.textStyle}>
              Topics and cliks are groups of individuals coming together for a
              mutual interest. Some examples for cliks which are private groups
              are #uclajudo or #uclaphysics whereas topics are more generalized
              such as /computer-science.{" "}
            </Text>
            <Text style={styles.textStyle}>
              Root topics are gold topics that all other topics, cliks, and
              feeds must tie into. Topics are denoted in green font. Cliks are
              denoted in blue font. It is our philosophical nature at #weclikd
              to be curious: to question what is true grue. To create a topic or
              clik, there is a quick approval process via submission of a
              document. Topics or cliks that are not yet approved do not appear
              on indexed content.
            </Text>
          </View>
        );
      case "Our Motto":
        return (
          <View
            style={[
              {
                padding: 25,
                marginTop: Dimensions.get("window").width <= 1200 ? 0 : 15,
                marginLeft: Dimensions.get("window").width <= 1200 ? 0 : 15,
              },
            ]}
          >
            <Text style={styles.titleTextStyle}>Our Motto</Text>
            <Text style={[styles.textStyle, { textAlign: "center" }]}>
              It is our philosophical nature at #weclikd to be curious: to
              question what is true grue. We C. We Likd. Cool, #weclikd.
            </Text>
          </View>
        );
      case "About":
        return (
          <View
            style={[
              {
                padding: 25,
                marginTop: Dimensions.get("window").width <= 1200 ? 0 : 15,
                marginLeft: Dimensions.get("window").width <= 1200 ? 0 : 15,
              },
            ]}
          >
            <Text style={styles.titleTextStyle}>About</Text>
            <Text style={styles.textStyle}>
              Weclikd inc. is a platform to help you monetize and promote
              quality intellectual discourse across the web. We ascertain to
              help you discover novel ideas our quality content across the web.
              Our goal is to amplify your voice across the web and recapture the
              loss value of your time.
            </Text>
            <Text style={styles.textStyle}>Users on our platform can: </Text>
            <View>
              <View style={{ flexDirection: "row" }}>
                <Text style={styles.bulletStyle}>{"\u2022"} </Text>
                <Text style={styles.bulletTextStyle}>
                  {" "}
                  Endorse quality discussions with your subscription
                </Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text style={styles.bulletStyle}>{"\u2022"} </Text>
                <Text style={[styles.bulletTextStyle, { paddingTop: 6 }]}>
                  {" "}
                  Get visibility & traffic to your community and/or site
                </Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text style={styles.bulletStyle}>{"\u2022"} </Text>
                <Text style={styles.bulletTextStyle}>
                  {" "}
                  Monetize off your insightful comments
                </Text>
              </View>
              <View style={{ flexDirection: "row" }}>
                <Text style={styles.bulletStyle}>{"\u2022"} </Text>
                <Text style={styles.bulletTextStyle}>
                  Most of all, have fun learning with other users
                </Text>
              </View>
            </View>
          </View>
        );
    }
  };

  render() {
    return (
      <View
        style={[
          Dimensions.get("window").width > 750
            ? ButtonStyle.threeColStruc
            : null,
          {
            width:
              Dimensions.get("window").width > 750
                ? Dimensions.get("window").width
                : "100%",
          },
        ]}
      >
        {Dimensions.get("window").width > 750 ? (
          <LeftPanel navigation={this.props.navigation} />
        ) : null}
        <View
          style={{
            // flex: 1,
            backgroundColor: ConstantColors.whiteBackground,
            width:
              this.props.windowSize.width > 1200 &&
              this.props.windowSize.width < 1600
                ? 900
                : this.props.windowSize.width > 1600
                ? 1200
                : this.props.windowSize.width <= 750
                ? this.props.windowSize.width
                : this.props.windowSize.width - 310,
            borderLeftWidth: 1,
            borderColor: ConstantColors.lightGray,
          }}
        >
          {this._renderItem(this.state.headingName)}
          <View
            style={[
              Dimensions.get("window").width <= 750
                ? null
                : HomeStyle.normalFeedStyleForDashboard,
              {
                marginTop: Dimensions.get("window").width <= 1200 ? 0 : 15,
                marginLeft: Dimensions.get("window").width <= 1200 ? 0 : 15,
                marginBottom: 40,
                alignItems: "normal",
              },
            ]}
          >
            <View
              style={{
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: Dimensions.get("window").width <= 750 ? "30%" : "33%",
                }}
              >
                <Hoverable>
                  {(isHovered) => (
                    <TouchableOpacity
                      style={{
                        flexDirection: "column",
                        // height: 200,
                      }}
                      onPress={() => {
                        this.setState({
                          headingName: "About",
                        });
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          height: 200,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                          }}
                        >
                          <Image
                            style={{
                              width: 60,
                              height: 60,
                            }}
                            source={require("../assets/image/logoCurrent.png")}
                          />
                        </View>
                        <Text
                          style={{
                            // textAlign: "center",
                            color:
                              isHovered == true
                                ? ConstantColors.blueColor
                                : "#000",
                            fontFamily: ConstantFontFamily.defaultFont,
                            marginTop: 10,
                            width: 60,
                          }}
                        >
                          About
                        </Text>
                      </View>
                    </TouchableOpacity>
                  )}
                </Hoverable>
              </View>

              {/* <View
                    style={{
                      width: Dimensions.get('window').width <= 750 ? "30%" : '33%',
                      // height: 200,

                    }}
                  >
                    <Hoverable>
                      {isHovered => (
                        <TouchableOpacity
                          style={
                            {
                              flexDirection: "column",
                              // height: 200,
                            }}
                          onPress={() => {
                            this.setState({
                              headingName: 'Values'
                            })
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              height: 200
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row"
                              }}
                            >
                              <Image
                                style={{
                                  width: 60,
                                  height: 60
                                }}
                                source={require("../assets/image/logoCurrent.png")}
                              />
                            </View>
                            <Text
                              style={{
                                // textAlign: "center",
                                color: isHovered == true ? ConstantColors.blueColor : "#000",
                                fontFamily:
                                  ConstantFontFamily.defaultFont,
                                marginTop: 10,
                                width: 60
                              }}
                            >
                              Values
                            </Text>
                          </View>
                        </TouchableOpacity>
                      )}
                    </Hoverable>
                  </View> */}

              <View
                style={{
                  width: Dimensions.get("window").width <= 750 ? "30%" : "33%",
                  height: 200,
                }}
              >
                <Hoverable>
                  {(isHovered) => (
                    <TouchableOpacity
                      style={{
                        flexDirection: "column",
                        height: 200,
                      }}
                      onPress={() => {
                        this.setState({
                          headingName: "Basics",
                        });
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          height: 200,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                          }}
                        >
                          <Image
                            style={{
                              width: 60,
                              height: 60,
                            }}
                            source={require("../assets/image/books_1f4da.png")}
                          />
                        </View>
                        <Text
                          style={{
                            textAlign: "center",
                            color:
                              isHovered == true
                                ? ConstantColors.blueColor
                                : "#000",
                            fontFamily: ConstantFontFamily.defaultFont,
                            marginTop: 10,
                          }}
                        >
                          Basics
                        </Text>
                      </View>
                    </TouchableOpacity>
                  )}
                </Hoverable>
              </View>

              <View
                style={{
                  width: Dimensions.get("window").width <= 750 ? "30%" : "33%",
                  // height: 200,
                }}
              >
                <Hoverable>
                  {(isHovered) => (
                    <TouchableOpacity
                      style={{
                        flexDirection: "column",
                        // height: 200,
                      }}
                      onPress={() => {
                        this.setState({
                          headingName: "Membership",
                        });
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          height: 200,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                          }}
                        >
                          <Image
                            style={{
                              width: 60,
                              height: 60,
                            }}
                            source={require("..//assets/image/sparkles_2728.png")}
                          />
                        </View>
                        <Text
                          style={{
                            textAlign: "center",
                            color:
                              isHovered == true
                                ? ConstantColors.blueColor
                                : "#000",
                            fontFamily: ConstantFontFamily.defaultFont,
                            marginTop: 10,
                          }}
                        >
                          Membership
                        </Text>
                      </View>
                    </TouchableOpacity>
                  )}
                </Hoverable>
              </View>
              {/* <View
                    style={{
                      width: Dimensions.get('window').width <= 750 ? "30%" : '33%',
                      height: 200,

                    }}
                  >
                    <Hoverable>
                      {isHovered => (
                        <TouchableOpacity
                          style={
                            {
                              flexDirection: "column",
                              height: 200,
                            }}
                          onPress={() => {
                            this.setState({
                              headingName: 'Profile'
                            })
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              height: 200
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row"
                              }}
                            >
                              <Image
                                style={{
                                  width: 60,
                                  height: 60
                                }}
                                source={require("../assets/image/smiling-face-with-sunglasses_1f60e.png")}
                              />
                            </View>
                            <Text
                              style={{
                                textAlign: "center",
                                color: isHovered == true ? ConstantColors.blueColor : "#000",
                                fontFamily:
                                  ConstantFontFamily.defaultFont,
                                marginTop: 10
                              }}
                            >
                              Profile
                            </Text>
                          </View>
                        </TouchableOpacity>
                      )}
                    </Hoverable>
                  </View> */}
              {/* <View
                    style={{
                      width: Dimensions.get('window').width <= 750 ? "30%" : '33%',
                      // height: 200,

                    }}
                  >
                    <Hoverable>
                      {isHovered => (
                        <TouchableOpacity
                          style={
                            {
                              flexDirection: "column",
                              // height: 200,
                            }}
                          onPress={() => {
                            this.setState({
                              headingName: 'Topics & cliks'
                            })
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              height: 200
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row"
                              }}
                            >
                              <Image
                                style={{
                                  width: 60,
                                  height: 60
                                }}
                                source={require("../assets/image/rocket_1f680.png")}
                              />
                            </View>
                            <Text
                              style={{
                                textAlign: "center",
                                color: isHovered == true ? ConstantColors.blueColor : "#000",
                                fontFamily:
                                  ConstantFontFamily.defaultFont,
                                marginTop: 10
                              }}
                            >
                              Topics & Cliks
                            </Text>
                          </View>
                        </TouchableOpacity>
                      )}
                    </Hoverable>
                  </View> */}
            </View>

            <View
              style={{
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: Dimensions.get("window").width <= 750 ? "30%" : "33%",
                  // height: 200,
                }}
              >
                <Hoverable>
                  {(isHovered) => (
                    <TouchableOpacity
                      style={{
                        flexDirection: "column",
                        // height: 200,
                      }}
                      onPress={() => {
                        this.setState({
                          headingName: "Monetization",
                        });
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          height: 200,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                          }}
                        >
                          <Image
                            style={{
                              width: 60,
                              height: 60,
                            }}
                            source={require("../assets/image/bank.png")}
                            // currency-exchange_1f4b1
                          />
                        </View>
                        <Text
                          style={{
                            textAlign: "center",
                            color:
                              isHovered == true
                                ? ConstantColors.blueColor
                                : "#000",
                            fontFamily: ConstantFontFamily.defaultFont,
                            marginTop: 10,
                          }}
                        >
                          Monetization
                        </Text>
                      </View>
                    </TouchableOpacity>
                  )}
                </Hoverable>
              </View>
              <View
                style={{
                  width: Dimensions.get("window").width <= 750 ? "30%" : "33%",
                  // height: 200,
                }}
              >
                <Hoverable>
                  {(isHovered) => (
                    <TouchableOpacity
                      style={{
                        flexDirection: "column",
                        // height: 200,
                      }}
                      onPress={() => {
                        this.setState({
                          headingName: "Contact",
                        });
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          height: 200,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                          }}
                        >
                          <Image
                            style={{
                              width: 60,
                              height: 60,
                            }}
                            source={require("../assets/image/speech-balloon_1f4ac.png")}
                          />
                        </View>
                        <Text
                          style={{
                            textAlign: "center",
                            color:
                              isHovered == true
                                ? ConstantColors.blueColor
                                : "#000",
                            fontFamily: ConstantFontFamily.defaultFont,
                            marginTop: 10,
                          }}
                        >
                          Contact
                        </Text>
                      </View>
                    </TouchableOpacity>
                  )}
                </Hoverable>
              </View>
              <View
                style={{
                  width: Dimensions.get("window").width <= 750 ? "30%" : "33%",
                  // height: 200,
                }}
              >
                <Hoverable>
                  {(isHovered) => (
                    <TouchableOpacity
                      style={{
                        flexDirection: "column",
                        // height: 200,
                      }}
                      onPress={() => {
                        this.setState({
                          headingName: "Beta testers",
                        });
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          height: 200,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                          }}
                        >
                          <Image
                            style={{
                              width: 60,
                              height: 60,
                            }}
                            source={require("../assets/image/crossed-swords_2694.png")}
                          />
                        </View>
                        <Text
                          style={{
                            textAlign: "center",
                            color:
                              isHovered == true
                                ? ConstantColors.blueColor
                                : "#000",
                            fontFamily: ConstantFontFamily.defaultFont,
                            marginTop: 10,
                          }}
                        >
                          Beta testers
                        </Text>
                      </View>
                    </TouchableOpacity>
                  )}
                </Hoverable>
              </View>
            </View>
            <View
              style={{
                flexDirection: "row",
              }}
            >
              <View
                style={{
                  width: Dimensions.get("window").width <= 750 ? "30%" : "33%",
                  // height: 200,
                }}
              >
                <Hoverable>
                  {(isHovered) => (
                    <TouchableOpacity
                      style={{
                        flexDirection: "column",
                        height: 200,
                      }}
                      onPress={() => {
                        this.setState({
                          headingName: "Extras",
                        });
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          height: 200,
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                          }}
                        >
                          <Image
                            style={{
                              width: 60,
                              height: 60,
                            }}
                            source={require("../assets/image/shopping-trolley_1f6d2.png")}
                          />
                        </View>
                        <Text
                          style={{
                            textAlign: "center",
                            color:
                              isHovered == true
                                ? ConstantColors.blueColor
                                : "#000",
                            fontFamily: ConstantFontFamily.defaultFont,
                            marginTop: 10,
                          }}
                        >
                          Extras
                        </Text>
                      </View>
                    </TouchableOpacity>
                  )}
                </Hoverable>
              </View>
              {/* <View
                    style={{
                      width: Dimensions.get('window').width <= 750 ? "30%" : '33%',
                      // height: 200,

                    }}
                  >
                    <Hoverable>
                      {isHovered => (
                        <TouchableOpacity
                          style={
                            {
                              flexDirection: "column",
                              height: 200,
                            }}
                          onPress={() => {
                            this.setState({
                              headingName: 'Our Motto'
                            })
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              height: 200
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row"
                              }}
                            >
                              <Image
                                style={{
                                  width: 60,
                                  height: 60
                                }}
                                source={require("../assets/image/logoCurrent.png")}
                              />
                            </View>
                            <Text
                              style={{
                                textAlign: "center",
                                color: isHovered == true ? ConstantColors.blueColor : "#000",
                                fontFamily:
                                  ConstantFontFamily.defaultFont,
                                marginTop: 10
                              }}
                            >
                              Our Motto
                            </Text>
                          </View>
                        </TouchableOpacity>
                      )}
                    </Hoverable>
                  </View>
                  <View
                    style={{
                      width: Dimensions.get('window').width <= 750 ? "30%" : '33%',
                      // height: 200,

                    }}
                  >
                    <Hoverable>
                      {isHovered => (
                        <TouchableOpacity
                          style={
                            {
                              flexDirection: "column",
                              height: 200,
                            }}
                          onPress={() => {
                            this.setState({
                              headingName: 'Your profile'
                            })
                          }}
                        >
                          <View
                            style={{
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              height: 200
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row"
                              }}
                            >
                              <Image
                                style={{
                                  width: 60,
                                  height: 60
                                }}
                                source={require("../assets/image/logoCurrent.png")}
                              />
                            </View>
                            <Text
                              style={{
                                textAlign: "center",
                                color: isHovered == true ? ConstantColors.blueColor : "#000",
                                fontFamily:
                                  ConstantFontFamily.defaultFont,
                                marginTop: 10
                              }}
                            >
                              Your profile
                            </Text>
                          </View>
                        </TouchableOpacity>
                      )}
                    </Hoverable>
                  </View> */}
            </View>
          </View>
        </View>
        {/* <Text style={{ fontSize: 13, color: 'grey', fontFamily: ConstantFontFamily.defaultFont, textAlign: "right", marginRight: 30, marginBottom: 10, marginTop: 10 }}>Build  number : {Bnumber}</Text> */}
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  windowSize: state.windowResizeReducer.windowResize,
});

const mapDispatchToProps = (dispatch) => ({
  searchOpenBarStatus: (payload) =>
    dispatch({ type: "SEARCHBAR_STATUS", payload }),
  screen: (payload) => dispatch(screen(payload)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(FAQScreen);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: ConstantColors.customeBackgroundColor,
    width: "100%",
  },
  textStyle: {
    fontSize: 15,
    paddingBottom: 10,
    fontFamily: ConstantFontFamily.defaultFont,
  },
  titleTextStyle: {
    fontSize: 25,
    fontWeight: "bold",
    paddingBottom: 15,
    textAlign: "center",
    fontFamily: ConstantFontFamily.defaultFont,
  },
  bulletStyle: {
    fontSize: 25,
    fontFamily: ConstantFontFamily.defaultFont,
  },
  bulletTextStyle: {
    fontSize: 15,
    fontFamily: ConstantFontFamily.defaultFont,
    alignSelf: "center",
    textAlign: "left",
    flex: 1,
  },
});
