import React, { Component } from "react";
import ConstantFontFamily from "../constants/FontFamily";
import {
  Dimensions,
  Platform,
  TextInput,
  TouchableOpacity,
  View,
} from "react-native";
import { Icon } from "react-native-elements";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import {
  SearchClikMutation,
  SearchFeedMutation,
  SearchTopicMutation,
  SearchUserMutation,
} from "../graphqlSchema/SearchMutation";
import {
  SearchClikVariables,
  SearchFeedVariables,
  SearchTopicVariables,
  SearchUserVariables,
} from "../graphqlSchema/SearchVariables";
import appolloClient from "../client";
import { AddTerm, TermConcat } from "../reducers/AddtermReducer";
import {
  IsCompleted,
  searchOpenBarStatus,
  setDisplayType,
  setSearchQuery,
} from "../reducers/AdminTrueFalseReducer";
import { setTermWeb, setTerm } from "../reducers/ScreenLoadingReducer";

class SearchInputWeb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      term: "",
      keyEvent: "",
      boxColor: "#333333",
      isFocus: false,
      clearInput: false,
      updatedData: [],
      drop: false,
      clikOutsude: true,
    };
  }

  
  componentDidMount() {
    if (
      Dimensions.get("window").width >= 750 &&
      Dimensions.get("window").width < 1200
    ) {
      this.setState({ isFocus: true });
    }

    this.props.setTerm(this.state.term);
    this.props.setKeyEvent(this.state.keyEvent);
    this.props.setDisplayType("");
    this.props.TermConcat([]);

    this.props.IsCompleted(this.state.drop);
    if (Dimensions.get("window").width <= 750) {
      this.props.nameInput.current.focus();
    }

    if (this.props.term && this.props.term.length > 0) {
      this.props.TermConcat(this.props.termArray.termArray);
    } else {
      this.props.TermConcat([]);
    }
    if (this.props.termWeb && this.props.termWeb.length > 0) {
      this.props.TermConcat(this.props.termArray.termArray);
    } else {
      this.props.TermConcat([]);
    }

    this.setState({ updatedArray: this.props.termArray.termArray });
    this.props.TermConcat([]);
    if (this.props.term.length > 0) {
      this.setState({
        term: this.props.termArray.termArray.toString().replace(",", ""),
      });
    } else {
      this.props.TermConcat([]);
    }
    if (
      window.location.href.includes("search") ||
      this.props.screenName == "search"
    ) {
      this.props.nameInput.current.focus();
    }
    if (this.props.screenName == "home") {
      this.setState({
        boxColor: "#333333",
      });
    }
  }

  componentDidUpdate(prevProps) {

    if (Dimensions.get("window").width > 1200) {
      if ((prevProps.term != this.props.term) != "") {
        this.setState({ term: this.props.term, isFocus: true });
        if (this.props.term && this.props.term.length > 0) {
        } else {
          this.props.TermConcat([]);
        }
    
      }
    }
  }

  handleEvent = async (e) => {
    let queryTerm = "";
    var code = e.keyCode || e.which;
    this.props.setKeyEvent(code);
    this.setState({ keyEvent: code });
    this.props.setDisplayType(this.props.displayType);
    var NewTerm = this.state.term.split(" ");
    var t = [];

    for (var i = 0; i <= NewTerm.length; i++) {
      if (NewTerm[i] != null && NewTerm[i].startsWith("@") == true) {
        t.push(NewTerm[i].replace("@", ""));
      } else if (NewTerm[i] != null && NewTerm[i].startsWith("#") == true) {
        t.push(NewTerm[i].replace("#", ""));
      } else if (NewTerm[i] != null && NewTerm[i].startsWith("/") == true) {
        t.push(NewTerm[i].replace("/", ""));
      } else if (NewTerm[i] != null && NewTerm[i].startsWith("%") == true) {
        t.push(NewTerm[i].replace("%", ""));
      } else if (NewTerm[i] == null) {
        t = t;
      } else {
        t.push(NewTerm[i]);
      }
    }

    if (e.nativeEvent.key == "Enter") {
      this.props.setSearchQuery(!this.props.searchQuery);
      console.log("enteredd");
      queryTerm = t.join("");
      this.props.navigation.navigate("search");
    }
   
  };

  getRespectiveSuggestions = async (value) => {
    
    var TotalTerm = value.split(" ");

    for (var i = 0; i < TotalTerm.length; i++) {
      if (TotalTerm[i].length > 0) {
        if (
          TotalTerm[i].slice(-5).startsWith("#") == true ||
          this.state.index == 1
        ) {
          await this.setState({
            TopicSuggestList: [],
            UserSuggestList: [],
            FeedSuggestList: [],
            drop: false,
          });
          await this.props.IsCompleted(this.state.drop);
          await this.getClikSuggestion(TotalTerm[i].replace("#", ""));
        } else if (
          TotalTerm[i].startsWith("/") == true ||
          this.state.index == 2
        ) {
          await this.setState({
            ClikSuggestList: [],
            UserSuggestList: [],
            FeedSuggestList: [],
            drop: false,
          });
          await this.props.IsCompleted(this.state.drop);
          await this.getTopicSuggestion(TotalTerm[i].replace("/", ""));
        } else if (
          TotalTerm[i].slice(-5).startsWith("@") == true ||
          this.state.index == 3
        ) {
          await this.setState({
            TopicSuggestList: [],
            ClikSuggestList: [],
            FeedSuggestList: [],
            drop: false,
          });
          await this.props.IsCompleted(this.state.drop);
          await this.getUserSuggestion(TotalTerm[i].replace("@", ""));
        } else if (
          TotalTerm[i].slice(-5).startsWith("%") == true ||
          this.state.index == 4
        ) {
          await this.setState({
            TopicSuggestList: [],
            ClikSuggestList: [],
            UserSuggestList: [],
            drop: false,
          });
          await this.props.IsCompleted(this.state.drop);
          await this.getFeedSuggestion(TotalTerm[i].replace("%", ""));
        } else {
          this.props.IsCompleted(true);
          await this.setState({
            TopicSuggestList: [],
            ClikSuggestList: [],
            UserSuggestList: [],
            FeedSuggestList: [],
            drop: true,
          });
          await this.props.IsCompleted(this.state.drop);
        }
      } else {
        await this.setState({ drop: true });
        await this.props.IsCompleted(this.state.drop);
      }
    }
  };
  getFeedSuggestion = (value) => {
    SearchFeedVariables.variables.prefix = value.replace("-", " ");
    appolloClient
      .query({
        query: SearchFeedMutation,
        ...SearchFeedVariables,
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        let data = res.data.search.feeds;
        this.setState({
          FeedSuggestList: data,
          TopicSuggestList: [],
          ClikSuggestList: [],
          UserSuggestList: [],
        });
      });
  };

  getTopicSuggestion = (value) => {
    SearchTopicVariables.variables.prefix = value;
    appolloClient
      .query({
        query: SearchTopicMutation,
        ...SearchTopicVariables,
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        let data = res.data.search.topics;
        this.setState({
          TopicSuggestList: data,
          ClikSuggestList: [],
          UserSuggestList: [],
          FeedSuggestList: [],
        });
      });
  };

  getClikSuggestion = (value) => {
    SearchClikVariables.variables.prefix = value;
    appolloClient
      .query({
        query: SearchClikMutation,
        ...SearchClikVariables,
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        let data = res.data.search.cliks;
        this.setState({
          ClikSuggestList: data,
          TopicSuggestList: [],
          UserSuggestList: [],
          FeedSuggestList: [],
        });
      });
  };

  getUserSuggestion = (value) => {
    SearchUserVariables.variables.prefix = value;
    appolloClient
      .query({
        query: SearchUserMutation,
        ...SearchUserVariables,
        fetchPolicy: "no-cache",
      })
      .then((res) => {
        let data = res.data.search.users;
        this.setState({
          UserSuggestList: data,
          TopicSuggestList: [],
          ClikSuggestList: [],
          FeedSuggestList: [],
        });
      });
  };

  handleArray = async (value) => {
   
    if (Platform.OS == "web") {
      let updated = [];
      var updatedArray = value.split(" ");
      for (var i = 0; i < updatedArray.length; i++) {
        if (updatedArray[i].startsWith("@") == true || this.state.index == 3) {
          this.getRespectiveSuggestions(updatedArray[i]);
          this.props.setTerm(value);
          this.props.setTermWeb(value);
          if (updatedArray[i].length > 1) {
           
          }
          if (updatedArray[i].length > 4) {
            updated.push(updatedArray[i]);
          }
        } else if (
          updatedArray[i].startsWith("#") == true ||
          this.state.index == 1
        ) {
          this.getRespectiveSuggestions(updatedArray[i]);
          this.props.setTerm(value);
          this.props.setTermWeb(value);
          if (updatedArray[i].length > 1) {
           
          }
          if (updatedArray[i].length > 4) {
            updated.push(updatedArray[i]);
          }
        } else if (
          updatedArray[i].startsWith("/") == true ||
          this.state.index == 2
        ) {
          this.getRespectiveSuggestions(updatedArray[i]);
          this.props.setTerm(value);
          this.props.setTermWeb(value);
          if (updatedArray[i].length > 1) {
           
          }
          if (updatedArray[i].length > 4) {
            updated.push(updatedArray[i]);
          }
        } else if (
          updatedArray[i].startsWith("%") == true ||
          this.state.index == 4
        ) {
          this.getRespectiveSuggestions(updatedArray[i]);
          this.props.setTerm(value);
          this.props.setTermWeb(value);
          if (updatedArray[i].length > 1) {
            
          }
          if (updatedArray[i].length > 4) {
            updated.push(updatedArray[i]);
          }
        } else {
          this.getRespectiveSuggestions(updatedArray[i]);
          updated.push(updatedArray[i]);
          this.props.TermConcat(updated);
          this.props.setTerm(value);
          this.props.setTermWeb(value);
        }
      }
    } else {
      await this.props.setTerm(value);
      await this.props.setTermWeb(value);
    }
  };

  render() {
    
    return (
      <View
        style={{
          width: "100%",
        }}
      >
        <View
          style={{
            backgroundColor: "#000",
            width: "100%",
            height: 50,
            borderRadius: 6,
            borderWidth: 0,
            flexDirection: "row",
            borderColor: this.state.isFocus ? "#388bfd" : "#444D56",
            justifyContent: "space-between",
            alignItems: "center",
            transition: "width 200ms",
            elevation: 5,
          }}
        >
          
          <View
            style={{
              flexDirection: "row",
              width: "100%",
              alignItems: "center",
              borderRadius: 6,
              backgroundColor: this.state.term
                ? "#fff"
                : this.state.isFocus
                  ? "#fff"
                  : "#333333",
            }}
          >
            <Icon
              iconStyle={{ paddingLeft: 5, width: 20 }}
              name="search"
              color={this.state.boxColor == "#fff" ? "#333333" : "#717071"}
              size={18}
              type="font-awesome"
            />
            <TextInput
              style={{
                width: "90%",
                paddingLeft: 15,
                height: 34,
                borderColor: "#388bfd",
                borderWidth: 0,
                borderRadius: 6,
                backgroundColor: this.props.boxColor,
                outline: "none",
                color: "#000",
                fontSize: 14,
                fontFamily: ConstantFontFamily.defaultFont,
              }}
              ref={this.props.nameInput}
              placeholder="Search Weclikd"
              placeholderTextColor={
                this.state.boxColor == "#fff" ? "#d3d3d3" : "#fff"
              }
              onChangeText={(term) => {

                this.setState({ term: term }, () => {
                  this.props.setTerm(this.state.term);
                 
                });
              }}
              value={
                this.props.term
              }
              onKeyPress={this.handleEvent}
  
              onSubmitEditing={async () => {
               
                await this.props.setTermWeb(this.state.term);
                await this.props.setTerm(this.state.term);
                this.props.navigation.navigate("search");
              }}
              onFocus={() => {
                this.setState({
                  boxColor: "#fff",
                  isFocus: true,
                });
              }}
              onBlur={() => {
                this.setState({
                  boxColor: "#fff",
                  isFocus: false,
                });
              }}
              
              returnKeyType={"search"}
            
            />
        
            {this.state.isFocus ? (
              <TouchableOpacity
                onPress={async () => {
                  if (Dimensions.get("window").width > 1200) {
                    this.setState({ isFocus: false });
                  }
                  this.props.searchOpenBarStatus(false);
                  this.props.setTerm("");
                  this.props.setTermWeb("");
                  
                }}
                style={{ width: 30 }}
              >
                <Icon name="times" size={20} type="font-awesome" />
              </TouchableOpacity>
            ) : null}
          </View>
        </View>
        
      </View>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    termArray: state.TermArrayReducer,
    addTermArray: state.TermArrayReducer,
    term: state.ScreenLoadingReducer.setTerm,
    getsearchBarStatus: state.AdminTrueFalseReducer.searchBarOpenStatus,
    getUserFollowTopicList: state.LoginUserDetailsReducer.userFollowTopicsList
      ? state.LoginUserDetailsReducer.userFollowTopicsList
      : [],
    getUserFollowCliksList: state.LoginUserDetailsReducer.userFollowCliksList
      ? state.LoginUserDetailsReducer.userFollowCliksList
      : [],
    getUserFollowUserList: state.LoginUserDetailsReducer.userFollowUserList
      ? state.LoginUserDetailsReducer.userFollowUserList
      : [],
    getUserFollowFeedList: state.LoginUserDetailsReducer.userFollowFeedList
      ? state.LoginUserDetailsReducer.userFollowFeedList
      : [],
    getDisplayType: state.AdminTrueFalseReducer.setDisplayType,
    searchQuery: state.AdminTrueFalseReducer.searchQuery,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setKeyEvent: (payload) => dispatch({ type: "SET_KEY_EVENT_WEB", payload }),
  setDisplayType: (payload) => dispatch(setDisplayType(payload)),
  searchOpenBarStatus: (payload) => dispatch(searchOpenBarStatus(payload)),
  IsCompleted: (payload) => dispatch(IsCompleted(payload)),
  AddTerm: (payload) => dispatch(AddTerm(payload)),
  TermConcat: (payload) => dispatch(TermConcat(payload)),
  setTerm: (payload) => dispatch(setTerm(payload)),
  setTermWeb: (payload) => dispatch(setTermWeb(payload)),
  setSearchQuery: (payload) => dispatch(setSearchQuery(payload)),
  setSearchBarStatus: (payload) =>
    dispatch({ type: "SEARCH_BAR_STATUS", payload }),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SearchInputWeb
);
