import { createSlice } from '@reduxjs/toolkit';




const initialState = {
  showComment: false,
  showmoreId: "",
  show: false,
  id: "",
  minimizeId: "",
  nestedId: "",
  showMoreId: ""
};

const ShowCommentReducer = createSlice({
  name: 'ShowCommentReducer',
  initialState,
  reducers: {
    showComment(state, action) {
      return { ...state, showComment: action.payload }
    },
    showbox(state, action) {
      return { ...state, id: action.payload.id, show: action.payload.show, showmoreId: action.payload.showmoreId, }
    },

    MinimizeId(state, action) {
      return { ...state, minimizeId: action.payload, }
    },
    showMoreNestedId(state, action) {
      return { ...state, nestedId: action.payload, }
    },
    showMoreId(state, action) {
      return { ...state, showMoreId: action.payload, }
    },



  },




})

export const { showComment, showbox, showMoreId, showMoreNestedId, MinimizeId } = ShowCommentReducer.actions
export default ShowCommentReducer.reducer


