import { takeEvery, put } from 'redux-saga/effects';
import { LOGINMODALACTION, } from "../constants/Action";
import { loginModalSuccess, loginModalFailure } from '../reducers/LoginModalReducer';

export function* LoginModalMiddleware({ payload }) {
    if (payload == true) {
        yield put(loginModalSuccess(payload));
    } else {
        yield put(loginModalFailure(payload));
    }
}

export default function* LoginModalMiddlewareWatcher() {
    yield takeEvery(LOGINMODALACTION, LoginModalMiddleware);
}