import React, { lazy, Suspense, Component } from "react";
// import { retry } from "../library/Helper";
import {
  Dimensions,
  Image,
  Platform,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { Icon } from "react-native-elements";
import { Hoverable } from "react-native-web-hooks";
import { connect } from "react-redux";
import { compose } from "react-recompose";
// import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";
// import { setUSERNAMEMODALACTION } from "../actionCreator/UsernameModalAction";
// import ConstantFontFamily from "../constants/FontFamily";
import ConstantColors from "../constants/Colors";
// import NavigationService from "../library/NavigationService";
import { heightPercentageToDP as hp } from "react-native-responsive-screen";
import OurMission from "./OurMission";
// import { Button } from "react-native-elements";
// import { getCurrentUserProfileDetails } from "../actionCreator/UserProfileDetailsAction";
// import BottomPart from "./BottomPart";

import DrawerScreens from "./DrawerScreens";
import TrendingTabs from "./TrendingTabs";

import BottomPart from "./BottomPart";
import { leftPanelModalFunc } from "../reducers/AdminTrueFalseReducer";

// const OurMission = lazy(() =>
//   retry(() => import("./OurMission"))
// );

// const TrendingTabs = lazy(() => import("./TrendingTabs")
// );

// import TrendingTabs from './TrendingTabs';
import TrendingClicks from "./TrendingClicks";
import ButtonStyle from "../constants/ButtonStyle";

class LeftPanel extends Component {
  totalHeigth = 0;
  constructor(props) {
    super(props);
    this.state = {
      getHeight: 100,
      randomItemEvent: 0,
      primaryIndex: 0,
      isSearched: false,
      searchedWord: "",
      isActive: false,
      searchedFollowText: "",
    };
  }

  calHeightLeftPannelMian = (height) => {
    if (height > 0 && height != null) {
      this.totalHeigth = height;
    }
  };

  activeIndexAction = (index) => {
    this.setState({
      primaryIndex: index,
      searchedWord: "",
    });
  };

  activeTrendingIndexAction = (index) => {
    this.setState({
      searchedFollowText: "",
    });
  };

  searchParaChanged = () => {
    this.setState({ searchedWord: "" });
  };

  searchTextField = (value) => {
    this.setState({ searchedWord: value });
  };

  componentDidMount = async () => {
    var newWidth = window.innerWidth;
    var newHeight = window.innerHeight;
    // this.props.setBrowserHeightWidth({ heigh: newHeight, width: newWidth });
    if (Platform.OS == "web") {
      window.addEventListener("resize", (event) => {
        var newWidth = window.innerWidth;
        var newHeight = window.innerHeight;
        // this.props.setBrowserHeightWidth({ heigh: newHeight, width: newWidth });
      });
    }
  };

  loginHandle = () => {
    // this.props.leftPanelModalFunc(false)
    // this.props.setLoginModalStatus(true);
  };

  inviteSignHandle = async () => {
    // await this.props.leftPanelModalFunc(false)
    // await this.props.setInviteUserDetail({
    //   clikName: "",
    //   inviteKey: "",
    //   userName: ""
    // });
    // await this.props.setUsernameModalStatus(true);
  };

  goToUserProfile = async (username) => {
    // console.log(username, 'username');
    // await this.props.userId({
    //   username: username,
    //   type: "feed"
    // });
    // await NavigationService.navigate("profile", {
    //   username: username,
    //   type: "feed",
    //   postId: ' '
    // });
    // this.props.leftPanelModalFunc(false)
  };

  goToPage = (pageName) => {
    // this.props.leftPanelModalFunc(false)
    // if (pageName == 'terms') {
    //   NavigationService.navigate("termsandconditions");
    // } else if (pageName == 'privacy') {
    //   NavigationService.navigate("privacyPolicy");
    // } else if (pageName == 'faq') {
    //   NavigationService.navigate("faq");
    // }
  };
  closeLeftPanel = () => {
    this.props.leftPanelModalFunc(false);
    // this.props.navigation.navigate("home")
  };
  render() {
    // console.log(this.props, 'this.props.navigationRef');
    return (
      <View
        style={[
          {
            flexDirection: "column",
            width: Dimensions.get("window").width <= 750 ? "100%" : 310,
            backgroundColor:
              Dimensions.get("window").width >= 1200
                ? ConstantColors.greyBackground
                : "#fff",
            marginBottom: 0,
            backgroundColor: "#fff",
            height:
              Dimensions.get("window").width <= 750
                ? Dimensions.get("window").height
                : Dimensions.get("window").height - 50,
          },
        ]}
      >
        {Dimensions.get("window").width <= 750 && (
          <View>
            {/* <Hoverable>
              {isHovered => (
                <TouchableOpacity
                  style={{
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    // flex: 1,
                    // position: "absolute",
                    zIndex: 999999,
                    // left: 0,
                    // top: 0,
                    width:'10%',
                    backgroundColor:'red'
                  }}
                  onPress={() => this.props.leftPanelModalFunc(false)}
                >
                  <Icon
                    color={isHovered == true ? "rgba(256,256,256,0.4)" : "#000"}
                    iconStyle={{
                      color: "#fff",
                      justifyContent: "center",
                      alignItems: "center"
                    }}
                    // reverse
                    name="close"
                    type="antdesign"
                    size={22}
                  />
                </TouchableOpacity>
              )}
            </Hoverable> */}

            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                backgroundColor: "#000",
                alignItems: "center",
                height: 55,
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                width: "100%",
              }}
            >
              <View
                style={{
                  alignItems: "flex-start",
                  width: "90%",
                }}
              >
                <Image
                  source={require("../assets/image/logolastOne.png")}
                  style={{
                    justifyContent: "flex-start",
                    height: 35,
                    width: 35,
                    // borderRadius: 8
                  }}
                  resizeMode={"contain"}
                />
              </View>
              <TouchableOpacity
                style={{
                  justifyContent: "center",
                  // zIndex: 99999,
                  marginTop: 10,
                  // flex: 1,
                  // position: "absolute",
                  // left: 0,
                  // top: 0,
                  width: "10%",
                  height: 50,
                }}
                onPress={this.closeLeftPanel}
              >
                <Icon
                  color={"#000"}
                  iconStyle={{
                    color: "#fff",
                    // justifyContent: 'flex-start',
                    // alignItems: "center"
                  }}
                  // reverse
                  name="close"
                  type="antdesign"
                  size={25}
                />
              </TouchableOpacity>
            </View>
          </View>
        )}

        <View
          style={{
            height:
              Platform.OS == "web" && Dimensions.get("window").width <= 750
                ? Dimensions.get("window").height - 100
                : Platform.OS == "web" && Dimensions.get("window").width > 750
                ? Dimensions.get("window").height - 90
                : hp("80%"),
          }}
        >
          {this.props.loginStatus == 1 ? (
            <Suspense fallback={null}>
              <DrawerScreens
                calHeightLeftPannelSend={this.calHeightLeftPannelMian}
                navigationRef={
                  Dimensions.get("window").width > 750
                    ? this.props.navigation
                    : this.props.navigation.current
                }
                activeIndex={this.activeIndexAction}
                searchParam={this.searchParaChanged}
                searchedWord={this.state.searchedWord}
              />
            </Suspense>
          ) : (
            <TrendingTabs
              navigation={this.props.navigation}
              randomItemEvent={this.state.randomItemEvent}
              searchedFollowText={this.state.searchedWord}
              activeIndex={this.activeTrendingIndexAction}
              onchangetext={(query) => this.searchTextField(query)}
              onpress={() => this.setState({ searchedFollowText: "" })}
            />
          )}
        </View>
        <BottomPart navigation={this.props.navigation} />
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  loginStatus: state.UserReducer.loginStatus,
  profileData: state.LoginUserDetailsReducer.userLoginDetails,
  // windowSize: state.windowResizeReducer.windowResize
});

const mapDispatchToProps = (dispatch) => ({
  leftPanelModalFunc: (payload) => dispatch(leftPanelModalFunc(payload)),

  // userId: payload => dispatch(getCurrentUserProfileDetails(payload)),
  // setLoginModalStatus: payload => dispatch(setLOGINMODALACTION(payload)),
  // setBrowserHeightWidth: payload =>
  //   dispatch({ type: "SET_BROWSER_HEIGHT_WIDTH", payload }),
  // leftPanelModalFunc: payload => dispatch({ type: 'LEFT_PANEL_OPEN', payload }),
  // setInviteUserDetail: payload =>
  //   dispatch({ type: "SET_INVITE_USER_DETAIL", payload }),
  // setUsernameModalStatus: payload => dispatch(setUSERNAMEMODALACTION(payload)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(LeftPanel);
