// import { fromJS } from "immutable";
// import { POSTDETAILS_SUCCESS, POSTDETAILS_FAILURE } from "../constants/Action";
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  PostDetails: null,
  setComment: false,
  getNotificationId: ''
};

const PostDetailsReducer = createSlice({
  name: 'PostDetails',
  initialState,
  reducers: {
    setPostDetailSuccess(state, action) {
      // console.log(action,'actionnn');
      state.PostDetails = action.payload.payload.data
    },
    setPostDetailFailure(state, action) {
      return state
    }
  }
})

export const { setPostDetailSuccess,setPostDetailFailure } = PostDetailsReducer.actions
export default PostDetailsReducer.reducer
