import { call, put, takeEvery } from "redux-saga/effects";
import applloClient from "../client";
import {
  FEED_PROFILE_DETAILS,
  // FEED_PROFILE_DETAILS_FAILURE,
  // FEED_PROFILE_DETAILS_SUCCESS
} from "../constants/Action";
import { ExternalFeedProfileQuery } from "../graphqlSchema/FeedMutation";
// import NavigationService from "../library/NavigationService";
// import { capitalizeFirstLetter } from "../library/Helper";
// import {
//   AsyncStorage
// } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
// import { get404 } from '../reducers/action/Completed'
import { feedDetailsSuccess, feedDetailsfailure } from '../reducers/FeedProfileReducer';


const getResponse = async req => {
  return await applloClient
    .query({
      query: ExternalFeedProfileQuery,
      variables: {
        id:req.id
      },
      fetchPolicy: "no-cache"
    })
    .then(res => res);
};

export function* FeedProfileMiddleware({ payload }) {
  try {
    const response = yield call(getResponse, payload);

    if (response.data.node == null) {
      yield put(feedDetailsfailure({}));
      // NavigationService.navigate("404");
      // yield put(get404(true))
    } else {
      if (response.data.node.id == "ExternalFeed:None") {
        yield put(feedDetailsfailure({}));
        // NavigationService.navigate("404");
        // yield put(get404(true))

      } else {
        if (payload.type == "feed" || payload.type == "wiki") {
          yield put(feedDetailsSuccess(response));

          AsyncStorage.setItem(
            'FeedTitle',
            JSON.stringify({ type: payload.type, FeedTitle: payload.id })
          );
          let postId;
          yield AsyncStorage.getItem('PostId').then(houses => { postId = JSON.parse(houses); });
          // console.log(postId,payload.id, '-------------------------------->');
          // NavigationService.navigate("cliksprofileId", {
          //   id: payload.id,
          //   type: payload.type,
          //   postId:postId
          // });
          // NavigationService.navigate("feedprofile", {
          //   title: response.data.node.name,
          //   type: !payload.type ? "feed" : payload.type,
          //   postId: ' '
          //   //,
          //   //postId:postId
          // });

          // NavigationService.navigate("feedprofile", {
          //   title: response.data.external_feed.name,
          //   type: !payload.type ? "feed" : payload.type
          // });
        } else {
          yield put(feedDetailsfailure({}));
          // NavigationService.navigate("404");
          // yield put(get404(true))

        }
      }
    }

  } catch (err) {
    yield put(feedDetailsfailure({err:err.message}));
    console.log(err);
  }
}

export default function* FeedProfileMiddlewareWatcher() {
  yield takeEvery(FEED_PROFILE_DETAILS, FeedProfileMiddleware);
}
