// import { openBrowserAsync } from "expo-web-browser";
import React, { lazy, Suspense, Component, createRef } from "react";

import {
  Animated,
  Dimensions,
  Image,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  ScrollView,
} from "react-native";
import { Icon } from "react-native-elements";
import {
  Menu,
  MenuOption,
  MenuOptions,
  MenuTrigger,
} from "react-native-popup-menu";
import { heightPercentageToDP as hp } from "react-native-responsive-screen";
// import { TabBar, TabView } from "react-native-tab-view";
import { Hoverable } from "react-native-web-hooks";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import { editFeed } from "../actionCreator/FeedEditAction";
import { getFeedProfileDetails } from "../actionCreator/FeedProfileAction";
import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";
import { saveUserLoginDaitails } from "../actionCreator/UserAction";
import appolloClient from "../client";
import ShadowSkeleton from "../components/ShadowSkeleton";
// import ShadowSkeletonForProfile from "../components/ShadowSkeletonForProfile";
import ConstantFontFamily from "../constants/FontFamily";
import {
  DeleteExternalFeedMutation,
  DeleteContentMutation,
} from "../graphqlSchema/FeedMutation";
import { ExternalFeedFollowingMutation } from "../graphqlSchema/UserMutation";
// import { DeleteExternalFeedVariables } from "../graphqlSchema/LikeContentVariables";
import ButtonStyle from "../constants/ButtonStyle";
// import this.props.navigation from "../library/this.props.navigation";
import Colors from "../constants/Colors";
// import CreateCommentCard from "../components/CreateCommentCard"
// import { showDiscussion, screen } from '../reducers/action/Completed';
import Overlay from "react-native-modal-overlay";
import HeaderRightDashboard from "../components/HeaderRightDashboard";
// import { retry } from "../library/Helper";
import { showDiscussionReducer } from "../reducers/AdminTrueFalseReducer";
import { ReportMutation } from "../graphqlSchema/GraphQLFragment";

// import DiscussionHomeFeed from '../components/DiscussionHomeFeed';

// const ExternalFeedStar = lazy(() => import("../components/ExternalFeedStar"))

import ExternalFeedStar from "../components/ExternalFeedStar";
import { feedIconUpload } from "../reducers/AdminTrueFalseReducer";

// const NewHomeFeed = lazy(() => import("../components/NewHomeFeed"))
// const TrendingHomeFeed = lazy(() => import("../components/TrendingHomeFeed"))
// const DiscussionHomeFeed = lazy(() => import("../components/DiscussionHomeFeed"))

import NewHomeFeed from "../components/NewHomeFeed";
import TrendingHomeFeed from "../components/TrendingHomeFeed";
import DiscussionHomeFeed from "../components/DiscussionHomeFeed";
import Header from "../components/Header";

// import SEOMetaData from "../components/SEOMetaData";

import CommentDetailScreen from "./CommentDetailScreen";

// const BottomScreen = lazy(() => import("../components/BottomScreen"))

// const HeaderRight = lazy(() => import("../components/HeaderRight"))

// const LeftPanel = lazy(() =>
//   retry(() => import("../components/LeftPanel"))
// );

import LeftPanel from "../components/LeftPanel";
import ProifileCardStyle from "../components/Style/ProifileCardStyle";
import FeedCommentStyle from "./Style/FeedCommentStyle";
import { screen } from "../reducers/ScreenNameReducer";

let lastTap = null;

function truncate(input) {
  if (input?.length > 10) {
    return input.substring(0, 10) + "...";
  }
  return input;
}

class ExternalFeedScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabPost: false,
      commentDelay: false,
      cliksselectItem: "",
      // cliksselectItem: this.props.navigation
      //   .getParam("type", "NO-ID")
      //   .toUpperCase(),
      showIcon: "#fff",
      routes: [
        { key: "first", title: "New", icon: "clock-o", type: "font-awesome" },
        {
          key: "second",
          title: "Trending",
          icon: "fire",
          type: "simple-line-icon",
        },
        {
          key: "third",
          title: "Bookmarks",
          icon: "bookmark",
          type: "font-awesome",
        },
      ],
      index: 1,
      id: "",
      ViewMode: "Default",
      scrollY: 0,
      ProfileHeight: 0,
      feedY: 0,
      menu: false,
      opentool: false,
      focused: "Trending",
    };
    this.flatListRefNew = createRef();
    this.flatListRefDiscussion = createRef();
    this.flatListRefTrending = createRef();
    this.feedProfilescrollview = createRef();
  }

  doScroll = (value, name) => {
    if (name == "new") {
      this.flatListRefNew = value;
    } else if (name == "trending") {
      this.flatListRefTrending = value;
    } else if (name == "discussion") {
      this.flatListRefDiscussion = value;
    }
  };

  onLoadingComment = () => {
    this.setState({ commentDelay: true });
    setTimeout(() => {
      if (this.props.PostCommentDetails) {
        this.setState({ commentDelay: false });
      }
    }, 500);
  };

  changeTabStatus = () => {
    this.setState({ tabPost: false });
  };

  scrollFunc = () => {
    {
      this.flatListRefNew.current &&
        this.flatListRefNew.current.scrollToOffset({
          x: 0,
          y: 0,
          animated: true,
        }),
        this.flatListRefTrending.current &&
        this.flatListRefTrending.current.scrollToOffset({
          x: 0,
          y: 0,
          animated: true,
        }),
        this.flatListRefDiscussion.current &&
        this.flatListRefDiscussion.current.scrollToOffset({
          x: 0,
          y: 0,
          animated: true,
        });
    }
  };

  handleDoubleTap = () => {
    if (lastTap !== null) {
      this.scrollFunc();
      clearTimeout(lastTap);
      lastTap = null;
    } else {
      lastTap = setTimeout(() => {
        clearTimeout(lastTap);
        lastTap = null;
      }, 1000);
    }
    this.setState({ tabPost: true });
  };
  _renderLazyPlaceholder = ({ route }) => <ShadowSkeleton />;

  _handleIndexChange = (index) => {
    this.setState({ index });
    // this.props.setPostCommentReset({
    //   payload: [],
    //   postId: "",
    //   title: "",
    //   loading: true,
    // });
  };

  _renderTabBar = (props) =>
    Dimensions.get("window").width >= 750 && (
      <View>
        <View
          style={[
            ButtonStyle.TabbarBorderStyleForDashboard,
            {
              flexDirection: "row",
              height: 55,
              backgroundColor:
                Dimensions.get("window").width <= 750 ? "#000" : "#fff",
              alignItems: "center",
              paddingHorizontal: 10,
              paddingVertical: 10,
            },
          ]}
        >
          <TabBar
            onTabPress={() => this.handleDoubleTap()}
            {...props}
            indicatorStyle={{
              backgroundColor: "transparent",
              height: 2,
              borderRadius: 6,
            }}
            style={{
              backgroundColor: "transparent",
              width: "100%",
              shadowColor: "transparent",
              height: 55,
              justifyContent: "center",
            }}
            labelStyle={{
              color: "#000",
              fontFamily: ConstantFontFamily.defaultFont,
            }}
            renderLabel={({ route, focused, color, isActive }) => (
              <Text
                style={[
                  ButtonStyle.tabbarTitleStyle,
                  {
                    color: focused ? "#000" : isActive ? "#000" : "#D3D3D3",
                  },
                ]}
              >
                {route.title}
              </Text>
            )}
          />
        </View>
      </View>
    );

  showIcon = (data) => {
    this.setState({
      showIcon: data,
    });
  };

  componentDidMount() {
    // this.props.showDiscussion(false)
    // this.props.searchOpenBarStatus(false);
    this.getStaticData();
    this.props.showDiscussion(false);

    // this.setState({ index: this.props.indexTab.index });
    // this.props.screen("feedScreen")
  }

  updateStar = () => {
    this.getStaticData();
    this.props.updateFeedStar(false);
  };

  getStaticData = async () => {
    // let itemId = this.props.feedDetails.id
    // this.setState({
    //   id: itemId,
    // });
    // if (itemId) {
    //   this.props.setFeedDetails({
    //     id: itemId,
    //     type: "feed"
    //   });
    // }
    // const index = this.props.getUserFollowFeedList.findIndex(
    //   (i) =>
    //     i.node.external_feed.name.toLowerCase() ==
    //     itemId.replace("%2F", "").toLowerCase().replace("ExternalFeed:", "")
    // );
    // if (index != -1) {
    //   if (
    //     this.props.getUserFollowFeedList[index].node.follow_type
    //     == "FAVORITE"
    //   ) {
    //     this.showIcon("#FADB4A");
    //   }
    //   if (
    //     this.props.getUserFollowFeedList[index].node.follow_type == "FOLLOW"
    //   ) {
    //     this.showIcon("#E1E1E1");
    //   }
    // } else {
    //   this.showIcon("#fff");
    // }
  };

  componentDidUpdate(prevProps) {
    // if (
    //   prevProps.navigation.getParam("title", "NO-ID").toUpperCase() !=
    //   this.props.feedDetails.toUpperCase()
    // ) {
    //   this.setState({
    //     scrollY: 0,
    //   });
    //   this.feedProfilescrollview.scrollTo({ x: 0, y: 0, animated: true });
    // }
    // if (prevProps.indexTab.index != this.props.indexTab.index) {
    //   this.setState({ index: this.props.indexTab.index });
    // }
  }

  loginHandle = () => {
    this.props.loginModalStatus(true);
  };

  openWindow = async (link) => {
    // await openBrowserAsync(link);
  };

  showAlert = () => {
    if (Platform.OS === "web") {
      console.log("ddd");
      var result = confirm(
        "Are you sure you want to delete " + this.props.feedDetails.name
      );
      if (result == true) {
        let feed_id = this.props.feedDetails.id;

        appolloClient
          .query({
            query: DeleteContentMutation,
            variables: {
              id: feed_id,
            },
            fetchPolicy: "no-cache",
          })
          .then((res) => {
            this.props.updateFeedStar(!this.props.getFeedIcon);
            let externalfollow = appolloClient
              .query({
                query: ExternalFeedFollowingMutation,
                variables: {
                  id: "User:CurrentUser",
                  first: 20,
                },
                fetchPolicy: "no-cache",
              })
              .then((e) => {
                return e.data.node.external_feeds_following.edges;
              });
            let userData = {
              data: this.props.profileData.data,
              externalfollow: externalfollow,
              clikfollow: this.props.getUserFollowCliksList,
              topicfollow: this.props.getUserFollowTopicList,
              userfollow: this.props.getUserFollowUserList,
            };
            this.props.saveLoginUser(userData);
            this.props.navigation.navigate("home");
          });
      } else {
      }
    } else {
      Alert.alert(
        "Are you sure you want to delete " +
        this.props.feedDetails.name[
        ({
          text: "NO",
          onPress: () => console.warn("NO Pressed"),
          style: "cancel",
        },
        {
          text: "YES",
          onPress: () => {
            let feed_id = this.props.feedDetails
              .getIn(["data", "node"])
              .get("id");
            appolloClient
              .query({
                query: DeleteContentMutation,
                variables: {
                  id: feed_id,
                },
                fetchPolicy: "no-cache",
              })
              .then(async (res) => {
                let externalfollow = await appolloClient
                  .query({
                    query: ExternalFeedFollowingMutation,
                    variables: {
                      id: "User:CurrentUser",
                      first: 20,
                    },
                    fetchPolicy: "no-cache",
                  })
                  .then((e) => {
                    return e.data.node.external_feeds_following.edges;
                  });
                let userData = {
                  data: this.props.profileData.data,
                  externalfollow: externalfollow,
                  clikfollow: this.props.getUserFollowCliksList,
                  topicfollow: this.props.getUserFollowTopicList,
                  userfollow: this.props.getUserFollowUserList,
                };
                await this.props.saveLoginUser(userData);
                this.props.navigation.navigate("home");
              });
          },
        })
        ]
      );
    }
  };

  listScroll = (value) => {
    this.setState({
      feedY: value,
    });
  };

  _renderScene = () => {
    switch (this.state.focused) {
      case "Discussion":
        return (
          <View>
            {this.props.loginStatus == 1 ? (
              <ScrollView
                style={{
                  height: hp("100%") - 50,
                  paddingBottom: 300,
                }}
                showsVerticalScrollIndicator={false}
                scrollEnabled={this.state.scrollY >= 100 ? true : false}
              >
                <DiscussionHomeFeed
                  navigation={this.props.navigation}
                  listType={"Feed"}
                  data={this.props.feedDetails}
                  ViewMode={this.state.ViewMode}
                  listScroll={this.listScroll}
                  onLoadingComment={this.onLoadingComment}
                  changeTabStatus={this.changeTabStatus}
                  tabPost={this.state.tabPost}
                  ActiveTab={this.state.routes[this.state.index].title}
                  doScroll={this.doScroll}
                />
              </ScrollView>
            ) : (
              <View>
                <View
                  style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Icon
                    color={"#000"}
                    iconStyle={{
                      color: "#fff",
                      justifyContent: "center",
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                    reverse
                    name="sticky-note"
                    type="font-awesome"
                    size={20}
                    containerStyle={{
                      alignSelf: "center",
                    }}
                  />
                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: ConstantFontFamily.defaultFont,
                      color: "#000",
                      alignSelf: "center",
                    }}
                  >
                    <Text
                      onPress={() => this.loginHandle()}
                      style={{
                        textDecorationLine: "underline",
                        fontFamily: ConstantFontFamily.defaultFont,
                      }}
                    >
                      Login
                    </Text>{" "}
                    to see bookmarked posts
                  </Text>
                </View>
              </View>
            )}
          </View>
        );
      case "New":
        return (
          <ScrollView
            style={{
              height: hp("100%") - 50,
              paddingBottom: 300,
            }}
            showsVerticalScrollIndicator={false}
            scrollEnabled={this.state.scrollY >= 100 ? true : false}
          >
            <NewHomeFeed
              navigation={this.props.navigation}
              listType={"Feed"}
              data={this.props.feedDetails.id}
              ViewMode={this.state.ViewMode}
              listScroll={this.listScroll}
              changeTabStatus={this.changeTabStatus}
              tabPost={this.state.tabPost}
              onLoadingComment={this.onLoadingComment}
              ActiveTab={this.state.routes[this.state.index].title}
              doScroll={this.doScroll}
            />
          </ScrollView>
        );
      case "Trending":
        return (
          <ScrollView
            style={{
              height: hp("100%") - 50,
              paddingBottom: 300,
            }}
            showsVerticalScrollIndicator={false}
            scrollEnabled={this.state.scrollY >= 200 ? true : false}
          >
            <TrendingHomeFeed
              navigation={this.props.navigation}
              listType={"Feed"}
              data={this.props.feedDetails.id}
              ViewMode={this.state.ViewMode}
              listScroll={this.listScroll}
              changeTabStatus={this.changeTabStatus}
              tabPost={this.state.tabPost}
              onLoadingComment={this.onLoadingComment}
              ActiveTab={this.state.routes[this.state.index].title}
              doScroll={this.doScroll}
            />
          </ScrollView>
        );
      default:
        return (
          <ScrollView
            style={{
              height: hp("100%") - 50,
              paddingBottom: 300,
            }}
            showsVerticalScrollIndicator={false}
            scrollEnabled={this.state.scrollY >= 100 ? true : false}
          >
            <NewHomeFeed
              navigation={this.props.navigation}
              listType={"Feed"}
              data={this.props.feedDetails.id}
              ViewMode={this.state.ViewMode}
              changeTabStatus={this.changeTabStatus}
              tabPost={this.state.tabPost}
              onLoadingComment={this.onLoadingComment}
              listScroll={this.listScroll}
              ActiveTab={this.state.routes[this.state.index].title}
              doScroll={this.doScroll}
            />
          </ScrollView>
        );
    }
  };

  renderTabViewForMobile = () => {
    if (this.props.getTabView == "Bookmarks") {
      return (
        <View>
          {this.props.loginStatus == 1 ? (
            <DiscussionHomeFeed
              navigation={this.props.navigation}
              listType={"Feed"}
              data={this.props.feedDetails}
              ViewMode={this.state.ViewMode}
              listScroll={this.listScroll}
              ActiveTab={this.state.routes[this.state.index].title}
              doScroll={this.doScroll}
            />
          ) : (
            <View>
              <View
                style={{
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Icon
                  color={"#000"}
                  iconStyle={{
                    color: "#fff",
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                  }}
                  reverse
                  name="sticky-note"
                  type="font-awesome"
                  size={20}
                  containerStyle={{
                    alignSelf: "center",
                  }}
                />
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: ConstantFontFamily.defaultFont,
                    color: "#000",
                    alignSelf: "center",
                  }}
                >
                  <Text
                    onPress={() => this.loginHandle()}
                    style={{
                      textDecorationLine: "underline",
                      fontFamily: ConstantFontFamily.defaultFont,
                    }}
                  >
                    Login
                  </Text>
                  to see bookmarked posts
                </Text>
              </View>
            </View>
          )}
        </View>
      );
    } else if (this.props.getTabView == "New") {
      return (
        <NewHomeFeed
          navigation={this.props.navigation}
          listType={"Feed"}
          data={this.props.feedDetails.id}
          ViewMode={this.state.ViewMode}
          listScroll={this.listScroll}
          ActiveTab={""}
          doScroll={this.doScroll}
        />
      );
    } else if (this.props.getTabView == "Trending") {
      return (
        <View>
          <TrendingHomeFeed
            navigation={this.props.navigation}
            listType={"Feed"}
            data={this.props.feedDetails.id}
            ViewMode={this.state.ViewMode}
            listScroll={this.listScroll}
            ActiveTab={""}
            doScroll={this.doScroll}
          />
        </View>
      );
    } else {
      return (
        <View>
          <TrendingHomeFeed
            navigation={this.props.navigation}
            listType={"Feed"}
            data={this.props.feedDetails.id}
            ViewMode={this.state.ViewMode}
            listScroll={this.listScroll}
            ActiveTab={""}
            doScroll={this.doScroll}
          />
        </View>
      );
    }
  };

  onClose = () => {
    this.setState({ opentool: false });
  };

  reportFeed = (feed_id) => {

    appolloClient
      .query({
        query: ReportMutation,
        variables: {
          id: feed_id,
        },
        fetchPolicy: "no-cache",
      }).then(e => {
        console.log(e, 'feed report success');

      }).catch(error => {
        console.log(error, 'errr');

      })
  }
  render() {
    // console.log(this.props.feedDetails,'feedDetails');
    return (
      <View style={styles.container}>
        {/* {Platform.OS == "web" && (


          <SEOMetaData
            title={
              this.props.feedDetails.getIn(["data", "node"])
                ? this.props.feedDetails
                  .getIn(["data", "node"])
                  .get("name")
                : ""
            }
            description={
              this.props.feedDetails.getIn(["data", "node"])
                ? this.props.feedDetails
                  .getIn(["data", "node"])
                  .get("description")
                : ""
            }
            image={
              this.props.feedDetails.getIn(["data", "node"])
                ? this.props.feedDetails
                  .getIn(["data", "node"])
                  .get("icon_url")
                : ""
            }
          />

        )} */}
        {/* {Dimensions.get("window").width <= 750 && (
          <Header
            title="External Feed"
            navigation={this.props.navigation}
            // leftPanelModalFunc={this.props.leftPanelModalFunc}
          />
        )} */}
        <ScrollView
          contentContainerStyle={
            Dimensions.get("window").width > 750
              ? ButtonStyle.threeColStruc
              : null
          }
          scrollEnabled={false}
        >
          {Dimensions.get("window").width > 750 ? (
            <LeftPanel navigation={this.props.navigation} />
          ) : null}
          <ScrollView
            contentContainerStyle={{
              height: Dimensions.get("window").height - 50,
            }}
            ref={(ref) => {
              this.feedProfilescrollview = ref;
            }}
            showsVerticalScrollIndicator={false}
            onScroll={(event) => {
              this.setState({
                scrollY: event.nativeEvent.contentOffset.y,
              });
            }}
            scrollEventThrottle={16}
          >
            {Dimensions.get("window").width >= 1200 ||
              this.props.show == false ? (
              <TouchableOpacity
                onPress={() => this.setState({ opentool: true })}
                // disabled={
                //   this.props.loginStatus == 1 && Platform.OS != "web"
                //     ? false
                //     : true
                // }
                style={[
                  ProifileCardStyle.mainCardContainer,
                  Dimensions.get("window").width > 750
                    ? null
                    : ButtonStyle.profileShadowStyle,
                ]}
              >
                <View
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    marginVertical: 15,
                  }}
                >
                  <View style={{ marginLeft: 15 }}>
                    <Image
                      source={
                        this.props.feedDetails &&
                          this.props.feedDetails.profile_pic != null
                          ? {
                            uri: this.props.feedDetails.profile_pic,
                          }
                          : require("../assets/image/logolastOne.png")
                      }
                      style={{
                        height: Dimensions.get("window").width <= 750 ? 60 : 80,
                        width: Dimensions.get("window").width <= 750 ? 60 : 80,
                        padding: 0,
                        margin: 0,
                        borderRadius: 10,
                        borderWidth: 1,
                        borderColor: "#fff",
                      }}
                    />
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      marginLeft: 15,
                      width:
                        // Dimensions.get("window").width <= 750 &&
                        // this.props.feedDetails.profile_pic != null
                        //   ? "78%"
                        //   :
                        Dimensions.get("window").width <= 750
                          ? // this.props.feedDetails.profile_pic == null
                          "70%"
                          : Dimensions.get("window").width > 750 &&
                            Dimensions.get("window").width < 1200
                            ? "80%"
                            : Dimensions.get("window").width > 1600
                              ? "90%"
                              : "87%",
                      alignItems: "flex-start",
                    }}
                  >
                    <View style={{ width: "85%" }}>
                      <View
                        style={[
                          ButtonStyle.clikNameBackgroundStyle,
                          {
                            marginTop: 0,
                            marginLeft: 0,
                            padding: 0,
                          },
                        ]}
                      >
                        <Text
                          style={[
                            ButtonStyle.clikNameTitleStyle,
                            {
                              width: "100%",
                            },
                          ]}
                        >
                          {this.props.feedDetails &&
                            this.props.feedDetails.name}
                        </Text>
                      </View>
                      <View
                        style={{
                          marginBottom: 10,
                          // marginTop: 10,
                          flexDirection:
                            Dimensions.get("window").width <= 750
                              ? "column"
                              : "row",
                          // alignItems: 'flex-end',
                        }}
                      >
                        <View
                          style={{
                            flexDirection: "row",
                            alignSelf:
                              Dimensions.get("window").width <= 750
                                ? "flex-start"
                                : "auto",
                            marginBottom:
                              Dimensions.get("window").width <= 750 ? 5 : 0,
                          }}
                        >
                          <Hoverable>
                            {(isHovered) => (
                              <TouchableOpacity
                                onPress={() => props.showMembers()}
                              >
                                <Text
                                  style={{
                                    color:
                                      isHovered == true
                                        ? Colors.blueColor
                                        : "grey",
                                    fontSize: 12,
                                    fontFamily: ConstantFontFamily.defaultFont,
                                  }}
                                >
                                  {this.props.feedDetails &&
                                    this.props.feedDetails.followers != null
                                    ? this.props.feedDetails.followers
                                    : 0}{" "}
                                  Followers{" "}
                                </Text>
                              </TouchableOpacity>
                            )}
                          </Hoverable>
                        </View>
                        <View
                          style={{
                            flexDirection: "row",
                            alignSelf: "flex-start",
                          }}
                        >
                          <View
                            style={{
                              marginLeft:
                                Dimensions.get("window").width <= 750 ? 0 : 5,
                            }}
                          >
                            <Icon
                              name="link"
                              type="font-awesome"
                              color="grey"
                              size={14}
                            />
                          </View>
                          <Hoverable>
                            {(isHovered) => (
                              <TouchableOpacity
                                onPress={() =>
                                  this.openWindow(
                                    this.props.feedDetails &&
                                    this.props.feedDetails.website
                                  )
                                }
                              >
                                <Text
                                  numberOfLines={1}
                                  style={{
                                    marginLeft: 5,
                                    color:
                                      isHovered == true
                                        ? Colors.blueColor
                                        : "grey",
                                    fontSize: 12,
                                    fontFamily: ConstantFontFamily.defaultFont,
                                    textDecorationLine:
                                      isHovered == true ? "underline" : "none",
                                  }}
                                >
                                  {" "}
                                  {this.props.feedDetails &&
                                    Dimensions.get("window").width <= 750 &&
                                    truncate(
                                      this.props.feedDetails?.website
                                        ?.replace("http://", "")
                                        ?.replace("https://", "")
                                        ?.replace("www.", "")
                                        ?.split(/[/?#]/)[0]
                                    )}
                                  {this.props.feedDetails &&
                                    Dimensions.get("window").width >= 750 &&
                                    this.props.feedDetails.website
                                      ?.replace("http://", "")
                                      ?.replace("https://", "")
                                      ?.replace("www.", "")
                                      ?.split(/[/?#]/)[0]}
                                </Text>
                              </TouchableOpacity>
                            )}
                          </Hoverable>
                        </View>
                      </View>
                    </View>

                    <View
                      style={{
                        width: "15%",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                      }}
                    >
                      {this.props.loginStatus == 1 &&
                        Dimensions.get("window").width > 750 && (
                          <TouchableOpacity
                            onMouseEnter={() => this.setState({ menu: true })}
                            onMouseLeave={() => this.setState({ menu: false })}
                          >
                            <Menu>
                              <MenuTrigger>
                                <Image
                                  source={require("../assets/image/menu.png")}
                                  style={{
                                    height: 16,
                                    width: 16,
                                    marginTop: 5,
                                    marginRight: 15,
                                    transform: [{ rotate: "90deg" }],
                                  }}
                                />
                              </MenuTrigger>
                              <MenuOptions
                                optionsContainerStyle={{
                                  borderRadius: 6,
                                  borderWidth: 1,
                                  borderColor: "#e1e1e1",
                                  shadowColor: "transparent",
                                }}
                                customStyles={{
                                  optionsContainer: {
                                    minHeight: 25,
                                    width: 150,
                                    marginTop: Platform.OS != "web" ? -20 : 20,
                                  },
                                }}
                              >
                                <MenuOption
                                  onSelect={() => { this.reportFeed(this.props.feedDetails.id) }}
                                >
                                  <Hoverable>
                                    {(isHovered) => (
                                      <Text
                                        style={{
                                          textAlign: "center",
                                          color:
                                            isHovered == true
                                              ? Colors.blueColor
                                              : "#000",
                                          fontFamily:
                                            ConstantFontFamily.defaultFont,
                                        }}
                                      >
                                        Report
                                      </Text>
                                    )}
                                  </Hoverable>
                                </MenuOption>
                                {this.props.isAdmin == true && (
                                  <MenuOption
                                    onSelect={async () => {
                                      // await this.props.showFeedDetails({
                                      //   name:
                                      //     this.props.feedDetails &&
                                      //     this.props.feedDetails.name,
                                      //   feed_id:
                                      //     this.props.feedDetails &&
                                      //     this.props.feedDetails
                                      //       .id,
                                      //   website:
                                      //     this.props.feedDetails &&
                                      //     this.props.feedDetails
                                      //       .website,
                                      //   base_topic:
                                      //     this.props.feedDetails &&
                                      //     this.props.feedDetails
                                      //       .topic,
                                      //   icon_url:
                                      //     this.props.feedDetails &&
                                      //     this.props.feedDetails
                                      //       .profile_pic,
                                      //   feedurl:
                                      //     this.props.feedDetails &&
                                      //     this.props.rss,
                                      // });
                                      this.props.navigation.navigate(
                                        "editFeed"
                                      );
                                    }}
                                  >
                                    <Hoverable>
                                      {(isHovered) => (
                                        <Text
                                          style={{
                                            textAlign: "center",
                                            color:
                                              isHovered == true
                                                ? Colors.blueColor
                                                : "#000",
                                            fontFamily:
                                              ConstantFontFamily.defaultFont,
                                          }}
                                        >
                                          Edit
                                        </Text>
                                      )}
                                    </Hoverable>
                                  </MenuOption>
                                )}
                                {/* {this.props.isAdmin == true && ( */}
                                <MenuOption onSelect={() => this.showAlert()}>
                                  <Hoverable>
                                    {(isHovered) => (
                                      <Text
                                        style={{
                                          textAlign: "center",
                                          color:
                                            isHovered == true
                                              ? Colors.blueColor
                                              : "#000",
                                          fontFamily:
                                            ConstantFontFamily.defaultFont,
                                        }}
                                      >
                                        Delete
                                      </Text>
                                    )}
                                  </Hoverable>
                                </MenuOption>
                                {/* )} */}
                              </MenuOptions>
                            </Menu>
                          </TouchableOpacity>
                        )}

                      <ExternalFeedStar
                        FeedName={
                          this.props.feedDetails && this.props.feedDetails.id
                        }
                        FeedId={
                          this.props.feedDetails && this.props.feedDetails.id
                        }
                        ContainerStyle={{}}
                        ImageStyle={{
                          height: 20,
                          width: 20,
                          alignSelf: "center",
                          marginLeft: 15,
                        }}
                        updateStar={this.updateStar}
                      />
                    </View>
                  </View>
                </View>
              </TouchableOpacity>
            ) : null}
            {/* {this.state.cliksselectItem == "FEED" && ( */}
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                height: "100%",
                backgroundColor: "#fff",
              }}
            >
              {Dimensions.get("window").width <= 750 ? (
                <View
                  style={{
                    flex: 1,
                    width: "100%",
                    height: "100%",
                  }}
                >
                  {this.renderTabViewForMobile()}
                </View>
              ) : (
                !this.props.show && (
                  <View
                    style={[
                      ButtonStyle.DashboardTabViewStyle,
                      {
                        width:
                          Dimensions.get("window").width > 1200 &&
                            Dimensions.get("window").width < 1600
                            ? 450
                            : Dimensions.get("window").width > 1600
                              ? 600
                              : Dimensions.get("window").width - 310,
                        backgroundColor: Colors.whiteBackground,
                      },
                    ]}
                  >
                    <View style={ButtonStyle.TabbarBorderStyleForDashboard}>
                      {["Trending", "New", "Discussion"].map((e) => {
                        return (
                          <TouchableOpacity
                            style={{
                              backgroundColor: "transparent",
                            }}
                            onPress={() => {
                              this.setState({
                                focused: e,
                              });
                            }}
                          >
                            <Text
                              style={[
                                ButtonStyle.tabbarTitleStyle,
                                {
                                  color:
                                    this.state.focused == e
                                      ? Colors.blueColor
                                      : "#D3D3D3",
                                  // color: "#D3D3D3",
                                  flexWrap: "wrap",
                                  flex: 1,
                                  width: "100%",
                                  fontWeight:
                                    this.state.focused == e ? "bold" : "100",
                                  // fontWeight: '100'
                                },
                              ]}
                            >
                              {e}
                            </Text>
                          </TouchableOpacity>
                        );
                      })}
                    </View>
                    {this._renderScene()}
                    {/* <TabView
                      swipeEnabled={false}
                      lazy
                      navigationState={this.state}
                      renderScene={this._renderScene}
                      renderLazyPlaceholder={this._renderLazyPlaceholder}
                      renderTabBar={this._renderTabBar}
                      onIndexChange={this._handleIndexChange}
                    /> */}
                  </View>
                )
              )}
              {this.props.windowSize.width > 1200 || this.props.show ? (
                <View
                  style={[
                    FeedCommentStyle.commentContainer,
                    {
                      width:
                        this.props.windowSize.width > 1200 &&
                          this.props.windowSize.width < 1600
                          ? 450
                          : this.props.windowSize.width > 1600
                            ? 600
                            : this.props.windowSize.width - 310,
                    },
                  ]}
                >
                  <View style={{ width: "92%", marginLeft: 20 }}>
                    {/* {console.log(this.props.show, this.props.windowSize.width, 'this.props.show')} */}

                    {this.props.windowSize.width < 1200 && this.props.show ? (
                      <View
                        style={[
                          ButtonStyle.TabbarBorderStyleForDashboard,
                          {
                            height: 55,
                            alignItems: "center",
                            paddingHorizontal: 10,
                            paddingVertical: 10,
                            marginLeft: 0,
                            width: "100%",
                            justifyContent: this.props.show
                              ? "flex-start"
                              : "center",
                            alignItems: "center",
                            flexDirection: "row",
                            backgroundColor: "#fff",
                            marginBottom: 10,
                            borderBottomWidth: 1,

                            // borderTopWidth: 2,
                          },
                        ]}
                      >
                        {this.props.show == true ? (
                          <TouchableOpacity
                            style={[
                              ButtonStyle.headerBackStyle,
                              { width: "20%" },
                            ]}
                            onPress={() => this.props.showDiscussion(false)}
                          >
                            <Icon
                              color={"#000"}
                              name="angle-left"
                              type="font-awesome"
                              size={40}
                            />
                          </TouchableOpacity>
                        ) : null}
                        <Text
                          style={[
                            ButtonStyle.tabbarTitleStyle,
                            {
                              width: this.props.show ? "55%" : "100%",
                              textAlign: "center",
                            },
                          ]}
                        >
                          {" "}
                          Discussion
                        </Text>
                      </View>
                    ) : null}

                    <CommentDetailScreen
                      type="external"
                      navigation={this.props.navigation}
                      postId={
                        this.props.PostDetails && this.props.PostDetails.node.id
                      }
                      listScroll={this.listScroll}
                      ProfileHeight={this.state.ProfileHeight}
                      commentDelay={this.state.commentDelay}
                      scrollY={this.state.scrollY}
                      ActiveTab={this.state.routes[this.state.index].title}
                    />
                  </View>
                </View>
              ) : null}
            </View>
            {/* )} */}
          </ScrollView>
        </ScrollView>

        {this.state.opentool ? (
          <Overlay
            animationType="fadeInUp"
            visible={this.state.opentool}
            onClose={this.onClose}
            closeOnTouchOutside
            children={
              <View
                style={{
                  width: "100%",
                  backgroundColor: "#fff",
                  borderRadius: 8,
                }}
              >
                <View style={{ padding: 20 }}>
                  <TouchableOpacity>
                    <TouchableOpacity onPress={() => reportFeed(this.props.feedDetails.id)}>
                      <Hoverable>
                        {(isHovered) => (
                          <Text
                            style={{
                              textAlign: "center",
                              color:
                                isHovered == true ? Colors.blueColor : "#000",
                              fontFamily: ConstantFontFamily.defaultFont,
                              fontSize: 20,
                            }}
                          >
                            Report
                          </Text>
                        )}
                      </Hoverable>
                    </TouchableOpacity>
                    {this.props.isAdmin == true && (
                      <TouchableOpacity
                        onPress={() => {
                          this.props.navigation.navigate("editFeed");
                          this.setState({ opentool: false });
                        }}
                      // onSelect={async () => {
                      //   await this.props.showFeedDetails({
                      //     name:
                      //       this.props.feedDetails &&
                      //       this.props.feedDetails.name,
                      //     feed_id:
                      //       this.props.feedDetails &&
                      //       this.props.feedDetails.id,
                      //     website:
                      //       this.props.feedDetails &&
                      //       this.props.feedDetails.website,
                      //     base_topic:
                      //       this.props.feedDetails &&
                      //       this.props.feedDetails.topic,
                      //     icon_url:
                      //       this.props.feedDetails &&
                      //       this.props.feedDetails.profile_pic,
                      //     feedurl:
                      //       this.props.feedDetails &&
                      //       this.props.feedDetails.rss,
                      //   });
                      //   // this.props.navigation.navigate("editfeed");
                      // }}
                      >
                        <Hoverable>
                          {(isHovered) => (
                            <Text
                              style={{
                                textAlign: "center",
                                color:
                                  isHovered == true ? Colors.blueColor : "#000",
                                fontFamily: ConstantFontFamily.defaultFont,
                                fontSize: 20,
                              }}
                            >
                              Edit
                            </Text>
                          )}
                        </Hoverable>
                      </TouchableOpacity>
                    )}
                    {this.props.isAdmin == true && (
                      <TouchableOpacity onPress={() => this.showAlert()}>
                        <Hoverable>
                          {(isHovered) => (
                            <Text
                              style={{
                                textAlign: "center",
                                color:
                                  isHovered == true ? Colors.blueColor : "#000",
                                fontFamily: ConstantFontFamily.defaultFont,
                                fontSize: 20,
                              }}
                            >
                              Delete
                            </Text>
                          )}
                        </Hoverable>
                      </TouchableOpacity>
                    )}
                  </TouchableOpacity>
                </View>
              </View>
            }
            childrenWrapperStyle={{
              padding: 0,
              margin: 0,
              borderRadius: 6,
            }}
          />
        ) : null}
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  loginStatus: state.UserReducer.loginStatus,
  feedDetails: state.FeedProfileReducer.getFeedProfileDetails,
  // getUserFollowFeedList: state.LoginUserDetailsReducer.userFollowFeedList
  //   ? state.LoginUserDetailsReducer.userFollowFeedList
  //   : [],
  isAdmin: state.AdminTrueFalseReducer.isAdmin,
  getFeedIcon: state.AdminTrueFalseReducer.feedIconUpload,
  profileData: state.LoginUserDetailsReducer.userLoginDetails,

  // getTabView: state.AdminTrueFalseReducer.get("tabType"),
  // getsearchBarStatus: state.AdminTrueFalseReducer.get("searchBarOpenStatus"),
  // indexTab: state.IndexTabReducer,
  // PostCommentDetails: state.PostCommentDetailsReducer.get("PostCommentDetails"),
  // getUserFollowCliksList: state.LoginUserDetailsReducer.userFollowCliksList
  //   ? state.LoginUserDetailsReducer.userFollowCliksList
  //   : [],
  // getUserFollowTopicList: state.LoginUserDetailsReducer.userFollowTopicsList
  //   ? state.LoginUserDetailsReducer.userFollowTopicsList
  //   : [],
  // getUserFollowUserList: state.LoginUserDetailsReducer.userFollowUserList
  //   ? state.LoginUserDetailsReducer.userFollowUserList
  //   : [],
  // profileData: state.LoginUserDetailsReducer.userLoginDetails,
  // PostId: state.PostCommentDetailsReducer.get("PostId"),
  // PostDetails: state.PostDetailsReducer.get("PostDetails"),
  show: state.AdminTrueFalseReducer.showDiscussions,
  windowSize: state.windowResizeReducer.windowResize,
});

const mapDispatchToProps = (dispatch) => ({
  saveLoginUser: (payload) => dispatch(saveUserLoginDaitails(payload)),
  loginModalStatus: (payload) => dispatch(setLOGINMODALACTION(payload)),
  setFeedDetails: (payload) => dispatch(getFeedProfileDetails(payload)),
  updateFeedStar: (payload) => dispatch(feedIconUpload(payload)),
  showDiscussion: (payload) => dispatch(showDiscussionReducer(payload)),
  screen: (payload) => dispatch(screen(payload)),
  getTrendingExternalFeeds: (payload) =>
    dispatch(getTrendingExternalFeeds(payload)),

  // showFeedDetails: (payload) => dispatch(editFeed(payload)),
  // setPostCommentReset: (payload) =>
  //   dispatch({ type: "POSTCOMMENTDETAILS_RESET", payload }),
  // searchOpenBarStatus: (payload) =>
  //   dispatch({ type: "SEARCHBAR_STATUS", payload }),
  // updateFeedStar: (payload) => dispatch({ type: "FEED_ICON_UPLOAD", payload }),
  // showDiscussion: (payload) => dispatch(showDiscussion(payload)),
  // screen: (payload) => dispatch(screen(payload))
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ExternalFeedScreen
);

const styles = StyleSheet.create({
  header: {
    position: Platform.OS == "web" ? "fixed" : null,
    left: 0,
    right: 0,
    zIndex: 10,
  },
  container: {
    flex: 1,
    backgroundColor: Colors.whiteBackground,
    height: "100%",
  },
});
