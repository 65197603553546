import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    link: "",
    postEditDetails: null
};

const LinkPostReducer = createSlice({
    name: 'LinkPostReducer',
    initialState,
    reducers: {
        postLink(state, action) {
            return { ...state, link: action.payload }
        },
        postEditDetails(state, action) {
            return { ...state, postEditDetails: action.payload }
        },

    },
})

export const { postLink, postEditDetails } = LinkPostReducer.actions
export default LinkPostReducer.reducer