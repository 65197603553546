// import Modal from "modal-enhanced-react-native-web";
// import * as React from 'react';
import { useEffect, useRef } from "react";
import {
  Dimensions,
  Platform,
  View,
  // Modal
} from "react-native";
import Modal from "react-native-modal";
import Overlay from "react-native-modal-overlay";
import { connect } from "react-redux";
import { compose } from "react-recompose";
// import { setCURRENTDEVICEWIDTHACTION } from "../actionCreator/CurrentDeviceWidthAction";
// import { setFEEDREPORTMODALACTION } from "../actionCreator/FeedReportModalAction";
// import { resetHomefeedList } from "../actionCreator/HomeFeedAction";
import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";
// import { setRESETPASSWORDMODALACTION } from "../actionCreator/ResetPasswordModalAction";
// import { setSHARELINKMODALACTION } from "../actionCreator/ShareLinkModalAction";
import { setSIGNUPMODALACTION } from "../reducers/AdminTrueFalseReducer";
// import { setLoginStatus } from "../actionCreator/UserAction";
import { setUSERNAMEMODALACTION } from "../reducers/AdminTrueFalseReducer";
// import { setVERIFYEMAILMODALACTION } from "../actionCreator/VerifyEmailModalAction";
import AddUsernameCard from "../components/AddUsernameCard";
// import FeedReportModal from "../components/FeedReportModal";
import LinkAddAlertCard from "../components/LinkAddAlertCard";
// import ResetPassword from "../components/ResetPassword";
// import SignupInvite from "../components/SignupInvite";
import LoginModal from "./LoginModal";
import SignUpModalStep1 from "./SignUpModalStep1";
// import { setInviteSIGNUPMODALACTION } from "../actionCreator/InviteSignUpModalAction";

import SignUpMessage from "./SignUpMessage";
// import EarningModalMsg from "./EarningModalMsg";
import LeftPanel from "./LeftPanel";
import PostShareModel from "./PostShareModel";
// import SubscriptionAlert from "./subscriptionAlert";
// import { subscriptionAlertOpen } from '../reducers/action/Completed';
import ClikInviteModal from "./ClikInviteModal";
import { setPostShareModel } from "../reducers/PostShareReducer";

import {
  setclikInviteModal,
  leftPanelModalFunc,
  showReportUserModal,
  setSHARELINKMODALACTION,
} from "../reducers/AdminTrueFalseReducer";
import ReportUserModal from "./ReportUserModal";

const Layout = (props) => {
  const navigationRef = useRef(null);

  useEffect(() => {
    // props.__resetHomefeedList({ identifier: "reset" });
    // props.setLoginModalStatus(true);
  }, []);

  const onClose = () => {
    props.setLoginModalStatus(false);
    props.setSignUpModalStatus(false);
    props.setUsernameModalStatus(false);
    // props.setResetPasswordModalStatus(false);
    // props.setVerifyEmailModalStatus(false);
    props.setShareLinkModalStatus(false);
    props.setPostShareModel({ id: "", postShareStatus: false });

    // props.setFeedReportModalStatus(false);
    // props.setFeedShareModalStatus(false);
    // props.setInviteSignUpModalStatus(false);
    // props.setMessageModalStatus(false);
    // props.setEarningModalStatus(false);
    // props.openCreateComment(false);
    props.leftPanelModalFunc(false);
    props.setclikInviteModal(false, "");
    // props.showReportUserModal(false)

    // props.setPostShareModel(false);
    // props.subscriptionAlertOpen(false)
  };

  const handleLoginButton = () => {
    // props.setSignUpModalStatus(false);
    props.setLoginModalStatus(true);
    // props.setInviteSignUpModalStatus(false);
  };

  const handleSignButton = () => {
    props.setSignUpModalStatus(true);
    props.setLoginModalStatus(false);
    props.setInviteSignUpModalStatus(false);
  };

  const handleInviteSignButton = () => {
    props.setUsernameModalStatus(true);
    props.setLoginModalStatus(false);
    props.setInviteSignUpModalStatus(false);
  };

  // console.log(props.navigationRef, 'LAYOUT___________')

  return (
    <View
    // onLayout={(event) => {
    //   let { width, height } = event.nativeEvent.layout;
    //   // props.__setCURRENTDEVICEWIDTHACTION(width);
    // }}
    >
      {props.getShareLinkModalStatus == true ? (
        Platform.OS !== "web" ? (
          <Overlay
            animationType="zoomIn"
            visible={props.getShareLinkModalStatus}
            onClose={onClose}
            closeOnTouchOutside
            childrenWrapperStyle={{
              margin: 0,
              padding: 0,
              borderRadius: 6,
            }}
            children={
              <LinkAddAlertCard
                onClose={onClose}
                navigation={props.navigationRef}
              />
            }
          />
        ) : (
          <Modal
            isVisible={props.getShareLinkModalStatus}
            onBackdropPress={onClose}
            style={{
              marginHorizontal:
                Dimensions.get("window").width > 750 ? "30%" : 10,
              padding: 0,
            }}
          >
            <LinkAddAlertCard
              onClose={onClose}
              navigation={props.navigationRef}
            />
          </Modal>
        )
      ) : null}

      {props.getFeedReportModalStatus == true ? (
        Platform.OS !== "web" ? (
          <Overlay
            animationType="zoomIn"
            visible={props.getFeedReportModalStatus}
            onClose={onClose}
            closeOnTouchOutside
            children={<FeedReportModal onClose={onClose} />}
            childrenWrapperStyle={{
              padding: 0,
              margin: 0,
              borderRadius: 6,
            }}
          />
        ) : (
          <Modal
            isVisible={props.getFeedReportModalStatus}
            onBackdropPress={onClose}
            style={{
              marginHorizontal:
                Dimensions.get("window").width > 750 ? "30%" : 10,
              padding: 0,
            }}
          >
            <FeedReportModal onClose={onClose} />
          </Modal>
        )
      ) : null}

      {props.getResetPasswordModalStatus == true ? (
        Platform.OS !== "web" ? null : ( // <Overlay
          //   animationType="zoomIn"
          //   visible={props.getResetPasswordModalStatus}
          //   onClose={onClose}
          //   closeOnTouchOutside
          //   children={<ResetPassword onClose={onClose} />}
          //   childrenWrapperStyle={{
          //     padding: 0,
          //     borderRadius: 6,
          //   }}
          // />
          <Modal
            isVisible={props.getResetPasswordModalStatus}
            onBackdropPress={onClose}
            style={{
              padding: 0,
              alignItems: Dimensions.get("window").width >= 750 && "center",
              justifyContent: "center",
            }}
          >
            <ResetPassword onClose={onClose} />
          </Modal>
        )
      ) : null}

      {props.getMessageModalStatus == true ? (
        Platform.OS != "web" ? (
          <Overlay
            animationType="zoomIn"
            visible={props.getMessageModalStatus}
            onClose={onClose}
            children={<SignUpMessage onClose={onClose} />}
            closeOnTouchOutside
            childrenWrapperStyle={{
              padding: 0,
              borderRadius: 6,
            }}
          />
        ) : (
          <Modal
            isVisible={props.getMessageModalStatus}
            onBackdropPress={onClose}
            style={{
              marginHorizontal:
                Dimensions.get("window").width > 750 ? "30%" : 0,
              paddingHorizontal: 10,
            }}
          >
            <View
              style={{
                paddingVertical: 10,
              }}
            >
              <SignUpMessage onClose={onClose} />
            </View>
          </Modal>
        )
      ) : null}

      {props.getSignUpModalStatus == true ? (
        Platform.OS == "web" ? (
          <Modal
            isVisible={props.getSignUpModalStatus}
            navigation={props.navigation}
            onBackdropPress={onClose}
            style={{
              marginHorizontal:
                Dimensions.get("window").width > 750 ? "30%" : 0,
              padding: 0,
            }}
          >
            <View
              style={{
                paddingVertical: 10,
              }}
            >
              <SignUpModalStep1
                onClose={onClose}
                navigate={props.navigation}
                handleLoginButton={handleLoginButton}
              />
            </View>
          </Modal>
        ) : (
          <Overlay
            animationType="zoomIn"
            visible={props.getSignUpModalStatus}
            onClose={onClose}
            children={
              <SignUpModalStep1
                onClose={onClose}
                navigate={props.navigation}
                handleSignButton={handleSignButton}
              />
            }
            closeOnTouchOutside
            childrenWrapperStyle={{
              padding: 0,
              borderRadius: 6,
            }}
          ></Overlay>
        )
      ) : null}

      {props.getUsernameModalStatus == true ? (
        Platform.OS == "web" ? (
          <Modal
            isVisible={props.getUsernameModalStatus}
            navigation={props.navigation}
            onBackdropPress={onClose}
            style={{
              padding: 0,
              alignItems: Dimensions.get("window").width >= 750 && "center",
              justifyContent: "center",
            }}
          >
            <AddUsernameCard onClose={onClose} navigate={props.navigation} />
          </Modal>
        ) : (
          <Overlay
            animationType="zoomIn"
            visible={props.getUsernameModalStatus}
            onClose={onClose}
            children={
              <AddUsernameCard onClose={onClose} navigate={props.navigation} />
            }
            closeOnTouchOutside
            childrenWrapperStyle={{
              padding: 0,
              borderRadius: 6,
            }}
          />
        )
      ) : null}

      {props.getLoginModalStatus == true ? (
        Platform.OS == "web" ? (
          <Modal
            isVisible={props.getLoginModalStatus}
            navigation={props.navigation}
            onBackdropPress={onClose}
            style={{
              alignItems: "center",
            }}
          >
            <LoginModal
              onClose={onClose}
              navigate={props.navigation}
              handleSignButton={handleSignButton}
            />
          </Modal>
        ) : (
          <Overlay
            animationType="zoomIn"
            visible={props.getLoginModalStatus}
            onClose={onClose}
            children={
              <LoginModal
                onClose={onClose}
                navigate={props.navigation}
                handleSignButton={handleSignButton}
              />
            }
            closeOnTouchOutside
            childrenWrapperStyle={{
              padding: 0,
              borderRadius: 6,
            }}
          ></Overlay>
        )
      ) : null}
      {props.getInviteSignUpModalStatus == true ? (
        Platform.OS == "web" ? (
          <Modal
            isVisible={props.getInviteSignUpModalStatus}
            navigation={props.navigation}
            onBackdropPress={onClose}
            style={{
              marginHorizontal:
                Dimensions.get("window").width > 750 ? "30%" : 0,
              padding: 0,
            }}
          >
            <View
              style={{
                height: Dimensions.get("window").height,
                paddingVertical: 10,
              }}
            >
              <SignupInvite
                onClose={onClose}
                navigate={props.navigation}
                handleInviteSignButton={handleInviteSignButton}
              />
            </View>
          </Modal>
        ) : (
          <Overlay
            animationType="zoomIn"
            visible={props.getInviteSignUpModalStatus}
            onClose={onClose}
            children={
              <SignupInvite
                onClose={onClose}
                navigate={props.navigation}
                handleInviteSignButton={handleInviteSignButton}
              />
            }
            closeOnTouchOutside
            childrenWrapperStyle={{
              padding: 0,
              borderRadius: 6,
            }}
          ></Overlay>
        )
      ) : null}

      {props.getEarningModalStatus == true ? (
        Platform.OS == "web" ? (
          <Modal
            isVisible={props.getEarningModalStatus}
            navigation={props.navigation}
            onBackdropPress={onClose}
            style={{
              marginHorizontal:
                Dimensions.get("window").width > 750 ? "30%" : 0,
              padding: 0,
            }}
          >
            <View
              style={{
                height: Dimensions.get("window").height,
                paddingVertical: 10,
              }}
            >
              <EarningModalMsg onClose={onClose} />
            </View>
          </Modal>
        ) : (
          <Overlay
            animationType="zoomIn"
            visible={props.getEarningModalStatus}
            onClose={onClose}
            children={<EarningModalMsg onClose={onClose} />}
            closeOnTouchOutside
            childrenWrapperStyle={{
              padding: 0,
              borderRadius: 6,
            }}
          ></Overlay>
        )
      ) : null}
      {props.getLeftPanelStatus ? (
        Platform.OS == "web" ? (
          // <Modal
          //   isVisible={props.getLeftPanelStatus}
          //   navigation={props.navigation}
          //   onBackdropPress={onClose}
          //   style={{
          //     marginHorizontal:
          //       Dimensions.get("window").width > 750 ? "30%" : 0,
          //     padding: 0,
          //   }}
          // >
          <Modal
            isVisible={props.getLeftPanelStatus}
            animationIn="slideInLeft"
            animationOut="slideOutLeft"
            onBackdropPress={() => props.leftPanelModalFunc(false)}
            style={{ justifyContent: "flex-start", margin: 0 }}
          >
            {/* <View
              style={{
                flex:1
              }}
            > */}
            <LeftPanel onClose={onClose} navigation={props.navigationRef} />
            {/* </View> */}
          </Modal>
        ) : (
          <Overlay
            // animationType="flipInX"
            // animationType='slide'
            // animationIn="slideInLeft"
            // animationOut="slideOutRight"
            animationType="zoomIn"
            visible={props.getLeftPanelStatus}
            onClose={onClose}
            children={
              <LeftPanel
                onClose={onClose}
                navigationRef={props.navigationRef.current}
              />
            }
            closeOnTouchOutside
            childrenWrapperStyle={{
              padding: 0,
              borderRadius: 6,
              // height: '80%'
              flex: 1,
              // width: "100%",
              // height: Dimensions.get("window").width <= 750 ? Dimensions.get("window").height : ""
            }}
          ></Overlay>
        )
      ) : null}

      {props.getPostShareModelStatus == true ? (
        Platform.OS == "web" ? (
          <Modal
            isVisible={props.getPostShareModelStatus}
            navigation={props.navigation}
            onBackdropPress={onClose}
            style={{
              marginHorizontal:
                Dimensions.get("window").width > 750 ? "30%" : 0,
              padding: 0,
              alignItems: Dimensions.get("window").width >= 750 && "center",
              justifyContent: "center",
              height: Dimensions.get("window").width >= 750 ? 480 : 500,
            }}
          >
            <PostShareModel onClose={onClose} navigate={props.navigation} />
          </Modal>
        ) : (
          <Overlay
            animationType="zoomIn"
            visible={props.getPostShareModelStatus}
            onClose={onClose}
            children={
              <PostShareModel onClose={onClose} navigate={props.navigation} />
            }
            closeOnTouchOutside
            childrenWrapperStyle={{
              padding: 0,
              borderRadius: 6,
            }}
          ></Overlay>
        )
      ) : null}

      {props.getSubscripionAlertStatus == true ? (
        Platform.OS == "web" ? (
          <Modal
            isVisible={props.getSubscripionAlertStatus}
            navigation={props.navigation}
            onBackdropPress={onClose}
            style={{
              marginHorizontal:
                Dimensions.get("window").width > 750 ? "30%" : 0,
              padding: 0,
              alignItems: Dimensions.get("window").width >= 750 && "center",
              justifyContent: "center",
              height: Dimensions.get("window").width >= 750 ? 300 : 500,
            }}
          >
            <SubscriptionAlert onClose={onClose} navigate={props.navigation} />
          </Modal>
        ) : (
          <Overlay
            animationType="zoomIn"
            visible={props.getSubscripionAlertStatus}
            onClose={onClose}
            children={
              <SubscriptionAlert
                onClose={onClose}
                navigate={props.navigation}
              />
            }
            closeOnTouchOutside
            childrenWrapperStyle={{
              padding: 0,
              borderRadius: 6,
            }}
          ></Overlay>
        )
      ) : null}
      {props.clikInviteModal == true ? (
        Platform.OS == "web" ? (
          <Modal
            isVisible={props.clikInviteModal}
            navigation={props.navigation}
            onBackdropPress={onClose}
            style={{
              marginHorizontal:
                Dimensions.get("window").width > 750 ? "30%" : 0,
              padding: 0,
              alignItems: Dimensions.get("window").width >= 750 && "center",
              justifyContent: "center",
              height: Dimensions.get("window").width >= 750 ? 300 : 500,
            }}
          >
            <ClikInviteModal onClose={onClose} navigate={props.navigation} />
          </Modal>
        ) : (
          <Overlay
            animationType="zoomIn"
            visible={props.getSubscripionAlertStatus}
            onClose={onClose}
            children={
              <ClikInviteModal onClose={onClose} navigate={props.navigation} />
            }
            closeOnTouchOutside
            childrenWrapperStyle={{
              padding: 0,
              borderRadius: 6,
            }}
          ></Overlay>
        )
      ) : null}

      {props.reportUserModal ? (
        Platform.OS == "web" ? (
          <Modal
            isVisible={props.reportUserModal}
            navigation={props.navigation}
            onBackdropPress={onClose}
            style={{
              marginHorizontal:
                Dimensions.get("window").width > 750 ? "30%" : 0,
              padding: 0,
              alignItems: Dimensions.get("window").width >= 750 && "center",
              justifyContent: "center",
              height: Dimensions.get("window").width >= 750 ? 300 : 500,
            }}
          >
            <ReportUserModal onClose={onClose} navigate={props.navigation} />
          </Modal>
        ) : (
          <Overlay
            animationType="zoomIn"
            visible={props.getSubscripionAlertStatus}
            onClose={onClose}
            children={
              <ReportUserModal onClose={onClose} navigate={props.navigation} />
            }
            closeOnTouchOutside
            childrenWrapperStyle={{
              padding: 0,
              borderRadius: 6,
            }}
          ></Overlay>
        )
      ) : null}
    </View>
  );
};

const mapStateToProps = (state) => ({
  getLoginModalStatus: state.LoginModalReducer.modalStatus,
  getSignUpModalStatus: state.AdminTrueFalseReducer.signupmodalStatus,
  // getInviteSignUpModalStatus: state.InviteSignUpModalReducer.get("modalStatus"),
  getUsernameModalStatus: state.AdminTrueFalseReducer.usernameModalStatus,
  getMessageModalStatus: state.AdminTrueFalseReducer.messageModalStatus,
  // getResetPasswordModalStatus: state.ResetPasswordModalReducer.get(
  //   "modalStatus"
  // ),
  // getVerifyEmailModalStatus: state.VerifyEmailModalReducer.get("modalStatus"),
  // getSignupFollowModalStatus: state.VerifyEmailModalReducer.get(
  //   "signupfollowmodal"
  // ),
  // getSignupJoinModalStatus: state.VerifyEmailModalReducer.get(
  //   "signupjoinmodal"
  // ),
  getShareLinkModalStatus: state.AdminTrueFalseReducer.shareLinkModalStatus,
  // getFeedReportModalStatus: state.FeedReportModalReducer.get("modalStatus"),
  // getFeedShareModalStatus: state.FeedReportModalReducer.get("shareFeedStatus"),
  // getMessageModalStatus: state.AdminTrueFalseReducer.get("messageModalStatus"),
  // getEarningModalStatus: state.AdminTrueFalseReducer.get("earningModalStatus"),
  getLeftPanelStatus: state.AdminTrueFalseReducer.leftPanelOpenStatus,

  getPostShareModelStatus: state.PostShareReducer.postShareStatus,
  clikInviteModal: state.AdminTrueFalseReducer.clikInviteModalStatus,
  reportUserModal: state.AdminTrueFalseReducer.reportUserModal,

  // getSubscripionAlertStatus: state.SubscriptionAlertReducer.subsAlert
});

const mapDispatchToProps = (dispatch) => ({
  // __setCURRENTDEVICEWIDTHACTION: (payload) =>
  //   dispatch(setCURRENTDEVICEWIDTHACTION(payload)),
  // __resetHomefeedList: (payload) => dispatch(resetHomefeedList(payload)),
  setLoginModalStatus: (payload) => dispatch(setLOGINMODALACTION(payload)),
  setSignUpModalStatus: (payload) => dispatch(setSIGNUPMODALACTION(payload)),
  setUsernameModalStatus: (payload) =>
    dispatch(setUSERNAMEMODALACTION(payload)),
  // setInviteSignUpModalStatus: (payload) =>
  //   dispatch(setInviteSIGNUPMODALACTION(payload)),
  // setResetPasswordModalStatus: (payload) =>
  //   dispatch(setRESETPASSWORDMODALACTION(payload)),
  // setVerifyEmailModalStatus: (payload) =>
  //   dispatch(setVERIFYEMAILMODALACTION(payload)),
  setShareLinkModalStatus: (payload) =>
    dispatch(setSHARELINKMODALACTION(payload)),
  // setFeedReportModalStatus: (payload) =>
  //   dispatch(setFEEDREPORTMODALACTION(payload)),
  // changeLoginStatus: (payload) => dispatch(setLoginStatus(payload)),
  // setFeedShareModalStatus: (payload) =>
  //   dispatch({ type: "SHAREFEEDSTATUS", payload }),
  // setMessageModalStatus: (payload) =>
  //   dispatch({ type: "MESSAGEMODALSTATUS", payload }),
  // setEarningModalStatus: (payload) =>
  //   dispatch({ type: "EARNINGMODALSTATUS", payload }),
  // openCreateComment: (payload) =>
  //   dispatch({ type: "OPEN_CREATE_COMMENT", payload }),
  leftPanelModalFunc: (payload) => dispatch(leftPanelModalFunc(payload)),

  setPostShareModel: (payload) => dispatch(setPostShareModel(payload)),
  setclikInviteModal: (clikInviteModalStatus, clikId) =>
    dispatch(
      setclikInviteModal({
        type: "clikInviteModal",
        payload: { clikInviteModalStatus, clikId },
      })
    ),
  showReportUserModal: (payload) => dispatch(showReportUserModal(payload)),

  // subscriptionAlertOpen: (payload) => dispatch(subscriptionAlertOpen(payload))
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Layout);
