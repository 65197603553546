import React, { useState } from "react";
import {
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Image
} from "react-native";
import { Button } from "react-native-elements";
import RNPickerSelect from "react-native-picker-select";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import applloClient from "../client";
import AppHelper from "../constants/AppHelper";
import ConstantFontFamily from "../constants/FontFamily";
import {
  InviteToClikMutation,
  RejectClikMemberMutation
} from "../graphqlSchema/FollowandUnFollowMutation";
import {
  InviteToClikVariables,
  RejectClikMemberVariables
} from "../graphqlSchema/FollowandUnfollowVariables";
import { listClikUserRequest } from "../actionCreator/ClikUserRequestAction";
import ButtonStyle from "../constants/ButtonStyle";

const ClikProfileUserList = props => {
  const inputRefs = {};
  const [reqUserMemberChange, setReqUserMemberChange] = useState("MEMBER");

  const sendInvite = async id => {
    console.log(id, ' props.ClikInfo')
    InviteToClikVariables.variables.clik_id = props.ClikInfo.id;
    InviteToClikVariables.variables.invited_users = [
      {
        user_id: id,
        member_type: reqUserMemberChange
      }
    ];
    try {
      await applloClient
        .query({
          query: InviteToClikMutation,
          ...InviteToClikVariables,
          fetchPolicy: "no-cache"
        })
        .then(async res => {
          await props.setClikUserRequest({
            id: props.ClikInfo.id,
            currentPage: AppHelper.PAGE_LIMIT
          });
        });
    } catch (e) {
      console.log(e);
    }
  };

  const rejectMember = async id => {
    RejectClikMemberVariables.variables.clik_id = props.ClikInfo.id
    RejectClikMemberVariables.variables.user_id = id;
    try {
      await applloClient
        .query({
          query: RejectClikMemberMutation,
          ...RejectClikMemberVariables,
          fetchPolicy: "no-cache"
        })
        .then(async res => {
          await props.setClikUserRequest({
            id: props.ClikInfo.id,
            currentPage: AppHelper.PAGE_LIMIT
          });
        });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <View
      style={{
        borderRadius: 6
      }}
    >
      <ScrollView
        style={{
          backgroundColor: "#fff",
          marginBottom: 10
        }}
      >
        <View style={{ flex: 1, margin: 10 }}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            <View style={{ flexDirection: "row" }}>
             
                <Image
                  source={props.item.item.node.applicant.profile_pic ? props.item.item.node.applicant.profile_pic : require("../assets/image/weclickd-logo.png")}
                  style={{
                    width: 40,
                    height: 40,
                    padding: 0,
                    margin: 5,
                    borderRadius: 20
                  }}
                />
              
              <View
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                  padding: 5
                }}
              >
                <Text
                  style={{
                    color: "#000",
                    fontSize: 14,
                    fontWeight: "bold",
                    fontFamily: ConstantFontFamily.defaultFont
                  }}
                >
                  @{props.item.item.node.applicant.username}
                </Text>
              </View>
            </View>
          </View>
          <View
            style={{
              marginTop: 10
            }}
          >
            <Text
              style={{
                fontFamily: ConstantFontFamily.defaultFont,
                fontSize: 14
              }}
            >
              {props.item.item.node.qualification}
            </Text>
          </View>

          <View
            style={{
              flexDirection: "row",
              justifyContent: "center"
            }}
          >
            <Text
              style={{
                textAlign: "center",
                alignSelf: "center",
                color: "#000",
                fontSize: 14,
                fontWeight: "bold",
                fontFamily: ConstantFontFamily.defaultFont
              }}
            >
              User will be a :
            </Text>

            <View
              style={[ButtonStyle.shadowStyle, {
                borderWidth: 0,
                width: 150,
                marginLeft: 5,
                borderRadius: 6,
                justifyContent: "center",
                shadowOpacity: 0.15
              }]}
            >
              <RNPickerSelect
                placeholder={{}}
                items={AppHelper.SUPER_ADMIN_ROLEITEMS}
                onValueChange={(itemValue, itemIndex) => {
                  setReqUserMemberChange(itemValue);
                }}
                onUpArrow={() => {
                  inputRefs.name.focus();
                }}
                onDownArrow={() => {
                  inputRefs.picker2.togglePicker();
                }}
                style={{ ...styles }}
                ref={el => {
                  inputRefs.picker = el;
                }}
              />
            </View>
          </View>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center"
            }}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "center",
                marginTop: 10
              }}
            >
              <TouchableOpacity style={{ justifyContent: "center" }}>
                <Button
                  title={"Accept"}
                  titleStyle={ButtonStyle.allButtonTextStyle}
                  buttonStyle={ButtonStyle.allButtonbackgroundStyle}
                  containerStyle={ButtonStyle.containerStyle}
                  onPress={() => sendInvite(props.item.item.node.applicant.id)}
                />
              </TouchableOpacity>
              <TouchableOpacity style={{ justifyContent: "center" }}>
                <Button
                  title={"Deny"}
                  titleStyle={ButtonStyle.allButtonTextStyle}
                  buttonStyle={ButtonStyle.allButtonbackgroundStyle}
                  containerStyle={ButtonStyle.containerStyle}
                  onPress={() => rejectMember(props.item.item.node.user.id)}
                />
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </ScrollView>
    </View>
  );
};

const mapDispatchToProps = dispatch => ({
  setClikUserRequest: payload => dispatch(listClikUserRequest(payload))
});

export default compose(connect(null, mapDispatchToProps))(
  React.memo(ClikProfileUserList)
);

const styles = StyleSheet.create({
  inputIOS: {
    paddingTop: 13,
    paddingHorizontal: 10,
    paddingBottom: 12,
    borderWidth: 0,
    borderRadius: 6,
    backgroundColor: "white",
    color: "black",
    fontSize: 15,
    fontWeight: "bold",
    fontFamily: ConstantFontFamily.defaultFont
  },
  inputAndroid: {
    paddingHorizontal: 10,
    paddingVertical: 8,
    borderWidth: 0,
    borderRadius: 6,
    color: "#000",
    backgroundColor: "white",
    paddingRight: 30,
    fontSize: 15,
    fontWeight: "bold",
    fontFamily: ConstantFontFamily.defaultFont
  }
});