import { ISLOADING, ShowCommentReducer,ADDICON,SHOWMOREID, GETTITLE, GETREPLIEDID, POSTCOMMENT, PARENT_CONTENTID, SHOWMORECOMMENT, MINIMIZEID, MOREICONID, SHOWMORENESTED, COMPLETED_SUCCESS, SHOW_COMMENT, FOCUS_SUCCESS, FOCUS_FAILURE, ADDTERM_SUCCESS, TERMCONCAT_SUCCESS, FIXTAB_SUCCESS, INDEXTAB_SUCCESS, CLIKD_SUCCESS, NOPOST_SUCCESS, GETFEED_SUCCESS, FIXINDEX_SUCCESS, GETNEWLIST_SUCCESS, GET404_SUCCESS, SUBSCRIPTIONALERT_SUCCESS, TOTAL_COMMENTS, TOTAL_NESTEDCOMMENTS, GET_PARENT_PATH, CHECK_COMMENT__COUNT, HIGHLIGHT_ID, TOOLKIT_OPEN, USER_BOLD, TOOLKIT_OFF, CLIK_HOVER, SHOW_DISCUSSION, WINDOW_RESIZE, SCREEN } from '../../constants/Action';

export const IsCompleted = (payload) => {
  return {
    type: COMPLETED_SUCCESS,
    payload,
  };
};

export const Focus = (payload) => {
  return {
    type: FOCUS_SUCCESS,
    payload,
  };
};

export const AddTerm = (payload) => {
  return {
    type: ADDTERM_SUCCESS,
    payload,
  };
};

export const TermConcat = (payload) => {
  return {
    type: TERMCONCAT_SUCCESS,
    payload,
  };
};

export const FixTab = (payload) => {
  return {
    type: FIXTAB_SUCCESS,
    payload,
  };
};


export const IndexTab = (payload) => {
  return {
    type: INDEXTAB_SUCCESS,
    payload,
  };
};

export const ClikPost = (payload) => {
  return {
    type: CLIKD_SUCCESS,
    payload,
  };
};


export const NoPost = (payload) => {
  return {
    type: NOPOST_SUCCESS,
    payload,
  };
};

export const feedFluctuation = (payload) => {
  return {
    type: GETFEED_SUCCESS,
    payload,
  };
};

export const fixIndex = (payload) => {
  return {
    type: FIXINDEX_SUCCESS,
    payload,
  };
};

export const setNewList = (payload) => {
  return {
    type: GETNEWLIST_SUCCESS,
    payload,
  };
};


export const get404 = (payload) => {
  return {
    type: GET404_SUCCESS,
    payload,
  };
};

export const subscriptionAlertOpen = (payload) => {
  return {
    type: SUBSCRIPTIONALERT_SUCCESS,
    payload,
  };
};


export const setTotalComment = (payload) => {
  return {
    type: TOTAL_COMMENTS,
    payload,
  };
};


export const setNestedTotalComment = (payload) => {
  return {
    type: TOTAL_NESTEDCOMMENTS,
    payload,
  };
};

export const setParentPath = (payload) => {
  return {
    type: GET_PARENT_PATH,
    payload,
  };
};

export const setCommentStatus = (payload) => {
  return {
    type: CHECK_COMMENT__COUNT,
    payload,
  };
};

export const setCommentID = (payload) => {
  return {
    type: HIGHLIGHT_ID,
    payload,
  };
};



export const openToolkit = (payload) => {
  return {
    type: TOOLKIT_OPEN,
    payload,
  };
};



export const userHighlight = (payload) => {
  return {
    type: USER_BOLD,
    payload,
  };
};



export const toolkitOff = (payload) => {
  return {
    type: TOOLKIT_OFF,
    payload,
  };
};



export const clikHover = (payload) => {
  return {
    type: CLIK_HOVER,
    payload,
  };
};


export const showDiscussion = (payload) => {
  return {
    type: SHOW_DISCUSSION,
    payload,
  };
};



export const windowResize = (payload) => {
  return {
    type: WINDOW_RESIZE,
    payload,
  };
};



export const screen = (payload) => {
  return {
    type: SCREEN,
    payload,
  };
};



export const isloading = (payload) => {
  return {
    type: ISLOADING,
    payload,
  };
};

export const showComment = (payload) => {
  return {
    type: SHOW_COMMENT,
    payload,
  };
}




// ===================================================== web ===========>



export const showbox = (payload) => {
  return {
    type: ShowCommentReducer,
    payload,
  };
};


export const getCommentTitle = (payload) => {
  return {
    type: GETTITLE,
    payload,
  };
};


export const getRepliedId = (payload) => {
  return {
    type: GETREPLIEDID,
    payload,
  };
};


export const postCommentStatus = (payload) => {
  return {
    type: POSTCOMMENT,
    payload,
  };
};


export const parentCommentId = (payload) => {
  return {
    type: PARENT_CONTENTID,
    payload,
  };
};


export const showMoreComment = (payload) => {

  return {
    type: SHOWMORECOMMENT,
    payload,
  };
}

export const MinimizeId = (payload) => {
  return {
    type: MINIMIZEID,
    payload,
  }
}

export const MoreIconId = (payload) => {
  return {
    type: MOREICONID,
    payload,
  }
}



export const showMoreNestedId = (payload) => {
  return {
    type: SHOWMORENESTED,
    payload,
  }
}




export const addIconId = (payload) => {
  return {
    type: ADDICON,
    payload,
  }
}



export const showMoreId = (payload) => {
  return {
    type: SHOWMOREID,
    payload,
  }
}





