import React, { Component } from "react";
import { View } from "react-native";
import ProgressBarAnimated from 'react-native-progress-bar-animated';
import { connect } from "react-redux";
import { compose } from "react-recompose";

class ProgressBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            progress: 0,
            progressWidth: 500
        };
    }

    render() {
        return (
            <View>
                {
                    this.props.progress == true &&
                    <View
                    // style={{marginHorizontal:'3%'}}
                        onLayout={event => {
                            let { x, y, width, height } = event.nativeEvent.layout;
                            this.setState({
                                progressWidth: width
                            })
                        }}
                    >
                        <ProgressBarAnimated
                            borderRadius={4}
                            borderColor ={'#fff'}
                            width={this.state.progressWidth}
                            value={this.props.value}
                            maxValue={100}
                            backgroundColor="#009B1A"
                            barAnimationDuration={10000}
                        />
                    </View>
                }
            </View>
        );
    }
}

export default compose(connect(null, null))(
    ProgressBar
);



