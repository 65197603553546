// import "@expo/browser-polyfill";
// import { openBrowserAsync } from "expo-web-browser";
import React, { useEffect, lazy, Suspense, useRef, useState } from "react";

import {
  TextInput,
  Text,
  TouchableOpacity,
  View,
  Dimensions,
  Platform,
  Image,
} from "react-native";
import * as Clipboard from "expo-clipboard";

import {
  Menu,
  MenuTrigger,
  MenuOption,
  MenuOptions,
} from "react-native-popup-menu";
import { Hoverable } from "react-native-web-hooks";

import { connect } from "react-redux";
import { compose } from "react-recompose";
import { setFEEDREPORTMODALACTION } from "../actionCreator/FeedReportModalAction";
import { setPostCommentDetails } from "../actionCreator/PostCommentDetailsAction";
import { setPostDetails } from "../actionCreator/PostDetailsAction";
import ConstantFontFamily from "../constants/FontFamily";
// import FeedImageDisplay from "../components/FeedImageDisplayClick";
// import FeedImageDisplayUser from "../components/FeedImageDisplayUser";
import ButtonStyle from "../constants/ButtonStyle";
import { PostQuery } from "../graphqlSchema/PostMutation";
import appolloClient from "../client";
import { DeleteContentMutation } from "../graphqlSchema/FeedMutation";
// import FeedImageDisplayUserNotExpanded from "../components/FeedImageDisplayUserNotExpanded";
// import FeedUserIcon from "../components/FeedUserIcon";
// import CommonTooltipBottom from "../components/CommonTooltipBottom";
import CommonTooltipBottomPost from "../components/CommonTooltipBottomPost";
import LeftPanel from "../components/LeftPanel";
import FeedCommentStyle from "./Style/FeedCommentStyle";
import CommentDetailScreen from "./CommentDetailScreen";
import { postEditDetails } from "../reducers/LinkPostReducer";
import { setPostShareModel } from "../reducers/PostShareReducer";
import Colors from "../constants/Colors";
import moment from "moment";
import CommonBottomSection from "../components/CommonBottomSection";
import {
  OpenWindow,
  WriteToClipboard,
  writeToClipboard,
} from "../constants/CommonFunction";
import Header from "../components/Header";

// const CommonBottomSection = lazy(() => import("../components/CommonBottomSection"))

const RelatedPostScreen = (props) => {
  let [id, setId] = useState("");
  let [title, setTitle] = useState("");
  let [summary, setSummary] = useState("");
  let [showMoreOption, setshowMoreOption] = useState(false);

  let [cliks, setCliks] = useState(null);
  let [users, setUsers] = useState(null);
  let [summaryHeight, setsummaryHeight] = useState(10);

  let [author, setAuthor] = useState(null);
  let [external_feed, setexternal_feed] = useState(null);

  const [heartCountNum, setheartCountNum] = useState(0);
  let [showBug, setshowBug] = useState(false);
  let [pressBug, setpressBug] = useState(false);

  const [item, setItem] = useState(null);
  const [FeedY, setFeedY] = useState(null);
  const [time, setTime] = useState("");
  const [titleHeight, settitleHeight] = useState();
  const [link, setlink] = useState("");

  let feed = useRef();

  const handleClick = (e) => {
    setshowBug(e);
  };

  const handleBugClick = (e) => {
    props.setFeedReportModalAction(true);
    setpressBug(true);
  };

  const hearCountFunc = (value) => {
    setheartCountNum(value);
  };

  useEffect(() => {
    appolloClient
      .query({
        query: PostQuery,
        variables: {
          id: props.PostId,
          cliks_first: 20,
          topics_first: 20,
          users_first: 20,
          feeds_first: 20,
        },
        fetchPolicy: "cache-first",
      })
      .then((res) => {
        // console.log(res, 'sssssssssssss');
        if (res.data.node != null) {
          setId(res.data.node.id);
          setTitle(res.data.node.title);
          setSummary(res.data.node.summary);
          setAuthor(res.data.node.author);
          setCliks(res.data.node.cliks);
          setUsers(res.data.node.users);
          setItem(res.data);
          setTime(res.data.node.created);
          setexternal_feed(res.data.node.external_feeds);
          setlink(res.data.node.link);
        }
      });
  }, []);

  const writeToClipboard = async () => {
    let PostId = "/post/" + id.replace("Post:", "");

    WriteToClipboard(id, "comment");
    // let Domain = window.location.href
    //   .replace("http://", "")
    //   .replace("https://", "")
    //   .replace("www.", "")
    //   .split(/[/?#]/)[0];
    // let PostId = "/post/" + id.replace("Post:", "");
    // await Clipboard.setString(
    //   Domain.startsWith("localhost") == true
    //     ? "http:/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/" + Domain + PostId
    //     : "https://" + Domain + PostId
    // );
  };

  const handleSharePost = () => {
    props.setPostShareModel({ status: true, id: id });
  };

  const handleClickComment = () => {
    if (Dimensions.get("window").width >= 1200) {
      props.focusCreateComment(true);
      props.setPostCommentDetails({
        id: id.replace("Post:", ""),
        title: title,
        loading: false,
      });

      let tempData =
        props.trendingHomeFeedId && props.trendingHomeFeedId.includes("Post:")
          ? props.trendingHomeFeedId.replace("Post:", "")
          : props.trendingHomeFeedId;
      let data1 = props.ActiveTab + id;
      let data2 = props.ActiveTab + "Post:" + tempData;
      let data1content = document.getElementById(data1);
      let data2content = document.getElementById(data2);
      if (data1content != null) {
        data1content.style.borderColor = "green";
      }
      if (data2content != null) {
        data2content.style.borderColor = "#c5c5c5";
      }
      if (data1 == data2) {
        goToPostDetailsScreen(id.replace("Post:", ""), false);
      } else {
        goToPostDetailsScreen(id.replace("Post:", ""), false);
      }
    } else {
      goToPost();
      goToPostDetailsScreen(id.replace("Post:", ""), true);
    }
  };

  const goToPost = () => {
    props.showComment(true);
  };

  const goToPostDetailsScreen = async (id, navigate) => {
    // props.setPostCommentReset({
    //   payload: [],
    //   postId: id
    //     .replace("Trending", "")
    //     .replace("New", "")
    //     .replace("Discussion", "")
    //     .replace("Search", ""),
    //   title: title,
    //   loading: false
    // });
    let title1 = "Home";
    props.setPostCommentDetails({
      id: "Post:" + id,
      title: title,
      loading: false,
      notification: true,
    });
    if (navigate == true) {
      props.setPostDetails({
        title: title1,
        id: "Post:" + id,
        navigate: navigate,
      });
    }
    props.setComment(false);
  };

  const handleDeleteFeed = (id) => {
    appolloClient
      .query({
        query: DeleteContentMutation,
        variables: {
          content_id: id,
        },
        fetchPolicy: "no-cache",
      })
      .then((response) => {
        if (response.data.content_delete.status.success == true) {
          props.deleteItem(id);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const listScroll = (value) => {
    setFeedY(value);
  };
  return (
    <View
      style={
        Dimensions.get("window").width > 750 ? ButtonStyle.threeColStruc : null
      }
    >
      {/* {Dimensions.get('window').width <= 750 &&
        <Header
          title="Create Post"
          navigation={props.navigation}
        // leftPanelModalFunc={this.props.leftPanelModalFunc}
        />
      } */}
      {Platform.OS == "web" && Dimensions.get("window").width > 750 ? (
        <LeftPanel navigation={props.navigation} />
      ) : null}
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          borderLeftWidth: 1,
          borderColor: "#d3d3d3",
        }}
      >
        {props?.PostId?.replace("Post:", "") == id?.replace("Post:", "") ? (
          <View style={{ backgroundColor: "#fff" }}>
            {
              <View
                ref={feed}
                nativeID={props.ActiveTab + id}
                style={[
                  Dimensions.get("window").width >= 750
                    ? ButtonStyle.normalFeedStyleForDashboard
                    : null,
                  {
                    width:
                      Dimensions.get("window").width <= 750
                        ? Dimensions.get("window").width
                        : Dimensions.get("window").width > 1200 &&
                          Dimensions.get("window").width < 1600
                        ? 450
                        : Dimensions.get("window").width > 1600
                        ? 600
                        : Dimensions.get("window").width - 310,
                    padding: 10,
                  },
                ]}
              >
                <View
                  style={{
                    flexDirection: "row",
                    width: "98%",
                    alignItems: "center",
                    paddingVertical:
                      Dimensions.get("window").width >= 750 &&
                      external_feed?.edges[0]?.node?.profile_pic != null
                        ? 10
                        : Dimensions.get("window").width < 750 &&
                          external_feed?.edges[0]?.node?.profile_pic != null
                        ? 10
                        : 0,
                  }}
                >
                  {author?.profile_pic ||
                  (external_feed?.edges[0]?.length > 0 &&
                    external_feed?.edges[0]?.node?.profile_pic != null) ? (
                    <TouchableOpacity
                      style={{
                        marginRight:
                          Dimensions.get("window").width >= 750 ? 20 : 5,
                        width:
                          Dimensions.get("window").width >= 750 ? "5%" : "15%",
                      }}
                      onPress={() => goToProfile(author, external_feed)}
                    >
                      <Image
                        source={{
                          uri: author?.profile_pic,
                        }}
                        style={{
                          width: 40,
                          height: 40,
                        }}
                      />
                    </TouchableOpacity>
                  ) : null}
                  {link ? (
                    <Hoverable>
                      {(isHovered) => (
                        <TouchableOpacity
                          style={{
                            alignSelf: "center",

                            flexDirection: "row",
                            marginRight: 10,
                          }}
                          onPress={() => OpenWindow(link)}
                        >
                          <Text
                            numberOfLines={1}
                            style={{
                              textAlign: "left",
                              color: "#6D757F",
                              fontSize: props.ViewMode == "Card" ? 14 : 14,
                              fontFamily: ConstantFontFamily.defaultFont,
                              textDecorationLine:
                                isHovered == true ? "underline" : "none",
                              flexWrap: "wrap",
                              width: "100%",
                            }}
                          >
                            {
                              link
                                ?.replace("http://", "")
                                ?.replace("https://", "")
                                ?.replace("www.", "")
                                ?.split(/[/?#]/)[0]
                            }
                          </Text>
                        </TouchableOpacity>
                      )}
                    </Hoverable>
                  ) : null}
                  <View style={{ width: "85%" }}>
                    <Text
                      style={{
                        color: "#6D757F",
                        fontSize: 14,
                        fontFamily: ConstantFontFamily.defaultFont,
                      }}
                    >
                      {moment.utc(time).local().fromNow()}
                    </Text>
                  </View>
                  {/* <View style={{ width: Dimensions.get('window').width >= 750 ? '96%' : '85%', }}>
                    {/* <Suspense fallback={null}> */}
                  {/* <CommonBottomSection
                      props={props.props}
                      item={{ item: item }}
                      heartCount={heartCountNum && heartCountNum}
                      loginModalStatusEvent={props.loginModalStatusEvent}
                      showBug={showBug}
                      pressBug={pressBug}
                      handleBugClick={handleBugClick}
                      writeToClipboard={writeToClipboard}
                      handleDeleteFeed={handleDeleteFeed}
                      handleClickComment={handleClickComment}
                      handleClick={handleClick}
                      hearCountFunc={hearCountFunc}
                      openWindow={openWindow}
                      handleSharePost={handleSharePost}
                    /> */}
                  {/* </Suspense> */}

                  {/* </View> */}
                  <View style={{ justifyContent: "flex-end" }}>
                    {Dimensions.get("window").width > 750 ? (
                      <Menu
                        onClose={() => {
                          // console.log('closing');
                          // props.clikHover({ isClik: false, clikId: id.replace("Post:", "") })
                          if (
                            props.PostId.replace("Post:", "") ==
                            id.replace("Post:", "")
                          ) {
                            setshowMoreOption(true);
                          }
                        }}
                      >
                        <MenuTrigger
                          onPress={() => {
                            if (
                              props.PostId &&
                              props.PostId.replace("Post:", "") ==
                                id.replace("Post:", "")
                            ) {
                              // props.clikHover({ isClik: true, clikId: id.replace("Post:", "") })
                            }
                          }}
                        >
                          {/* {showMoreOption
                || (props.clikId == id.replace("Post:", "")
                  && props.isClik == true)
                ? */}
                          <Image
                            source={require("../assets/image/menu.png")}
                            style={{
                              height: 20,
                              width: 20,
                              alignSelf: "flex-end",
                              transform: [{ rotate: "90deg" }],
                            }}
                          />
                          {/* // : null} */}
                        </MenuTrigger>
                        <MenuOptions
                          optionsContainerStyle={{
                            borderRadius: 6,
                            borderWidth: 1,
                            borderColor: "#d3d3d3",
                            shadowColor: "transparent",
                          }}
                          customStyles={{
                            optionsContainer: {
                              minHeight: 50,
                              width: 150,
                              marginTop: Platform.OS != "web" ? -30 : 15,
                              marginLeft:
                                Dimensions.get("window").width < 1350
                                  ? -25
                                  : -140,
                            },
                          }}
                        >
                          <MenuOption
                            onSelect={() => {
                              props.loginStatus == 1
                                ? handleSharePost(id)
                                : props.setLoginModalStatus(true);
                            }}
                          >
                            <Hoverable>
                              {(isHovered) => (
                                <Text
                                  style={{
                                    textAlign: "center",
                                    color:
                                      isHovered == true
                                        ? Colors.blueColor
                                        : "#000",
                                    fontFamily: ConstantFontFamily.defaultFont,
                                  }}
                                >
                                  Share post
                                </Text>
                              )}
                            </Hoverable>
                          </MenuOption>

                          <MenuOption onSelect={() => writeToClipboard()}>
                            <Hoverable>
                              {(isHovered) => (
                                <Text
                                  style={{
                                    textAlign: "center",
                                    color:
                                      isHovered == true
                                        ? Colors.blueColor
                                        : "#000",
                                    fontFamily: ConstantFontFamily.defaultFont,
                                  }}
                                >
                                  Copy Link
                                </Text>
                              )}
                            </Hoverable>
                          </MenuOption>
                          {props.isAdmin && (
                            <MenuOption
                              onSelect={() => {
                                props.loginStatus == 1
                                  ? handleDeleteFeed(id)
                                  : props.setLoginModalStatus(true);
                              }}
                            >
                              <Hoverable>
                                {(isHovered) => (
                                  <Text
                                    style={{
                                      textAlign: "center",
                                      color:
                                        isHovered == true
                                          ? Colors.blueColor
                                          : "#000",
                                      fontFamily:
                                        ConstantFontFamily.defaultFont,
                                    }}
                                  >
                                    Delete
                                  </Text>
                                )}
                              </Hoverable>
                            </MenuOption>
                          )}

                          <MenuOption
                            onSelect={() => {
                              props.setPostEditDetails(item);
                              props.navigation.navigate("editPost");
                            }}
                          >
                            <Hoverable>
                              {(isHovered) => (
                                <Text
                                  style={{
                                    textAlign: "center",
                                    color:
                                      isHovered == true
                                        ? Colors.blueColor
                                        : "#000",
                                    fontFamily: ConstantFontFamily.defaultFont,
                                  }}
                                >
                                  Edit
                                </Text>
                              )}
                            </Hoverable>
                          </MenuOption>
                        </MenuOptions>
                      </Menu>
                    ) : null}
                  </View>
                </View>

                <View
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    alignItems: "center",
                    paddingHorizontal: "12%",
                  }}
                >
                  {Platform.OS == "web" ? (
                    <TextInput
                      value={title}
                      readOnly={true}
                      style={[
                        ButtonStyle.feedListTitle,
                        {
                          height: Math.max(25, titleHeight),
                        },
                      ]}
                      scrollEnabled={false}
                      multiline={true}
                      onContentSizeChange={(e) => {
                        settitleHeight(e.nativeEvent.contentSize.height);
                      }}
                    />
                  ) : (
                    <Text
                      selectable
                      style={[
                        ButtonStyle.profileTitleStyle,
                        {
                          textAlign: "center",
                          color: "#000",
                          fontFamily: ConstantFontFamily.defaultFont,
                          fontSize: 16,
                          paddingVertical: 20,
                          overflow: "hidden",
                          fontWeight: "bold",
                        },
                      ]}
                    >
                      {title}
                    </Text>
                  )}
                </View>
                <View
                  style={{
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    marginBottom: 10,
                    marginTop: Dimensions.get("window").width >= 750 ? 0 : 20,
                  }}
                >
                  {/* {console.log(users, 'cliks==========')} */}
                  {cliks?.edges.map((data1, index) => {
                    return (
                      <TouchableOpacity
                        onPress={() => goToClikProfile(data1.node?.name)}
                        key={index}
                        style={{
                          flexDirection: "row",
                          marginRight: 10,
                          alignItems: "center",
                        }}
                      >
                        <View
                          style={[
                            ButtonStyle.clikNameBackgroundStyle,
                            {
                              marginTop: 5,
                              backgroundColor: "#E8F5FA",
                              marginLeft: 0,
                            },
                          ]}
                        >
                          <Text
                            style={[
                              ButtonStyle.clikNameTitleStyle,
                              { fontSize: 11, color: Colors.blueColor },
                            ]}
                          >
                            #{data1.node?.name}
                          </Text>
                        </View>
                      </TouchableOpacity>
                    );
                  })}

                  {cliks?.edges.length < 2
                    ? users?.edges.map((data2, index) => {
                        return (
                          <TouchableOpacity
                            key={index}
                            style={{
                              flexDirection: "row",
                              marginRight: 10,
                              alignItems: "center",
                            }}
                            onPress={() =>
                              goToUserProfile(data2.node?.username)
                            }
                          >
                            <Image
                              source={
                                data2.node?.profile_pic
                                  ? { uri: data2.node?.profile_pic }
                                  : require("../assets/image/userDefault.png")
                              }
                              style={{
                                width: 20,
                                height: 20,
                                marginRight: 5,
                                marginLeft: 0,
                              }}
                            />
                            <Text
                              style={{
                                fontSize: 11,
                                fontFamily: ConstantFontFamily.defaultFont,
                                color: "#000",
                              }}
                            >
                              @{data2.node?.username}
                            </Text>
                          </TouchableOpacity>
                        );
                      })
                    : null}
                </View>

                {/* <View
                  style={{
                    flexDirection: 'row', width: '100%', justifyContent: 'center', flexWrap: 'wrap', marginBottom: 10, marginTop: Dimensions.get("window").width >= 750 ? 0 : 20
                  }}
                >



                  {cliks > 0 ?

                    <FeedImageDisplayUserNotExpanded
                      item={cliks.edges}
                      item2={users.edges}
                      currentusername={author &&
                        author.username}
                    /> : null}
                  {users > 0 ?

                    <FeedUserIcon
                      summaryHeight={summaryHeight}
                      item={cliks.edges}
                      item2={users.edges}
                      currentusername={author &&
                        author.username}
                    /> : null
                  }
                </View> */}
                {/* <View style={{ flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}> */}

                <View
                  style={{
                    width: "100%",
                    paddingRight: 5,
                    alignSelf: "flex-end",
                    marginBottom: 10,
                    paddingLeft: Dimensions.get("window").width >= 750 ? 0 : 5,
                  }}
                >
                  {Dimensions.get("window").width >= 750 ? (
                    <Text
                      style={{
                        color: "#000",
                        paddingHorizontal: 5,
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 15,
                        lineHeight: 20,
                        paddingVertical: 10,
                        overflow: "hidden",
                        textAlign: "left",
                      }}
                    >
                      {summary}
                    </Text>
                  ) : null}

                  {/* <CommonTooltipBottom
                    props={props.props.item}
                    item={item}
                    heartCount={heartCountNum && heartCountNum}
                    type={'relatedpost'}
                    handleClickComment={handleClickComment}
                  /> */}
                  <CommonTooltipBottomPost
                    props={props}
                    item={item}
                    //   heartCount={heartCountNum && heartCountNum}
                    type={"relatedpost"}
                    //   handleClickComment={handleClickComment}
                    // handleDoubleTap={handleDoubleTap}
                    handleClickComment={handleClickComment}
                  />
                </View>
                {/* </View> */}
              </View>
            }
          </View>
        ) : null}
        {props.windowSize.width > 1200 || props.show ? (
          <View style={FeedCommentStyle.commentContainer}>
            <View style={{ width: "92%", marginLeft: 10 }}>
              <View
                style={[
                  ButtonStyle.TabbarBorderStyleForDashboard,
                  {
                    height: 55,
                    alignItems: "center",
                    paddingHorizontal: 10,
                    paddingVertical: 10,
                    marginLeft: 0,
                    width: "100%",
                    justifyContent: props.show ? "flex-start" : "center",
                    alignItems: "center",
                    flexDirection: "row",
                    backgroundColor: "#fff",
                    marginBottom: 10,
                    // borderTopWidth: 2,
                  },
                ]}
              >
                {props.show == true ? (
                  <TouchableOpacity
                    style={[ButtonStyle.headerBackStyle, { width: "20%" }]}
                    onPress={() => props.showDiscussion(false)}
                  >
                    <Icon
                      color={"#000"}
                      name="angle-left"
                      type="font-awesome"
                      size={40}
                    />
                  </TouchableOpacity>
                ) : null}
                <Text
                  style={[
                    ButtonStyle.tabbarTitleStyle,
                    { width: props.show ? "55%" : "100%", textAlign: "center" },
                  ]}
                >
                  {" "}
                  Discussion
                </Text>
              </View>

              <CommentDetailScreen
                type=""
                navigation={props.navigation}
                postId={props.PostDetails && props.PostDetails.node.id}
                listScroll={listScroll}
                // ProfileHeight={state.ProfileHeight}
                // commentDelay={state.commentDelay}
                // scrollY={state.scrollY}
                // ActiveTab={state.routes[this.state.index].title}
              />
            </View>
          </View>
        ) : null}
      </View>
    </View>
  );
};

const mapStateToProps = (state) => ({
  // trendingHomeFeedId: state.PostCommentDetailsReducer.get("PostId"),
  PostId: state.PostCommentDetailsReducer.PostId,
  windowSize: state.windowResizeReducer.windowResize,
  show: state.AdminTrueFalseReducer.showDiscussions,
});

const mapDispatchToProps = (dispatch) => ({
  setPostEditDetails: (payload) => dispatch(postEditDetails(payload)),
  setFeedReportModalAction: (payload) =>
    dispatch(setFEEDREPORTMODALACTION(payload)),
  setPostCommentDetails: (payload) => dispatch(setPostCommentDetails(payload)),
  setComment: (payload) => dispatch({ type: "SET_COMMENT", payload }),
  // setPostCommentReset: payload =>
  //   dispatch({ type: "POSTCOMMENTDETAILS_RESET", payload }),
  showComment: (payload) => dispatch({ type: "SHOW_COMMENT", payload }),
  focusCreateComment: (payload) =>
    dispatch({ type: "FOCUS_CREATE_COMMENT", payload }),
  setPostShareModel: (payload) => dispatch(setPostShareModel(payload)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  React.memo(RelatedPostScreen)
);
