import React, { useRef } from "react";
import {
  // AsyncStorage,
  Dimensions,
  Image,
  Platform,
  Text,
  TouchableOpacity,
  View,
  Animated,
} from "react-native";
import HeaderRightDashboard from "./HeaderRightDashboard";
import ConstantFontFamily from "../constants/FontFamily";
import { Icon } from "react-native-elements";
import ButtonStyle from "../constants/ButtonStyle";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import SearchInputWeb from "./SearchInputWeb";
import { leftPanelModalFunc } from "../reducers/AdminTrueFalseReducer";
import { setTerm } from "../reducers/ScreenLoadingReducer";

const Header = (props) => {
  const nameInput = useRef(null);

  const { title, navigation, getsearchBarStatus, loginStatus } = props;



  return (
    <>
      {title == "home" && (
        <View
          style={{
            width: "100%",
            flexDirection: "row",
            justifyContent: "space-between",
            backgroundColor: "#000",
            height: 50,
            alignItems: "center",
          }}
        >
          <TouchableOpacity
            style={{ width: "10%" }}
            onPress={() => {
              props.leftPanelModalFunc(true);
            }}
          >
            <Image
              source={require("../assets/image/logolastOne.png")}
              style={{
                marginRight: 5,
                //   alignSelf: "center",
                height: 35,
                width: 35,
                marginLeft: 15,
                borderRadius: 5,
                // borderRadius: 8
              }}
              resizeMode={"contain"}
            />
          </TouchableOpacity>

          <View
            style={{
              width: "80%",
              // alignSelf: getsearchBarStatus ? "auto" : "flex-end",
              marginRight: 15,
            }}
          >
            <HeaderRightDashboard navigation={navigation} />
          </View>
        </View>
      )}

      {title != "home" && (
        <Animated.View
          style={{
            left: 0,
            right: 0,
            zIndex: 10,
            overflow: "hidden",
            borderRadius: Dimensions.get("window").width <= 750 ? 0 : 6,
          }}
        >
          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <View
              style={{
                width: "100%",
                height: 50,
                flexDirection: "row",
                alignItems: "center",
                marginBottom: Dimensions.get("window").width <= 750 ? 0 : 10,
                backgroundColor: "#000",
                borderRadius: Dimensions.get("window").width <= 750 ? 0 : 6,
              }}
            >
              <TouchableOpacity
                style={[
                  ButtonStyle.headerBackStyle,
                  {
                    width: "5%",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignSelf: "center",
                  },
                ]}
                onPress={() => {

                  props.setTerm("")

                  if (navigation.canGoBack()) {
                    navigation.goBack();

                  } else {
                    // Handle no back screen case
                    navigation.navigate("home");

                  }
                }}
              >
                <Icon
                  color={"#fff"}
                  name="angle-left"
                  type="font-awesome"
                  size={40}
                />
              </TouchableOpacity>
              {title == "Search" ? (
                <View style={{ width: "80%" }}>
                  <SearchInputWeb
                    // title="Discussion"
                    navigation={navigation}
                    refs={(ref) => {
                      this.input = ref;
                    }}
                    displayType={"web"}
                    press={(status) => {
                      this.setState({ showSearchIcon: status });
                    }}
                    nameInput={nameInput}
                  />
                </View>
              ) : null}
              {!getsearchBarStatus && title != "Search" && (
                <TouchableOpacity
                  style={{
                    flex: loginStatus
                      ? 1
                      : !loginStatus && Platform.OS == "web"
                        ? 1
                        : !loginStatus && Platform.OS != "web" && 0,
                    justifyContent: "center",
                    marginLeft: 10,
                  }}
                >
                  <Text
                    style={{
                      color: "#fff",
                      fontWeight: "bold",
                      fontSize: 18,
                      fontFamily: ConstantFontFamily.defaultFont,
                    }}
                  >
                    {/* {props.route?.params?.id
                      ? props.route?.params?.id
                      : props.route.name} */}
                    {title}
                  </Text>
                </TouchableOpacity>
              )}
            </View>
          </View>
        </Animated.View>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  getsearchBarStatus: state.AdminTrueFalseReducer.searchBarOpenStatus,
});
const mapDispatchToProps = (dispatch) => ({
  userId: (payload) => dispatch(getCurrentUserProfileDetails(payload)),
  searchOpenBarStatus: (payload) => dispatch(searchOpenBarStatus(payload)),
  leftPanelModalFunc: (payload) => dispatch(leftPanelModalFunc(payload)),
  setTerm: (payload) => dispatch(setTerm(payload)),

  setTermWeb: (payload) => dispatch({ type: "SET_TERM_WEB", payload }),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(Header);
