import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    screen:''
};

const screenNameReducer = createSlice({
    name: 'screenNameReducer',
    initialState,
    reducers: {
        screen(state, action) {
            return{
                ...state,
                screen:action.payload
            }
        },
    }
})

export const { screen} = screenNameReducer.actions
export default screenNameReducer.reducer