import { takeEvery, call, put } from 'redux-saga/effects';
import { CLIKMEMBERS, CLIKMEMBERS_SUCCESS, CLIKMEMBERS_FAILURE } from "../constants/Action";
import appolloClient from '../client';
import { ClikMembersMutation } from '../graphqlSchema/PostMutation';
import { clikMemberSuccess } from '../reducers/TrendingCliksProfileReducer';


const getResponse = (req) => {
    // console.log(req, 'req=========')
    let redId = ''
    if (req.id.includes("Clik:"))
    {
        redId = req.id.replace('Clik:','')
    }else {
        redId = req.id  
    }
    return appolloClient.query({
        query: ClikMembersMutation,
        variables: {
            id: "Clik:"+redId,
            first: 20
        },
        fetchPolicy: 'no-cache'
    }).then(res => res)
        ;
};

export function* ClikMembersMiddleware({ payload }) {
    try {
        const response = yield call(getResponse, payload);
        yield put(clikMemberSuccess(response));
    } catch (err) {
        yield put({
            type: CLIKMEMBERS_FAILURE,
            payload: err.message
        });
        console.log(err);
    }
}

export default function* ClikMembersMiddlewareWatcher() {
    yield takeEvery(CLIKMEMBERS, ClikMembersMiddleware);

}