import ConstantFontFamily from "../../constants/FontFamily";
import { Dimensions, Platform } from "react-native";
import Colors from '../../constants/Colors';


const feedCommentContainer = {
    flexDirection: "row",
    width: "100%",
    height: "100%",
    marginLeft: 0,
    // marginLeft: Dimensions.get("window").width < 1200 && Dimensions.get("window").width >= 750 ? 13 : 0,
    backgroundColor: '#fff',
}

const commentContainer = {
    width:Dimensions.get('window').width > 1200 && Dimensions.get('window').width < 1600 ? 450 :
    Dimensions.get('window').width > 1600 && 600,
    marginBottom: 5,
    // marginLeft: 1,
    marginRight: Dimensions.get("window").width >= 750 && Dimensions.get("window").width <= 1200 ? 5 : 0,
    display:
        Dimensions.get("window").width >= 750 && Platform.OS == "web"
            ? "flex"
            : "none",
    // marginLeft: 5,
    // height: this.state.scrollY > 250 ? Dimensions.get("window").height - 50 : Dimensions.get("window").height - 300,
    backgroundColor: Colors.whiteBackground,
    borderLeftWidth: 1,
    borderColor: '#d3d3d3'
}

export default {
    feedCommentContainer,
    commentContainer,

}