import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import React, { lazy, Suspense } from "react";
import {
  TouchableOpacity,
  // AsyncStorage,
  Dimensions,
  Image,
  Platform,
  Text,
  View,
  StyleSheet,
} from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Button } from "react-native-elements";
import {
  Menu,
  MenuOption,
  MenuOptions,
  MenuTrigger,
} from "react-native-popup-menu";
import { Hoverable } from "react-native-web-hooks";
import { connect } from "react-redux";
import { compose } from "react-recompose";
// import { setAdminStatus } from "../actionCreator/AdminAction";
import { setAdminStatus } from "../reducers/AdminTrueFalseReducer";
import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";
import { setSHARELINKMODALACTION } from "../reducers/AdminTrueFalseReducer";
// import { setSIGNUPMODALACTION } from "../actionCreator/SignUpModalAction";
// import {
//   setLoginStatus,
//   saveUserLoginDaitails,
// } from "../actionCreator/UserAction";
import { setUSERNAMEMODALACTION } from "../reducers/AdminTrueFalseReducer";
import { getCurrentUserProfileDetails } from "../actionCreator/UserProfileDetailsAction";
import { setLoginStatus } from "../reducers/UserReducer";
import ConstantFontFamily from "../constants/FontFamily";
import { Badge, Icon } from "react-native-elements";
// import this.props.navigation from "../library/this.props.navigation";
import { setUserApproachAction } from "../actionCreator/UserApproachAction";
import { setScreenLoadingModalAction } from "../actionCreator/ScreenLoadingModalAction";
// import { setLocalStorage } from "../library/Helper";
import jwt_decode from "jwt-decode";
import {
  UserLoginMutation,
  UserFollowingMutation,
  ClikFollowingMutation,
  TopicFollowingMutation,
  ExternalFeedFollowingMutation,
} from "../graphqlSchema/UserMutation";
import AppHelper from "../constants/AppHelper";
// import { getHomefeedList } from "../actionCreator/HomeFeedAction";
// import { getTrendingClicks } from "../actionCreator/TrendingCliksAction";
// import { getTrendingExternalFeeds } from "../actionCreator/TrendingExternalFeedsAction";
// import { getTrendingTopics } from "../actionCreator/TrendingTopicsAction";
// import { getTrendingUsers } from "../actionCreator/TrendingUsersAction";
import appolloClient from "../client";
import {
  GetNumUnreadNotificationsMutation,
  MarkNotificationsAsReadMutation,
} from "../graphqlSchema/Notification";
import { GetAccountNotificationsMutation } from "../graphqlSchema/Notification";
// import { ClikPost } from "../reducers/action/Completed";
// import { Popable } from 'react-native-popable';
import Colors from "../constants/Colors";

import { searchOpenBarStatus } from "../reducers/AdminTrueFalseReducer";
import SearchInputWeb from "./SearchInputWeb";

// const SearchInputWeb = lazy(() =>
//   import("../components/SearchInputWeb")
// );

// import SearchInputWeb from "../components/SearchInputWeb";

class HeaderRight extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      UnreadNotifications: 0,
      showSearchIcon: true,
      toast: false,
    };
    this.nameInput = React.createRef();
  }
  gotoprofile = () => {
    this.props.userId({
      username:
        this.props.profileData &&
        this.props.profileData.data?.data?.user?.username &&
        this.props.profileData.data?.data?.user?.username
          ? this.props.profileData.data?.data?.user?.username
          : this.props.profileData.data?.user?.username,
      type: "feed",
    });
    this.props.navigationRef.current?.navigate("userProfile", {
      id: this.props.profileData?.data?.data?.user?.username
        ? this.props.profileData.data?.data?.user?.username
        : this.props.profileData.data?.user?.username,
    });
  };

  logout = async () => {
    await localStorage.clear();
    const auth = getAuth();
    await signOut(auth);
    await this.props.changeLoginStatus(0);
    await this.props.changeAdminStatus(false);
    // if (
    //   (await this.props.navigation.getCurrentRoute().routeName) ==
    //   "notification" ||
    //   (await this.props.navigation.getCurrentRoute().routeName) == "settings" ||
    //   (await this.props.navigation.getCurrentRoute().routeName) == "info" ||
    //   (await this.props.navigation.getCurrentRoute().routeName) == "feedId" ||
    //   (await this.props.navigation.getCurrentRoute().routeName) == "profile"
    // ) {
    this.props.navigationRef.current?.navigate("home");

    //  window.location.reload();
    // }
    // await this.props.resetLoginUserDetails();
    // await this.props.resetUserProfileDetails();

    if (Platform.OS == "web") {
      this.extensionLogout();
    }
    // var req = indexedDB.deleteDatabase("firebaseLocalStorageDb");
  };

  extensionLogout = () => {
    try {
      window.parent.postMessage({ type: "wecklid_logout" }, "*");
    } catch (err) {
      console.log("Extension Logout Error ", err);
    }
  };

  loginHandle = () => {
    // this.props.leftPanelModalFunc(false);
    // this.props.setLoginButtonText("Login")
    this.props.loginModalStatus(true);
    // this.props.ClikPost(0);
  };

  inviteSignHandle = async () => {
    // this.props.ClikPost(0);
    // await this.props.leftPanelModalFunc(false);
    // await this.props.setGoogleLogin(false);
    // await this.props.setInviteUserDetail({
    //   clikName: "",
    //   inviteKey: "",
    //   userName: "",
    // });
    await this.props.setUsernameModalStatus(true);
  };

  componentDidMount = async () => {
    if (
      this.props.getUserApproach == "login" ||
      AsyncStorage.getItem("userLoginId") == null
    ) {
      await this.props.setUserApproachAction({ type: "" });
      // this.userLogin();
    }
    if (this.props.getUserApproach == "signUp") {
      this.props.setSignUpModalStatus(true);
    }
    if (this.props.loginStatus == 1) {
      this.getUnreadNotifications();
      this.props.searchOpenBarStatus(false);
    }

    if (this.state.UnreadNotifications > 0) {
      AsyncStorage.setItem(
        "notificationMessageNumber",
        this.state.UnreadNotifications
      );
    }
  };

  onModalClose = async () => {
    await this.props.setLoginButtonText("Logged In!");
    await this.props.setGoogleLogin(true);
    setTimeout(() => {
      this.props.loginModalStatus(false);
      this.props.setLoginButtonText("Login");
      this.props.setGoogleLogin(false);
    }, 1000);
  };

  getUnreadNotifications = () => {
    appolloClient
      .query({
        query: GetNumUnreadNotificationsMutation,
        variables: {
          id: "Account:CurrentUser",
          first: 100,
        },
        fetchPolicy: "no-cache",
      })
      .then(async (res) => {
        this.setState(
          {
            UnreadNotifications: res.data.node.num_unread_notifications,
          },
          () => {
            // this.props.showUnReadNotification(res.data.node.num_unread_notifications);
          }
        );
        // let timer = setTimeout(() => {
        //   if (this.props.loginStatus == 1) {
        //     this.getUnreadNotifications();
        //   }
        // }, 600);
      });
  };

  setMarkAsRead = () => {
    appolloClient
      .query({
        query: MarkNotificationsAsReadMutation,
        variables: {
          id: "Account:CurrentUser",
        },
        fetchPolicy: "no-cache",
      })
      .then(async (res) => {
        if (
          res.data.account_mark_notifications_as_read.status.success == true
        ) {
          this.props.navigationRef.current?.navigate("notification");
          this.setState({
            UnreadNotifications: 0,
            toast: false,
          });
          this.getUnreadNotifications();
        } else {
          this.setState({ toast: true });
        }
      })
      .catch((e) => console.log(e));
  };

  userLogin = async () => {
    let __self = this;
    await this.props.setLoginButtonText("Logging in...");

    const auth = getAuth();
    await onAuthStateChanged(auth, async (res) => {
      if (res) {
        console.log(res, "headerrighttttt onauthstate change");

        AsyncStorage.setItem("googleEmail", res.email);
        __self.props.setScreenLoadingModalAction(true);
        return await res
          .getIdToken(true)
          .then(async function (idToken) {
            AsyncStorage.setItem("userIdTokenFirebase", idToken);
            AsyncStorage.setItem(
              "admin",
              jwt_decode(idToken)?.claims?.admin ? true : false
            );
            await __self.props.changeAdminStatus(
              jwt_decode(idToken)?.claims?.admin
                ? jwt_decode(idToken)?.claims?.admin
                : false
            );

            if (Platform.OS == "web") {
              __self.setUserNameInExtension =
                __self.setLoginTokenInExtension(idToken);
            }
            return idToken;
          })
          .then(async (res) => {
            if (res) {
              await appolloClient
                .query({
                  query: UserLoginMutation,
                  variables: {
                    id: "Account:" + "CurrentUser",
                  },
                  fetchPolicy: "no-cache",
                })
                .then(async (res) => {
                  if (res) {
                    let userfollow = await appolloClient
                      .query({
                        query: UserFollowingMutation,
                        variables: {
                          id: "User:CurrentUser",
                          first: 20,
                        },
                        fetchPolicy: "no-cache",
                      })
                      .then((e) => {
                        return e.data.node.users_following.edges;
                      });

                    let topicfollow = await appolloClient
                      .query({
                        query: TopicFollowingMutation,
                        variables: {
                          id: "User:CurrentUser",
                          first: 20,
                        },
                        fetchPolicy: "no-cache",
                      })
                      .then((e) => {
                        return e.data.node.topics_following.edges;
                      });
                    let clikfollow = await appolloClient
                      .query({
                        query: ClikFollowingMutation,
                        variables: {
                          id: "User:CurrentUser",
                          first: 20,
                        },
                        fetchPolicy: "no-cache",
                      })
                      .then((e) => {
                        return e.data.node.cliks_following.edges;
                      });
                    let externalfollow = await appolloClient
                      .query({
                        query: ExternalFeedFollowingMutation,
                        variables: {
                          id: "User:CurrentUser",
                          first: 20,
                        },
                        fetchPolicy: "no-cache",
                      })
                      .then((e) => {
                        return e.data.node.external_feeds_following.edges;
                      });
                    let userData = {
                      data: res.data.node,
                      externalfollow: externalfollow,
                      clikfollow: clikfollow,
                      topicfollow: topicfollow,
                      userfollow: userfollow,
                    };
                    await this.props.saveLoginUser(userData);
                    await this.props.changeLoginStatus(1);

                    await __self.props.getHomefeed({
                      currentPage: AppHelper.PAGE_LIMIT,
                    });
                    await __self.props.getTrendingUsers({
                      currentPage: AppHelper.PAGE_LIMIT,
                    });
                    await __self.props.getTrendingClicks({
                      currentPage: AppHelper.PAGE_LIMIT,
                    });
                    await __self.props.getTrendingTopics({
                      currentPage: AppHelper.PAGE_LIMIT,
                    });
                    await __self.props.getTrendingExternalFeeds({
                      currentPage: AppHelper.PAGE_LIMIT,
                    });
                    await AsyncStorage.setItem(
                      "userLoginId",
                      res.data.node.user.id
                    );
                    await AsyncStorage.setItem(
                      "MyUserUserId",
                      res.data.node.user.id
                    );
                    await AsyncStorage.setItem(
                      "userIdTokenWeclikd",
                      __self.state.access_key
                    );
                    await AsyncStorage.setItem("UserId", res.data.node.user.id);
                    await AsyncStorage.setItem(
                      "UserName",
                      res.data.node.user.username
                    );
                    await __self.onModalClose();
                    if (Platform.OS == "web") {
                      await __self.setUserNameInExtension(
                        res.data.node.user.username
                      );
                    }
                    await __self.props.setScreenLoadingModalAction(false);
                  } else {
                    __self.props.setScreenLoadingModalAction(false);
                    await __self.props.setUsernameModalStatus(true);

                    __self.props.setLoginButtonText("Login");
                    __self.props.setGoogleLogin(true);
                    __self.props.loginModalStatus(false);
                  }
                });
            }
          })
          .catch((error) => {
            console.log(error);
            __self.props.setScreenLoadingModalAction(false);
            alert("Invalid email or password");
            this.props.setLoginButtonText("Login");
            this.props.setGoogleLogin(false);
            return false;
          });
      }
    });
  };

  setLoginTokenInExtension = (idToken) => (UserName) => {
    try {
      window.parent.postMessage(
        { type: "wecklid_login", userIdTokenFirebase: idToken, UserName },
        "*"
      );
    } catch (e) {
      console.log("extension login Error ", e);
    }
  };

  getAccountNotifications = () => {
    appolloClient
      .query({
        query: GetAccountNotificationsMutation,
        variables: {
          first: 100,
          after: null,
        },
        fetchPolicy: "no-cache",
      })
      .then(async (res) => {});
  };

  render() {
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
          justifyContent:
            this.props.getsearchBarStatus &&
            Dimensions.get("window").width <= 1200
              ? "flex-start"
              : "flex-end",
        }}
      >
        {this.props.getsearchBarStatus == true &&
          Dimensions.get("window").width <= 1200 && (
            <View
              style={{ width: this.props.getsearchBarStatus ? "100%" : null }}
            >
              <SearchInputWeb
                navigation={this.props.navigation}
                refs={(ref) => {
                  this.input = ref;
                }}
                displayType={"web"}
                press={(status) => {
                  this.setState({ showSearchIcon: status });
                }}
                nameInput={this.nameInput}
              />
            </View>
          )}

        {this.props.loginStatus == 1 ? (
          <View
            style={{
              alignItems: "center",
              height: 40,
              flexDirection: "row",
            }}
          >
            {Dimensions.get("window").width >= 1200 && (
              <View
                style={{
                  flexDirection: "row",
                  marginLeft: 10,
                }}
              >
                {Dimensions.get("window").width >= 750 && (
                  <Icon
                    testID="AddLink"
                    size={22}
                    name="edit"
                    type="font-awesome"
                    iconStyle={styles.actionButtonIcon}
                    color="#fff"
                    underlayColor="#000"
                    onPress={() => this.props.setShareLinkModalStatus(true)}
                  />
                )}
                <Icon
                  size={20}
                  name="bar-chart"
                  type="font-awesome"
                  iconStyle={styles.actionButtonIcon}
                  color="#fff"
                  underlayColor="#000"
                  onPress={() => {
                    // if (this.props.NewHomeFeed.length > 0) {
                    this.props.navigationRef.current.navigate("Analytics");
                    // }
                  }}
                />
                <View
                  style={{
                    flexDirection: "row",
                    marginRight:
                      Dimensions.get("window").width <= 750 ? 19 : 45,
                  }}
                >
                  <Icon
                    name="bell-o"
                    size={22}
                    type="font-awesome"
                    iconStyle={{
                      color:
                        Dimensions.get("window").width <= 750 ? "#fff" : "#fff",
                      alignSelf: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    underlayColor={"#000"}
                    color="#fff"
                    onPress={() => {
                      // if (this.props.NewHomeFeed.length > 0) {
                      this.props.navigationRef.current.navigate("Notification");
                      this.setMarkAsRead();
                      // }
                    }}
                  />
                  {this.state.UnreadNotifications > 0 && (
                    <Badge
                      value={this.state.UnreadNotifications}
                      containerStyle={{
                        position: "absolute",
                        top: -5,
                        right: this.state.UnreadNotifications >= 10 ? -15 : -10,
                      }}
                      badgeStyle={{
                        backgroundColor: "#de5246",
                        borderRadius: 10,
                        borderColor: "#de5246",
                        justifyContent: "center",
                      }}
                      textStyle={{
                        textAlign: "center",
                        color: "#fff",
                        fontSize: 13,
                        fontFamily: ConstantFontFamily.defaultFont,
                      }}
                    />
                  )}
                  {this.state.toast ? (
                    <View
                      style={{
                        zIndex: 999,
                        position: "absolute",
                        top: 20,
                        right: 5,
                        width: 150,
                        backgroundColor: "#fff",
                        borderRadius: 20,
                      }}
                    >
                      <Text style={{ color: "#000" }}>
                        you are un authorized
                      </Text>
                    </View>
                  ) : null}
                </View>
              </View>
            )}
            {console.log(
              this.props.getsearchBarStatus,
              "searchBarStatus on 2 cols==="
            )}
            {Dimensions.get("window").width <= 1200 &&
              !this.props.getsearchBarStatus &&
              (console.log("inside the cond"),
              (
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Icon
                    name="search"
                    size={25}
                    type="feather"
                    iconStyle={{
                      color:
                        Dimensions.get("window").width <= 750 ? "#fff" : "#fff",
                      marginRight:
                        Dimensions.get("window").width <= 750 ? 19 : 30,
                    }}
                    color="#fff"
                    underlayColor="#000"
                    onPress={() => {
                      this.props.setSearchBarStatus(true);
                      this.props.searchOpenBarStatus(true);
                      this.setState({ showSearchIcon: false });
                    }}
                  />
                  {Dimensions.get("window").width >= 750 && (
                    <Icon
                      testID="AddLink"
                      size={24}
                      name="plus"
                      type="font-awesome"
                      iconStyle={styles.actionButtonIcon}
                      color="#fff"
                      underlayColor="#000"
                      onPress={() => this.props.setShareLinkModalStatus(true)}
                    />
                  )}
                  {/* {Dimensions.get("window").width >= 1200 && (
                    <Icon
                      size={22}
                      name="bar-chart"
                      type="font-awesome"
                      iconStyle={{
                        color:
                          Dimensions.get("window").width <= 750
                            ? "#000"
                            : "#fff",
                        marginRight:
                          Dimensions.get("window").width <= 750 ? 19 : 30,
                      }}
                      color="#fff"
                      underlayColor="#000"
                      onPress={() => this.props.navigation.navigate("analytics")}
                    />
                  )} */}

                  <View
                    style={{
                      flexDirection: "row",
                      marginRight:
                        Dimensions.get("window").width <= 750 ? 19 : 30,
                    }}
                  >
                    <Icon
                      name="bell-o"
                      size={24}
                      type="font-awesome"
                      iconStyle={[styles.actionButtonIcon, { marginRight: 0 }]}
                      underlayColor={
                        Dimensions.get("window").width <= 750 ? "#000" : "#000"
                      }
                      color="#fff"
                      onPress={() => {
                        this.setMarkAsRead();
                      }}
                    />
                    {this.state.UnreadNotifications > 0 && (
                      <Badge
                        value={this.state.UnreadNotifications}
                        containerStyle={{
                          position: "absolute",
                          top: -5,
                          right:
                            this.state.UnreadNotifications >= 10 ? -15 : -10,
                        }}
                        badgeStyle={{
                          backgroundColor: "#de5246",
                          borderRadius: 10,
                          borderColor: "#de5246",
                          justifyContent: "center",
                        }}
                        textStyle={{
                          textAlign: "center",
                          color: "#fff",
                          fontSize: 13,
                          fontFamily: ConstantFontFamily.defaultFont,
                        }}
                      />
                    )}
                  </View>
                  <TouchableOpacity
                    testID="ProfileIcon"
                    onPress={() => {
                      this.props.leftPanelModalFunc(true);
                      this.props.navigationRef.current.navigate("userProfile", {
                        id: "",
                      });
                    }}
                  >
                    {/* {this.props.profileData && this.props.profileData?.data?.user?.profile_pic ? (
                      <Image
                        source={{
                          uri: this.props.profileData?.data?.user?.profile_pic,
                        }}
                        style={{
                          height: 27,
                          width: 27,
                          borderRadius: 13,
                          borderWidth: 1,
                          borderColor:
                            Dimensions.get("window").width <= 750
                              ? "#fff"
                              : "#fff",
                          marginRight:
                            Dimensions.get("window").width <= 1200 ? 10 : null,
                        }}
                        navigation={this.props.navigation}
                      />
                    ) : ( */}
                    <Image
                      source={
                        this.props.profileData?.data?.data?.user?.profile_pic
                          ? this.props.profileData?.data?.data?.user
                              ?.profile_pic
                          : this.props.profileData?.data?.user?.profile_pic
                          ? this.props.profileData?.data?.user?.profile_pic
                          : this.props.profileData?.user?.profile_pic
                          ? this.props.profileData?.user?.profile_pic
                          : require("../assets/image/default-image.png")
                      }
                      style={{
                        height: 27,
                        width: 27,
                        borderRadius: 13,
                        borderWidth: 1,
                        borderColor:
                          Dimensions.get("window").width <= 750
                            ? "#fff"
                            : "#fff",
                      }}
                      navigation={this.props.navigation}
                    />
                    {/* )} */}
                  </TouchableOpacity>
                </View>
              ))}

            {this.state.showSearchIcon === true &&
              Dimensions.get("window").width >= 1200 && (
                <Menu>
                  <MenuTrigger testID="ProfileIcon">
                    {/* {this.props.profileData && this.props.profileData?.data?.user?.profile_pic ? (
                      <Image
                        source={{
                          uri: this.props.profileData?.data?.user?.profile_pic,
                        }}
                        style={{
                          height: 27,
                          width: 27,
                          borderRadius: 13,
                          borderWidth: 1,
                          borderColor:
                            Dimensions.get("window").width <= 750
                              ? "#fff"
                              : "#fff",
                          marginRight:
                            Dimensions.get("window").width <= 1200 ? 10 : null,
                        }}
                        navigation={this.props.navigation}
                      />
                    ) : ( */}
                    <Image
                      source={this.props.profileData?.data?.user?.profile_pic}
                      style={{
                        height: 27,
                        width: 27,
                        borderRadius: 13,
                        borderWidth: 1,
                        borderColor:
                          Dimensions.get("window").width <= 750
                            ? "#fff"
                            : "#fff",
                      }}
                      navigation={this.props.navigation}
                    />
                    {/* )} */}
                  </MenuTrigger>
                  <MenuOptions
                    optionsContainerStyle={{
                      borderRadius: 10,
                      borderWidth: 1,
                      borderColor: "#c5c5c5",
                      shadowColor: "transparent",
                      marginLeft: 0,
                      // this.props.windowSize.width > 1600 ? -180 : 0,
                    }}
                    customStyles={{
                      optionsContainer: {
                        marginTop: 50,
                        // right:270,
                        // marginLeft:-1100
                      },
                      optionWrapper: {
                        padding: 5,
                      },
                    }}
                  >
                    <MenuOption onSelect={() => this.gotoprofile()}>
                      <Hoverable>
                        {(isHovered) => (
                          <Text
                            testID="ProfileDetails"
                            style={{
                              textAlign: "center",
                              color:
                                isHovered == true ? Colors.blueColor : "#000",
                              fontFamily: ConstantFontFamily.defaultFont,
                            }}
                          >
                            Profile
                          </Text>
                        )}
                      </Hoverable>
                    </MenuOption>

                    <MenuOption
                      onSelect={() => {
                        //  this.props.navigation.navigate("settings");
                        this.props.navigationRef.current?.navigate("settings");
                      }}
                    >
                      <Hoverable>
                        {(isHovered) => (
                          <Text
                            style={{
                              textAlign: "center",
                              color:
                                isHovered == true ? Colors.blueColor : "#000",
                              fontFamily: ConstantFontFamily.defaultFont,
                            }}
                          >
                            Settings
                          </Text>
                        )}
                      </Hoverable>
                    </MenuOption>
                    {/* <MenuOption
                      onSelect={() =>
                        this.props.navigation.navigate("analytics")
                      }
                    >
                      <Hoverable>
                        {(isHovered) => (
                          <Text
                            style={{
                              textAlign: "center",
                              color: isHovered == true ? Colors.blueColor : "#000",
                              fontFamily: ConstantFontFamily.defaultFont,
                            }}
                          >
                            Analytics
                          </Text>
                        )}
                      </Hoverable>
                    </MenuOption> */}
                    <MenuOption onSelect={() => this.logout()}>
                      <Hoverable>
                        {(isHovered) => (
                          <Text
                            testID="SignOut"
                            style={{
                              textAlign: "center",
                              color:
                                isHovered == true ? Colors.blueColor : "#000",
                              fontFamily: ConstantFontFamily.defaultFont,
                            }}
                          >
                            Sign Out
                          </Text>
                        )}
                      </Hoverable>
                    </MenuOption>
                  </MenuOptions>
                </Menu>
              )}
          </View>
        ) : (
          <View>
            {this.props.getScreenLoadingStatus ? null : (
              <View
                style={{
                  // flex: 1,
                  flexDirection: "row",
                  marginLeft: 10,
                  // display:
                  //   Dimensions.get("window").width <= 750 ? "none" : "flex",
                }}
              >
                {this.props.loginStatus == 0 && (
                  <Button
                    testID="Sign1"
                    title="Sign Up"
                    titleStyle={{
                      fontSize: 14,
                      fontFamily: ConstantFontFamily.defaultFont,
                      fontWeight: "bold",
                      color:
                        Dimensions.get("window").width <= 750 ? "#000" : "#fff",
                    }}
                    buttonStyle={{
                      backgroundColor:
                        Dimensions.get("window").width <= 750
                          ? "#f4f4f4"
                          : "#000",
                      borderColor:
                        Dimensions.get("window").width <= 750
                          ? "#f4f4f4"
                          : "#fff",
                      borderRadius: 6,
                      borderWidth: 1,
                      alignSelf: "center",
                      width: 80,
                      height: 30,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    containerStyle={{
                      alignSelf: "center",
                      marginRight: 10,
                    }}
                    onPress={() => this.inviteSignHandle()}
                  />
                )}

                {this.props.loginStatus == 0 && (
                  <Button
                    testID="Login1"
                    title="Login"
                    titleStyle={{
                      fontSize: 14,
                      fontFamily: ConstantFontFamily.defaultFont,
                      fontWeight: "bold",
                      color:
                        Dimensions.get("window").width <= 750 ? "#000" : "#fff",
                    }}
                    buttonStyle={{
                      backgroundColor:
                        Dimensions.get("window").width <= 750
                          ? "#f4f4f4"
                          : "#000",
                      borderColor:
                        Dimensions.get("window").width <= 750
                          ? "#f4f4f4"
                          : "#fff",
                      borderRadius: 6,
                      borderWidth: 1,
                      alignSelf: "center",
                      width: 80,
                      height: 30,
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    containerStyle={{
                      alignSelf: "center",
                    }}
                    onPress={this.loginHandle}
                  />
                )}
              </View>
            )}
          </View>
        )}
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  loginStatus: state.UserReducer.loginStatus,
  profileData: state.LoginUserDetailsReducer.userLoginDetails,
  windowSize: state.windowResizeReducer.windowResize,
  getsearchBarStatus: state.AdminTrueFalseReducer.searchBarOpenStatus,

  // getScreenLoadingStatus: state.ScreenLoadingReducer.get("modalStatus"),
  // getUserApproach: state.UserApproachReducer.get("setUserApproach"),
  // getsearchBarStatus: state.AdminTrueFalseReducer.get("searchBarOpenStatus"),
  // NewHomeFeed: state.HomeFeedReducer.get("NewHomeFeedList"),
  // userDetails: state.UserProfileDetailsReducer.get(
  //   "getCurrentUserProfileDetails"
  // ),
});

const mapDispatchToProps = (dispatch) => ({
  // loginModalStatus: (payload) => dispatch(loginModalSuccess(payload))
  userId: (payload) => dispatch(getCurrentUserProfileDetails(payload)),
  loginModalStatus: (payload) => dispatch(setLOGINMODALACTION(payload)),
  // setSignUpModalStatus: (payload) => dispatch(setSIGNUPMODALACTION(payload)),
  setUsernameModalStatus: (payload) =>
    dispatch(setUSERNAMEMODALACTION(payload)),
  setShareLinkModalStatus: (payload) =>
    dispatch(setSHARELINKMODALACTION(payload)),
  changeLoginStatus: (payload) => dispatch(setLoginStatus(payload)),
  changeAdminStatus: (payload) => dispatch(setAdminStatus(payload)),
  setSearchBarStatus: (payload) =>
    dispatch({ type: "SEARCH_BAR_STATUS", payload }),
  searchOpenBarStatus: (payload) => dispatch(searchOpenBarStatus(payload)),

  // setUserApproachAction: (payload) => dispatch(setUserApproachAction(payload)),
  // setScreenLoadingModalAction: (payload) =>
  //   dispatch(setScreenLoadingModalAction(payload)),
  // saveLoginUser: (payload) => dispatch(saveUserLoginDaitails(payload)),
  // getHomefeed: (payload) => dispatch(getHomefeedList(payload)),
  // getTrendingUsers: (payload) => dispatch(getTrendingUsers(payload)),
  // getTrendingTopics: (payload) => dispatch(getTrendingTopics(payload)),
  // getTrendingClicks: (payload) => dispatch(getTrendingClicks(payload)),
  // getTrendingExternalFeeds: (payload) =>
  //   dispatch(getTrendingExternalFeeds(payload)),
  // setSearchBarStatus: (payload) =>
  //   dispatch({ type: "SEARCH_BAR_STATUS", payload }),
  // resetLoginUserDetails: (payload) =>
  //   dispatch({ type: "LOGIN_USER_DETAILS_RESET", payload }),
  // resetUserProfileDetails: (payload) =>
  //   dispatch({ type: "USER_PROFILE_DETAILS_RESET", payload }),

  // setLoginButtonText: (payload) =>
  //   dispatch({ type: "SET_LOGIN_BUTTON_TEXT", payload }),
  // setInviteUserDetail: (payload) =>
  //   dispatch({ type: "SET_INVITE_USER_DETAIL", payload }),
  // setGoogleLogin: (payload) => dispatch({ type: "SET_GOOGLE_LOGIN", payload }),
  // leftPanelModalFunc: (payload) =>
  //   dispatch({ type: "LEFT_PANEL_OPEN", payload }),
  // searchOpenBarStatus: (payload) =>
  //   dispatch({ type: "SEARCHBAR_STATUS", payload }),
  // showUnReadNotification: (payload) =>
  //   dispatch({ type: "NOTIFICATION", payload }),
  // ClikPost: (payload) => dispatch(ClikPost(payload)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  HeaderRight
);

const styles = StyleSheet.create({
  actionButtonIcon: {
    backgroundColor: "#000",
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
    marginRight: 45,
  },
});
