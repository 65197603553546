import { createSlice, configureStore } from '@reduxjs/toolkit';
import createReducer from './reducers/index'
// import createSagaMiddleware from 'redux-saga';

import { persistStore, persistReducer,FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import AsyncStorage from '@react-native-async-storage/async-storage';
import createSagaMiddleware from 'redux-saga';

import sagas from './Middleware/index';

const sagaMiddleware = createSagaMiddleware()


const persistConfig = {
  key: 'weclikStorage',
  storage: AsyncStorage,

};
const persistedReducer = persistReducer(persistConfig, createReducer());


export const store = configureStore({
  reducer: persistedReducer,
  // undefined,
  // middleware: [sagaMiddleware]
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware({
  //     serializableCheck: {
  //       ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  //     },
  //   }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([sagaMiddleware]),
})

sagaMiddleware.run(sagas);


export const persistor = persistStore(store);




// sagaMiddleware.run(mySaga)




// export default store;
