// import firebase from "firebase/app";
// import "firebase/auth";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import React, { Component, lazy, Suspense } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  Animated,
  // AsyncStorage,
  Dimensions,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import * as Animatable from "react-native-animatable";
import Accordion from "react-native-collapsible/Accordion";
import { Icon } from "react-native-elements";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import InviteFriend from "../components/InviteFriend";
import PaymentInfo from "../components/PaymentInfo";
import Security from "../components/Security";
import Subscription from "../components/Subscription";
import ButtonStyle from "../constants/ButtonStyle";
import ConstantFontFamily from "../constants/FontFamily";
import Colors from "../constants/Colors";
import LeftPanel from "../components/LeftPanel";

class SettingsScreen extends Component {
  constructor(props) {
    super(props);
    this.CONTENT = [
      {
        title: "Membership",
        content: <Subscription />,
      },
      {
        title: "Payment & Payout Info",
        content: <PaymentInfo />,
      },
      {
        title: "Invite Friends",
        content: <InviteFriend />,
      },
      {
        title: "Security",
        content: <Security />,
      },
    ];
  }

  logout = async () => {
    await AsyncStorage.multiRemove([
      "userLoginId",
      "MyUserUserId",
      "userIdTokenWeclikd",
      "UserId",
      "admin",
      "rememberMe",
      "skipCredentials",
    ]).then(async (r) => {
      const auth = getAuth();
      await signOut(auth).then(
        function () {
          this.props.changeLoginStatus(0);
          this.props.changeAdminStatus(false);
          if (Platform.OS == "web") {
            this.extensionLogout();
          }
          this.props.navigation.navigate("home");
        },
        function (error) {
          console.error("Sign Out Error", error);
        }
      );
    });
  };

  state = {
    activeSections: [0],
    collapsed: false,
    multipleSelect: false,
  };

  setSections = (sections) => {
    this.setState({
      activeSections: sections.includes(undefined) ? [] : sections,
    });
  };

  extensionLogout = () => {
    try {
      window.parent.postMessage({ type: "wecklid_logout" }, "*");
    } catch (err) {
      console.log("Extension Logout Error ", err);
    }
  };

  componentDidUpdate = async (prevState) => {
    if (prevState.activeSections != this.state.activeSections) {
      let getSession;
      await AsyncStorage.getItem(
        "getSettingActiveSection",
        async (error, result) => {
          getSession = JSON.parse(result);
          AsyncStorage.removeItem("getSettingActiveSection");
          getSession == "Upgrade" && this.setState({ activeSections: [0] });
        }
      );
    }
  };

  renderHeader = (section, _, isActive) => {
    return (
      <Animatable.View
        duration={400}
        style={
          ([styles.header, isActive ? styles.active : styles.inactive],
          {
            flexDirection: "row",
            paddingHorizontal: 20,
          })
        }
        transition="backgroundColor"
      >
        <Icon
          color={"#000"}
          iconStyle={{
            justifyContent: "center",
            alignItems: "center",
            alignSelf: "center",
            justifyContent: "flex-start",
            width: "100%",
          }}
          name={isActive ? "minus" : "plus"}
          type="font-awesome"
          size={20}
          containerStyle={{
            alignSelf: "center",
          }}
        />
        <Text
          style={{
            color: "#000",
            padding: 10,
            borderRadius: 6,
            fontFamily: ConstantFontFamily.defaultFont,
            fontSize: 18,
            fontWeight: "bold",
            width: "90%",
            textAlign: "center",
          }}
        >
          {section.title}
        </Text>
      </Animatable.View>
    );
  };

  renderContent(section, _, isActive) {
    return (
      <Animatable.View
        duration={400}
        style={[styles.content, isActive ? styles.active : styles.inactive]}
        animation={isActive ? "bounceIn" : undefined}
      >
        <Animatable.Text
          duration={400}
          easing="ease-out"
          animation={isActive ? "zoomIn" : false}
        >
          {section.content}
        </Animatable.Text>
      </Animatable.View>
    );
  }

  render() {
    const { multipleSelect, activeSections } = this.state;
    console.log(activeSections, "activeSections");
    return (
      <View
        style={[
          Dimensions.get("window").width > 750
            ? ButtonStyle.threeColStruc
            : null,
          {
            width:
              Dimensions.get("window").width > 750
                ? Dimensions.get("window").width
                : "100%",
          },
        ]}
        scrollEnabled={false}
      >
        {Dimensions.get("window").width > 750 ? (
          <LeftPanel
            // ref={navigatorRef => {
            //   NavigationService.setTopLevelNavigator(navigatorRef);
            // }}
            navigation={this.props.navigation}
          />
        ) : null}
        {/* <ScrollView
          showsVerticalScrollIndicator={false}
          ref={scrollview => {
            this.UserProfilescrollview = scrollview;
          }}
          onLayout={event => {
            let { x, y, width, height } = event.nativeEvent.layout;
            if (width < 1024) {
              this.setState({
                showsVerticalScrollIndicatorView: true,
                currentScreentWidth: width
              });
            } else {
              this.setState({
                showsVerticalScrollIndicatorView: false,
                currentScreentWidth: width
              });
            }
          }}
          style={{
            height:
              Platform.OS != "web"
                ? null
                : Dimensions.get("window").height - 80,
            backgroundColor: Colors.whiteBackground,
            borderLeftWidth: 1,
            borderColor: Colors.lightGray,
            width: Dimensions.get('window').width > 1200 ? 1200 : '100%',

          }}
        > */}
        <View
          style={{
            borderLeftWidth: 1,
            borderColor: Colors.lightGray,
            height: "100%",
            width:
              this.props.windowSize.width > 1200 &&
              this.props.windowSize.width < 1600
                ? 900
                : this.props.windowSize.width > 1600
                ? 1200
                : this.props.windowSize.width <= 750
                ? this.props.windowSize.width
                : this.props.windowSize.width - 310,
          }}
        >
          {Dimensions.get("window").width <= 750 && Platform.OS != "web" ? (
            <Animated.View
              style={{
                position: Platform.OS == "web" ? "sticky" : null,
                top: 0,
                left: 0,
                right: 0,
                zIndex: 10,
                overflow: "hidden",
              }}
            >
              <View
                style={{
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <View
                  style={{
                    width: "100%",
                    flexDirection: "row",
                    backgroundColor: "#000",
                    //borderRadius: 6,
                    height: 50,
                  }}
                >
                  <TouchableOpacity
                    style={ButtonStyle.headerBackStyle}
                    onPress={() => {
                      let nav =
                        this.props.navigation.dangerouslyGetParent().state;
                      if (nav.routes.length > 1) {
                        this.props.navigation.goBack();
                        return;
                      } else {
                        this.props.navigation.navigate("home");
                      }
                    }}
                  >
                    <Icon
                      color={"#fff"}
                      name="angle-left"
                      type="font-awesome"
                      size={40}
                    />
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={[
                      ButtonStyle.headerTitleStyle,
                      { backgroundColor: "#000" },
                    ]}
                  >
                    <Text
                      style={{
                        color: "#fff",
                        textAlign: "center",
                        fontWeight: "bold",
                        fontSize: 18,
                        fontFamily: ConstantFontFamily.defaultFont,
                      }}
                    >
                      Settings
                    </Text>
                  </TouchableOpacity>
                  {/* <View style={[
                      ButtonStyle.headerRightStyle,

                      { width: this.props.getsearchBarStatus ? "80%" : '20%', marginLeft:10 },
                    ]}>
                      <HeaderRightDashboard navigation={this.props.navigation} />
                    </View> */}
                </View>
              </View>
            </Animated.View>
          ) : null}

          <View>
            <View style={styles.container}>
              <ScrollView
                contentContainerStyle={{
                  paddingBottom: 30,
                  // width: Dimensions.get('window').width > 1200 ? 1200 : '100%',
                  borderLeftWidth: 1,
                  borderColor: Colors.lightGray,
                }}
              >
                <Accordion
                  activeSections={activeSections}
                  sections={this.CONTENT}
                  touchableComponent={TouchableOpacity}
                  expandMultiple={multipleSelect}
                  renderHeader={this.renderHeader}
                  renderContent={this.renderContent}
                  duration={400}
                  onChange={this.setSections}
                  sectionContainerStyle={{
                    backgroundColor: "#fff",
                    paddingVertical: 5,
                    width: "100%",
                    marginLeft: Dimensions.get("window").width <= 1200 ? 0 : 2,
                    marginTop: 15,
                    borderColor: "#d3d3d3",
                    borderBottomWidth: 1,
                  }}
                />
                <TouchableOpacity
                  onPress={this.logout}
                  style={{ backgroundColor: "#F4F4F4", alignItems: "center" }}
                >
                  <Text
                    style={{
                      fontSize: 16,
                      fontWeight: "bold",
                      textAlign: "center",
                      backgroundColor: "#fff",
                      color: "#000",
                      padding: 10,
                      borderRadius: 20,
                      borderColor: "#D7D7D7",
                      borderWidth: 1,
                      width: 150,
                      fontFamily: ConstantFontFamily.defaultFont,
                      display: "none",
                    }}
                  >
                    Logout
                  </Text>
                </TouchableOpacity>
              </ScrollView>
            </View>
          </View>
        </View>
        {/* </ScrollView> */}
      </View>
    );
  }
}
const mapStateToProps = (state) => ({
  windowSize: state.windowResizeReducer.windowResize,

  // windowSize: state.windowResizeReducer.windowResize,
});
const mapDispatchToProps = (dispatch) => ({
  // changeLoginStatus: payload => dispatch(setLoginStatus(payload)),
  // changeAdminStatus: payload => dispatch(setAdminStatus(payload))
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  SettingsScreen
);

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    backgroundColor: Colors.whiteBackground,
  },
  header: {
    backgroundColor: "#FFF",
    padding: 10,
  },
  content: {
    backgroundColor: "#fff",
    flex: 1,
  },
  active: {
    backgroundColor: "transparent",
  },
  inactive: {
    backgroundColor: "#fff",
  },
});
