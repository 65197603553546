import React, { Component } from "react";
import {
  Animated,
  Dimensions,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { Icon } from "react-native-elements";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import ConstantColors from "../constants/Colors";
import ConstantFontFamily from "../constants/FontFamily";
import { WebView } from 'react-native-webview';
import { heightPercentageToDP as hp } from "react-native-responsive-screen";
import HomeStyle from "../components/Style/HomeStyle";
import { screen } from '../reducers/action/Completed'

class TermsAndConditionsScreen extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.props.screen('termsScreen')
  }

  render() {
    return (
      <View style={styles.container}>
        <ScrollView
          ref={scrollview => {
            this.Pagescrollview = scrollview;
          }}
          showsVerticalScrollIndicator={false}
          onLayout={event => {
            let { x, y, width, height } = event.nativeEvent.layout;
            if (width < 1024) {
              this.setState({
                showsVerticalScrollIndicatorView: true,
                currentScreentWidth: width
              });
            } else {
              this.setState({
                showsVerticalScrollIndicatorView: false,
                currentScreentWidth: width
              });
            }
          }}
          contentContainerStyle={{
            height:
              Platform.OS !== "web"
                ? null
                : Dimensions.get("window").height - 80,

          }}
        >
          <View>
            {Dimensions.get('window').width <= 750 && Platform.OS != "web" ? (
              <Animated.View
                style={{
                  position: Platform.OS == "web" ? "sticky" : null,
                  top: 0,
                  left: 0,
                  right: 0,
                  zIndex: 10,
                  overflow: "hidden",
                  borderRadius: 0
                }}
              >
                <View
                  style={{
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <View
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      marginBottom: 10,
                      backgroundColor: "#000",
                      borderRadius: 0,
                      height: 42,
                    }}
                  >
                    <TouchableOpacity
                      style={{
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignSelf: "center",
                        paddingLeft: 10
                      }}
                      onPress={() => {
                        this.props.searchOpenBarStatus(false);
                        let nav = this.props.navigation.dangerouslyGetParent()
                          .state;
                        if (nav.routes.length > 1) {
                          this.props.navigation.goBack();
                          return;
                        } else {
                          this.props.navigation.navigate("home");
                        }
                      }}
                    >
                      <Icon
                        color={"#fff"}
                        name="angle-left"
                        type="font-awesome"
                        size={40}
                      />
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{
                        flex: 1,
                        backgroundColor: "#000",
                        justifyContent: "center",
                        marginLeft: 20,
                        borderRadius: 6
                      }}
                    >
                      <Text
                        style={{
                          color: "white",
                          fontWeight: "bold",
                          fontSize: 18,
                          fontFamily: ConstantFontFamily.defaultFont
                        }}
                      >
                        Terms And Conditions
                    </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </Animated.View>
            ) : (
              null
            )}
            <ScrollView showsVerticalScrollIndicator={false}>

              <View
                style={{
                  paddingHorizontal: Platform.OS != "web" ? 5 : 0,
                }}
              >
                {Platform.OS === 'web' ? (
                  <ScrollView contentContainerStyle={[HomeStyle.normalFeedStyleForDashboard, {
                    marginTop: Dimensions.get("window").width <= 1200 ? 0 : 15,
                    marginLeft: Dimensions.get("window").width <= 1200 ? 0 : 15,
                    paddingHorizontal: 20, height: hp('100%') - 100
                  }]}>
                    <Text style={[styles.titleStyle, { alignSelf: 'center' }]}>Terms of Service</Text>
                    <Text style={[styles.titleStyle]}>TERMS OF USE</Text>
                    <Text style={[styles.titleStyle, { alignSelf: 'left', color: 'gray' }]}>Last updated July 13, 2021</Text>
                    <Text style={[styles.titleStyle]}>AGREEMENT TO TERMS</Text>
                    <Text style={styles.normalStyle}> These Terms of Use constitute a legally binding agreement made between you, whether
                      personally or on behalf of an entity (“you”) and Weclikd Inc. ("Company", “we”, “us”, or
                      “our”), concerning your access to and use of the https://weclikd.com website as well as
                      any other media form, media channel, mobile website or mobile application related,
                      linked, or otherwise connected thereto (collectively, the “Site”). The Site provides an
                      online marketplace for the following goods, products, and/or services: We are a social
                      platform offering a digital subscription service. (the “Marketplace Offerings”). In order to
                      help make the Site a secure environment for the purchase and sale of Marketplace
                      Offerings, all users are required to accept and comply with these Terms of Use . You
                      agree that by accessing the Site and/or the Marketplace Offerings, you have read,
                      understood, and agree to be bound by all of these Terms of Use. IF YOU DO NOT
                      AGREE WITH ALL OF THESE TERMS OF USE, THEN YOU ARE EXPRESSLY
                      PROHIBITED FROM USING THE SITE AND/OR THE MARKETPLACE OFFERINGS
                      AND YOU MUST DISCONTINUE USE IMMEDIATELY.</Text>

                    <Text style={styles.normalStyle}>
                      Supplemental terms and conditions or documents that may be posted on the Site from
                      time to time are hereby expressly incorporated herein by reference. We reserve the
                      right, in our sole discretion, to make changes or modifications to these Terms of Use at
                      any time and for any reason. All users who are minors in the jurisdiction in which they
                      reside (generally under the age of 18) must have the permission of, and be directly
                      supervised by, their parent or guardian to use the Site. If you are a minor, you must
                      have your parent or guardian read and agree to these Terms of Use prior to you using
                      the Site.
                    </Text>


                    <Text style={[styles.titleStyle]}>INTELLECTUAL PROPERTY RIGHTS</Text>
                    <Text style={styles.normalStyle}> Unless otherwise indicated, the Site and the Marketplace Offerings are our proprietary
                      property and all source code, databases, functionality, software, website designs, audio,
                      video, text, photographs, and graphics on the Site (collectively, the “Content”) and the
                      trademarks, service marks, and logos contained therein (the “Marks”) are owned or
                      controlled by us or licensed to us, and are protected by copyright and trademark laws
                      and various other intellectual property rights and unfair competition laws of the United
                      States, foreign jurisdictions, and international conventions. No part of the Site or the
                      Marketplace Offerings and no Content or Marks may be copied, reproduced,
                      aggregated, republished, uploaded, posted, publicly displayed, encoded, translated,
                      transmitted, distributed, sold, licensed, or otherwise exploited for any commercial
                      purpose whatsoever, without our express prior written permission.You may not access
                      or use the Site for any purpose other than that for which we make the Site available. As
                      a user of the Site, you agree not to:</Text>

                    <Text style={styles.normalStyle}>1. Systematically retrieve data or other content from the Site to create or compile,
                      directly or indirectly, a collection, compilation, database, or directory without written
                      permission from us.</Text>

                    <Text style={styles.normalStyle}>2. ​ Make any unauthorized use of the Marketplace Offerings, including collecting
                      usernames and/or email addresses of users by electronic or other means for the
                      purpose of sending unsolicited email, or creating user accounts by automated means
                      or under false pretenses.</Text>

                    <Text style={styles.normalStyle}>3. ​ Use the Site to advertise or offer to sell goods and services.</Text>
                    <Text style={styles.normalStyle}>4. ​ Circumvent, disable, or otherwise interfere with security-related features of the
                      Site, including features that prevent or restrict the use or copying of any Content or
                      enforce limitations on the use of the Site and/or the Content contained therein.</Text>
                    <Text style={styles.normalStyle}>5. ​ Trick, defraud, or mislead us and other users, especially in any attempt to learn
                      sensitive account information such as user passwords.</Text>
                    <Text style={styles.normalStyle}>6. ​ Engage in any automated use of the system, such as using scripts to send
                      comments or messages, or using any data mining, robots, or similar data gathering
                      and extraction tools.</Text>
                    <Text style={styles.normalStyle}>7. ​ Interfere with, disrupt, or create an undue burden on the Site or the networks or
                      services connected to the Site.</Text>
                    <Text style={styles.normalStyle}>8. ​ Use the Marketplace Offerings as part of any effort to compete with us or
                      otherwise use the Site and/or the Content for any revenue-generating endeavor or
                      commercial enterprise.</Text>
                    <Text style={styles.normalStyle}>9. ​ Decipher, decompile, disassemble, or reverse engineer any of the software
                      comprising or in any way making up a part of the Site.</Text>
                    <Text style={styles.normalStyle}>10. ​ Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or
                      other material, including excessive use of capital letters and spamming (continuous
                      posting of repetitive text), that interferes with any party’s uninterrupted use and
                      enjoyment of the Site or modifies, impairs, disrupts, alters, or interferes with the use,
                      features, functions, operation, or maintenance of the Marketplace Offerings.</Text>
                    <Text style={styles.normalStyle}>11. ​ Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Site.</Text>
                    <Text style={styles.normalStyle}>12. ​ Use the Site in a manner inconsistent with any applicable laws or regulations.</Text>

                    <Text style={styles.titleStyle}>MOBILE APPLICATION LICENSE</Text>
                    <Text style={styles.normalStyle}>If you access the Marketplace Offerings via a mobile application, then we grant you a
                      revocable, non-exclusive, non-transferable, limited right to install and use the mobile
                      application on wireless electronic devices owned or controlled by you, and to access
                      and use the mobile application on such devices strictly in accordance with the terms
                      and conditions of this mobile application license contained in these Terms of Use. You
                      shall not: (1) decompile, reverse engineer, disassemble, attempt to derive the source
                      code of, or decrypt the application; (2) make any modification, adaptation, improvement,
                      enhancement, translation, or derivative work from the application; (3) violate any
                      applicable laws, rules, or regulations in connection with your access or use of the
                      application; (4) remove, alter, or obscure any proprietary notice (including any notice of
                      copyright or trademark) posted by us or the licensors of the application; (5) use the
                      application for any revenue generating endeavor, commercial enterprise, or other
                      purpose for which it is not designed or intended; (6) make the application available over
                      a network or other environment permitting access or use by multiple devices or users at
                      the same time; (7) use the application for creating a product, service, or software that is,
                      directly or indirectly, competitive with or in any way a substitute for the application; (8)
                      use the application to send automated queries to any website or to send any unsolicited
                      commercial e-mail; or (9) use any proprietary information or any of our interfaces or our
                      other intellectual property in the design, development, manufacture, licensing, or
                      distribution of any applications, accessories, or devices for use with the application.</Text>

                    <Text style={styles.titleStyle}>THIRD-PARTY WEBSITES AND CONTENT</Text>
                    <Text style={styles.normalStyle}>The Site may contain (or you may be sent via the Site or the Marketplace Offerings)
                      links to other websites ("Third-Party Websites") as well as articles, photographs, text,
                      graphics, pictures, designs, music, sound, video, information, applications, software,
                      and other content or items belonging to or originating from third parties ("Third-PartyContent"). Such Third-Party Websites and Third-Party Content are not investigated,
                      monitored, or checked for accuracy, appropriateness, or completeness by us, and we
                      are not responsible for any Third Party Websites accessed through the Site or any
                      Third-Party Content posted on, available through, or installed from the Site, including
                      the content, accuracy, offensiveness, opinions, reliability, privacy practices, or other
                      policies of or contained in the Third-Party Websites or the Third-Party Content. Inclusion
                      of, linking to, or permitting the use or installation of any Third-Party Websites or any
                      Third-PartyContent does not imply approval or endorsement thereof by us. If you decide
                      to leave the Site and access the Third-Party Websites or to use or install any
                      Third-Party Content, you do so at your own risk, and you should be aware these Terms
                      of Use no longer govern. You should review the applicable terms and policies, including
                      privacy and data gathering practices, of any website to which you navigate from the Site
                      or relating to any applications you use or install from the Site. Any purchases you make
                      through Third-Party Websites will be through other websites and from other companies,
                      and we take no responsibility whatsoever in relation to such purchases which are
                      exclusively between you and the applicable third party. You agree and acknowledge
                      that we do not endorse the products or services offered on Third-Party Websites and
                      you shall hold us harmless from any harm caused by your purchase of such products or
                      services. Additionally, you shall hold us harmless from any losses sustained by you or
                      harm caused to you relating to or resulting in any way from any Third-Party Content or
                      any contact with Third-Party Websites.</Text>

                    <Text style={styles.titleStyle}>SITE MANAGEMENT</Text>
                    <Text style={styles.normalStyle}>We reserve the right, but not the obligation, to: (1) monitor the Site for violations of
                      these Terms of Use; (2) take appropriate legal action against anyone who, in our sole
                      discretion, violates the law or these Terms of Use, including without limitation, reporting
                      such user to law enforcement authorities; (3) in our sole discretion and withoutlimitation, refuse, restrict access to, limit the availability of, or disable (to the extent
                      technologically feasible) any of your Contributions or any portion thereof; (4) in our sole
                      discretion and without limitation, notice, or liability, to remove from the Site or otherwise
                      disable all files and content that are excessive in size or are in any way burdensome to
                      our systems; and (5) otherwise manage the Site in a manner designed to protect our
                      rights and property and to facilitate the proper functioning of the Site and the
                      Marketplace Offerings.</Text>

                    <Text style={styles.titleStyle}>PRIVACY POLICY</Text>
                    <Text style={styles.normalStyle}>We care about data privacy and security. By using the Site or the Marketplace
                      Offerings, you agree to be bound by our Privacy Policy posted on the Site, which is
                      incorporated into these Terms of Use. Please be advised the Site and the Marketplace
                      Offerings are hosted in the United States. If you access the Site or the Marketplace
                      Offerings from the European Union, Asia, or any other region of the world with laws or
                      other requirements governing personal data collection, use, or disclosure that differ from
                      applicable laws in the United States, then through your continued use of the Site, you
                      are transferring your data to the United States, and you expressly consent to have your
                      data transferred to and processed in the United States.</Text>


                    <Text style={styles.titleStyle}>TERM AND TERMINATION</Text>
                    <Text style={styles.normalStyle}>These Terms of Use shall remain in full force and effect while you use the Site.
                      WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE
                      RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE ORLIABILITY, DENY ACCESS TO AND USE OF THE SITE AND THE MARKETPLACE
                      OFFERINGS (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON
                      FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR
                      BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED
                      IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR REGULATION. WE
                      MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE AND THE
                      MARKETPLACE OFFERINGS OR DELETE YOUR ACCOUNT AND ANY CONTENT
                      OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN
                      OUR SOLE DISCRETION.</Text>

                    <Text style={styles.normalStyle}>If we terminate or suspend your account for any reason, you are prohibited from
                      registering and creating a new account under your name, a fake or borrowed name, or
                      the name of any third party, even if you may be acting on behalf of the third party. In
                      addition to terminating or suspending your account, we reserve the right to take
                      appropriate legal action, including without limitation pursuing civil, criminal, and
                      injunctive redress.</Text>

                    <Text style={styles.titleStyle}>DISCLAIMER</Text>
                    <Text style={styles.normalStyle}>THE SITE AND THE MARKETPLACE OFFERINGS ARE PROVIDED ON AN AS-IS
                      AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SITE AND OUR
                      SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED
                      BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN
                      CONNECTION WITH THE SITE AND THE MARKETPLACE OFFERINGS AND YOUR
                      USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES
                      OF
                      MERCHANTABILITY,
                      FITNESS
                      FOR
                      A
                      PARTICULAR
                      PURPOSE,
                      AND
                      NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONSABOUT THE ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR THE
                      CONTENT OF ANY WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO
                      LIABILITY
                      OR
                      RESPONSIBILITY
                      FOR ANY (1) ERRORS, MISTAKES, OR
                      INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR
                      PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR
                      ACCESS TO AND USE OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR
                      USE
                      OF
                      OUR
                      SECURE
                      SERVERS
                      AND/OR
                      ANY AND ALL PERSONAL
                      INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY
                      INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SITE OR
                      THE MARKETPLACE OFFERINGS, (5) ANY BUGS, VIRUSES, TROJAN HORSES,
                      OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE BY ANY
                      THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND
                      MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A
                      RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR
                      OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO NOT WARRANT,
                      ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR
                      SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SITE,
                      ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION
                      FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A
                      PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY
                      TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF
                      PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR
                      SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE
                      YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.</Text>

                    <Text style={styles.titleStyle}>LIMITATIONS OF LIABILITY</Text>
                    <Text style={styles.normalStyle}>IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE
                      LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT,
                      CONSEQUENTIAL,
                      EXEMPLARY,
                      INCIDENTAL,
                      SPECIAL,
                      OR
                      PUNITIVE
                      DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR
                      OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE OR THE
                      MARKETPLACE OFFERINGS, EVEN IF WE HAVE BEEN ADVISED OF THE
                      POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE
                      CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE
                      WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL
                      TIMES BE LIMITED TO $5.00 USD. CERTAIN STATE LAWS DO NOT ALLOW
                      LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF
                      CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE
                      ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY
                      HAVE ADDITIONAL RIGHTS.</Text>

                    <Text style={styles.titleStyle}>INDEMNIFICATION</Text>
                    <Text style={styles.normalStyle}>You agree to defend, indemnify, and hold us harmless, including our subsidiaries,
                      affiliates, and all of our respective officers, agents, partners, and employees, from and
                      against any loss, damage, liability, claim, or demand, including reasonable attorneys’
                      fees and expenses, made by any third party due to or arising out of: (1) your
                      Contributions; (2) use of the Marketplace Offerings; (3) breach of these Terms of Use;
                      (4) any breach of your representations and warranties set forth in these Terms of Use;
                      (5) your violation of the rights of a third party, including but not limited to intellectual
                      property rights; or (6) any overt harmful act toward any other user of the Site or the
                      Marketplace Offerings with whom you connected via the Site. Notwithstanding the
                      foregoing, we reserve the right, at your expense, to assume the exclusive defense and
                      control of any matter for which you are required to indemnify us, and you agree tocooperate, at your expense, with our defense of such claims. We will use reasonable
                      efforts to notify you of any such claim, action, or proceeding which is subject to this
                      indemnification upon becoming aware of it.</Text>

                    <Text style={styles.titleStyle}>MISCELLANEOUS</Text>
                    <Text style={styles.normalStyle}>These Terms of Use and any policies or operating rules posted by us on the Site or in
                      respect to the Marketplace Offerings constitute the entire agreement and understanding
                      between you and us. Our failure to exercise or enforce any right or provision of these
                      Terms of Use shall not operate as a waiver of such right or provision. These Terms of
                      Use operate to the fullest extent permissible by law. We may assign any or all of our
                      rights and obligations to others at any time. We shall not be responsible or liable for any
                      loss, damage, delay, or failure to act caused by any cause beyond our reasonable
                      control. If any provision or part of a provision of these Terms of Use is determined to be
                      unlawful, void, or unenforceable, that provision or part of the provision is deemed
                      severable from these Terms of Use and does not affect the validity and enforceability of
                      any remaining provisions. There is no joint venture, partnership, employment or agency
                      relationship created between you and us as a result of these Terms of Use or use of the
                      Marketplace Offerings. You agree that these Terms of Use will not be construed against
                      us by virtue of having drafted them. You hereby waive any and all defenses you may
                      have based on the electronic form of these Terms of Use and the lack of signing by the
                      parties hereto to execute these Terms of Use.</Text>

                  </ScrollView>
                ) : (

                  <View style={{ width: "100%", height: 500 }}>
                    <WebView

                      scalesPageToFit={false}
                      javaScriptEnabled={true}
                      domStorageEnabled={true}
                      source={{ uri: 'https://drive.google.com/file/d/1eLnFZOMaa-3yjglnqMNZaS7PvA1gWCGs/view?usp=sharing' }}
                    />
                  </View>
                )
                }
              </View>
            </ScrollView>
          </View>
        </ScrollView>
      </View>
    );
  }
}


const mapDispatchToProps = dispatch => ({
  searchOpenBarStatus: (payload) =>
    dispatch({ type: "SEARCHBAR_STATUS", payload }),
  screen: (payload) => dispatch(screen(payload))
});

export default compose(connect(null, mapDispatchToProps))(
  TermsAndConditionsScreen
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: ConstantColors.customeBackgroundColor,
    paddingTop: 0,
  },
  titleStyle: {
    color: "#000",
    fontWeight: "bold",
    fontSize: 20,
    fontFamily: ConstantFontFamily.defaultFont,
    paddingVertical: 10
  },
  normalStyle: {
    color: "#000",
    fontSize: 15,
    fontFamily: ConstantFontFamily.defaultFont,
    paddingVertical: 10
  }
});
