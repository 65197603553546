// import "@expo/browser-polyfill";
// import { openBrowserAsync } from "expo-web-browser";
import moment from "moment";
import React, { useEffect, useState, lazy, Suspense } from "react";
// import { retry } from "../library/Helper";
import {
  Image,
  Text,
  TouchableOpacity,
  View,

  Dimensions,
  Platform
} from "react-native";
// import Clipboard from 'expo-clipboard';

import { connect } from "react-redux";
import { compose } from "react-recompose";
// import { setFEEDREPORTMODALACTION } from "../actionCreator/FeedReportModalAction";
// import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";
// import { setSIGNUPMODALACTION } from "../actionCreator/SignUpModalAction";
import ConstantFontFamily from "../constants/FontFamily";
import ButtonStyle from "../constants/ButtonStyle";
// import FeedImageDisplayUser from "./FeedImageDisplayUser";
// import FeedImageDisplay from "./FeedImageDisplayClick";
import { DeleteContentMutation } from "../graphqlSchema/FeedMutation";
// import { setPostCommentDetails } from "../actionCreator/PostCommentDetailsAction";
// import CommonBottomSection from "./CommonBottomSection";
import CommonTooltipBottomPost from "./CommonTooltipBottomPost";
import { OpenWindow } from "../constants/CommonFunction";
import { Hoverable } from "react-native-web-hooks";
import HomeStyle from "./Style/HomeStyle";

// import Overlay from "react-native-modal-overlay";
// import { Icon } from "react-native-elements";
// import ConstantColor from '../constants/Colors';
// import { Hoverable } from "react-native-web-hooks";
// import { postEditDetails } from "../actionCreator/LinkPostAction";

// const RelatedCommonBottom = lazy(() =>
//   retry(() => import("./RelatedCommonBottom"))
// );


const PostDetailscard = props => {

  let singleItem = props.item && props.item?.node;
  // console.log(singleItem,'singleeeeeeeeeeeeeeeeee');
  let profileImage = {
    uri: singleItem?.author ? singleItem?.author?.profile_pic : null
  };
  let username = singleItem?.author ? singleItem.author?.username : null;
  let full_name = singleItem?.author ? singleItem.author?.full_name : null;
  let text = singleItem?.text;
  let summary = singleItem?.summary;
  let title = singleItem?.title;
  let link = singleItem?.link;
  const [modalVisible, setmodalVisible] = useState(false);
  const [outSideClick, setoutSideClick] = useState(false);
  const [parent_content_id, setparent_content_id] = useState(" ");
  let [MainHeight, setMainHeight] = useState(0);
  const [opentool, setOpentool] = useState(false)
  let [showBug, setshowBug] = useState(false);
  let [pressBug, setpressBug] = useState(false);
  let [pressComment, setpressComment] = useState(false);
  let [openCreateComment, setOpenCreateComment] = useState(false);
  const [heartCountNum, setheartCountNum] = useState(0)
  let [showOtherIcon, setshowOtherIcon] = useState(false)
  let [yellowLikeCount, setyellowLikeCount] = useState(0)
  let [silverLikeCount, setsilverLikeCount] = useState(0)
  let [blueLikeCount, setblueLikeCount] = useState(0)
  let [redLikeCount, setredLikeCount] = useState(0)
  const [showCaption, setShowCaption] = useState('Select an Emoji')
  const [openMenu, setOpenMenu] = useState(false)
  let [clikAdmin, setclikAdmin] = useState(false)

  moment.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s ago",
      s: "1m",
      ss: "%ds",
      m: "1m",
      mm: "%dm",
      h: "1h",
      hh: "%dh",
      d: "1d",
      dd: "%dd",
      w: "1w",
      ww: "%dw",
      M: "1M",
      MM: "%dM",
      y: "1y",
      yy: "%dy"
    }
  });


  async function onOpen(id) {
    if (props.loginStatus == 0) {
      props.setLoginModalStatus(true);
      return false;
    }
    setmodalVisible(true);
    setparent_content_id(id);
    setoutSideClick(true);
    props.clickEvent(true);
    setOpenCreateComment(!openCreateComment)
    props.openCreateComment(!openCreateComment)
    props.focusCreateComment(true)
  }










  useEffect(() => {
    setOpentool(false)


  }, []);

  useEffect(() => {
    if (props.modalStatus != pressComment) {
      setpressComment(props.modalStatus);
    }
  }, [props.modalStatus]);

  const writeToClipboard = async () => {
    let PostId = "/post/" + singleItem?.id.replace("Post:", "");

    if (Platform.OS == 'web') {
      let Domain = window.location.href
        .replace("http://", "")
        .replace("https://", "")
        .replace("www.", "")
        .split(/[/?#]/)[0];
      // let PostId = "/post/" + singleItem?.id.replace("Post:", "");
      // await Clipboard.setString(
      //   Domain.startsWith("localhost") == true
      //     ? "http://" + Domain + PostId
      //     : "https://" + Domain + PostId
      // );
    } else {
      setOpenMenu(false)
      let webLink = "https://weclikd-beta.com" + PostId
      // console.log('webLink',webLink)
      await openBrowserAsync(webLink)
    }
  };

  const handleDeleteFeed = id => {
    applloClient
      .query({
        query: DeleteContentMutation,
        variables: {
          content_id: id
        },
        fetchPolicy: "no-cache"
      })
      .then(response => {
        if (response.data.content_delete.status.success == true) {
          props.deleteItem(id);
        }
      })
      .catch(e => {
        console.log(e);
      });
  };

  const handleClickComment = (data) => {
    if (Dimensions.get("window").width >= 1100) {
      props.focusCreateComment(true)
      props.setPostCommentDetails({
        id: data.id.replace("Post:", ""),
        title: data.title,
        loading: data.comments_score > 0 ? true : false
      });
    }
    else {
      setOpenCreateComment(!openCreateComment)
      props.openCreateComment(!openCreateComment)
    }
  };

  const setLoginModalStatus = () => {
    this.props.setLoginModalStatus(true);
  }

  const handleDoubleTap = () => {
    props.setPostCommentDetails({
      id: "Post:" + singleItem?.id.replace("Post:", ""),
      title: singleItem.title,
      loading: singleItem.comments_score > 0 ? true : false
    });
  }
  const hearCountFunc = (value) => {
    setheartCountNum(value)
  }

  const showTooltipOptions = () => {
    setOpentool(true)
  }

  const onClose = () => {
    // console.log('hittingg outside');
    // props.setFeedShareModal(false);
    // setpressShare(false);
    // props.setFeedReportModalAction(false);
    // setpressBug(false);
    setOpentool(false)
    setOpenMenu(false)
  };

  const increaseLikeCount = (id, likeColor) => {

    if (likeColor == 'DIAMOND') {
      setblueLikeCount(blueLikeCount + 1)
    } else if (likeColor == 'GOLD') {
      setyellowLikeCount(yellowLikeCount + 1)
    } else if (likeColor == 'SILVER') {
      setsilverLikeCount(silverLikeCount + 1)
    } else if (likeColor == 'RED') {
      if (redLikeCount == 0) {
        setredLikeCount(redLikeCount + 1)
      }
    }
    // props.LikeContent({
    //   content_id: props.type == "comment" ? id : props.PostId,
    //   like_type: likeColor
    // });
    // setState({ showTooltip: false, showRedHeart: false })
    setOpentool(false)
  }

  const handleSharePost = () => {
    props.setPostShareModel(true);

  }

  // console.log(props.postData, 'propssss');

  return (
    <TouchableOpacity
      onPress={handleDoubleTap}
      onLongPress={() =>
        setOpenMenu(true)}

      nativeID={singleItem?.id}
      style={[
        // props.PostId && props.PostId.replace("Post:", "") == singleItem?.id.replace("Post:", "") ? ButtonStyle.selectFeedStyleForDashboard :
        HomeStyle.normalFeedStyleForDashboard,
        {
          marginBottom: 20,
        }
      ]}
    >
      <View style={{
        flexDirection: 'row',
        width: '98%',
        alignItems: 'center',
        marginTop: 10,
        height: 40,
        paddingVertical: Dimensions.get("window").width >= 750 && props?.postData?.node?.external_feeds?.edges[0]?.node?.profile_pic != null ? 10 :
          Dimensions.get("window").width < 750 && props?.postData?.node?.external_feeds?.edges[0]?.node?.profile_pic != null ? 10 : 0,

      }}>
        {props?.postData?.node?.author?.profile_pic || props?.postData?.node?.external_feeds?.edges?.length > 0 && props?.postData?.node?.external_feeds?.edges[0]?.node?.profile_pic != null ?
          <TouchableOpacity
            style={{

              // alignSelf: 'flex-start',
              // paddingVertical: Dimensions.get("window").width >= 750 ? 10 : 20,
              marginRight: Dimensions.get('window').width >= 750 ? 20 : 5,
              width: Dimensions.get('window').width >= 750 ? '5%' : '8%'
            }}
            onPress={() => goToPage(props?.postData?.node)}
          >

            <Image
              source={props?.postData?.node?.author?.profile_pic ? { uri: props?.postData?.node?.author?.profile_pic } : props?.postData?.node?.external_feeds?.edges?.length > 0 && props?.postData?.node?.external_feeds?.edges[0]?.node?.profile_pic != null && { uri: props?.postData?.node?.external_feeds?.edges[0]?.node?.profile_pic }}
              style={{
                width: 40,
                height: 40,
                borderRadius: 8
              }}
            />
          </TouchableOpacity> :
          null}
        <Hoverable>
          {isHovered => (
            <TouchableOpacity
              style={{
                alignSelf: "center",

                flexDirection: 'row',
              }}
              onPress={() => OpenWindow(props?.postData?.node?.link)}
            >
              <Text
                numberOfLines={1}
                style={{
                  textAlign: "left",
                  color: "#6D757F",
                  fontSize: props.ViewMode == "Card" ? 14 : 14,
                  fontFamily: ConstantFontFamily.defaultFont,
                  textDecorationLine:
                    isHovered == true ? "underline" : "none",
                  flexWrap: 'wrap',
                  width: '100%'
                }}
              >
                {
                  props?.postData?.node?.link
                    ?.replace("http://", "")
                    ?.replace("https://", "")
                    ?.replace("www.", "")
                    ?.split(/[/?#]/)[0]
                }
              </Text>
            </TouchableOpacity>
          )}
        </Hoverable>
      </View>

      {/* <View
        style={{
          flex: 1,
          marginHorizontal: 40,
          marginTop: singleItem?.thumbnail_pic == null ? 20 : 10,
          justifyContent: "center"
        }}
      >
        {link == null ||
          (link == "" && (
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignSelf: "center",
                marginVertical: 10
              }}
            >
              <View style={{ flexDirection: "row" }}>
                {singleItem.author && singleItem.author.profile_pic ? (
                  <Image
                    source={{ uri: profileImage }}
                    style={{
                      height: 34,
                      width: 34,
                      padding: 0,
                      margin: 0,
                      borderRadius: 17,
                      borderWidth: 1,
                      borderColor: "#fff"
                    }}
                  />
                ) : (
                  <Image
                    source={require("../assets/image/default-image.png")}
                    style={{
                      height: 34,
                      width: 34,
                      padding: 0,
                      margin: 0,
                      borderRadius: 17,
                      borderWidth: 1,
                      borderColor: "#fff"
                    }}
                  />
                )}
                <View style={{ padding: 5 }}>
                  {full_name && (
                    <Text
                      style={{
                        color: "#000",
                        fontWeight: "bold",
                        fontSize: 15,
                        fontFamily: ConstantFontFamily.defaultFont
                      }}
                    >
                      {full_name}
                    </Text>
                  )}
                  <Text
                    style={{
                      color: "#000",
                      fontSize: 13,
                      fontFamily: ConstantFontFamily.defaultFont
                    }}
                  >
                    @{username}
                  </Text>
                </View>
              </View>
            </View>
          ))}
      </View> */}

      {/* <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          flexWrap: "wrap",
          width: "100%",
          marginBottom: 10,
          paddingHorizontal: 10
        }}
      >


      </View> */}



      <View style={{
        width: '100%',
        // paddingHorizontal: Dimensions.get('window').width >= 750 ? 0 : 5,
        alignItems: 'center'
      }}>
        <Text style={HomeStyle.feedListTitle}>
          {props?.postData?.node?.title}
        </Text>
        <View style={{ flexDirection: 'row', width: '100%', justifyContent: 'center', flexWrap: 'wrap', marginBottom: 10, marginTop: Dimensions.get("window").width >= 750 ? 0 : 20 }}>
          {(
            props.postData?.node?.users?.edges.length > 0) ?
            props.postData?.node?.users?.edges.map((data2, index) => {
              return (
                <View key={index} style={{ flexDirection: 'row', marginRight: 10, alignItems: 'center' }}>

                  <Image
                    source={data2.node?.profile_pic ? { uri: data2.node?.profile_pic } : require("../assets/image/userDefault.png")}
                    style={{
                      width: 20,
                      height: 20,
                      marginRight: 5,
                      marginLeft: 0,
                      borderRadius: 6

                    }}
                  />
                  <Text style={{ fontSize: 11, fontFamily: ConstantFontFamily.defaultFont, color: '#000' }}>@{data2.node?.username}</Text>

                </View>
              )
            }) : null}
        </View>
      </View>
      <View style={{ flexDirection: 'row', alignItems: 'center', width: '98%', marginBottom: 10 }}>

        <Text
          style={HomeStyle.summaryTextStyle

          }
        >
          {summary}
        </Text>
      </View>
      




      {/* {openMenu ? <Overlay
        animationType="fadeInUp"
        visible={openMenu}
        onClose={onClose}
        closeOnTouchOutside

        children={
          <View style={{ width: Dimensions.get('window').width, height: Dimensions.get('window').height - 650, backgroundColor: "#fff", borderRadius: 8, position: "absolute", top: 250, bottom: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0, flexDirection: 'column', justifyContent: "flex-start" }}>

            <TouchableOpacity style={{ flexDirection: "row", justifyContent: "flex-start", alignItems: "center", marginTop: 20 }} onPress={() => {
              props.loginStatus == 1
                ? handleSharePost(singleItem?.id)
                : props.setLoginModalStatus(true)
            }}>
              <Icon
                type="material-community"
                iconStyle={{ alignSelf: "center", marginLeft: 10 }}
                containerStyle={{ alignSelf: "center" }}
                // color={'#fff'}
                name={"share-outline"}
                size={23}
              />
              <Hoverable>
                {isHovered => (
                  <Text
                    style={{
                      textAlign: "center",
                      color: isHovered == true ? "#009B1A" : "#000",
                      fontFamily: ConstantFontFamily.defaultFont,
                      marginLeft: 10
                    }}
                  >
                    Share post
                  </Text>
                )}
              </Hoverable>

            </TouchableOpacity>
            <TouchableOpacity style={{ flexDirection: "row", marginTop: 20, justifyContent: "flex-start", alignItems: "center" }} onPress={() => writeToClipboard()}>
              <Icon
                type={'ant-design'}
                name={'link'}
                iconStyle={{
                  marginLeft: 10
                }}
              />
              <Hoverable>
                {isHovered => (
                  <Text
                    style={{
                      textAlign: "center",
                      color: isHovered == true ? "#009B1A" : "#000",
                      fontFamily: ConstantFontFamily.defaultFont,
                      marginLeft: 10
                    }}
                  >
                    Copy Link
                  </Text>
                )}
              </Hoverable>
            </TouchableOpacity>
            {clikAdmin == true ? <TouchableOpacity
              style={{ flexDirection: "row", marginTop: 20, justifyContent: "flex-start", alignItems: "center" }}
              onPress={() => {
                props.loginStatus == 1
                  ? handleDeleteFeed(singleItem?.id)
                  : props.setLoginModalStatus(true);
              }}
            >
              <Icon
                type={'ant-design'}
                name={'delete'}
                iconStyle={{
                  marginLeft: 10
                }}
              />
              <Hoverable>
                {isHovered => (
                  <Text
                    style={{
                      textAlign: "center",
                      color: isHovered == true ? "#009B1A" : "#000",
                      fontFamily:
                        ConstantFontFamily.defaultFont,
                      marginLeft: 10
                    }}
                  >
                    Delete
                  </Text>
                )}
              </Hoverable>
            </TouchableOpacity> : null}
            <TouchableOpacity
              style={{ flexDirection: "row", marginTop: 20, justifyContent: "flex-start", alignItems: "center" }}
              onPress={() => {
                props.setPostEditDetails(singleItem)
                setOpenMenu(false)
              }}
            >

              <Icon
                type={'feather'}
                name={'edit-2'}
                iconStyle={{
                  marginLeft: 10
                }}
              />
              <Hoverable>
                {isHovered => (
                  <Text
                    style={{
                      textAlign: "center",
                      color: isHovered == true ? "#009B1A" : "#000",
                      fontFamily:
                        ConstantFontFamily.defaultFont,
                      marginLeft: 10
                    }}
                  >
                    Edit
                  </Text>
                )}
              </Hoverable>
            </TouchableOpacity>
          </View>
        }
        childrenWrapperStyle={{
          padding: 0,
          margin: 0,
          borderRadius: 6
        }}


      /> : null} */}
      <View style={{ alignSelf: 'flex-start' }}>
        <CommonTooltipBottomPost
          props={props}
          item={props.item}
          //   heartCount={heartCountNum && heartCountNum}
          type={'postDetail'}
          //   handleClickComment={handleClickComment}
          handleDoubleTap={handleDoubleTap}
          navigation={props.navigation}
        />

      </View>

    </TouchableOpacity>
  );
};

const mapStateToProps = state => ({
  // loginStatus: state.UserReducer.get("loginStatus"),
  PostId: state.PostCommentDetailsReducer.PostId,
  postData: state.PostCommentDetailsReducer.item
});

const mapDispatchToProps = dispatch => ({
  // setLoginModalStatus: payload => dispatch(setLOGINMODALACTION(payload)),
  // setSignUpModalStatus: payload => dispatch(setSIGNUPMODALACTION(payload)),
  // setFeedReportModalAction: payload =>
  //   dispatch(setFEEDREPORTMODALACTION(payload)),
  // openCreateComment: (payload) =>
  //   dispatch({ type: "OPEN_CREATE_COMMENT", payload }),
  // focusCreateComment: (payload) =>
  //   dispatch({ type: "FOCUS_CREATE_COMMENT", payload }),
  // setPostCommentDetails: payload => dispatch(setPostCommentDetails(payload)),
  // setPostShareModel: (payload) =>
  //   dispatch({ type: "POSTSHARESTATUS", payload }),
  // setPostEditDetails: payload => dispatch(postEditDetails(payload)),

});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PostDetailscard
);
