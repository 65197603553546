import { createSlice } from '@reduxjs/toolkit';
// import { CLIKS_PROFILE_DETAILS_FAILURE, CLIKS_PROFILE_DETAILS_SUCCESS } from "../constants/Action";




const initialState = {
    getTrendingCliksProfileDetails: {},
    clikMemberList: [],
    ClikApplicationList:[],
    ClikUserRequestList: null,
    ClikUserRequestListPagination: null,
};


const ClikProfileDetailsReducer = createSlice({
    name: 'clikProfileDetails',
    initialState,
    reducers: {
        clikProfileDetailsSuccess(state, action) {
            return { ...state, getTrendingCliksProfileDetails: action.payload.data.node }
            // state.getTrendingCliksProfileDetails=action.payload.data.node
        },
        clikProfileDetailsfailure(state, action) {
            // state.getTrendingCliksProfileDetails=action.payload.data.node
        },
        clikMemberSuccess(state, action) {
            return { ...state, clikMemberList: action.payload.data?.node?.members?.edges }
        },

        clikApplicationSuccess(state, action) {
            console.log(action.payload.data?.node?.applications?.edges, '11111111111111')
            return { ...state, ClikApplicationList: action.payload.data?.node?.applications?.edges }
        },
        clikUserRequestSuccess(state, action) {
            // console.log(action, 'actionnnnnnnnnnnn');
            return { ...state, ClikUserRequestList: action.payload.data.node.applications.edges, ClikUserRequestListPagination: action.payload.data.node.applications.pageInfo }
        },
        
    },
})

export const { clikProfileDetailsSuccess,clikUserRequestSuccess, clikProfileDetailsfailure,clikMemberSuccess,clikApplicationSuccess } = ClikProfileDetailsReducer.actions
export default ClikProfileDetailsReducer.reducer