// import firebase from "firebase/app";
// import "firebase/auth";
import React, { Component } from "react";
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  ScrollView,
  Text,
  TouchableOpacity,
  View,
  // AsyncStorage,
  Platform,
  Image,
  Dimensions
} from "react-native";
import { connect } from "react-redux";
import { setCreateAccount } from "../actionCreator/CreateAccountAction";
import { setSIGNUPMODALACTION } from "../actionCreator/SignUpModalAction";
import { setVERIFYEMAILMODALACTION } from "../actionCreator/VerifyEmailModalAction";
import ConstantFontFamily from "../constants/FontFamily";
import getEnvVars from "../environment";
// import { getLocalStorage, setLocalStorage } from "../library/Helper";
// import jwt_decode from "jwt-decode";
import {
  saveUserLoginDaitails,
  setLoginStatus
} from "../actionCreator/UserAction";
import { setAdminStatus } from "../actionCreator/AdminAction";
import { Button, Icon } from "react-native-elements";
import { Hoverable } from "react-native-web-hooks";
import { setUSERNAMEMODALACTION,setMessageModalStatus } from "../reducers/AdminTrueFalseReducer";
// import SidePanel from "./SidePanel";
// import NavigationService from "../library/NavigationService";
import ButtonStyle from "../constants/ButtonStyle";
import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";

// const apiUrl = getEnvVars();

class SignUpMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "unsend",
      buttonName: "Creating account...",
      disableBtn: true,
      UserName: ""
    };
  }
  componentDidMount() {
    let __self = this;
    AsyncStorage.getItem("newUserFirebase").then(r => {
      // let data = JSON.parse(r);
      // console.log(r, '2nd step')
      // __self.setState({
      //   UserName: data ? data.UserName : ""
      // });
    }).catch(e => console.log(e, 'error'));
    setTimeout(
      () => this.setState({ buttonName: "Continue", disableBtn: false }),
      5000
    );
  }

  // formSubmit = async () => {
  //   let __self = this;
  //   let actionCodeSettings = {
  //     url: apiUrl.APPDYNAMICLINK,
  //     handleCodeInApp: true
  //   };
  //   await firebase
  //     .auth()
  //     .currentUser.getIdToken(true)
  //     .then(async function (idToken) {
  //       return await getLocalStorage("userIdTokenFirebase").then(async res => {
  //         await setLocalStorage("userIdTokenFirebase", idToken);
  //         await setLocalStorage(
  //           "admin",
  //           jwt_decode(idToken).claims.admin ? "true" : "false"
  //         );
  //         await __self.props.changeAdminStatus(
  //           jwt_decode(idToken).claims.admin ? jwt_decode(idToken).claims.admin : false
  //         );
  //         return idToken;
  //       });
  //     })
  //     .then(async res => {
  //       if (res) {
  //         await firebase
  //           .auth()
  //           .currentUser.sendEmailVerification(actionCodeSettings)
  //           .then(res => {
  //             __self.props.setGoogleLogin(false);
  //             AsyncStorage.removeItem("userIdTokenFirebase");
  //             __self.props.setCreateAccount({
  //               username: "",
  //               email: "",
  //               password: "",
  //               first_name: "",
  //               last_name: ""
  //             });
  //             this.setState({
  //               status: "resend"
  //             });
  //             //__self.props.setVerifyEmailModalStatus(false);
  //           })
  //           .catch(e => {
  //             // __self.props.setVerifyEmailModalStatus(false);
  //             if (e.code == "auth/too-many-requests") {
  //               alert(e.message);
  //             } else {
  //               console.log(e);
  //             }
  //           });
  //       }
  //     });
  // };

  render() {
    return (
      <View
        style={{
          backgroundColor: "#000",
          borderColor: "#c5c5c5",
          borderRadius: 6,
          // maxHeight: 450,
          width: Dimensions.get('window').width >= 750 ? 500 : '100%',
          alignSelf: 'center',
          height: 710,
          paddingHorizontal: 30,
          paddingTop: 20,
          paddingBottom: 30
        }}
      >
        <View style={{ marginBottom: 20, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
          <Hoverable>
            {isHovered => (
              <TouchableOpacity
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  flex: 1,
                  left: 0,
                  position: "absolute",
                  zIndex: 999999,

                }}
                onPress={this.props.onClose}
              >
                <Icon
                  color={isHovered == true ? "rgba(256,256,256,0.4)" : "#000"}
                  iconStyle={{
                    color: "#fff",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                  // reverse
                  name="close"
                  type="antdesign"
                  size={16}
                />
              </TouchableOpacity>
            )}
          </Hoverable>

          {/* <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              backgroundColor: "#000",
              alignItems: "center",
              height: 50,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              width: "100%"
            }}
          > */}
          <View
            style={{
              alignItems: "center",
              // justifyContent: "center",
              flexDirection: "row"
            }}
          >
            <Image
              source={require("../assets/image/logolastOne.png")}
              style={{
                // justifyContent: "flex-start",
                // marginLeft: 10,
                height: 35,
                width: 35,
                marginRight: 5,
                // borderRadius: 8
              }}
              resizeMode={"contain"}
            />
            <Text
              style={[ButtonStyle.profileTitleStyle, {
                fontSize: 20,
                textAlign: "center",
                color: "white",
                fontWeight: 'bold'
              }]}
            >
              weclikd
            </Text>
          </View>
          {/* </View> */}
        </View>
        {/* private cliks and monetizing
        editing posts within 5 minutes */}




        <View style={{ backgroundColor: '#fff', padding: 30 }}>

          <Text style={{ fontFamily: ConstantFontFamily.defaultFont, fontSize: 15, textAlign: 'center' }}> Welcome, Thank you for supporting us! You have received one month of premium (without the cash).</Text>
          <View style={{ marginTop: 15, width: 245, marginHorizontal: 'auto' }}>
            <View style={{ flexDirection: 'row' }}><Text style={styles.normaltextStyle}>{'\u2022'}{''} </Text><Text style={styles.normaltextStyle}>You get paid for each like you get</Text></View>
            <View style={{ flexDirection: 'row' }}><Text style={styles.normaltextStyle}>{'\u2022'}{' '}</Text><Text style={styles.normaltextStyle}>Index and personalize news and feeds</Text></View>
            <View style={{ flexDirection: 'row' }}><Text style={styles.normaltextStyle}>{'\u2022'}{' '}</Text><Text style={styles.normaltextStyle}>Create and represent groups (cliks)</Text></View>
            <View style={{ flexDirection: 'row' }}><Text style={styles.normaltextStyle}>{'\u2022'}{' '}</Text><Text style={styles.normaltextStyle}>Form relationships with depth</Text></View>

          </View>
        </View>
        <View style={{ backgroundColor: '#fff', padding: 30, marginBottom: 20, marginTop: 30 }}>
          <Text style={[styles.textStyle, { marginBottom: 15 }]}>Dear {this.props.usernameData},</Text>
          <Text style={[styles.textStyle, { marginBottom: 15 }]}>
            We are the platform made for the coolest, smartest people on the planet.
            We are the first dedicated platform solely for intellectual discourse.
            This is the only platform where the most thoughtful content, not the most popular gets recognized and paid.
            To gain the best experience, ensure your posts contribute to the discussions and invite others to join. Let us build a better discussion platform.</Text>

          {/* <Text style={styles.textStyle}>#weclikd.</Text> */}
        </View>

        <TouchableOpacity
          block
          style={[
            ButtonStyle.backgroundStyle,
            ButtonStyle.containerStyle,
            { justifyContent: "center", marginBottom: 0 }
          ]}
          onPress={() => {
            this.props.setUsernameModalStatus(false);
            this.props.setMessageModalStatus(false);
          }}
          disabled={this.state.disableBtn}
        >
          <Text
            style={[
              ButtonStyle.titleStyle,
              {
                textAlign: "center"
              }
            ]}
          >
            {this.state.buttonName}
          </Text>
        </TouchableOpacity>

      </View>
    );
  }
}

const mapStateToProps = state => ({
  getCreateAccount: state.CreateAccountReducer.setCreateAccountData,
  // getCurrentDeviceWidthAction: state.CurrentDeviceWidthReducer.get("dimension"),
  usernameData: state.AdminTrueFalseReducer.name
});

const mapDispatchToProps = dispatch => ({
  // setVerifyEmailModalStatus: payload =>
  //   dispatch(setVERIFYEMAILMODALACTION(payload)),
  // setSignUpModalStatus: payload => dispatch(setSIGNUPMODALACTION(payload)),
  // setCreateAccount: payload => dispatch(setCreateAccount(payload)),
  // changeLoginStatus: payload => dispatch(setLoginStatus(payload)),
  // changeAdminStatus: payload => dispatch(setAdminStatus(payload)),
  // SignUpFollowModalStatus: payload =>
  //   dispatch({ type: "SIGNUP_FOLLOW_MODAL", payload }),
  // setUsernameModalStatus: payload => dispatch(setUSERNAMEMODALACTION(payload)),
  // setMessageModalStatus: payload =>
  //   dispatch({ type: "MESSAGEMODALSTATUS", payload }),
  // setInviteUserDetail: payload =>
  //   dispatch({ type: "SET_INVITE_USER_DETAIL", payload }),
  // setLoginModalStatus: payload => dispatch(setLOGINMODALACTION(payload)),
  // setGoogleLogin: payload => dispatch({ type: "SET_GOOGLE_LOGIN", payload }),
  setMessageModalStatus:payload=>dispatch(setMessageModalStatus(payload)),
  setUsernameModalStatus:payload=>dispatch(setUSERNAMEMODALACTION(payload))

});

export default connect(mapStateToProps, mapDispatchToProps)(SignUpMessage);
export const styles = {
  TextHeaderStyle: {
    fontSize: 23,
    color: "#000",
    textAlign: "center",
    fontFamily: ConstantFontFamily.defaultFont
  },
  StaticTextStyle: {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 14,
    fontFamily: ConstantFontFamily.defaultFont
  },
  textStyle: {
    // marginTop: 20,
    textAlign: "left",
    fontWeight: "bold",
    fontSize: 15,
    fontFamily: ConstantFontFamily.defaultFont
  },
  normaltextStyle: {
    // marginTop: 20,
    textAlign: "left",
    fontSize: 15,
    fontFamily: ConstantFontFamily.defaultFont
  }
};
