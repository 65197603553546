import { createSlice } from '@reduxjs/toolkit';
// import { CLIKS_PROFILE_DETAILS_FAILURE, CLIKS_PROFILE_DETAILS_SUCCESS } from "../constants/Action";




const initialState = {
    getCurrentUserProfileDetails: {},
};


const UserProfileDetailsReducer = createSlice({
    name: 'userProfileDetails',
    initialState,
    reducers: {
        userProfileDetailsSuccess(state, action) {
            return { ...state, getCurrentUserProfileDetails: action.payload.data.node }
        },
        userProfileDetailsfailure(state, action) {
            // state.getTrendingCliksProfileDetails=action.payload.data.node
        },

    },
})

export const { userProfileDetailsSuccess, userProfileDetailsfailure } = UserProfileDetailsReducer.actions
export default UserProfileDetailsReducer.reducer