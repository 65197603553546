import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    modalStatus: false,
};


const LoginModalReducer = createSlice({
    name: 'LoginModalReducer',
    initialState,
    reducers: {
        loginModalSuccess(state, action) {
            return { ...state, modalStatus: action.payload }
        },
        loginModalFailure(state, action) {
            return { ...state, modalStatus: action.payload }
        },

    },
})

export const { loginModalSuccess, loginModalFailure } = LoginModalReducer.actions
export default LoginModalReducer.reducer
