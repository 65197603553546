import React, { useEffect, useState } from "react";
import {
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Dimensions,
  TextInput
} from "react-native";
import { Icon, Button } from "react-native-elements";
import { heightPercentageToDP as hp } from "react-native-responsive-screen";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import { getTrendingCliksProfileDetails } from "../actionCreator/TrendingCliksProfileAction";
import { getCurrentUserProfileDetails } from "../actionCreator/UserProfileDetailsAction";
import appolloClient from "../client";
import ConstantColors from "../constants/Colors";
import ConstantFontFamily from "../constants/FontFamily";
import { EditCommentMutation } from "../graphqlSchema/LikeContentMutation";
import { EditCommentVariables } from "../graphqlSchema/LikeContentVariables";
import { setPostCommentDetails } from "../actionCreator/PostCommentDetailsAction";
import ButtonStyle from "../constants/ButtonStyle";
// import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
// import { Editor } from 'react-draft-wysiwyg';
// import draftToHtml from 'draftjs-to-html';
// import '../assets/react-draft.css';
import SearchinputComment from "./SearchInputComponent";
import { Menu, MenuOptions, MenuTrigger } from "react-native-popup-menu";
import { GetCommentMutation } from '../graphqlSchema/PostMutation';
import Colors from "../constants/Colors";
import CreateCommentStyle from "./Style/CreateCommentStyle";
import { showbox } from "../reducers/ShowCommentReducer";


const EditCommentCard = props => {
  const [title, settitle] = useState(
    props.topComment.text ? props.topComment.text : ""
  );
  const [item, setitem] = useState([]);
  const [click, setclick] = useState(null);
  const [showsubmitbutton, setshowsubmitbutton] = useState(true);
  const blocksFromHTML = props.topComment ? props.topComment.text : ""
  const [placeholderState, setPlaceholderState] = useState(true);
  const [titleContent, setTitleContent] = useState("characters more");
  const [getSubmitData, setSubmitData] = useState('');
  const [getSelectedItem, setSelectedItem] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedCliks, setSelectedCliks] = useState({});
  const [opened, setOpened] = useState(false);
  const [getLockStatus, SetLockStatus] = useState(false);
  let [clikData, setClikData] = useState([])
  let [userData, setUserData] = useState([])
  const [displayBox, setdisplayBox] = useState(false)
  const [text, setText] = useState('')
  const [close, setClose] = useState(false)




  // console.log(ContentState, 'ContentStateContentState');

  //   const onEditorStateChange = (editorState) => {
  //     if (editorState.getCurrentContent().getPlainText('\u0001').length == 0) {
  //       setPlaceholderState(false)
  //     }
  //     onChange(editorState);
  //     settitle(editorState.getCurrentContent().getPlainText('\u0001'))
  //     title.length < 140 ? setTitleContent('characters more') : setTitleContent('characters left')
  //     setSubmitData(draftToHtml(convertToRaw(editorState.getCurrentContent())))
  //   };

  // const content = ContentState().createFromBlockArray(
  //   blocksFromHTML.contentBlocks,
  //   blocksFromHTML.entityMap
  // );

  // const [editorState, onChange] = useState(EditorState.createWithContent(""));


  const submitComment = mode => {
    EditCommentVariables.variables.text = mode == "edit" ? title : "";
    EditCommentVariables.variables.content_id = props.topComment.id;
    setshowsubmitbutton(false);
    let clikData1 = []
    let userData1 = []
    clikData?.forEach(e => {
      clikData1.push(e.node.clik.name)

    })
    userData?.forEach(e => {
      userData1.push(e.node.user.username)

    })
    appolloClient
      .mutate({
        mutation: EditCommentMutation,
        variables: {
          comment_id: props.topComment.id,
          text: EditCommentVariables.variables.text,
          cliks: clikData1 ? clikData1 : null,
          users: userData1 ? userData1 : null

        },
        fetchPolicy: "no-cache"
      }).then(async e => {
        props.closeModalBySubmit("", "");
        props.onClose();
        const itemId = props.parentPostId;
        await getCommentList(itemId);
      })
  };

  useEffect(() => {
    let data = [
      {
        label: "Everyone",
        value: "all",
        key: -1
      }
    ];

    setitem(data);
    setclick(data.length > 0 ? data[0].label : null);
  }, []);


  useEffect(() => {
    if (getSelectedItem.selectedUser) {
      let newArray = Object.assign(
        [],
        selectedUser,
        getSelectedItem.selectedUser
      );
      setSelectedUser(getSelectedItem.selectedUser);
    }
    if (getSelectedItem.selectedCliks) {
      let newArray = Object.assign({}, getSelectedItem.selectedCliks);

      if (Object.values(getSelectedItem.selectedCliks).length > 0) {
        setOpened(false);
      }
      setSelectedCliks(getSelectedItem.selectedCliks);
    }
  }, [getSelectedItem]);

  const goToUserProfile = async username => {
    props.onClose();
    await props.userId({
      username: username
    });
    await props.navigation.navigate("profile", {
      username: username,
      type: "profile"
    });
  };

  const getCommentList = async id => {

    let comment = await appolloClient
      .query({
        query:
          GetCommentMutation,
        variables: {
          id: id
        },
        fetchPolicy: "no-cache"
      }).then(async res => {
        console.log(res, 'resssssss');
        let l1 = JSON.parse(res.data.node.nested_comments).edges
        console.log(l1, 'l111111');
        let itemId = l1[l1.length - 1].node.comment.id


        let updateArray = []
        let updateArray1 = []

        l1.forEach(e => {
          props.PostCommentDetails.forEach(a => {
            if (e.node.comment.id == a.node.comment.id) {
              if (e.node.comment.text != a.node.comment.text) {
                let id = e.node.comment.id + 'updated'
                let obj = {
                  node: {
                    cliks: e.node.cliks,
                    comment: {
                      id: id,
                      text: e.node.comment.text,
                      comments: e.node.comment.comments,
                      created: e.node.comment.created,
                      author: e.node.comment.author,
                      num_comments: e.node.comment.num_comments,
                      num_likes: e.node.comment.num_likes

                    },
                    id: e.node.id,
                    locked: e.node.locked,
                    private: e.node.private,
                    users: e.node.users
                  }
                }
                console.log(obj, 'objobjobj');
                updateArray.unshift(obj)
                console.log(updateArray, 'updateArray');
                // props.PostCommentDetails.shift()
                let bottomList = props.PostCommentDetails
                let newArray = updateArray.concat(bottomList)
                // console.log(newArray, 'newArray');
                props.setPostCommentDetails(updateArray);


                // updateArray1.unshift(e)
                // props.PostCommentDetails.shift()
                // let bottomList1 = props.PostCommentDetails
                // let newArray1 = updateArray1.concat(bottomList1)
                // console.log(newArray1, 'newArray1');
                // props.setPostCommentDetails(newArray1);
              }
            }

          });
        });
      })
  };
  const customRenderUserTags = (tags) => {
    return (
      <View
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "flex-start",
          backgroundColor: "#fff",
          width: "100%",
          paddingVertical: 2,
          flexDirection: "column",
        }}
      >
        {tags.map((t, i) => {
          return (
            <View
              key={t.name}
              style={{
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 2,
              }}
            >

              <Text
                style={[ButtonStyle.profileTitleStyle, {
                  color: "grey",
                  padding: 5,
                }]}
              >
                @{t.name.toLowerCase()}
              </Text>
              <Icon
                color={"grey"}
                name="times"
                type="font-awesome"
                size={20}
                iconStyle={{ marginLeft: 5, cursor: "pointer" }}
                onPress={() => {
                  let newArray = Object.assign([], selectedUser);
                  delete newArray[i];
                  setSelectedUser(Object.assign([], newArray));
                }}
              />
            </View>
          );
        })}
      </View>
    );
  };

  const customRenderClikTags = (tags) => {
    return (
      <TouchableOpacity
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          alignItems: "flex-start",
          backgroundColor: "#fff",
          width: "100%",
          marginBottom: 2,
        }}
      >
        <View style={{ flexDirection: "row", alignItems: "center" }}>

          <View
            style={{
              flexDirection: "row",
              backgroundColor: "#E8F5FA",
              alignItems: "center",
              cursor: "pointer",
              borderRadius: 6,
              padding: 5,
            }}
          >
            <Text
              style={[ButtonStyle.profileTitleStyle, {
                alignSelf: "flex-start",
                padding: 2,
                color: "#4169e1",
              }]}
            >
              #{tags.name}
            </Text>
            <Icon
              color={"#4C82B6"}
              name="times"
              type="font-awesome"
              size={20}
              iconStyle={{ marginLeft: 5, cursor: "pointer" }}
              onPress={() => {
                setSelectedCliks({});
                setclick(null);
              }}
            />
          </View>
        </View>
      </TouchableOpacity>
    );
  };

  const openMenu = () => {
    setOpened(false)
  }

  const showClose = () => {
    setClose(true)
  }

  const handleUserDelete = (e) => {
    // console.log(userData,'eeeeee');
    let user = userData
    user.splice(user.indexOf(e), 1)

    // let user=[...userData]
    // console.log(user, 'iiii');
    setText('')
    setUserData([...user])
  }

  const handleClikDelete = (e) => {
    // console.log(userData,'eeeeee');
    let clik = clikData
    clik.splice(clik.indexOf(e), 1)
    setText('')

    // let user=[...userData]
    // console.log(user,'iiii');
    setClikData([...clik])
  }

  return (
    <View
      style={{
        borderRadius: 20,
        width: "98%",
        backgroundColor: "#fff",
      }}
    >

      <View
        style={{
          height: Platform.OS != "web" ? hp("25%") : null,
          backgroundColor: '#fff',

        }}
      >
        <View style={{ flex: 1 }}>
          <View
            style={{
              // borderBottomWidth: 1,
              // borderTopWidth: 1,
              borderColor: "#e8e8e8",
              borderRadius: 0,
              ...Platform.select({
                web: {
                  marginHorizontal: 0
                },
                android: {
                  op: 10
                },
                ios: {
                  op: 10
                }
              })
            }}
          >

            <View style={{ flexDirection: 'column' }}>
              <View style={{
                minHeight: 175,
                maxHeight: hp('55%'),
                borderWidth: 1,
                width: "100%",
                borderRadius: 15,
                borderColor: '#d3d3de',
                padding: 10,
                marginTop: 10
                // marginHorizontal: 20

              }}>
                <View style={[CreateCommentStyle.tagsMainView, { width: '100%', marginLeft: 0 }]}>
                  {/* <View> */}
                  {clikData.length > 0 || userData.length > 0 ?
                    <View style={CreateCommentStyle.tagWrapView}>
                      {clikData.map(e => {

                        return (
                          <View style={[ButtonStyle.clikNameBackgroundStyle, {
                            marginLeft: 5,
                            backgroundColor: Colors.clikNamebackgroundColor,
                            flexDirection: "row"

                          }]}
                            key={e.id}
                          >
                            <Text style={[ButtonStyle.clikNameTitleStyle,
                            {
                              paddingHorizontal: 5,
                              color: Colors.blueColor,

                            }]}>#{e.name}</Text>
                            <Icon
                              name="close" size={18} type="font-awesome"
                              iconStyle={{ marginLeft: 5, }}
                              //  this.props.type == "dashboard" ? 5 : this.props.type == "topicPrfoile" ?  : this.props.type == "userProfile" || this.props.type == "external" ? 50 : 70 }}
                              onPress={() => {
                                // this.handleSuggestionDelete()

                                // this.props.handlefocus()
                                // this.props.setZindexZero(true)
                                handleClikDelete(e)

                              }}

                            />
                          </View>
                        )
                      })}
                      {userData.map(e => {
                        // console.log(e, 'eeeeeee');
                        return (
                          <View style={[ButtonStyle.clikNameBackgroundStyle, {
                            marginLeft: 10,
                            flexDirection: "row"

                          }]}
                            key={e.id}>
                            <Text style={[ButtonStyle.clikNameTitleStyle,
                            {
                              paddingHorizontal: 5,
                            }]}>{e.username}</Text>
                            <Icon
                              name="close" size={18} type="font-awesome"
                              iconStyle={{ marginLeft: 5, }}
                              //  this.props.type == "dashboard" ? 5 : this.props.type == "topicPrfoile" ?  : this.props.type == "userProfile" || this.props.type == "external" ? 50 : 70 }}
                              onPress={() => {
                                // this.handleSuggestionDelete()

                                // this.props.handlefocus()
                                // this.props.setZindexZero(true)
                                handleUserDelete(e)
                              }}

                            />
                          </View>
                        )
                      })}
                    </View>
                    :
                    <View style={{ flexDirection: 'row', justifyContent: "space-between", alignItems: "center", width: "94%" }}>
                      <TextInput placeholder="Tag @users"
                        style={{ outline: "none" }}
                        onFocus={() => showClose()}
                        value={text} onChangeText={(e) => { setText(e), setdisplayBox(true) }} />




                    </View>
                  }

                  <Icon
                    name="close" size={18} type="font-awesome"
                    iconStyle={{ marginHorizontal: 5, marginRight: 20 }}
                    //  this.props.type == "dashboard" ? 5 : this.props.type == "topicPrfoile" ?  : this.props.type == "userProfile" || this.props.type == "external" ? 50 : 70 }}
                    onPress={() => {
                      setdisplayBox(false),
                        setText('')



                    }}

                  />
                </View>

                {!selectedUser || selectedUser.length > 0 || !selectedCliks.name ?
                  <>
                    <SearchinputComment
                      input={text}
                      setClikData={setClikData}
                      setUserData={setUserData}
                      setdisplayBox={setdisplayBox}
                    />
                  </>

                  : null}

                <TextInput
                  style={{
                    minHeight: 175,
                    maxHeight: hp('55%'),
                    overflowY: "unset",
                    fontSize: props.initial == "main" ? 20 : 14,
                    color: "#000",
                    fontWeight: "bold",
                    fontFamily: ConstantFontFamily.defaultFont,
                    paddingLeft: 0,
                    paddingRight: 10,
                    textAlign: 'left',
                    alignContent: "flex-start",
                    width: "95%",
                    alignItems: "flex-start",
                    outline: "none",
                    marginTop: 20,
                    justifyContent: "flex-start",
                  }}
                  value={title}
                  onChangeText={(e) => {
                    settitle(e)
                  }}
                  // ref={comment}
                  multiline={true}
                  maxLength={3000}



                />
              </View>
              {/* <Editor
                editorState={editorState}
                wrapperClassName="demo-wrapper"
                editorClassName="demo-editor"
                onChange={() => {
                  props.closeCreateComment()
                }
                }
                toolbarStyle={{
                  borderRadius: 0,
                  borderRightWidth: 0,
                  borderLeftWidth: 0,
                  borderWidth: 0,
                  borderBottomWidth: 1
                }}
                onEditorStateChange={onEditorStateChange}
                toolbar={
                  {
                    options: ['inline', 'blockType', 'list', 'link'],
                    inline:
                    {
                      options: ['bold', 'italic', 'underline']
                    },
                    blockType: {
                      inDropdown: false,
                      options: ['Blockquote', 'Code'],
                      className: undefined,
                      component: undefined,
                      dropdownClassName: undefined,
                    },
                    list: {
                      options: ['unordered'],
                    },
                    link: {
                      options: ['link'],
                    }
                  }
                }
                placeholder={placeholderState ? "Write an insightful comment (longer than 140 characters)." : ''}
                editorStyle={{
                  height: title.length == 0 ? '100px' : "160px",
                  overflowY: title.length > 0 ? 'auto' : 'unset', fontSize: '13px',
                  fontFamily: ConstantFontFamily.defaultFont, paddingLeft: props.initial != "main" ? 30 : 10,
                }}
              /> */}
            </View>

          </View>
          <View
            style={{
              flexDirection: "row",
              width: "100%",
              paddingHorizontal: 10,
              alignItems: "center",
              justifyContent: "flex-start",
              // zIndex: -1
            }}
          >
            <View
              style={{
                flexDirection: "row",
                width: "50%",
                alignItems: 'center'
              }}
            >
              <View
                style={{
                  alignItems: "flex-start",
                  justifyContent: "flex-start"
                }}
              >
                {/* <TouchableOpacity
                  disabled={showsubmitbutton == false ? true : false}
                  onPress={() => submitComment("delete")}
                >
                  <Icon
                    color={"#D14C4B"}
                    iconStyle={{
                      color: "#fff",
                      justifyContent: "center",
                      alignItems: "center",
                      alignSelf: "center"
                    }}
                    reverse
                    name="trash"
                    type="font-awesome"
                    size={15}
                    containerStyle={{
                      alignSelf: "center"
                    }}
                  />
                </TouchableOpacity> */}
              </View>
              {props.initial == "main" ?
                <Text
                  style={{
                    color: title.length < 140 ? "#de5246" : '#009B1A',
                    fontSize: 20,
                    fontFamily: ConstantFontFamily.defaultFont,
                    // op: 5,
                    // marginLeft: 15
                  }}
                >
                  {title && title.length < 25 ? `${25 - title.length} ${titleContent}` : ''}
                </Text> :
                <Text
                  style={{
                    color: title.length < 140 ? "#de5246" : '#009B1A',
                    fontSize: 14,
                    fontFamily: ConstantFontFamily.defaultFont,
                    // op: 5,
                    // marginLeft: 15
                  }}
                >
                  {title ? title.length < 25 ? `${25 - title.length} ${titleContent}` : `${2000 - title.length} ${titleContent}` : ''}
                </Text>
              }
            </View>

            <View
              style={{
                flexDirection: "row",
                width: "50%",
                justifyContent: "flex-end",
                alignItems: "center",
                alignSelf: "center",
                op: 5
              }}
            >
              <Button
                title="Cancel"
                titleStyle={[ButtonStyle.allButtonTextStyle, { color: '#fff' }]}
                buttonStyle={[
                  ButtonStyle.allButtonbackgroundStyle,
                  {

                    backgroundColor: "#de5246",
                  }
                ]}
                containerStyle={[
                  ButtonStyle.containerStyle,
                  {
                    marginBottom: 5
                  }
                ]}

                onPress={() => {
                  props.showbox({ show: false, id: '', showmoreId:'' })
                }}
              />
              <Button
                title="Submit"
                titleStyle={ButtonStyle.allButtonTextStyle}
                buttonStyle={[ButtonStyle.allButtonbackgroundStyle, {
                  // paddingHorizontal: 10,
                  // paddingVertical:
                  //   Dimensions.get("window").width <= 750 ? 5 : 6,
                }]
                }
                containerStyle={[
                  ButtonStyle.containerStyle,
                  {
                    marginBottom: 5
                  }
                ]}
                // disabled={
                //   (props.initial == "main" &&
                //     title.replace(/ /g, "").length <= 50) ||
                //     (props.initial != "main" &&
                //       title.replace(/ /g, "").length <= 25) ||
                //     showsubmitbutton == false
                //     ? true
                //     : false
                // }
                onPress={
                  () => {
                    // onChange(EditorState.push(editorState, ContentState.createFromText('')))
                    return submitComment("edit")
                  }
                }
              />
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};
const mapStateToProps = state => ({
  profileData: state.LoginUserDetailsReducer.userLoginDetails,
  clikList: state.LoginUserDetailsReducer.userFollowCliksList,
  userList: state.LoginUserDetailsReducer.userFollowUserList,
  PostCommentDetails: state.PostCommentDetailsReducer.PostCommentDetails,
  parentPostId: state.PostCommentDetailsReducer.PostId,
  loginStatus: state.UserReducer.loginStatus,

});

const mapDispatchToProps = dispatch => ({
  userId: payload => dispatch(getCurrentUserProfileDetails(payload)),
  clikId: payload => dispatch(getTrendingCliksProfileDetails(payload)),
  setPostCommentDetails: payload => dispatch(setPostCommentDetails(payload)),
  focusCreateComment: (payload) =>
    dispatch({ type: "FOCUS_CREATE_COMMENT", payload }),
  showbox: (payload) => dispatch(showbox(payload)),

});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  EditCommentCard
);

const styleMap = {
  'CODE': {
    'backgroundColor': 'red',
    'fontFamily': '"Inconsolata", "Menlo", "Consolas", monospace',
    'fontSize': 16,
    'padding': 2,
  },
  'HIGHLIGHT': {
    'color': "#000",
    'fontFamily': ConstantFontFamily.defaultFont,
    'width': "100%",
    'borderRadius': 6,
  },
  'superFancyBlockquote': {
    'color': '#999',
    'font-family': 'Hoefler Text',
    'font-style': 'italic',
    'text-align': 'center',
  }
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: ConstantColors.customeBackgroundColor,
    padding: Platform.OS == "web" ? 10 : 0
  },
  totalFollowCount: {
    op: 15,
    color: "#ffffff",
    alignItems: "center"
  },
  containerFollow: {
    justifyContent: "flex-end",
    marginRight: 10
  },
  TotalcontainerFollow: {
    flexDirection: "row",
    justifyContent: "flex-end",
    marginRight: 12,
    marginBottom: 10
  },
  profileContainer: {
    op: 10
  },
  totalProfileContainer: {
    alignItems: "center",
    justifyContent: "center"
  },
  image: {
    width: "100%",
    height: hp("40%")
  },
  followbtnStyle: {
    height: 30,
    alignContent: "center",
    justifyContent: "center",
    backgroundColor: "#1A1819",
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 30,
    paddingRight: 30,
    borderColor: "#fff",
    borderWidth: 2,
    borderRadius: 5
  },
  bgContainerProfile: {
    margin: 0,
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center"
  },
  usertext: {
    color: "#fff",
    fontSize: 12,
    fontFamily: ConstantFontFamily.defaultFont
  },
  userblacktext: {
    color: "#fff",
    fontSize: 12,
    fontFamily: ConstantFontFamily.defaultFont
  },
  inputIOS: {
    paddingTop: 13,
    paddingHorizontal: 10,
    paddingBottom: 12,
    borderRadius: 4,
    backgroundColor: "#E8F5FA",
    color: "#4169e1",
    fontSize: 15,
    fontWeight: "bold",
    fontFamily: ConstantFontFamily.defaultFont
  },
  inputAndroid: {
    paddingVertical: 8,
    paddingHorizontal: 10,
    borderRadius: 8,
    backgroundColor: "#E8F5FA",
    color: "#4169e1",
    fontSize: 15,
    fontWeight: "bold",
    fontFamily: ConstantFontFamily.defaultFont
  },
  TextStyle: {
    textAlign: "center"
  }
});

