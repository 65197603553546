import React, { Component } from "react";
import {
  Animated,
  Dimensions,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { Icon } from "react-native-elements";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import ConstantColors from "../constants/Colors";
import ConstantFontFamily from "../constants/FontFamily";
import { WebView } from 'react-native-webview';
import { heightPercentageToDP as hp } from "react-native-responsive-screen";
import { screen } from '../reducers/action/Completed'
import HomeStyle from "../components/Style/HomeStyle";
class PrivacyPolicyScreen extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount(){
    this.props.screen('privacyScreen')
  }

  render() {
    return (
      <View style={styles.container}>
        <ScrollView
          ref={scrollview => {
            this.Pagescrollview = scrollview;
          }}
          showsVerticalScrollIndicator={false}
          onLayout={event => {
            let { x, y, width, height } = event.nativeEvent.layout;
            if (width < 1024) {
              this.setState({
                showsVerticalScrollIndicatorView: true,
                currentScreentWidth: width
              });
            } else {
              this.setState({
                showsVerticalScrollIndicatorView: false,
                currentScreentWidth: width
              });
            }
          }}
          contentContainerStyle={{
            height:
              Platform.OS !== "web"
                ? null
                : Dimensions.get("window").height - 80
          }}
        >
          <View>
            {Dimensions.get('window').width <= 750 && Platform.OS != "web" ? (
              <Animated.View
                style={{
                  position: Platform.OS == "web" ? "sticky" : null,
                  top: 0,
                  left: 0,
                  right: 0,
                  zIndex: 10,
                  overflow: "hidden",
                  borderRadius: 0
                }}
              >
                <View
                  style={{
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <View
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      marginBottom: 10,
                      backgroundColor: "#000",
                      borderRadius: 0,
                      height: 42
                    }}
                  >
                    <TouchableOpacity
                      style={{
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignSelf: "center"
                      }}
                      onPress={() => {
                        this.props.searchOpenBarStatus(false);
                        let nav = this.props.navigation.dangerouslyGetParent()
                          .state;
                        if (nav.routes.length > 1) {
                          this.props.navigation.goBack();
                          return;
                        } else {
                          this.props.navigation.navigate("home");
                        }
                      }}
                    >
                      <Icon
                        color={"#fff"}
                        name="angle-left"
                        type="font-awesome"
                        size={40}
                      />
                    </TouchableOpacity>
                    <TouchableOpacity
                      style={{
                        flex: 1,
                        backgroundColor: "#000",
                        justifyContent: "center",
                        marginLeft: 20,
                        borderRadius: 0
                      }}
                    >
                      <Text
                        style={{
                          color: "white",
                          fontWeight: "bold",
                          fontSize: 18,
                          fontFamily: ConstantFontFamily.Avenir
                        }}
                      >
                        Privacy policy
                      </Text>
                    </TouchableOpacity>
                  </View>
                </View>
              </Animated.View>
            ) : (
              null

            )}
            <ScrollView showsVerticalScrollIndicator={false}>
              <View
                style={{
                  borderRadius: 6
                }}
              ></View>
              <View>
                <View
                  style={{
                    paddingHorizontal: Platform.OS != "web" ? 10 : 0
                  }}
                >
                  {Platform.OS === "web" ? (
                    <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={[HomeStyle.normalFeedStyleForDashboard, {
                      marginLeft: Dimensions.get("window").width <= 1200 ? 0 : 15,
                      marginTop: Dimensions.get("window").width <= 1200 ? 0 : 15, paddingHorizontal: 20, height: hp('100%') - 100
                    }]}>
                      <Text style={[styles.titleStyle]}>PRIVACY NOTICE</Text>
                      <Text style={[styles.titleStyle, { color: 'gray', fontSize: 18 }]}>Last updated September 24, 2020</Text>

                      <Text style={styles.normalStyle}>
                        Thank you for choosing to be part of our community at Weclikd Inc. ("Company", "we", "us", "our"). We are committed to protecting your personal information and
                        your right to privacy. If you have any questions or concerns about this privacy notice, or our practices with regards to your personal information, please contact us at
                        info@weclikd.com.
                      </Text>

                      <Text style={styles.normalStyle}>
                        When you visit our website https://www.weclikd.com (the "Website"), use our mobile application, as the case may be (the "App") and more generally, use any of our
                        services (the "Services", which include the Website and App), we appreciate that you are trusting us with your personal information. We take your privacy very
                        seriously. In this privacy notice, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation
                        to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy notice that you do not agree with, please
                        discontinue use of our Services immediately.
                      </Text>

                      <Text style={styles.normalStyle}>
                        This privacy notice applies to all information collected through our Services (which, as described above, includes our Website and App), as well as, any related
                        services, sales, marketing or events.
                      </Text>
                      <Text style={[styles.titleStyle, { color: 'gray', fontSize: 18 }]}>Please read this privacy notice carefully as it will help you understand what we do with the information that we collect.</Text>

                      <Text style={[styles.titleStyle, { fontSize: 18 }]}>TABLE OF CONTENTS</Text>

                      <Text style={[styles.normalStyle, { paddingVertical: 0 }]}>1. WHAT INFORMATION DO WE COLLECT?</Text>
                      <Text style={[styles.normalStyle, { paddingVertical: 0 }]}>2. HOW DO WE USE YOUR INFORMATION?</Text>
                      <Text style={[styles.normalStyle, { paddingVertical: 0 }]}>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</Text>
                      <Text style={[styles.normalStyle, { paddingVertical: 0 }]}>4. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</Text>
                      <Text style={[styles.normalStyle, { paddingVertical: 0 }]}>5. HOW LONG DO WE KEEP YOUR INFORMATION?</Text>
                      <Text style={[styles.normalStyle, { paddingVertical: 0 }]}>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</Text>
                      <Text style={[styles.normalStyle, { paddingVertical: 0 }]}>7. DO WE COLLECT INFORMATION FROM MINORS?</Text>
                      <Text style={[styles.normalStyle, { paddingVertical: 0 }]}>8. WHAT ARE YOUR PRIVACY RIGHTS?</Text>
                      <Text style={[styles.normalStyle, { paddingVertical: 0 }]}>9. CONTROLS FOR DO-NOT-TRACK FEATURES</Text>
                      <Text style={[styles.normalStyle, { paddingVertical: 0 }]}>10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</Text>
                      <Text style={[styles.normalStyle, { paddingVertical: 0 }]}>11. DO WE MAKE UPDATES TO THIS NOTICE?</Text>
                      <Text style={[styles.normalStyle, { paddingVertical: 0 }]}>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</Text>
                      <Text style={[styles.normalStyle, { paddingVertical: 0 }]}>13. HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU?</Text>

                      <Text style={[styles.titleStyle, { fontSize: 18 }]}>1. WHAT INFORMATION DO WE COLLECT?</Text>
                      <Text style={[styles.titleStyle, { fontSize: 18 }]}>Personal information you disclose to us</Text>
                      <Text style={[styles.normalStyle, { fontWeight: 'bold' }]}>In Short: <Text style={[styles.normalStyle, { fontWeight: '100' }]}>We collect personal information that you provide to us.</Text></Text>
                      <Text style={styles.normalStyle}>We collect personal information that you voluntarily provide to us when you register on the Services, express an interest in obtaining information about us or our
                        products and Services, when you participate in activities on the Services (such as by posting messages in our online forums or entering competitions, contests or
                        giveaways) or otherwise when you contact us.</Text>
                      <Text style={styles.normalStyle}>The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make and the products and features
                        you use. The personal information we collect may include the following:</Text>

                      <Text style={[styles.normalStyle, { fontWeight: 'bold' }]}>Personal Information Provided by You.<Text style={[styles.normalStyle, { fontWeight: '100' }]}> We collect email addresses; usernames; and other similar information.</Text></Text>

                      <Text style={[styles.normalStyle, { fontWeight: 'bold' }]}>Payment Data.<Text style={[styles.normalStyle, { fontWeight: '100' }]}> We may collect data necessary to process your payment if you make purchases, such as your payment instrument number (such as a credit card
                        number), and the security code associated with your payment instrument. All payment data is stored by Stripe. You may find their privacy notice link(s) here:
                        https://stripe.com/privacy.</Text></Text>


                      <Text style={[styles.normalStyle, { fontWeight: 'bold' }]}>Social Media Login Data.<Text style={[styles.normalStyle, { fontWeight: '100' }]}> We may provide you with the option to register with us using your existing social media account details, like your Facebook, Twitter or
                        other social media account. If you choose to register in this way, we will collect the Information described in the section called "HOW DO WE HANDLE YOUR SOCIAL
                        LOGINS" below.</Text></Text>


                      <Text style={styles.normalStyle}>All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.</Text>

                      <View style={{ flexDirection: 'row' }}><Text style={[styles.normalStyle, { fontWeight: 'bold' }]}>In Short:</Text>
                        <Text style={styles.normalStyle}> We collect information regarding your push notifications, when you use our App.</Text>
                      </View>
                      <Text style={styles.normalStyle}> If you use our App, we also collect the following information:</Text>
                      <Text style={styles.bulletStyle}>{'\u2022'}{' '}Push Notifications. We may request to send you push notifications regarding your account or certain features of the App. If you wish to opt-out from receiving
                        these types of communications, you may turn them off in your device's settings.</Text>

                      <Text style={styles.normalStyle}>This information is primarily needed to maintain the security and operation of our App, for troubleshooting and for our internal analytics and reporting purposes.</Text>



                      <Text style={[styles.titleStyle, { fontSize: 18 }]}>2. HOW DO WE USE YOUR INFORMATION?</Text>

                      <Text style={[styles.normalStyle, { fontWeight: 'bold' }]}>In Short<Text style={styles.normalStyle}>We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal
                        obligations, and/or your consent.</Text></Text>

                      <Text style={styles.normalStyle}>We use personal information collected via our Services for a variety of business purposes described below. We process your personal information for these purposes
                        in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal
                        obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.</Text>
                      <Text style={styles.normalStyle}>We use the information we collect or receive:</Text>

                      <Text style={styles.bulletStyle}>{'\u2022'}{' '}<Text style={[styles.normalStyle, { fontWeight: 'bold' }]}>To facilitate account creation and logon process.</Text><Text style={styles.normalStyle}> If you choose to link your account with us to a third-party account (such as your Google or Facebook
                        account), we use the information you allowed us to collect from those third parties to facilitate account creation and logon process for the performance of the
                        contract. See the section below headed "HOW DO WE HANDLE YOUR SOCIAL LOGINS" for further information.</Text></Text>
                      <Text style={styles.bulletStyle}>{'\u2022'}{' '}<Text style={[styles.normalStyle, { fontWeight: 'bold' }]}>To post testimonials.</Text><Text style={styles.normalStyle}> We post testimonials on our Services that may contain personal information. Prior to posting a testimonial, we will obtain your consent to
                        use your name and the content of the testimonial. If you wish to update, or delete your testimonial, please contact us at info@weclikd.com and be sure to
                        include your name, testimonial location, and contact information.</Text></Text>
                      <Text style={styles.bulletStyle}>{'\u2022'}{' '}<Text style={[styles.normalStyle, { fontWeight: 'bold' }]}>Request feedback.</Text><Text style={styles.normalStyle}> We may use your information to request feedback and to contact you about your use of our Services.</Text></Text>
                      <Text style={styles.bulletStyle}>{'\u2022'}{' '}<Text style={[styles.normalStyle, { fontWeight: 'bold' }]}>To enable user-to-user communications.</Text><Text style={styles.normalStyle}>We may use your information in order to enable user-to-user communications with each user's consent.</Text></Text>
                      <Text style={styles.bulletStyle}>{'\u2022'}{' '}<Text style={[styles.normalStyle, { fontWeight: 'bold' }]}>To manage user accounts.</Text><Text style={styles.normalStyle}>We may use your information for the purposes of managing our account and keeping it in working order.</Text></Text>
                      <Text style={styles.bulletStyle}>{'\u2022'}{' '}<Text style={[styles.normalStyle, { fontWeight: 'bold' }]}>To send administrative information to you.</Text><Text style={styles.normalStyle}> We may use your personal information to send you product, service and new feature information and/or
                        information about changes to our terms, conditions, and policies.</Text></Text>
                      <Text style={styles.bulletStyle}>{'\u2022'}{' '}<Text style={[styles.normalStyle, { fontWeight: 'bold' }]}>To protect our Services.</Text><Text style={styles.normalStyle}> We may use your information as part of our efforts to keep our Services safe and secure (for example, for fraud monitoring and
                        prevention).</Text></Text>
                      <Text style={styles.bulletStyle}>{'\u2022'}{' '}<Text style={[styles.normalStyle, { fontWeight: 'bold' }]}>To enforce our terms, conditions and policies for business purposes, to comply with legal and regulatory requirements or in connection with our
                        contract:</Text></Text>
                      <Text style={styles.bulletStyle}>{'\u2022'}{' '}<Text style={[styles.normalStyle, { fontWeight: 'bold' }]}>To respond to legal requests and prevent harm.</Text><Text style={styles.normalStyle}> If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how
                        to respond.</Text></Text>
                      <Text style={styles.bulletStyle}>{'\u2022'}{' '}<Text style={[styles.normalStyle, { fontWeight: 'bold' }]}>Fulfill and manage your orders.</Text><Text style={styles.normalStyle}>We may use your information to fulfill and manage your orders, payments, returns, and exchanges made through the
                        Services</Text></Text>
                      <Text style={styles.bulletStyle}>{'\u2022'}{' '}<Text style={[styles.normalStyle, { fontWeight: 'bold' }]}>Administer prize draws and competitions.</Text><Text style={styles.normalStyle}>We may use your information to administer prize draws and competitions when you elect to participate in our
                        competitions.</Text></Text>
                      <Text style={styles.bulletStyle}>{'\u2022'}{' '}<Text style={[styles.normalStyle, { fontWeight: 'bold' }]}>To deliver and facilitate delivery of services to the user.</Text><Text style={styles.normalStyle}> We may use your information to provide you with the requested service.</Text></Text>
                      <Text style={styles.bulletStyle}>{'\u2022'}{' '}<Text style={[styles.normalStyle, { fontWeight: 'bold' }]}>To respond to user inquiries/offer support to users.</Text><Text style={styles.normalStyle}> We may use your information to respond to your inquiries and solve any potential issues you might
                        have with the use of our Services.</Text></Text>
                      <Text style={styles.bulletStyle}>{'\u2022'}{' '}<Text style={[styles.normalStyle, { fontWeight: 'bold' }]}>For other business purposes.</Text><Text style={styles.normalStyle}> We may use your information for other business purposes, such as data analysis, identifying usage trends, determining the
                        effectiveness of our promotional campaigns and to evaluate and improve our Services, products, marketing and your experience. We may use and store this information in aggregated and anonymized form so that it is not associated with individual end users and does not include personal information. We will not use
                        identifiable personal information without your consent.</Text></Text>
                      <Text style={styles.bulletStyle}>{'\u2022'}{' '}<Text style={[styles.normalStyle, { fontWeight: 'bold' }]}>Consent:</Text><Text style={styles.normalStyle}> We</Text></Text>




                      <Text style={[styles.titleStyle, { fontSize: 18 }]}>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</Text>
                      <View style={{ flexDirection: 'row' }}><Text style={[styles.normalStyle, { fontWeight: 'bold' }]}>In Short
                        <Text style={styles.normalStyle}>We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.</Text></Text>
                      </View>
                      <Text style={styles.normalStyle}> We may process or share your data that we hold based on the following legal basis:</Text>
                      <Text style={styles.bulletStyle}>{'\u2022'}{' '}<Text style={[styles.normalStyle, { fontWeight: 'bold' }]}>Consent:</Text><Text style={styles.normalStyle}> We may process your data if you have given us specific consent to use your personal information for a specific purpose.</Text></Text>
                      <Text style={styles.bulletStyle}>{'\u2022'}{' '}<Text style={[styles.normalStyle, { fontWeight: 'bold' }]}>Legitimate Interests:</Text><Text style={styles.normalStyle}> We may process your data when it is reasonably necessary to achieve our legitimate business interests.</Text></Text>
                      <Text style={styles.bulletStyle}>{'\u2022'}{' '}<Text style={[styles.normalStyle, { fontWeight: 'bold' }]}>Performance of a Contract:</Text><Text style={styles.normalStyle}> Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.</Text></Text>
                      <Text style={styles.bulletStyle}>{'\u2022'}{' '}<Text style={[styles.normalStyle, { fontWeight: 'bold' }]}>Legal Obligations:</Text><Text style={styles.normalStyle}> We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a
                        judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet
                        national security or law enforcement requirements).</Text></Text>
                      <Text style={styles.bulletStyle}>{'\u2022'}{' '}<Text style={[styles.normalStyle, { fontWeight: 'bold' }]}>Vital Interests:</Text><Text style={styles.normalStyle}> We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our
                        policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are
                        involved</Text></Text>

                      <Text style={styles.normalStyle}>More specifically, we may need to process your data or share your personal information in the following situations:</Text>
                      <Text style={styles.bulletStyle}>{'\u2022'}{' '}<Text style={[styles.normalStyle, { fontWeight: 'bold' }]}>Business Transfers:</Text><Text style={styles.normalStyle}> We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or
                        acquisition of all or a portion of our business to another company.</Text></Text>



                      <Text style={[styles.titleStyle, { fontSize: 18 }]}>4. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</Text>
                      <View style={{ flexDirection: 'row' }}><Text style={[styles.normalStyle, { fontWeight: 'bold' }]}>In Short
                        <Text style={styles.normalStyle}>If you choose to register or log in to our services using a social media account, we may have access to certain information about you.</Text></Text>
                      </View>
                      <Text style={styles.normalStyle}>Our Services offers you the ability to register and login using your third-party social media account details (like your Facebook or Twitter logins). Where you choose to
                        do this, we will receive certain profile information about you from your social media provider. The profile Information we receive may vary depending on the social
                        media provider concerned, but will often include your name, email address, friends list, profile picture as well as other information you choose to make public on such
                        social media platform.</Text>
                      <Text style={styles.normalStyle}>We will use the information we receive only for the purposes that are described in this privacy notice or that are otherwise made clear to you on the relevant Services.
                        Please note that we do not control, and are not responsible for, other uses of your personal information by your third-party social media provider. We recommend that
                        you review their privacy notice to understand how they collect, use and share your personal information, and how you can set your privacy preferences on their sites
                        and apps.</Text>


                      <Text style={[styles.titleStyle, { fontSize: 18 }]}>5. HOW LONG DO WE KEEP YOUR INFORMATION?</Text>
                      <View style={{ flexDirection: 'row' }}><Text style={[styles.normalStyle, { fontWeight: 'bold' }]}>In Short
                        <Text style={styles.normalStyle}>We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law.</Text></Text>
                      </View>
                      <Text style={styles.normalStyle}>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or
                        permitted by law (such as tax, accounting or other legal requirements). No purpose in this notice will require us keeping your personal information for longer than the
                        period of time in which users have an account with us.</Text>
                      <Text style={styles.normalStyle}>When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not
                        possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it
                        from any further processing until deletion is possible.</Text>


                      <Text style={[styles.titleStyle, { fontSize: 18 }]}>6. HOW DO WE KEEP YOUR INFORMATION SAFE?</Text>
                      <View style={{ flexDirection: 'row' }}><Text style={[styles.normalStyle, { fontWeight: 'bold' }]}>In Short</Text>
                        <Text style={styles.normalStyle}>We aim to protect your personal information through a system of organizational and technical security measures.</Text>
                      </View>
                      <Text style={styles.normalStyle}>We have implemented appropriate technical and organizational security measures designed to protect the security of any personal information we process. However,
                        despite our safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be
                        100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security, and
                        improperly collect, access, steal, or modify your information. Although we will do our best to protect your personal information, transmission of personal information to
                        and from our Services is at your own risk. You should only access the Services within a secure environment.</Text>


                      <Text style={[styles.titleStyle, { fontSize: 18 }]}>7. DO WE COLLECT INFORMATION FROM MINORS?</Text>
                      <View style={{ flexDirection: 'row' }}><Text style={[styles.normalStyle, { fontWeight: 'bold' }]}>In Short
                        <Text style={styles.normalStyle}>We do not knowingly collect data from or market to children under 18 years of age.</Text></Text>
                      </View>
                      <Text style={styles.normalStyle}>We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the
                        parent or guardian of such a minor and consent to such minor dependent's use of the Services. If we learn that personal information from users less than 18 years of
                        age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any
                        data we may have collected from children under age 18, please contact us at info@weclikd.com.</Text>



                      <Text style={[styles.titleStyle, { fontSize: 18 }]}>8. WHAT ARE YOUR PRIVACY RIGHTS?</Text>
                      <View style={{ flexDirection: 'row' }}><Text style={[styles.normalStyle, { fontWeight: 'bold' }]}>In Short
                        <Text style={styles.normalStyle}>You may review, change, or terminate your account at any time.</Text></Text>
                      </View>
                      <Text style={styles.normalStyle}>If you are a resident in the European Economic Area and you believe we are unlawfully processing your personal information, you also have the right to complain to
                        your local data protection supervisory authority. You can find their contact details here: http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.</Text>
                      <Text style={styles.normalStyle}>If you are a resident in Switzerland, the contact details for the data protection authorities are available here: https://www.edoeb.admin.ch/edoeb/en/home.html.</Text>
                      <Text style={styles.normalStyle}>If you have questions or comments about your privacy rights, you may email us at info@weclikd.com.</Text>



                      <Text style={[styles.titleStyle, { fontSize: 18 }]}>9. CONTROLS FOR DO-NOT-TRACK FEATURES</Text>
                      <Text style={styles.normalStyle}>Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your
                        privacy preference not to have data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and
                        implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates
                        your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised
                        version of this privacy notice.</Text>



                      <Text style={[styles.titleStyle, { fontSize: 18 }]}>10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</Text>
                      <Text style={[styles.normalStyle, { fontWeight: 'bold' }]}>In Short: <Text style={[styles.normalStyle, { fontWeight: '100' }]}>Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.</Text></Text>
                      <Text style={styles.normalStyle}> California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a
                        year and free of charge, information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and
                        addresses of all third parties with which we shared personal information in the immediately preceding calendar year. If you are a California resident and would like to
                        make such a request, please submit your request in writing to us using the contact information provided below.</Text>
                      <Text style={styles.normalStyle}>If you are under 18 years of age, reside in California, and have a registered account with a Service, you have the right to request removal of unwanted data that you
                        publicly post on the Services. To request removal of such data, please contact us using the contact information provided below, and include the email address
                        associated with your account and a statement that you reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware
                        that the data may not be completely or comprehensively removed from all our systems (e.g. backups, etc.).</Text>


                      <Text style={[styles.titleStyle, { fontSize: 18 }]}>CCPA Privacy Notice</Text>
                      <Text style={styles.normalStyle}>The California Code of Regulations defines a "resident" as:</Text>

                      <Text style={styles.normalStyle}>(1) every individual who is in the State of California for other than a temporary or transitory purpose and</Text>
                      <Text style={styles.normalStyle}>(2) every individual who is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose</Text>

                      <Text style={styles.normalStyle}>All other individuals are defined as "non-residents."</Text>
                      <Text style={styles.normalStyle}>If this definition of "resident" applies to you, we must adhere to certain rights and obligations regarding your personal information.</Text>

                      <Text style={[styles.normalStyle, { fontWeight: 'bold' }]}>What categories of personal information do we collect?</Text>
                      <Text style={styles.normalStyle}>We have collected the following categories of personal information in the past twelve (12) months:</Text>


                      <View style={{ borderWidth: 1, borderColor: '#D7D7D7' }}>
                        <table>
                          <thead>
                            <tr>
                              <th>Category</th>
                              <th>Examples</th>
                              <th>Collected</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td style={{
                                width: '20%', borderWidth: 1,
                                borderColor: '#d7d7d7',
                                borderStyle: 'solid'
                              }}>A. Identifiers</td>
                              <td style={{
                                borderWidth: 1,
                                borderColor: '#d7d7d7',
                                borderStyle: 'solid'
                              }}>Contact details, such as real name, alias, postal address, telephone or mobile
                                contact number, unique personal identifier, online identifier, Internet Protocol
                                address, email address and account name</td>
                              <td style={{
                                borderWidth: 1,
                                borderColor: '#d7d7d7',
                                borderStyle: 'solid'
                              }}>YES</td>
                            </tr>
                            <tr>
                              <td style={{
                                borderWidth: 1,
                                borderColor: '#d7d7d7',
                                borderStyle: 'solid', width: '20%'
                              }}>B. Personal information categories listed in the
                                California Customer Records statute</td>
                              <td style={{
                                borderWidth: 1,
                                borderColor: '#d7d7d7',
                                borderStyle: 'solid'
                              }}>Name, contact information, education, employment, employment history and
                                financial information</td>
                              <td style={{
                                borderWidth: 1,
                                borderColor: '#d7d7d7',
                                borderStyle: 'solid'
                              }}>YES</td>
                            </tr>
                            <tr>
                              <td style={{
                                borderWidth: 1,
                                borderColor: '#d7d7d7',
                                borderStyle: 'solid', width: '20%'
                              }}>C. Protected classification characteristics under</td>
                              <td style={{
                                borderWidth: 1,
                                borderColor: '#d7d7d7',
                                borderStyle: 'solid'
                              }}>Gender and date of birth</td>
                              <td style={{
                                borderWidth: 1,
                                borderColor: '#d7d7d7',
                                borderStyle: 'solid'
                              }}>NO</td>
                            </tr>
                            <tr>
                              <td style={{
                                width: '20%', borderWidth: 1,
                                borderColor: '#d7d7d7',
                                borderStyle: 'solid'
                              }}>D. Commercial information</td>
                              <td style={{
                                borderWidth: 1,
                                borderColor: '#d7d7d7',
                                borderStyle: 'solid'
                              }}>Transaction information, purchase history, financial details and payment information</td>
                              <td style={{
                                borderWidth: 1,
                                borderColor: '#d7d7d7',
                                borderStyle: 'solid'
                              }}>NO</td>
                            </tr>
                            <tr>
                              <td style={{
                                width: '20%', borderWidth: 1,
                                borderColor: '#d7d7d7',
                                borderStyle: 'solid'
                              }}>E. Biometric information</td>
                              <td style={{
                                borderWidth: 1,
                                borderColor: '#d7d7d7',
                                borderStyle: 'solid'
                              }}>Fingerprints and voiceprints</td>
                              <td style={{
                                borderWidth: 1,
                                borderColor: '#d7d7d7',
                                borderStyle: 'solid'
                              }}>NO</td>
                            </tr>
                            <tr>
                              <td style={{
                                width: '20%', borderWidth: 1,
                                borderColor: '#d7d7d7',
                                borderStyle: 'solid'
                              }}>F. Internet or other similar network activity</td>
                              <td style={{
                                borderWidth: 1,
                                borderColor: '#d7d7d7',
                                borderStyle: 'solid'
                              }}>Browsing history, search history, online behavior, interest data, and interactions with
                                our and other websites, applications, systems and advertisements</td>
                              <td style={{
                                borderWidth: 1,
                                borderColor: '#d7d7d7',
                                borderStyle: 'solid'
                              }}>NO</td>
                            </tr>
                            <tr>
                              <td style={{
                                width: '20%', borderWidth: 1,
                                borderColor: '#d7d7d7',
                                borderStyle: 'solid'
                              }}>G. Geolocation data</td>
                              <td style={{
                                borderWidth: 1,
                                borderColor: '#d7d7d7',
                                borderStyle: 'solid'
                              }}>Device location</td>
                              <td style={{
                                borderWidth: 1,
                                borderColor: '#d7d7d7',
                                borderStyle: 'solid'
                              }}>NO</td>
                            </tr>
                            <tr>
                              <td style={{
                                width: '20%', borderWidth: 1,
                                borderColor: '#d7d7d7',
                                borderStyle: 'solid'
                              }}>H. Audio, electronic, visual, thermal, olfactory, or
                                similar information</td>
                              <td style={{
                                borderWidth: 1,
                                borderColor: '#d7d7d7',
                                borderStyle: 'solid'
                              }}>Images and audio, video or call recordings created in connection with our business
                                activities</td>
                              <td style={{
                                borderWidth: 1,
                                borderColor: '#d7d7d7',
                                borderStyle: 'solid'
                              }}>NO</td>
                            </tr>
                            <tr>
                              <td style={{
                                width: '20%', borderWidth: 1,
                                borderColor: '#d7d7d7',
                                borderStyle: 'solid'
                              }}>I. Professional or employment-related information</td>
                              <td style={{
                                borderWidth: 1,
                                borderColor: '#d7d7d7',
                                borderStyle: 'solid'
                              }}>Business contact details in order to provide you our services at a business level, job
                                title as well as work history and professional qualifications if you apply for a job with
                                us</td>
                              <td style={{
                                borderWidth: 1,
                                borderColor: '#d7d7d7',
                                borderStyle: 'solid'
                              }}>NO</td>
                            </tr>
                            <tr>
                              <td style={{
                                width: '20%', borderWidth: 1,
                                borderColor: '#d7d7d7',
                                borderStyle: 'solid'
                              }}>J. Education Information</td>
                              <td style={{
                                borderWidth: 1,
                                borderColor: '#d7d7d7',
                                borderStyle: 'solid'
                              }}>Student records and directory information</td>
                              <td style={{
                                borderWidth: 1,
                                borderColor: '#d7d7d7',
                                borderStyle: 'solid'
                              }}>NO</td>
                            </tr>
                            <tr>
                              <td style={{
                                width: '20%', borderWidth: 1,
                                borderColor: '#d7d7d7',
                                borderStyle: 'solid'
                              }}>K. Inferences drawn from other personal information</td>
                              <td style={{
                                borderWidth: 1,
                                borderColor: '#d7d7d7',
                                borderStyle: 'solid'
                              }}>Inferences drawn from any of the collected personal information listed above to
                                create a profile or summary about, for example, an individual's preferences and
                                characteristics</td>
                              <td style={{
                                borderWidth: 1,
                                borderColor: '#d7d7d7',
                                borderStyle: 'solid'
                              }}>NO</td>
                            </tr>

                          </tbody>
                        </table>
                      </View>




                      <Text style={styles.normalStyle}>We may also collect other personal information outside of these categories instances where you interact with us in-person, online, or by phone or mail in the context
                        of:</Text>
                      <Text style={styles.bulletStyle}>{'\u2022'}{' '}<Text style={styles.normalStyle}>Receiving help through our customer support channels;</Text></Text>
                      <Text style={styles.bulletStyle}>{'\u2022'}{' '}<Text style={styles.normalStyle}>Participation in customer surveys or contests; and</Text></Text>
                      <Text style={styles.bulletStyle}>{'\u2022'}{' '}<Text style={styles.normalStyle}>Facilitation in the delivery of our Services and to respond to your inquiries.</Text></Text>

                      <Text style={[styles.normalStyle, { fontWeight: 'bold' }]}>How do we use and share your personal information?</Text>
                      <Text style={styles.normalStyle}>More information about our data collection and sharing practices can be found in this privacy notice.</Text>
                      <Text style={styles.normalStyle}>You may contact us by email at info@weclikd.com, or by referring to the contact details at the bottom of this document.</Text>
                      <Text style={styles.normalStyle}>If you are using an authorized agent to exercise your right to opt-out we may deny a request if the authorized agent does not submit proof that they have been validly
                        authorized to act on your behalf.</Text>

                      <Text style={[styles.normalStyle, { fontWeight: 'bold' }]}>Will your information be shared with anyone else?</Text>

                      <Text style={styles.normalStyle}>We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Each service provider is a
                        for-profit entity that processes the information on our behalf.</Text>
                      <Text style={styles.normalStyle}>We may use your personal information for our own business purposes, such as for undertaking internal research for technological development and demonstration.
                        This is not considered to be "selling" of your personal data.</Text>
                      <Text style={styles.normalStyle}>Weclikd Inc. has not disclosed or sold any personal information to third parties for a business or commercial purpose in the preceding 12 months. Weclikd Inc. will not
                        sell personal information in the future belonging to website visitors, users and other consumers.</Text>


                      <Text style={[styles.normalStyle, { fontWeight: 'bold' }]}>Your rights with respect to your personal data</Text>

                      <Text style={[styles.normalStyle, { textDecorationLine: 'underline' }]}>Right to request deletion of the data - Request to delete</Text>
                      <Text style={styles.normalStyle}>You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your request and delete your personal
                        information, subject to certain exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her right to free speech, our
                        compliance requirements resulting from a legal obligation or any processing that may be required to protect against illegal activities.</Text>

                      <Text style={[styles.normalStyle, { textDecorationLine: 'underline' }]}>Right to be informed - Request to know</Text>

                      <Text style={styles.normalStyle}>Depending on the circumstances, you have a right to know:</Text>
                      <Text style={styles.bulletStyle}>{'\u2022'}{' '}<Text style={styles.normalStyle}>whether we collect and use your personal information;</Text></Text>
                      <Text style={styles.bulletStyle}>{'\u2022'}{' '}<Text style={styles.normalStyle}> the categories of personal information that we collect;</Text></Text>
                      <Text style={styles.bulletStyle}>{'\u2022'}{' '}<Text style={styles.normalStyle}> the purposes for which the collected personal information is used;</Text></Text>
                      <Text style={styles.bulletStyle}>{'\u2022'}{' '}<Text style={styles.normalStyle}> whether we sell your personal information to third parties;</Text></Text>
                      <Text style={styles.bulletStyle}>{'\u2022'}{' '}<Text style={styles.normalStyle}> the categories of personal information that we sold or disclosed for a business purpose;</Text></Text>
                      <Text style={styles.bulletStyle}>{'\u2022'}{' '}<Text style={styles.normalStyle}>the categories of third parties to whom the personal information was sold or disclosed for a business purpose; and</Text></Text><Text style={styles.bulletStyle}>{'\u2022'}{' '}<Text style={styles.normalStyle}> you may object to the processing of your personal data</Text></Text>
                      <Text style={styles.bulletStyle}>{'\u2022'}{' '}<Text style={styles.normalStyle}> the business or commercial purpose for collecting or selling personal information.</Text></Text>
                      <Text style={styles.normalStyle}>In accordance with applicable law, we are not obligated to provide or delete consumer information that is de-identified in response to a consumer request or to re-
                        identify individual data to verify a consumer request.</Text>
                      <Text style={[styles.normalStyle, { textDecorationLine: 'underline' }]}>Right to Non-Discrimination for the Exercise of a Consumer's Privacy Rights</Text>

                      <Text style={styles.normalStyle}>We will not discriminate against you if you exercise your privacy rights.</Text>

                      <Text style={[styles.normalStyle, { textDecorationLine: 'underline' }]}>Verification process</Text>
                      <Text style={styles.normalStyle}>Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our system. These
                        verification efforts require us to ask you to provide information so that we can match it with information you have previously provided us. For instance, depending on
                        the type of request you submit, we may ask you to provide certain information so that we can match the information you provide with the information we already have
                        on file, or we may contact you through a communication method (e.g. phone or email) that you have previously provided to us. We may also use other verification
                        methods as the circumstances dictate.</Text>
                      <Text style={styles.normalStyle}>We will only use personal information provided in your request to verify your identity or authority to make the request. To the extent possible, we will avoid requesting
                        additional information from you for the purposes of verification. If, however, if we cannot verify your identity from the information already maintained by us, we may
                        request that you provide additional information for the purposes of verifying your identity, and for security or fraud-prevention purposes. We will delete such
                        additionally provided information as soon as we finish verifying you.</Text>

                      <Text style={[styles.normalStyle, { textDecorationLine: 'underline' }]}>Other privacy rights</Text>

                      <Text style={styles.bulletStyle}>{'\u2022'}{' '}<Text style={styles.normalStyle}> you may object to the processing of your personal data</Text></Text>
                      <Text style={styles.bulletStyle}>{'\u2022'}{' '}<Text style={styles.normalStyle}> you may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of the data</Text></Text>
                      <Text style={styles.bulletStyle}>{'\u2022'}{' '}<Text style={styles.normalStyle}> you can designate an authorized agent to make a request under the CCPA on your behalf. We may deny a request from an authorized agent that does not
                        submit proof that they have been validly authorized to act on your behalf in accordance with the CCPA.</Text></Text>
                      <Text style={styles.bulletStyle}>{'\u2022'}{' '}<Text style={styles.normalStyle}> you may request to opt-out from future selling of your personal information to third parties. Upon receiving a request to opt-out, we will act upon the request as
                        soon as feasibly possible, but no later than 15 days from the date of the request submission.</Text></Text>

                      <Text style={styles.normalStyle}>To exercise these rights, you can contact us by email at info@weclikd.com, or by referring to the contact details at the bottom of this document. If you have a
                        complaint about how we handle your data, we would like to hear from you.</Text>

                      <Text style={[styles.titleStyle, { fontSize: 18 }]}>11. DO WE MAKE UPDATES TO THIS NOTICE?</Text>
                      <Text style={[styles.normalStyle, { fontWeight: 'bold' }]}>In Short: <Text style={[styles.normalStyle, { fontWeight: '100' }]}>Yes, we will update this notice as necessary to stay compliant with relevant laws.</Text></Text>
                      <Text style={styles.normalStyle}>We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the updated version will be effective as
                        soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently posting a notice of such changes or by directly
                        sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.</Text>

                      <Text style={[styles.titleStyle, { fontSize: 18 }]}>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</Text>

                      <Text style={styles.normalStyle}>If you have questions or comments about this notice, you may email us at info@weclikd.com or by post to:</Text>
                      <Text style={styles.normalStyle}>Weclikd Inc.</Text>

                      <Text style={[styles.titleStyle, { fontSize: 18 }]}>13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</Text>
                      <Text style={styles.normalStyle}>
                        Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or
                        delete it in some circumstances. To request to review, update, or delete your personal information, please visit: https://www.weclikd.com. We will respond to your
                        request within 30 days.</Text>


                    </ScrollView>
                  ) :
                    <View style={{ width: "100%", height: 500 }}>
                      <WebView

                        scalesPageToFit={false}
                        javaScriptEnabled={true}
                        domStorageEnabled={true}
                        source={{ uri: "https://drive.google.com/file/d/1bJIpvhCT--ktDd62YGe5dn0usyqjdseU/view?usp=sharing" }}
                      />
                    </View>

                  }

                </View>
              </View>
            </ScrollView>
          </View>
        </ScrollView>
      </View>
    );
  }
}


const mapDispatchToProps = dispatch => ({
  searchOpenBarStatus: (payload) =>
    dispatch({ type: "SEARCHBAR_STATUS", payload }),
    screen: (payload) => dispatch(screen(payload))
});

export default compose(connect(null, mapDispatchToProps))(
  PrivacyPolicyScreen
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: ConstantColors.customeBackgroundColor,
    paddingTop: 0,
  },
  titleStyle: {
    color: "#000",
    fontWeight: "bold",
    fontSize: 20,
    fontFamily: ConstantFontFamily.Avenir,
    paddingVertical: 10
  },
  normalStyle: {
    color: "#000",
    fontSize: 15,
    fontFamily: ConstantFontFamily.Avenir,
    paddingVertical: 10
  },
  bulletStyle: {
    fontSize: 15,
    fontFamily: ConstantFontFamily.Avenir,
    paddingBottom: 10
  },
  borderStyle: {
    borderWidth: 1,
    borderColor: '#d7d7d7',
    borderStyle: 'solid'
  }
});
