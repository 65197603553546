// import { logInAsync } from "expo-google-app-auth";
import { getAuth, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup, createUserWithEmailAndPassword } from "firebase/auth";
import React, { Component } from "react";
// import { graphql } from "react-apollo";
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  // AsyncStorage,
  Image,
  Platform,
  ScrollView,
  Text,
  TextInput,
  TouchableOpacity,
  View,
  ActivityIndicator,
  Dimensions
} from "react-native";
import { Button, Icon } from "react-native-elements";
import { Hoverable } from "react-native-web-hooks";
import { connect } from "react-redux";
import { setCreateAccount } from "../reducers/CreateAccountReducer";
import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";
import { setSIGNUPMODALACTION } from "../reducers/AdminTrueFalseReducer";
import { setMessageModalStatus } from '../reducers/AdminTrueFalseReducer'
import { setLoginStatus } from '../reducers/UserReducer';
// import {
//   saveUserLoginDaitails,
//   setLoginStatus
// } from "../actionCreator/UserAction";
import { setUserApproachAction } from "../actionCreator/UserApproachAction";
import { setUSERNAMEMODALACTION } from "../actionCreator/UsernameModalAction";
import { setVERIFYEMAILMODALACTION } from "../actionCreator/VerifyEmailModalAction";
import appolloClient from "../client";
import ConstantFontFamily from "../constants/FontFamily";
import getEnvVars from "../environment";
import {
  AccountCreateMutation,
  UserLoginMutation,
  UserQueryMutation, UserFollowingMutation, ExternalFeedFollowingMutation, TopicFollowingMutation, ClikFollowingMutation
} from "../graphqlSchema/UserMutation";
import { AccountCreateVariables } from "../graphqlSchema/UserVariables";
// import { AsyncStorage.getItem, AsyncStorage.setItem } from "../library/Helper";
import jwt_decode from "jwt-decode";
import { setAdminStatus } from "../actionCreator/AdminAction";
import { getTrendingClicks } from "../actionCreator/TrendingCliksAction";
import { getTrendingTopics } from "../actionCreator/TrendingTopicsAction";
import { getTrendingUsers } from "../actionCreator/TrendingUsersAction";
import { getTrendingExternalFeeds } from "../actionCreator/TrendingExternalFeedsAction";
// import AppHelper from "../constants/AppHelper";
import ButtonStyle from "../constants/ButtonStyle";
// import SidePanel from "./SidePanel";
import { InviteKeyClikProfileMutation } from "../graphqlSchema/FollowandUnFollowMutation";
import { ClikPost } from '../reducers/action/Completed';
import Colors from "../constants/Colors";
import { saveUserLoginDaitails } from '../reducers/LoginUserDetailsReducer';




const apiUrl = getEnvVars();

const expr = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

class SignUpModalStep1 extends Component {
  static navigationOptions = {
    title: "",
    header: null
  };

  constructor(props) {
    super(props);
    this.isMount = {};
    this.state = {
      UserName: "",
      UserEmail: "",
      UserPassword: "",
      errorMessage: "",
      typingUsername: false,
      errorForm: true,
      fromSubmit: false,
      exists_msg: "",
      loading: false,
      InviteClik: {},
      inviteUsername: "",
      inviterProfilePic: "",
      clikTextColor: Colors.blueColor,
      clikBtnColor: Colors.buttonbackground,
    };
  }

  async componentDidMount() {
    let __self = this;
    AsyncStorage.getItem("newUserFirebase").then(r => {
      let data = JSON.parse(r);
      console.log(data, '2nd step')
      __self.setState({
        UserName: data ? data.UserName : ""
      });
    });

    if (this.props.getUserApproach == "signUp") {
      await this.props.setUserApproachAction({ type: "" });
      await this.userSignUp();
    }

    this.setState(
      {
        clikName:
          this.props.inviteUserDetails &&
          this.props.inviteUserDetails.clikName,
        inviteKey:
          this.props.inviteUserDetails &&
          this.props.inviteUserDetails.inviteKey
      },
      () => {

      }
    );
    if (
      this.props.inviteUserDetails &&
      this.props.inviteUserDetails.inviteKey != ""
    ) {
      this.getKeyProfile(
        this.props.inviteUserDetails &&
        this.props.inviteUserDetails.inviteKey
      );
    }
    if (
      this.props.inviteUserDetails &&
      this.props.inviteUserDetails.userName != ""
    ) {
      this.setState({
        inviteUsername:
          this.props.inviteUserDetails &&
          this.props.inviteUserDetails.userName
      });
    }
    this.props.setSignUpModalStatus(true);
  }

  getKeyProfile = async key => {
    try {
      await appolloClient
        .query({
          query: InviteKeyClikProfileMutation,
          variables: {
            invite_key: key
          },
          fetchPolicy: "no-cache"
        })
        .then(async res => {
          this.setState({
            InviteClik: res.data.clik_invite_key,
            inviteUsername: res.data.clik_invite_key.inviter.username,
            inviterProfilePic: res.data.clik_invite_key.inviter.profile_pic
          });
        });
    } catch (e) {
      console.log(e);
    }
  };

  usernameModal = async () => {
    this.props.setUsernameModalStatus(true);
  };

  onClose = () => {
    this.props.setUsernameModalStatus(false);
    this.props.setVerifyEmailModalStatus(false);
    this.props.setSignUpModalStatus(false);
  };

  checkEmail = async email => {
    this.setState({
      typingUsername: true
    });
    return this.ValidateEmail(email);
  };

  ValidateEmail = email => {
    if (!expr.test(email)) {
      let msg = "Please check you email address";
      this.setState({
        errorMessage: msg,
        UserEmail: email,
        errorForm: true
      });
    } else {
      this.setState({
        UserEmail: email,
        errorMessage: "",
        errorForm: false
      });
    }
  };

  nextStep = () => {
    let __self = this;
    // __self.props.setSignUpModalStatus(false);
    //__self.props.setVerifyEmailModalStatus(true);
    //this.props.setUsernameModalStatus(true);
    //this.props.navigation.navigate("verification");
    //return false;
    if (!this.state.errorForm) {
      this.checkOutnextStep();
    }
  };

  checkOutnextStep = async () => {
    let __self = this;

    if (this.state.errorForm == false) {
      const email = this.state.UserEmail;
      const password = this.state.UserPassword;
      const auth = getAuth();
      await createUserWithEmailAndPassword(auth, email, password).then(async res => {
        if (res) {
          await auth
            .currentUser.getIdToken(true)
            .then(async function (idToken) {
              return await AsyncStorage.getItem("userIdTokenFirebase").then(
                async res => {
                  await AsyncStorage.setItem("userIdTokenFirebase", idToken);
                  await AsyncStorage.setItem(
                    "admin",
                    jwt_decode(idToken).admin ? "true" : "false"
                  );
                  await __self.props.changeAdminStatus(
                    jwt_decode(idToken).admin
                      ? jwt_decode(idToken).admin
                      : false
                  );

                  await __self.props.setSignUpModalStatus(false);

                  __self.props.setMessageModalStatus(true)
                  return idToken;
                }
              );
            })
            .then(async res => {
              if (res) {
                __self.props.setCreateAccount({
                  username: __self.state.UserName,
                  email: __self.state.UserEmail,
                  password: __self.state.UserPassword,
                  first_name: "",
                  last_name: ""
                });
                AccountCreateVariables.variables.email =
                  __self.state.UserEmail;
                AccountCreateVariables.variables.username =
                  __self.state.UserName;
                AccountCreateVariables.variables.clik_invite_key =
                  this.props.inviteUserDetails.inviteKey == ""
                    ? '' + this.props.inviteUserDetails.clikName
                    : this.props.inviteUserDetails.inviteKey;
                AccountCreateVariables.variables.inviter_username = this.state.inviteUsername;
                return await appolloClient
                  .query({
                    query: AccountCreateMutation,
                    ...AccountCreateVariables,
                    fetchPolicy: "no-cache"
                  })
                  .then(async res => {
                    if (res) {
                      new Promise(async (resole, reject) => {
                        if (res.data.account_create.status.success) {
                          let id =
                            res.data.account_create.account.user.id;
                          await AsyncStorage.setItem("userLoginId", id);
                          await AsyncStorage.setItem("MyUserUserId", id);
                          await AsyncStorage.setItem("UserId", id);
                          resole(res);
                        }
                      })
                        .then(async (res) => {
                          console.log(res, 'ressss');
                          await __self.props.setSignUpModalStatus(false);
                          await __self.props.setMessageModalStatus(true);
                          // console.log(this.props,'prprpr')
                          // return await __self.props.Login();
                          // await this.props.setAnonymousUser({
                          //   value: false,
                          //   token: ""
                          // })
                          await AsyncStorage.setItem(
                            "userLoginId",
                            res.data.account_create.account.id
                          );
                          await AsyncStorage.setItem(
                            "newUserFirebase",
                            res.data.account_create.account.user.username
                          );

                          await AsyncStorage.setItem(
                            "MyUserUserId",
                            res.data.account_create.account.user.length > 0
                              ? res.data.account_create.account.user.id
                              : ""
                          );
                          await AsyncStorage.setItem(
                            "UserId",
                            res.data.account_create.account.user.length > 0
                              ? res.data.account_create.account.user.id
                              : ""
                          );
                          // await __self.props.getTrendingUsers({
                          //   currentPage: AppHelper.PAGE_LIMIT
                          // });
                          // await __self.props.getTrendingClicks({
                          //   currentPage: AppHelper.PAGE_LIMIT
                          // });
                          // await __self.props.getTrendingTopics({
                          //   currentPage: AppHelper.PAGE_LIMIT
                          // });
                          // await __self.props.getTrendingExternalFeeds({
                          //   currentPage: AppHelper.PAGE_LIMIT
                          // });

                          let userfollow = await appolloClient
                            .query({
                              query: UserFollowingMutation,
                              variables: {
                                id: "User:CurrentUser",
                                first: 20
                              },
                              fetchPolicy: "no-cache"
                            }).then(e => {
                              return e.data.node.users_following.edges
                            })

                          let topicfollow = await appolloClient
                            .query({
                              query: TopicFollowingMutation,
                              variables: {
                                id: "User:CurrentUser",
                                first: 20
                              },
                              fetchPolicy: "no-cache"
                            }).then(e => {
                              return e.data.node.topics_following.edges
                            })
                          let clikfollow = await appolloClient
                            .query({
                              query: ClikFollowingMutation,
                              variables: {
                                id: "User:CurrentUser",
                                first: 20
                              },
                              fetchPolicy: "no-cache"
                            }).then(e => {
                              return e.data.node.cliks_following.edges
                            })
                          let externalfollow = await appolloClient
                            .query({
                              query: ExternalFeedFollowingMutation,
                              variables: {
                                id: "User:CurrentUser",
                                first: 20
                              },
                              fetchPolicy: "no-cache"
                            }).then(e => {
                              return e.data.node.external_feeds_following.edges
                            })
                          let userData = {
                            data: res.data.account_create.account,
                            externalfollow: externalfollow,
                            clikfollow: clikfollow,
                            topicfollow: topicfollow,
                            userfollow: userfollow

                          }
                          await __self.props.saveLoginUser(userData)
                        })
                      await __self.props.changeLoginStatus(1);
                      await __self.props.setMessageModalStatus(true);
                      let actionCodeSettings = {
                        url: apiUrl.APPDYNAMICLINK,
                        handleCodeInApp: true
                      };

                    }
                  });

              }
            });
        }
      })
        .catch(e => {
          console.log(e);
          if (e.code == "auth/email-already-in-use") {
            this.setState({
              exists_msg: "Account Already Exists"
            });
            this.isMount = setTimeout(() => {
              this.setState({
                exists_msg: ""
              });
            }, 5000);
          }
        });
    }
  };

  handleLoginButton = async () => {
    this.props.setLoginModalStatus(true);
    this.props.setSignUpModalStatus(false);
  };

  handleNoAccount = async () => {
    this.onClose();
    this.props.setUsernameModalStatus(true);
  };

  loginWithGoogle = async () => {
    let __self = this;
    new Promise(async (resole, reject) => {
      await __self.props.setCreateAccount({
        username: __self.state.UserName,
        email: __self.state.UserEmail,
        password: __self.state.UserPassword,
        first_name: "",
        last_name: ""
      });
      resole(true);
    }).then(async res => {
      if (res) {
        if (Platform.OS == "web") {
          await __self.loginWithGoogleWeb();
        } else {
          await __self.loginWithGoogleMobile();
        }
      }
    });
  };

  loginWithGoogleWeb = async () => {
    await this.props.setUserApproachAction({ type: "signUp" });
    // await this.props.setAnonymousUser({ value: false, token: "" });
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account'
    });
    const auth = getAuth();

    await signInWithPopup(auth, provider);

  };

  userSignUp = async () => {
    this.props.ClikPost(0)
    let __self = this;
    await firebase.auth().onAuthStateChanged(async res => {
      if (res) {

        // __self.props.setScreenLoadingModalAction(true);
        this.setState({
          loading: true
        });
        await firebase
          .auth()
          .currentUser.getIdToken(true)
          .then(async function (idToken) {

            return await AsyncStorage.getItem("userIdTokenFirebase").then(
              async res => {
                await AsyncStorage.setItem("userIdTokenFirebase", idToken);
                await AsyncStorage.setItem(
                  "admin",
                  jwt_decode(idToken).admin ? "true" : "false"
                );
                await __self.props.changeAdminStatus(
                  jwt_decode(idToken).admin ? jwt_decode(idToken).admin : false
                );
                __self.props.setSignUpModalStatus(false);
                __self.props.setMessageModalStatus(true);
                return idToken;
              }
            );
          })
          .then(async res => {
            // console.log(res, 'res===')
            if (res) {
              let user = await firebase.auth().currentUser;
              let userDetails =
                user.displayName == null
                  ? null
                  : await user.displayName.split(" ");
              let UserFirstName = userDetails != null ? userDetails[0] : "";
              let UserLastName = userDetails != null ? userDetails[1] : "";
              let newUserData = {
                username: __self.props.getCreateAccount.getIn(["username"]),
                email: user.email,
                password: __self.props.getCreateAccount.getIn(["password"]),
                first_name: UserFirstName,
                last_name: UserLastName
              };
              await __self.props.setCreateAccount(newUserData);
              AccountCreateVariables.variables.email = __self.props.getCreateAccount.getIn(
                ["email"]
              );
              AccountCreateVariables.variables.full_name = __self.props.getCreateAccount.getIn(
                ["full_name"]
              );
              // AccountCreateVariables.variables.last_name = __self.props.getCreateAccount.getIn(
              //   ["last_name"]
              // );
              AccountCreateVariables.variables.username = __self.props.getCreateAccount.getIn(
                ["username"]
              );
              AccountCreateVariables.variables.clik_invite_key =
                this.props.inviteUserDetails.inviteKey == ""
                  ? '' + this.props.inviteUserDetails.clikName
                  : this.props.inviteUserDetails.inviteKey;
              AccountCreateVariables.variables.inviter_username = this.state.inviteUsername;
              let glres = await appolloClient.query({
                query: AccountCreateMutation,
                ...AccountCreateVariables,
                fetchPolicy: "no-cache"
              });
              if (glres) {
                new Promise(async (resole, reject) => {
                  let id = glres.data.account_create.account.user.id;
                  await AsyncStorage.setItem("userLoginId", id);
                  await AsyncStorage.setItem("MyUserUserId", id);
                  await AsyncStorage.setItem("UserId", id);
                  resole(glres);
                })
                  .then(async () => {
                    __self.props.setSignUpModalStatus(false);
                    __self.props.setMessageModalStatus(true);
                    return await __self.props.Login();
                  })
                  .then(async res => {
                    await AsyncStorage.setItem(
                      "userLoginId",
                      res.data.login.account.id
                    );
                    await AsyncStorage.setItem(
                      "MyUserUserId",
                      res.data.login.account.user.length > 0
                        ? res.data.login.account.user.id
                        : ""
                    );
                    await AsyncStorage.setItem(
                      "UserId",
                      res.data.login.account.user.length > 0
                        ? res.data.login.account.user.user.id
                        : ""
                    );

                    await __self.props.getTrendingUsers({
                      currentPage: AppHelper.PAGE_LIMIT
                    });
                    await __self.props.getTrendingClicks({
                      currentPage: AppHelper.PAGE_LIMIT
                    });
                    await __self.props.getTrendingTopics({
                      currentPage: AppHelper.PAGE_LIMIT
                    });
                    await __self.props.getTrendingExternalFeeds({
                      currentPage: AppHelper.PAGE_LIMIT
                    });

                    await __self.props.saveLoginUser(res.data.login);
                    await __self.props.changeLoginStatus(1);
                    await __self.props.setCreateAccount({
                      username: "",
                      email: "",
                      password: "",
                      first_name: "",
                      last_name: ""
                    });
                  });

                this.setState({
                  loading: false
                });

              }
            }
          })
          .catch(async e => {
            console.log(e);

            await __self.props.setCreateAccount({
              username: "",
              email: "",
              password: "",
              first_name: "",
              last_name: ""
            });
            //this.props.setLoginModalStatus(true);
            this.setState({
              loading: false
            });

          });
      }
    });
  };

  loginWithGoogleMobile = async () => {
    try {
      const result = await logInAsync({
        androidClientId: apiUrl.ANDROIDCLIENT_ID,
        iosClientId: apiUrl.IOSCLIENT_ID,
        scopes: ["profile", "email"]
      });
      if (result.type === "success") {
        // await this.props.setAnonymousUser({ value: false, token: "" });
        await this.onSignIn(result);
      } else {
        return { cancelled: true };
      }
    } catch (e) {
      return { error: true };
    }
  };

  onSignIn = async googleUser => {
    let __self = this;
    __self.onClose();
    let credential = await firebase.auth.GoogleAuthProvider.credential(
      googleUser.idToken,
      googleUser.accessToken
    );
    await firebase
      .auth()
      .signInWithCredential(credential)
      .then(async function (result) {
        if (result) {
          await __self.props.setUserApproachAction({ type: "signUp" });
        }
      });
  };

  componentWillUnmount() {
    clearTimeout(this.isMount);
  }

  continueWithEmail = async () => {
    let __self = this;
    firebase
      .auth()
      .signInAnonymously()
      .then(async res => {
        if (res) {
          await firebase
            .auth()
            .currentUser.getIdToken(true)
            .then(async function (idToken) {
              return await AsyncStorage.getItem("userIdTokenFirebase").then(
                async res => {
                  await AsyncStorage.setItem("userIdTokenFirebase", idToken);
                  await AsyncStorage.setItem(
                    "admin",
                    jwt_decode(idToken).admin ? "true" : "false"
                  );
                  await __self.props.changeAdminStatus(
                    jwt_decode(idToken).admin
                      ? jwt_decode(idToken).admin
                      : false
                  );
                  // __self.props.setSignUpModalStatus(false);
                  // __self.props.setMessageModalStatus(true);
                  return idToken;
                }
              );
            })
            .then(async res => {
              if (res) {
                __self.props.setCreateAccount({
                  username: this.state.UserName,
                  email: "",
                  password: "",
                  first_name: "",
                  last_name: ""
                });
                AccountCreateVariables.variables.email = "";
                AccountCreateVariables.variables.first_name = "";
                AccountCreateVariables.variables.last_name = "";
                AccountCreateVariables.variables.username = this.state.UserName;
                AccountCreateVariables.variables.clik_invite_key =
                  this.props.inviteUserDetails.inviteKey == ""
                    ? '' + this.props.inviteUserDetails.clikName
                    : this.props.inviteUserDetails.inviteKey;
                AccountCreateVariables.variables.inviter_username = this.state.inviteUsername;

                return await appolloClient
                  .query({
                    query: AccountCreateMutation,
                    ...AccountCreateVariables,
                    fetchPolicy: "no-cache"
                  })
                  .then(async res => {
                    if (res) {
                      new Promise(async (resole, reject) => {
                        if (res.data.account_create.status.success) {
                          let id =
                            res.data.account_create.account.user.id;
                          await AsyncStorage.setItem("userLoginId", id);
                          await AsyncStorage.setItem("MyUserUserId", id);
                          await AsyncStorage.setItem("UserId", id);
                          resole(res);
                        }
                      })
                        .then(async () => {
                          __self.props.setSignUpModalStatus(false);
                          __self.props.setMessageModalStatus(true);
                          // console.log(__self.props,'prpr');
                          // return await __self.props.Login();
                        })
                        .then(async res => {
                          // this.props.navigation.navigate("message");
                          // await this.props.setAnonymousUser({
                          //   value: true,
                          //   token: await AsyncStorage.getItem(
                          //     "userIdTokenFirebase"
                          //   )
                          // }),
                          await AsyncStorage.setItem(
                            "userLoginId",
                            res.data.login.account.id
                          );
                          await AsyncStorage.setItem(
                            "MyUserUserId",
                            res.data.login.account.user.length > 0
                              ? res.data.login.account.user.id
                              : ""
                          );
                          await AsyncStorage.setItem(
                            "UserId",
                            res.data.login.account.user.length > 0
                              ? res.data.login.account.user.id
                              : ""
                          );
                          await __self.props.getTrendingUsers({
                            currentPage: AppHelper.PAGE_LIMIT
                          });
                          await __self.props.getTrendingClicks({
                            currentPage: AppHelper.PAGE_LIMIT
                          });
                          await __self.props.getTrendingTopics({
                            currentPage: AppHelper.PAGE_LIMIT
                          });
                          await __self.props.getTrendingExternalFeeds({
                            currentPage: AppHelper.PAGE_LIMIT
                          });
                          await __self.props.saveLoginUser(res.data.login);
                          await __self.props.changeLoginStatus(1);
                          let actionCodeSettings = {
                            url: apiUrl.APPDYNAMICLINK,
                            handleCodeInApp: true
                          };
                        });
                    }
                  });
              }
            });
        }
      })
      .catch(e => {
        console.log(e);
        if (e.code == "auth/email-already-in-use") {
          this.setState({
            exists_msg: "Account Already Exists"
          });
          this.isMount = setTimeout(() => {
            this.setState({
              exists_msg: ""
            });
          }, 5000);
        }
      });
  };

  render() {
    const { typingUsername, errorMessage, fromSubmit, exists_msg } = this.state;
    return (
      <View
        style={{
          backgroundColor: "#fff",
          borderColor: "#c5c5c5",
          borderRadius: 6,
          // maxHeight: 450,
          width: Dimensions.get('window').width >= 750 ? 450 : '100%',
          height: 400,
          alignSelf: 'center',
        }}
      >
        <View>
          <Hoverable>
            {isHovered => (
              <TouchableOpacity
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  flex: 1,
                  position: "absolute",
                  zIndex: 999999,
                  left: 0,
                  top: 0
                }}
                onPress={this.props.onClose}
              >
                <Icon
                  color={isHovered == true ? "rgba(256,256,256,0.4)" : "#000"}
                  iconStyle={{
                    color: "#fff",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                  reverse
                  name="close"
                  type="antdesign"
                  size={16}
                />
              </TouchableOpacity>
            )}
          </Hoverable>

          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              backgroundColor: "#000",
              alignItems: "center",
              height: 50,
              borderTopLeftRadius: 6,
              borderTopRightRadius: 6,
              width: "100%"
            }}
          >
            {/* <Text style={[styles.TextHeaderStyle, { color: "#fff" }]}>
              Sign Up
            </Text> */}
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row"
              }}
            >

              <Image
                source={require("../assets/image/logolastOne.png")}
                style={{
                  justifyContent: "flex-start",
                  // marginLeft: 10,
                  height: 35,
                  width: 35,
                  marginRight: 5,
                  borderRadius: 8
                }}
                resizeMode={"contain"}
              />

              <Text
                style={[ButtonStyle.profileTitleStyle, {
                  // fontFamily: ConstantFontFamily.defaultFont,
                  // fontWeight: "bold",
                  fontSize: 20,
                  textAlign: "center",
                  color: "white",
                  fontWeight: 'bold'
                }]}
              >
                weclikd
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{
            alignItems: "center",
            // flex: 1,
            padding: 10,
            borderRadius: 6,
            backgroundColor: "#fff"
          }}
        >
          <View
            style={{
              overflow: "visible",
              width: "100%",
              backgroundColor: "#fff",
              borderBottomLeftRadius: 6,
              borderBottomRightRadius: 6
            }}
          >
            {exists_msg != "" && (
              <Text
                style={{
                  fontSize: 17,
                  color: "red",
                  textAlign: "center",
                  padding: 10,
                  fontWeight: "600",
                  fontFamily: ConstantFontFamily.defaultFont
                }}
              >
                {exists_msg}
              </Text>
            )}


          </View>

          <View
            style={{
              width: "100%",
              paddingHorizontal: 10,
              paddingVertical: 10,
              justifyContent: 'center',
            }}
          >
            <TouchableOpacity
              style={{
                backgroundColor: "#FFFFFF",
                borderRadius: 10,
                height: 40,
                flexDirection: "row",
                paddingHorizontal: "5%",
                borderWidth: 1,
                borderColor: "#c5c5c5",
                justifyContent: "center",
                alignItems: "center"
              }}
              onPress={this.loginWithGoogle}
            >
              <Image
                source={require("../assets/image/gLogin.png")}
                style={{
                  height: 25,
                  width: 25,
                  marginRight: "auto"
                }}
              />
              <Text
                style={{
                  fontSize: 16,
                  color: "#000",
                  textAlign: "center",
                  marginRight: "auto",
                  fontFamily: ConstantFontFamily.defaultFont,
                  marginLeft: 5
                }}
              >
                Sign up with Google
              </Text>
            </TouchableOpacity>
            <View
              style={{
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
                justifyContent: "center",
                height: 50,
                marginVertical: 10
              }}
            >
              <View
                style={{
                  borderWidth: 1,
                  borderColor: "#e1e1e1",
                  width: "43%",
                  justifyContent: "flex-start",
                  height: 1,
                  borderRadius: 6,
                  backgroundColor: "#e1e1e1"
                }}
              ></View>
              <Text
                style={{
                  fontSize: 16,
                  color: "#49525D",
                  textAlign: "center",
                  marginHorizontal: "4%",
                  marginVertical: "10%",
                  fontFamily: ConstantFontFamily.defaultFont
                }}
              >
                or
              </Text>
              <View
                style={{
                  borderWidth: 1,
                  borderColor: "#e1e1e1",
                  width: "43%",
                  justifyContent: "flex-end",
                  height: 1,
                  borderRadius: 6,
                  backgroundColor: "#e1e1e1"
                }}
              ></View>
            </View>

            <View
              style={{
                marginBottom: 10,
                flexDirection: "row",
                height: 45,
                alignItems: "center",
                width: "100%"
              }}
            >
              <TextInput
                testID="mail1"
                onChangeText={UserEmail => this.checkEmail(UserEmail)}
                placeholder="Email"
                underlineColorAndroid="transparent"
                style={[ButtonStyle.textAreaShadowStyle, {
                  height: 45,
                  paddingLeft: 10,
                  fontFamily: ConstantFontFamily.defaultFont,
                  width: "100%"
                }]}
              />
              <View
                style={{
                  flex: 1,
                  flexDirection: "row",
                  justifyContent: "flex-end"
                }}
              >
                {this.state.typingUsername == true ? (
                  this.state.errorMessage == "" ? (
                    <Icon
                      size={20}
                      name="check"
                      type="font-awesome"
                      iconStyle={{ marginRight: 10 }}
                      color="#009B1A"
                      underlayColor="#000"
                    />
                  ) : (
                    <Icon
                      size={20}
                      name="close"
                      type="font-awesome"
                      iconStyle={{ marginRight: 10 }}
                      color="#de5246"
                      underlayColor="#000"
                    />
                  )
                ) : this.state.UserEmail.length > 0 ? (
                  <Icon
                    size={20}
                    name="close"
                    type="font-awesome"
                    iconStyle={{ marginRight: 10 }}
                    color="#de5246"
                    underlayColor="#000"
                  />
                ) : null}
              </View>
            </View>

            <TextInput
              testID="password1"
              placeholder="Password"
              placeholderTextColor="#53555A"
              underlineColorAndroid="transparent"
              style={[ButtonStyle.textAreaShadowStyle, {
                height: 45,
                paddingLeft: 10,
                fontFamily: ConstantFontFamily.defaultFont,
                width: "100%"
              }]}
              secureTextEntry={true}
              onChangeText={UserPassword => this.setState({ UserPassword })}
            />
          </View>
          <View
            style={{
              width: "100%",
              paddingHorizontal: "5%"
            }}
          >
            <View
              style={{
                alignItems: "center"
              }}
            >
              <Button
                testID="Sign2"
                title="Sign up"
                titleStyle={ButtonStyle.allButtonTextStyle}
                containerStyle={ButtonStyle.containerStyle}
                buttonStyle={[
                  ButtonStyle.allButtonbackgroundStyle,
                  {
                    backgroundColor:
                      this.state.UserEmail == "" ||
                        this.state.UserPassword == ""
                        ? "grey"
                        : this.state.clikBtnColor,
                    borderColor: (this.state.UserEmail != "" &&
                      this.state.UserPassword != "") ? Colors.blueColor : '#d3d3d3'
                  }
                ]}
                disabled={
                  this.state.UserEmail == "" || this.state.UserPassword == ""
                    ? true
                    : false
                }
                onPress={this.nextStep}
              />
            </View>


          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = state => ({
  // getCurrentDeviceWidthAction: state.CurrentDeviceWidthReducer.get("dimension"),
  // getUserApproach: state.UserApproachReducer.get("setUserApproach"),
  getCreateAccount: state.CreateAccountReducer.setCreateAccountData,
  inviteUserDetails: state.AdminTrueFalseReducer.inviteUserDetails
});

const mapDispatchToProps = dispatch => ({
  saveLoginUser: payload => dispatch(saveUserLoginDaitails(payload)),
  changeLoginStatus: payload => dispatch(setLoginStatus(payload)),
  setUsernameModalStatus: payload => dispatch(setUSERNAMEMODALACTION(payload)),
  setSignUpModalStatus: payload => dispatch(setSIGNUPMODALACTION(payload)),
  // setVerifyEmailModalStatus: payload =>
  //   dispatch(setVERIFYEMAILMODALACTION(payload)),
  // setLoginModalStatus: payload => dispatch(setLOGINMODALACTION(payload)),
  setCreateAccount: payload => dispatch(setCreateAccount(payload)),
  setUserApproachAction: payload => dispatch(setUserApproachAction(payload)),
  changeAdminStatus: payload => dispatch(setAdminStatus(payload)),
  setMessageModalStatus: payload => dispatch(setMessageModalStatus(payload)),

  // getTrendingUsers: payload => dispatch(getTrendingUsers(payload)),
  // getTrendingTopics: payload => dispatch(getTrendingTopics(payload)),
  // getTrendingClicks: payload => dispatch(getTrendingClicks(payload)),
  // getTrendingExternalFeeds: payload =>
  //   dispatch(getTrendingExternalFeeds(payload)),
  // setMessageModalStatus: payload =>
  //   dispatch({ type: "MESSAGEMODALSTATUS", payload }),
  // setInviteUserDetail: payload =>
  //   dispatch({ type: "SET_INVITE_USER_DETAIL", payload }),
  // setAnonymousUser: payload => dispatch({ type: "ANONYMOUS_USER", payload }),
  // ClikPost: payload => dispatch(ClikPost(payload)),

});

// const LoginModalContainerWrapper = graphql(UserLoginMutation, {
//   name: "Login",
//   options: {
//     variables: {
//       id: "Account:" + "CurrentUser"
//     },
//     fetchPolicy: "no-cache"
//   }
// })(SignUpModalStep1);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SignUpModalStep1);

const styles = {
  MainContainer: {
    flex: 1,
    backgroundColor: "#fff",
    flexDirection: "column",
    alignItems: "stretch"
  },
  LogoImageStyle: {
    height: 80,
    width: 235
  },
  LogoContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    margin: 25
  },
  TextInputStyleClass: {
    marginBottom: 10,
    height: 45,
    paddingLeft: 10,
    borderWidth: 0,
    // borderRadius: 10,
    // borderColor: "#c5c5c5",
    color: "#000",
    // backgroundColor: "#fff",
    fontFamily: ConstantFontFamily.defaultFont
  },
  TextHeaderStyle: {
    fontSize: 23,
    color: "#000",
    textAlign: "center",
    marginBottom: 10,
    marginTop: 10,
    fontWeight: "bold",
    fontFamily: ConstantFontFamily.defaultFont
  },
  buttonStyle: {
    marginTop: 25,
    backgroundColor: "#555555",
    justifyContent: "center",
    borderRadius: 5,
    height: 40,
    width: 100
  },
  buttontextstyle: {
    fontSize: 20,
    color: "#fff",
    fontFamily: ConstantFontFamily.defaultFont,
    alignItems: "center",
    justifyContent: "center"
  }
};
