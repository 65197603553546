import { combineReducers } from "redux";
import counterSlice from './testReducer';
import HomefeedListSlice from "./HomeFeedReducer";
import PostCommentDetailsReducer from './PostCommentDetailsReducer';
import PostDetailsReducer from "./PostDetailsReducer";
import UserReducer from './UserReducer';
// import AdminTrueFalseReducer from "./AdminTrueFalseReducer";
import LoginModalReducer from './LoginModalReducer';
import windowResizeReducer from './windowResizeReducer';
import HasScrolledReducer from './HasScrolledReducer';
import ClikProfileDetailsReducer from './TrendingCliksProfileReducer';
import TopicProfileDetailsReducer from './TrendingTopicProfileReducer';
import UserProfileDetailsReducer from './UserProfileDetailsReducer';
import FeedProfileReducer from './FeedProfileReducer';
// import UserReducer from './UserReducer';
import LoginUserDetailsReducer from './LoginUserDetailsReducer';
import AdminTrueFalseReducer from './AdminTrueFalseReducer';
import LinkPostReducer from './LinkPostReducer';
import EditClikReducer from './EditClikReducer'
// import ClikMembersReducer from './ClikMembersReducer';
import ScreenLoadingReducer from './ScreenLoadingReducer';
import TermArrayReducer from './AddtermReducer';
import PostShareReducer from './PostShareReducer';
// import ClikUserRequestReducer from './ClikUserRequestReducer'
import screenNameReducer from './ScreenNameReducer';
import openTookitReducer from "./openTookitReducer";
import ShowCommentReducer from './ShowCommentReducer';
import CreateAccountReducer from './CreateAccountReducer';



export default function createReducer() {
  return combineReducers({
    counterSlice,
    HomefeedListSlice,
    PostCommentDetailsReducer,
    PostDetailsReducer,
    UserReducer,
    AdminTrueFalseReducer,
    LoginModalReducer,
    windowResizeReducer,
    HasScrolledReducer,
    ClikProfileDetailsReducer,
    TopicProfileDetailsReducer,
    UserProfileDetailsReducer,
    FeedProfileReducer,
    LoginUserDetailsReducer,
    LinkPostReducer,
    EditClikReducer,
    // ClikMembersReducer,
    ScreenLoadingReducer,
    TermArrayReducer,
    PostShareReducer,
    // ClikUserRequestReducer
    screenNameReducer,
    openTookitReducer,
    ShowCommentReducer,
    CreateAccountReducer
  });
}
