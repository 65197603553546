import { Platform } from "react-native";
import { call, put, takeEvery, select } from "redux-saga/effects";
import {
  POSTCOMMENTDETAILS,
} from "../constants/Action";
import getEnvVars from "../environment";
import appolloClient from "../client";
import { GetCommentMutation } from '../graphqlSchema/PostMutation';
import { setPostCommentDetailSuccess } from "../reducers/PostCommentDetailsReducer";

const isWeb = Platform.OS === "web";
const apiUrl = getEnvVars();
const getResponse = (req) => {
  if (req.id) {


    let comment = appolloClient
      .query({
        query:
          GetCommentMutation,
        variables: {
          id: req.id
        },
        fetchPolicy: "no-cache"
      }).then(data => {
        if (data?.data?.node?.nested_comments?.length > 0) {
          let commentData = JSON.parse(data?.data?.node?.nested_comments)
          return commentData.edges.reverse()
        }
        return []

      })
    return comment
  } else {
    return [];
  }
};

export function* PostCommentMiddleware({ payload }) {
  // console.log(payload,'ppppppppp');
  const getPostId = (state) => state.PostCommentDetailsReducer.PostId;
  const PostId = yield select(getPostId);

  const getTitle = (state) => state.PostCommentDetailsReducer.Title;
  const title = yield select(getTitle);
  const getPostCommentDetails = (state) =>
    state.PostCommentDetailsReducer.PostCommentDetails;
  const PostCommentDetails = yield select(getPostCommentDetails);



  try {
    let response;
    if (Array.isArray(payload)) {
      response = payload;
    } else {
      // if (PostId != payload.id) {
      yield put(setPostCommentDetailSuccess({
        // type: POSTCOMMENTDETAILS_SUCCESS,
        payload: [],
        postId: payload.id && payload.id
          .replace("Trending", "")
          .replace("New", "")
          .replace("Discussion", "")
          .replace("Search", ""),
        title: payload.title,
        loading: payload.loading && payload.loading,
      }));
      response = yield call(getResponse, payload);
      // } else {
      //   response = PostCommentDetails;
      // }
    }


    let data;

    // if (payload?.notification == true) {
    //   data = setSwipeNotification(response, payload)
    // } else {
      data = setArrowKeys(response, payload)

    // }  

    yield put(setPostCommentDetailSuccess({
      payload:data,
      postId: Array.isArray(payload) ? PostId : payload.id,
      title: Array.isArray(payload) ? title : payload.title,
      loading: payload.loading && payload.loading,
    }));


    // yield put({
    //   type: POSTCOMMENTDETAILS_SUCCESS,
    //   payload: [],
    //   postId: payload.id,
    //   title: payload.title,
    //   loading: false,
    // });
    // title: Array.isArray(payload) ? title : payload.title,
    yield put({
      type: "SET_COMMENT_LEVEL",
      payload: 2,
    });
  } catch (err) {
    // yield put({
    //   type: POSTCOMMENTDETAILS_FAILURE,
    //   payload: err.message,
    // });
    console.log(err);
  }
}

export default function* PostCommentMiddlewareWatcher() {
  yield takeEvery(POSTCOMMENTDETAILS, PostCommentMiddleware);
}


function nestedSwipe(arr) {
  let nestedSwipeArray = [...arr]

  nestedSwipeArray.forEach((e, ind) => {
    if (ind == 0) {
      let newObj = {
        ...e, swipe: true
      }
      nestedSwipeArray.splice(0, 1, newObj)
    } else {
      let newObj1 = {
        ...e, swipe: false


      }
      nestedSwipeArray.splice(nestedSwipeArray.indexOf(e), 1, newObj1)
    }



  })

  nestedSwipeArray.forEach((e) => {
    // console.log('this is calling here ');

    let newUpddated = nestedSwipe(e.node.comments.edges)
    
    nestedSwipeArray.splice(nestedSwipeArray.indexOf(e), 1, {
      ...e, node: {
        ...e.node, comments: {
          ...e.node.comments, edges: [...newUpddated]
        }
      }
    })
  })

  return nestedSwipeArray;


}

function setArrowKeys(items, payload) {

  // console.log(payload, 'eeenestedparent');

  let newSwipeParent = [...items]

  newSwipeParent.forEach((e, ind) => {

    let newSwipe = nestedSwipe(e.node.comment.comments.edges)
    newSwipeParent.splice(newSwipeParent.indexOf(e), 1, {
      ...e, node: {
        ...e.node, minimize: false, comment: {
          ...e.node.comment, comments: {
            ...e.node.comment.comments, edges: [...newSwipe]
          }
        }
      }
    })

  })

  // console.log(newSwipeParent, 'newSwipeParent');

  return newSwipeParent;
  // }
}








function nestedComment(item, payload) {
  let nestedComments = [...item]


  nestedComments.forEach((e, ind) => {
    if (e.node.id == payload.item.id) {
      // console.log(e, 'eee');
      let newObj = {
        ...e, swipe: true
      }
      nestedComments.splice(nestedComments.indexOf(e), 1, newObj)
    } else {

      payload.item.path.forEach(nval => {
        if (nval == e.node.id) {
          nestedComments.splice(nestedComments.indexOf(e), 1, {
            ...e, swipe: true, node: {
              ...e.node, comments: {
                ...e.node.comments, edges: nestedComment(e.node.comments.edges, payload)
              }
            }
          })
        }
      })

    }


  })


  // console.log(nestedComments, 'newww');


  return nestedComments;

}


function setSwipeNotification(items, payload) {

  // console.log(payload, 'payy')
  let newSwipeParent = [...items]


  newSwipeParent.forEach((e, ind) => {
    if (e.node.comment.id == payload.item.path[1]) {
      // console.log(e, 'checkkk parenttt');
      let newSwipe = nestedComment(e.node.comment.comments.edges, payload)
      // console.log(newSwipe, 'newSwipeee');
      newSwipeParent.splice(newSwipeParent.indexOf(e), 1)
      newSwipeParent.unshift({
        ...e, node: {
          ...e.node, minimize: false, comment: {
            ...e.node.comment, comments: {
              ...e.node.comment.comments, edges: [...newSwipe]

            }

          }
        }
      })
      // console.log(newSwipeParent, 'newSwipeParent');

    } else {
      newSwipeParent.splice(newSwipeParent.indexOf(e), 1, {
        ...e, node: {
          ...e.node, minimize: false,
          comment: {
            ...e.node.comment, comments: {
              ...e.node.comment.comments, edges: nestedSwipe(e.node.comment.comments.edges)

            }

          }
        }
      })
    }


  })
  // }


  return newSwipeParent;
}