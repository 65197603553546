import Constants from "expo-constants";

const getEnvVars = (
  env = process.env.REACT_NATIVE_NODE_CONFIG_ENV || Constants
) => {
  if (
    env === "weclikd_local" ||
    Constants.experienceUrl == "http://localhost:19006" ||
    Constants.experienceUrl == "http://www.localhost:19006"
  ) {
    return {
      EXPOCLIENT_ID:
        "519921550901-o18f1m5ffo7b6i4j1n9tcqlonq795njc.apps.googleusercontent.com",
      PROJECT_URL: "http://localhost:19006/home",
      API_URL: "https://backend-bgsfkvxhwq-uc.a.run.app/v1/",
      GRAPHQL_URL: "https://backend-bgsfkvxhwq-uc.a.run.app/v1/graphql",
      ANDROIDCLIENT_ID:
        "519921550901-mvt1ol8gur0vp5g85g49pqb2n83f1vat.apps.googleusercontent.com",
      IOSCLIENT_ID:
        "519921550901-cqcdqrmvhm4qo0g6l3jvic90n80kbkmj.apps.googleusercontent.com",
      APIKEY: "AIzaSyBNvlsGvHp-KPCj1KMTswQjLOsjHiy3_Q8",
      AUTHDOMAIN: "electric-block-241402.firebaseapp.com",
      DATABASEURL: "https://electric-block-241402.firebaseio.com",
      PROJECTID: "electric-block-241402",
      STORAGEBUCKET: "electric-block-241402.appspot.com",
      MESSAGINGSENDERID: "519921550901",
      APPID: "1:519921550901:web:cd515d8bfb9a52c4f2537c",
      APPDYNAMICLINK: "https://weclikddev.page.link/links",
      MEASUREMENTID: "G-QZMNJ3HRLG",
    };
  } else if (
    env === "weclikd_staging" ||
    Constants.experienceUrl == "https://weclikd-beta.com" ||
    Constants.experienceUrl == "https://www.weclikd-beta.com"
  ) {
    return {
      EXPOCLIENT_ID:
        "1068132403542-9sf28v9n1ji5i5k03ng34j6b63um86sl.apps.googleusercontent.com",
      PROJECT_URL: "https://www.weclikd-beta.com",
      API_URL: "https://api.weclikd-beta.com/v1/",
      GRAPHQL_URL: "https://api.weclikd-beta.com/v1/graphql",
      //----------Prod Firebase Key--------
      ANDROIDCLIENT_ID:
        "1068132403542-0uqnsvfb5ut1d2nt2ebgknt3la59rave.apps.googleusercontent.com",
      IOSCLIENT_ID:
        "com.googleusercontent.apps.1068132403542-kf7ifohq5r4d88au454813fo0kp49keb",
      APIKEY: "AIzaSyBPC13eCcUpqblHrQOjnh-T933-jNIP4UM",
      AUTHDOMAIN: "weclikd-prod.firebaseapp.com",
      DATABASEURL: "https://weclikd-prod.firebaseio.com",
      PROJECTID: "weclikd-prod",
      STORAGEBUCKET: "weclikd-prod.appspot.com",
      MESSAGINGSENDERID: "1068132403542",
      APPID: "1:1068132403542:web:de68950c45b7fdd4c06ef7",
      APPDYNAMICLINK: "https://weclikdprod.page.link/links",
      measurementId: "G-6MTSK4CKWL",
    };
  } else if (
    env === "weclikd_prod" ||
    Constants.experienceUrl == "https://www.weclikd.com" ||
    Constants.experienceUrl == "https://weclikd.com"
  ) {
    return {
      EXPOCLIENT_ID:
        "1068132403542-9sf28v9n1ji5i5k03ng34j6b63um86sl.apps.googleusercontent.com",
      PROJECT_URL: "https://weclikd.com",

      API_URL: " https://api.weclikd.com/v1/",
      GRAPHQL_URL: " https://api.weclikd.com/v1/graphql",
      ANDROIDCLIENT_ID:
        "1068132403542-0uqnsvfb5ut1d2nt2ebgknt3la59rave.apps.googleusercontent.com",
      IOSCLIENT_ID:
        "1068132403542-7b8f1n6coe4mab9h40ti9mhpk91vdonn.apps.googleusercontent.com",
      APIKEY: "AIzaSyBPC13eCcUpqblHrQOjnh-T933-jNIP4UM",
      AUTHDOMAIN: "weclikd-prod.firebaseapp.com",
      DATABASEURL: "https://weclikd-prod.firebaseio.com",
      PROJECTID: "weclikd-prod",
      STORAGEBUCKET: "weclikd-prod.appspot.com",
      MESSAGINGSENDERID: "1068132403542",
      APPID: "1:1068132403542:web:de68950c45b7fdd4c06ef7",
      APPDYNAMICLINK: "https://weclikdprod.page.link/links",
      measurementId: "G-6MTSK4CKWL",
    };
  } else {
    // console.log(env, "else electrcblock");
    return {
      PROJECT_URL: "https://electric-block-241402.appspot.com",
      EXPOCLIENT_ID:
        "519921550901-o18f1m5ffo7b6i4j1n9tcqlonq795njc.apps.googleusercontent.com",
      API_URL: "https://backend-bgsfkvxhwq-uc.a.run.app/v1/",
      GRAPHQL_URL: "https://backend-bgsfkvxhwq-uc.a.run.app/v1/graphql",
      ANDROIDCLIENT_ID:
        "519921550901-mvt1ol8gur0vp5g85g49pqb2n83f1vat.apps.googleusercontent.com",
      IOSCLIENT_ID:
        "519921550901-cqcdqrmvhm4qo0g6l3jvic90n80kbkmj.apps.googleusercontent.com",
      APIKEY: "AIzaSyBNvlsGvHp-KPCj1KMTswQjLOsjHiy3_Q8",
      AUTHDOMAIN: "electric-block-241402.firebaseapp.com",
      DATABASEURL: "https://electric-block-241402.firebaseio.com",
      PROJECTID: "electric-block-241402",
      STORAGEBUCKET: "electric-block-241402.appspot.com",
      MESSAGINGSENDERID: "519921550901",
      APPID: "1:519921550901:web:cd515d8bfb9a52c4f2537c",
      APPDYNAMICLINK: "https://weclikddev.page.link/links",
      MEASUREMENTID: "G-QZMNJ3HRLG",
    };
  }
};

export default getEnvVars;
