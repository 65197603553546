import ConstantFontFamily from "../../constants/FontFamily";
import { Dimensions, Platform } from "react-native";
import Colors from '../../constants/Colors';


const TitleNameStyle = {
    fontSize: 14,
    fontFamily: ConstantFontFamily.HeaderBoldFont,
    color: '#000',
    width: "100%",

}
export default {
    TitleNameStyle,
    
}