import { Component } from "react"
// import { exp } from "react-native-reanimated"
import {
    View,
    ScrollView,
    Text,
    TouchableOpacity,
    TextInput,
    Dimensions,
} from "react-native";
import { Hoverable } from "react-native-web-hooks";
import { heightPercentageToDP as hp } from "react-native-responsive-screen";
import ConstantFontFamily from "../constants/FontFamily";
// import Colors from '../constants/Colors';
// import NavigationService from "../library/NavigationService";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import { leftPanelModalFunc } from '../reducers/AdminTrueFalseReducer';
import { screen } from '../reducers/ScreenNameReducer';


class BottomPart extends Component {

    goToPage = (pageName) => {
        this.props.leftPanelModalFunc(false)
        if (pageName == 'terms') {
            this.props.navigation.navigate("termsandconditions");
        } else if (pageName == 'privacy') {
            this.props.navigation.navigate("privacyPolicy");
        } else if (pageName == 'faq') {
            this.props.screen("faq")
            this.props.navigation.current ? this.props.navigation.current.navigate("faq") : this.props.navigation.navigate("faq");
        }
    }

    render() {
        return (
            <View
                style={{
                    marginTop: 10,
                    width: "100%",
                    flexDirection: "row",
                    height: Dimensions.get("window").width > 750 ? 30 : 50,
                    backgroundColor: '#fff',
                    paddingHorizontal: 20,
                    justifyContent: 'space-between',
                    borderRadius: 20,
                    // alignItems: 'center',
                }}
            >
                <Hoverable>
                    {isHovered => (
                        <TouchableOpacity
                            onPress={() => {
                                this.goToPage('terms')
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: 10,
                                    color: "grey",
                                    textAlign: "center",
                                    fontFamily: ConstantFontFamily.defaultFont,
                                    textDecorationLine: isHovered == true ? "underline" : "none"
                                }}
                            >
                                Terms of Service
                            </Text>
                        </TouchableOpacity>
                    )}
                </Hoverable>

                <Hoverable>
                    {isHovered => (
                        <TouchableOpacity
                            onPress={() => {
                                this.goToPage('faq')
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: 10,
                                    color: "grey",
                                    textAlign: "center",
                                    fontFamily: ConstantFontFamily.defaultFont,
                                    textDecorationLine: isHovered == true ? "underline" : "none"
                                }}
                            >
                                FAQ
                            </Text>
                        </TouchableOpacity>
                    )}
                </Hoverable>

                <Hoverable>
                    {isHovered => (
                        <TouchableOpacity
                            onPress={() => {
                                this.goToPage('privacy')
                            }}
                        >
                            <Text
                                style={{
                                    fontSize: 10,
                                    color: "grey",
                                    textAlign: "center",
                                    fontFamily: ConstantFontFamily.defaultFont,
                                    textDecorationLine: isHovered == true ? "underline" : "none"
                                }}
                            >
                                Privacy Policy
                            </Text>
                        </TouchableOpacity>
                    )}
                </Hoverable>
            </View>
        )
    }
}

const mapStateToProps = state => ({
    // windowSize: state.windowResizeReducer.windowResize
})
const mapDispatchToProps = dispatch => ({
    leftPanelModalFunc: (payload) => dispatch(leftPanelModalFunc(payload)),
    screen: (payload) => dispatch(screen(payload)),

});


export default compose(connect(mapStateToProps, mapDispatchToProps))(
    BottomPart
);

// export default BottomPart