import "react-native-gesture-handler";
// import { initializeAuth, getReactNativePersistence } from 'firebase/auth';
import { useCallback, useEffect, useState, useRef } from "react";
import { StatusBar } from "expo-status-bar";
import {
  StyleSheet,
  Platform,
  Animated,
  AppRegistry,
  SafeAreaView,
} from "react-native";
import { store, persistor } from "./initializeStore";
import { PersistGate } from "redux-persist/integration/react";

import { Provider } from "react-redux";
// import NavigationComponent from './components/NavigationComponent';
import appolloClient from "./client";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  gql,
} from "@apollo/client";
import AppNavigatorWeb from "./navigations/AppNavigatorWeb";
// import AppNavigator from './navigations/AppNavigator';
import { MenuProvider } from "react-native-popup-menu";

import { useFonts } from "expo-font";
import getEnvVars from "./environment";
import { initializeApp } from "firebase/app";

const env = getEnvVars();

// Initialize Firebase
const firebaseConfig = {
  apiKey: env.APIKEY,
  authDomain: env.AUTHDOMAIN,
  databaseURL: env.DATABASEURL,
  projectId: env.PROJECTID,
  storageBucket: env.STORAGEBUCKET,
  messagingSenderId: env.MESSAGINGSENDERID,
  appId: env.APPID,
  measurementId: env.MEASUREMENTID,
};

const app = initializeApp(firebaseConfig);

export default function App(props) {
  const [isLoadingComplete, setLoadingComplete] = useState(false);
  const [animatePress, setAnimatePress] = useState(new Animated.Value(1));
  const [fontsLoaded] = useFonts({
    HelveticaBold: require("./assets/fonts/Helvetica-Bold.ttf"),
    Helvetica: require("./assets/fonts/Helvetica.ttf"),
  });

  const navigationRef = useRef(null);

  const onLayoutRootView = useCallback(async () => {
    if (fontsLoaded) {
      // await SplashScreen.hideAsync();
    }
  }, [fontsLoaded]);
  useEffect(() => {
    onLayoutRootView();
    animateIn();
  }, []);

  const animateIn = () => {
    Animated.timing(animatePress, {
      toValue: 0.5,
      duration: 500,
      useNativeDriver: true, // Add This line
    }).start();
  };

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <ApolloProvider client={appolloClient}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <StatusBar backgroundColor={"transparent"} translucent />

            {/* <View style={styles.container}> */}
            <MenuProvider>
              {
                Platform.OS == "web" ? (
                  // <><LeftPanel />

                  <AppNavigatorWeb />
                ) : //  </>
                null
                // <AppNavigator navigationRef={navigationRef} />
              }

              {/* <NavigationComponent />


              <Text>Open up App.js to start working on your app!</Text> */}
            </MenuProvider>

            <StatusBar style="auto" />
            {/* </View> */}
          </PersistGate>
        </Provider>
      </ApolloProvider>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
});

AppRegistry.registerComponent("main", () => App);
