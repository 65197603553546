import React, { Component, lazy, Suspense } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  CheckBox,
  StyleSheet,
  Platform,
  TextInput
} from "react-native";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import ButtonStyle from "../constants/ButtonStyle";
import { Button, Icon } from "react-native-elements";
import ConstantFontFamily from "../constants/FontFamily";
import applloClient from "../client";
import { ClikJoinMutation } from "../graphqlSchema/FollowandUnFollowMutation";
import { ClikJoinVariables } from "../graphqlSchema/FollowandUnfollowVariables";
import { UserLoginMutation, UserFollowingMutation, ExternalFeedFollowingMutation, TopicFollowingMutation, ClikFollowingMutation } from "../graphqlSchema/UserMutation";
// import { graphql } from "react-apollo";
import { saveUserLoginDaitails } from "../actionCreator/UserAction";
import { Hoverable } from "react-native-web-hooks";
// import { List } from "immutable";
import { editClik } from "../actionCreator/ClikEditAction";
import { NoPost, feedFluctuation } from '../reducers/action/Completed'


const styles = StyleSheet.create({
  checkbox: {
    alignSelf: "center",
    backgroundColor: "#000"
  }
});

class QualificationToJoin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qualification: "",
      username: "",
      showError: false,
      MutipleUserList: [],
      UserList: [],
      status: "Default",
      member_type: "",
      user_msg: "",
      invite_key: "",
      isSelected: false,
      getCheckboxContent: [],
      otherqualification: "",
      Linkedinqualification: ""
    };
  }

  getQualificationString = () => {
    let value = this.props.type == "Apply" ? true : false;
    let newArray = [];
    this.state.getCheckboxContent.forEach(obj => {
      if (obj.checked == value) {
        if (obj.item == "Add your Linkedin") {
          newArray.push("Linkedin " + this.state.Linkedinqualification);
        } else {
          newArray.push(obj.item);
        }
      }
    });
    if (this.state.otherqualification != "" && this.props.type == "Apply") {
      newArray.push("Other:" + this.state.otherqualification);
    }
    return newArray.join("\n");
  };

  requestInvite = async () => {
    ClikJoinVariables.variables.clik_id = this.props.cliksDetails.id;
    ClikJoinVariables.variables.qualification = this.getQualificationString();
    ClikJoinVariables.variables.known_members = [];
    ClikJoinVariables.variables.invite_key = this.state.invite_key;
    try {
      await applloClient
        .query({
          query: ClikJoinMutation,
          variables: {
            clik_id: ClikJoinVariables.variables.clik_id,
            qualification: null,
          },
          fetchPolicy: "no-cache"
        })
        .then(async res => {
          console.log(res);

          if (res.data.clik_join.status.custom_status == "JOINED") {
            let member_type = res.data.clik_join.member_type;
            this.setState({
              status: "Success",
              member_type: member_type
            });
            let resDataLogin = await applloClient
              .query({
                query: UserLoginMutation,
                variables: {
                  id: "Account:" + "CurrentUser"
                },
                fetchPolicy: "no-cache"
              }).then(res => {
                return res
              })

            let userfollow = await applloClient
              .query({
                query: UserFollowingMutation,
                variables: {
                  id: "User:CurrentUser",
                  first: 20
                },
                fetchPolicy: "no-cache"
              }).then(e => {
                return e.data.node.users_following.edges
              })

            let topicfollow = await applloClient
              .query({
                query: TopicFollowingMutation,
                variables: {
                  id: "User:CurrentUser",
                  first: 20
                },
                fetchPolicy: "no-cache"
              }).then(e => {
                return e.data.node.topics_following.edges
              })
            let clikfollow = await applloClient
              .query({
                query: ClikFollowingMutation,
                variables: {
                  id: "User:CurrentUser",
                  first: 20
                },
                fetchPolicy: "no-cache"
              }).then(e => {
                return e.data.node.cliks_following.edges
              })
            let externalfollow = await applloClient
              .query({
                query: ExternalFeedFollowingMutation,
                variables: {
                  id: "User:CurrentUser",
                  first: 20
                },
                fetchPolicy: "no-cache"
              }).then(e => {
                return e.data.node.external_feeds_following.edges
              })
            let userData = {
              data: resDataLogin.data.node,
              externalfollow: externalfollow,
              clikfollow: clikfollow,
              topicfollow: topicfollow,
              userfollow: userfollow

            }
            await this.props.saveLoginUser(userData);
          } else if (res.data.clik_join.application.status == "PENDING") {
            this.setState({
              status: "Pending",
              user_msg : "Successfully applied to join this clik"

            });
          } else {
            let user_msg = res.data.clik_join.status.user_msg;
            this.setState({
              status: "Failure",
              user_msg: user_msg
            });
          }
        });
    } catch (e) {
      console.log(e);
    }
  };

  componentDidMount() {
    let applyArray = [
      {
        item: "Add your Linkedin",
        checked: false,
        id: 0
      }
    ];
    let newArray = [];
    newArray = this.props.cliksDetails.qualifications
      ? this.props.cliksDetails.qualifications
        .map((item, index) => {
          return { item: item, checked: false, id: index };
        })
      : [];
    if (this.props.type == "Apply") {
      newArray = newArray.concat(applyArray);
    }
    this.setState({ getCheckboxContent: newArray });

  }

  componentDidUpdate(prevProps, prevState) {
    this.props.feedFluctuation(false)

    // this.setState({status:"Default"})

    if (prevProps.isfeed != this.props.isfeed) {
      this.setState({ status: "Default" })
    }

  }


  getClikEdit = () => {
    let value = false;
    if (this.props.loginStatus == 1) {

      // const Clikindex = this.props.clikMemberList?.findIndex(
      //   i =>
      //     i.node.user.id ==
      //     this.props.profileData.user.id &&
      //     (i.node.member_type == "SUPER_ADMIN" || i.node.member_type == "ADMIN")
      // );
      // if (Clikindex != -1) {
      //   value = true;
      // } else {
      //   value = false;
      // }
    }
    return value;
  };
  render() {
    // console.log('this.state.status', this.state.status)
    return (
      <View
        style={[
          // ButtonStyle.ZeroBorderStyle,
          {
            backgroundColor: "#fff",
            marginTop: 20,
            paddingBottom: 20,
            borderBottomWidth: 1,
            borderColor: '#D7D7D7',
          }
        ]}
      >
        <View style={{ flexDirection: "row", alignItems: 'center' }}>
          <Text
            style={{
              textAlign: "left",
              color: "#000",
              fontSize: 16,
              fontWeight: "bold",
              fontFamily: ConstantFontFamily.defaultFont,
              // marginVertical: 10,
              marginHorizontal: 10,
              // marginBottom:10
            }}
          >
            Qualifications
          </Text>
          {this.getClikEdit() == true && (
            <Hoverable>
              {isHovered => (
                <Icon
                  iconStyle={{
                    alignSelf: "center"
                  }}
                  name="edit"
                  type="font-awesome"
                  color={"#000"}
                  size={18}
                  onPress={async () => {
                    await this.props.editClik({
                      clikId:
                        this.props.cliksDetails &&
                        this.props.cliksDetails.id,
                      cliksName:
                        this.props.cliksDetails &&
                        this.props.cliksDetails.name,

                      description:
                        this.props.cliksDetails &&
                        this.props.cliksDetails.description,
                      cliksFollowers:
                        this.props.cliksDetails &&
                        this.props.cliksDetails.num_followers,
                      cliksMembers:
                        this.props.cliksDetails &&
                        this.props.cliksDetails.num_members,
                      website:
                        this.props.cliksDetails &&
                        this.props.cliksDetails.website,
                      invite_only:
                        this.props.cliksDetails &&
                        this.props.cliksDetails.invite_only,
                      qualifications:
                        this.props.cliksDetails &&
                        this.props.cliksDetails.qualifications
                    });
                    this.props.navigation.navigate("editclik");
                  }}
                />
              )}
            </Hoverable>
          )}
        </View>
        {this.props.cliksDetails.qualifications == null && (
          <Text
            style={{
              textAlign: "center",
              color: "#000",
              fontFamily: ConstantFontFamily.defaultFont,
              fontSize: 16,
              margin: 10
            }}
          >
            {this.props.cliksDetails.invite_only == true ? "No qualifications, but you must apply to join this private clik." : "No qualifications"}
          </Text>
        )}
        {/* {this.props.cliksDetails.qualifications != null &&
          this.state.getCheckboxContent.map((ele, index) => {
            return (
              <View style={{ flexDirection: "row", }} key={index}>
                <Text
                  style={{
                    color: "#000",
                    fontFamily: ConstantFontFamily.defaultFont,
                    fontSize: 16,
                    marginBottom: 5,
                    marginLeft: 30
                  }}
                >
                  {this.props.cliksDetails.invite_only == true &&
                    this.props.type == "Apply" ? (
                    <CheckBox
                      value={ele.checked}
                      onClick={() => {
                        let newArray = this.state.getCheckboxContent.map(
                          (item1, id) => {
                            if (id === index) {
                              item1.checked = !item1.checked;
                              return item1;
                            } else return item1;
                          }
                        );
                        this.setState({ getCheckboxContent: newArray });
                      }}
                      style={styles.checkbox}
                      checked={ele.checked}
                      color={ele.checked ? "#009B1A" : undefined}
                    />
                  ) : (
                    <Text>{"\u25CF"}</Text>
                  )}{""}
                </Text>
                <Text
                  style={{
                    color: "#000",
                    fontFamily: ConstantFontFamily.defaultFont,
                    fontSize: 16,
                    marginRight: 10,
                    marginLeft: 15
                  }}
                >
                  {ele.item}{" "}
                </Text>
                {ele.item == "Add your Linkedin" && (
                  <TextInput
                    value={this.state.Linkedinqualification}
                    placeholder={"Copy Link to Linkedin Profile Here"}
                    style={[this.state.focusInput ? ButtonStyle.selecttextAreaShadowStyle : ButtonStyle.textAreaShadowStyle,
                    {
                      alignSelf: "center",
                      padding: 5,
                      color: "#000",
                      fontFamily: ConstantFontFamily.defaultFont,
                      fontSize: 16,
                      borderRadius: 6,
                      marginLeft: 3,
                      marginBottom: 10,
                      width: "50%",
                      position: "relative",
                      bottom: 5
                    }]}
                    onChangeText={text =>
                      this.setState({ Linkedinqualification: text })
                    }
                    onFocus={() => this.setState({ focusInput: true })}
                    onBlur={() => this.setState({ focusInput: false })}
                  />
                )}
              </View>
            );
          })} */}
        {this.props.type == "Apply" && this.props.cliksDetails.qualifications != null && (
          <View>
            <TextInput
              value={this.state.otherqualification}
              multiline={true}
              numberOfLines={Platform.OS === "ios" ? null : 4}
              placeholder={
                "Click on each box that you qualify above. Add any other qualifications you want to share here to #" +
                this.props.cliksDetails.name +
                " admins."
              }
              style={[this.state.focusInputQualification ? ButtonStyle.selecttextAreaShadowStyle : ButtonStyle.textAreaShadowStyle,
              {
                padding: 5,
                color: "#000",
                fontFamily: ConstantFontFamily.defaultFont,
                fontSize: 14,
                // borderColor: "#c5c5c5",
                marginLeft: 25,
                width: "95%"
              }]}
              onChangeText={text => this.setState({ otherqualification: text })}
              onFocus={() => this.setState({ focusInputQualification: true })}
              onBlur={() => this.setState({ focusInputQualification: false })}
            />
          </View>
        )}
        {this.props.MemberType ==null && this.props.cliksDetails.invite_only ==
          true && this.state.status == "Default" && (
            <Button
              onPress={() => this.requestInvite()}
              color="#000"
              title={"Apply"}
              titleStyle={ButtonStyle.titleStyle}
              buttonStyle={ButtonStyle.backgroundStyle}
              containerStyle={ButtonStyle.containerStyle}
            />
          )}

        {this.props.MemberType ==null && this.props.cliksDetails.invite_only ==
          false &&
          this.state.status == "Default" && (
            <Button
              onPress={() => this.requestInvite()}
              color="#000"
              title="Join"
              titleStyle={ButtonStyle.titleStyle}
              buttonStyle={ButtonStyle.backgroundStyle}
              containerStyle={[ButtonStyle.containerStyle, { marginBottom: 2 }]}
            />
          )}
        {this.state.status == "Success" && (
          <View
            style={{
              width: "100%",
              backgroundColor: "#fff",
              paddingVertical: 20
            }}
          >
            <Text
              style={{
                textAlign: "center",
                color: "#000",
                fontFamily: ConstantFontFamily.defaultFont,
                fontSize: 16,
                margin: 10
              }}
            >
              Congratulations!
            </Text>

            <View
              style={{
                justifyContent: "center",
                flexDirection: "row",
                alignItems: "center"
              }}
            >
              <Text
                style={{
                  textAlign: "center",
                  color: "#000",
                  fontFamily: ConstantFontFamily.defaultFont,
                  fontSize: 16,
                  margin: 10
                }}
              >
                You are now a {this.state.member_type.toLowerCase()} of{" "}
              </Text>

              <TouchableOpacity
                style={{
                  marginTop: 5,
                  height: 30,
                  alignSelf: "flex-start",
                  padding: 5,
                  backgroundColor: "#E8F5FA",
                  borderRadius: 6
                }}
              >
                <Hoverable>
                  {isHovered => (
                    <Text
                      style={{
                        width: "100%",
                        color: "#4169e1",
                        fontSize: 16,
                        fontWeight: "bold",
                        fontFamily: ConstantFontFamily.defaultFont,
                        textDecorationLine:
                          isHovered == true ? "underline" : "none"
                      }}
                    >
                      {" "}
                      #
                      {this.props.cliksDetails.name}{" "}
                    </Text>
                  )}
                </Hoverable>
              </TouchableOpacity>
            </View>
          </View>
        )}

        {this.state.status == "Failure" && (
          <View
            style={{
              width: "100%",
              backgroundColor: "#fff",
            }}
          >
            <Text
              style={{
                textAlign: "center",
                color: "#000",
                fontFamily: ConstantFontFamily.defaultFont,
                fontSize: 16,
                margin: 5
              }}
            >
              Sorry, you could not join the clik at this time.
            </Text>

            <Text
              style={{
                textAlign: "center",
                color: "#000",
                fontFamily: ConstantFontFamily.defaultFont,
                fontSize: 16,
                marginTop: 5
              }}
            >
              {this.state.user_msg}
            </Text>
          </View>
        )}

        {this.state.status == "Pending" && (
          <View
            style={{
              width: "100%",
              backgroundColor: "#fff",
              paddingVertical: 20
            }}
          >
            <Text
              style={{
                textAlign: "center",
                color: "#000",
                fontFamily: ConstantFontFamily.defaultFont,
                fontSize: 16,
                margin: 10
              }}
            >
              An admin of the clik will review your application.
            </Text>
          </View>
        )}
      </View>
    );
  }
}
const mapStateToProps = state => (
  {
    cliksDetails: state.ClikProfileDetailsReducer.getTrendingCliksProfileDetails,

    // listClikUserRequest:
    //  !state.ClikUserRequestReducer.get("ClikUserRequestList")
    //   ? List()
    // :state.ClikUserRequestReducer.get("ClikUserRequestList"),
    isfeed: state.AdminTrueFalseReducer.isfeed,
    // listClikMembers: 
    // !state.ClikMembersReducer.get("clikMemberList")
    //   ? List()
    // :state.ClikMembersReducer.get("clikMemberList"),
    loginStatus: state.UserReducer.loginStatus,
    profileData: state.LoginUserDetailsReducer.userLoginDetails,
    // getCurrentDeviceWidthAction: state.CurrentDeviceWidthReducer.dimension,
    getUserFollowCliksList: state.LoginUserDetailsReducer.userFollowCliksList
      ? state.LoginUserDetailsReducer.userFollowCliksList
      : [],
    clikMemberList: state.ClikMembersReducer.clikMemberList

  });

const mapDispatchToProps = dispatch => ({
  saveLoginUser: payload => dispatch(saveUserLoginDaitails(payload)),
  editClik: payload => dispatch(editClik(payload)),
  feedFluctuation: payload => dispatch(feedFluctuation(payload)),

});

// const QualificationToJoinWrapper = graphql(UserLoginMutation, {
//   name: "Login",
//   options: { 
//     variables: {
//       id: "Account:" + "CurrentUser"
//     },
//     fetchPolicy: "no-cache" }
// })(QualificationToJoin);

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  QualificationToJoin
);
