import { ElementsConsumer } from "@stripe/react-stripe-js";
import Modal from "react-native-modal";
import React from "react";
import {
  Dimensions,
  Linking,
  Platform,
  Text,
  TouchableOpacity,
  // AsyncStorage,
  View
} from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Button } from "react-native-elements";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import { saveUserLoginDaitails } from "../actionCreator/UserAction";
import { graphql } from '@apollo/client/react/hoc';
import ChangePaymentModal from "../components/ChangePaymentModal";
import ConstantFontFamily from "../constants/FontFamily";
import getEnvVars from "../environment";
import {
  GetPaymentInfoOuery,
  IsAccountHaveOuery
} from "../graphqlSchema/StripeMutation";
import {
  ChangeAccountSettingsMutation,
  UserLoginMutation, UserFollowingMutation, ExternalFeedFollowingMutation, TopicFollowingMutation, ClikFollowingMutation
} from "../graphqlSchema/UserMutation";
import { ChangeAccountSettingsVariables } from "../graphqlSchema/UserVariables";
// import {
//   getFirebaseToken,
//   getMyUserId,
//   getWeclikdToken
// } from "../library/Helper";
import ButtonStyle from "../constants/ButtonStyle";
import { Icon } from "react-native-elements";
// import NavigationService from "../library/NavigationService";
import applloClient from "../client";

const apiUrlMain = getEnvVars();

class StripePayment extends React.Component {
  state = {
    switchValue1: '',

    switchValue2: '',

    switchValue3: '',

    Email: '',

    showTextInput: false,
    cardInfoShow: false,
    infoCard: null,
    isAccountStripe: false,
    loading: false,
    showSubcriptionModal: false,
    conformModal: "",
    token: ''
  };

  componentDidMount() {

    let __self = this;

    applloClient
      .query({
        query: IsAccountHaveOuery,
        variables: {
          id: "Account:" + "CurrentUser"
        },
        fetchPolicy: "no-cache"
      })
      .then(async res => {
        if (res) {
          __self.setState({
            isAccountStripe: res.data.node.payout_info.has_stripe_account
          });
        }
      });
  }
  getinfo = () => {
    let __self = this;
    applloClient
      .query({
        query: IsAccountHaveOuery,
        variables: {
          id: "Account:" + "CurrentUser"
        },
        fetchPolicy: "no-cache"
      })
      .then(async res => {
        if (res) {
          __self.setState({
            isAccountStripe: res.data.node.payout_info.has_stripe_account
          });
        }
      });
  };

  ChangeSubscription = () => {
    ChangeAccountSettingsVariables.variables.email = this.state.Email;
    ChangeAccountSettingsVariables.variables.email_notification_monthly_earnings = this.state.switchValue1;
    ChangeAccountSettingsVariables.variables.email_notification_clik_invitations = this.state.switchValue3;
    ChangeAccountSettingsVariables.variables.email_notification_weclikd_updates = this.state.switchValue2;
    applloClient
      .query({
        query: ChangeAccountSettingsMutation,
        ...ChangeAccountSettingsVariables,
        fetchPolicy: "no-cache"
      })
      .then(async res => {
        let resDataLogin = await applloClient
          .query({
            query: UserLoginMutation,
            variables: {
              id: "Account:" + "CurrentUser"
            },
            fetchPolicy: "no-cache"
          }).then(res => {
            return res
          })

        let userfollow = await applloClient
          .query({
            query: UserFollowingMutation,
            variables: {
              id: "User:CurrentUser",
              first: 20
            },
            fetchPolicy: "no-cache"
          }).then(e => {
            return e.data.node.users_following.edges
          })

        let topicfollow = await applloClient
          .query({
            query: TopicFollowingMutation,
            variables: {
              id: "User:CurrentUser",
              first: 20
            },
            fetchPolicy: "no-cache"
          }).then(e => {
            return e.data.node.topics_following.edges
          })
        let clikfollow = await applloClient
          .query({
            query: ClikFollowingMutation,
            variables: {
              id: "User:CurrentUser",
              first: 20
            },
            fetchPolicy: "no-cache"
          }).then(e => {
            return e.data.node.cliks_following.edges
          })
        let externalfollow = await applloClient
          .query({
            query: ExternalFeedFollowingMutation,
            variables: {
              id: "User:CurrentUser",
              first: 20
            },
            fetchPolicy: "no-cache"
          }).then(e => {
            return e.data.node.external_feeds_following.edges
          })
        let userData = {
          data: resDataLogin.data.node,
          externalfollow: externalfollow,
          clikfollow: clikfollow,
          topicfollow: topicfollow,
          userfollow: userfollow

        }
        await props.saveLoginUser(userData);
      });
  };

  getCardInfo = () => {
    let __self = this;
    this.setState({
      loading: true
    });
    applloClient
      .query({
        query: GetPaymentInfoOuery,
        variables: {
          id: "Account:" + "CurrentUser"
        },
        fetchPolicy: "no-cache"
      })
      .then(async res => {
        __self.setState({
          infoCard: {
            last4: res.data.node.payment_info.last4,
            brand: res.data.node.payment_info.brand,
            exp_month:
              res.data.node.payment_info.exp_month.toString().length == 1
                ? "0" + res.data.node.payment_info.exp_month
                : res.data.node.payment_info.exp_month,
            exp_year: res.data.node.payment_info.exp_year,
            exp_year: res.data.node.payment_info.exp_year
              .toString()
              .substring(
                res.data.node.payment_info.exp_year.toString().length - 2
              )
          },
          cardInfoShow: !__self.state.cardInfoShow,
          loading: false
        });
      })
      .catch(e => {
        console.log(e);
        __self.setState({
          loading: false
        });
      });
  };

  onClose = () => {
    this.getCardInfo();
    this.setState({
      showSubcriptionModal: false
    });
  };

  // auth = async () => {
  //   const firebaseToken = await getFirebaseToken();
  //   const tempToken = await getWeclikdToken();
  //   const tempCurrentUserId = await getMyUserId();
  //   let weclikdToken = tempToken ? tempToken : null;
  //   let CurrentUserId = tempCurrentUserId ? tempCurrentUserId : null;
  //   return await {
  //     firebaseToken,
  //     weclikdToken,
  //     CurrentUserId
  //   };
  // };

  // openWindow = async () => {
  //   let apiUrl = apiUrlMain.API_URL + "plutus/payout/authorize";
  //   let apiUrlHeader = await this.auth();
  //   let headersSet = {
  //     "Weclikd-Authorization": encodeURI("Bearer " + apiUrlHeader.weclikdToken),
  //     Authorization: encodeURI("Custom " + apiUrlHeader.firebaseToken)
  //   };
  //   let str = "";
  //   for (let key in headersSet) {
  //     if (str != "") {
  //       str += "&";
  //     }
  //     str += key + "=" + headersSet[key];
  //   }
  //   const uri = apiUrl + "?" + str;
  //   Linking.openURL(uri);
  // };

  // openWindowDashboard = async () => {
  //   let token = await AsyncStorage.getItem("userIdTokenFirebase")
  //   let apiUrl = apiUrlMain.API_URL + "plutus/payout/dashboard";
  //   let apiUrlHeader = await this.auth();
  //   let headersSet = {
  //     "Weclikd-Authorization": encodeURI("Bearer " + apiUrlHeader.weclikdToken),
  //     Authorization: token
  //   };
  //   let str = "";
  //   for (let key in headersSet) {
  //     if (str != "") {
  //       str += "&";
  //     }
  //     str += key + "=" + headersSet[key];
  //   }
  //   const URL = getEnvVars();

  //   const uri = URL.API_URL + 'stripe/payout/authorize?Authorization=Bearer ' + token;
  //   // console.log(uri, 'uri-----------')
  //   Linking.openURL(uri);
  // };

  render() {

    const {
      cardInfoShow,
      conformModal,
      isAccountStripe,
      loading,
      showSubcriptionModal
    } = this.state;

    return (
      <>
        <View
          style={[
            ButtonStyle.normalFeedStyleForDashboard,
            {
              marginTop: 10,
              paddingVertical: 10,
              backgroundColor: "#fff",
              marginLeft: Dimensions.get("window").width >= 750 ? '2%' : 0,
              width: Dimensions.get("window").width <= 750 ? '100%' : '96%'
            }
          ]}
        >
          <View style={{ margin: 5, paddingHorizontal: 20 }}>
            <Text
              style={{
                fontSize: 16,
                fontFamily: ConstantFontFamily.defaultFont,
                fontWeight: "bold",
                textAlign: "center",
                paddingHorizontal: 20
              }}
            >
              Stripe Payouts
            </Text>
          </View>
          {this.state.isAccountStripe == false ? (
            <View style={{ padding: 10, paddingBottom: 5, flexDirection: "column", width: "100%", justifyContent: 'center' }}>
              <View style={{
                justifyContent: "center",
                width: "100%",
                textAlign: 'center'
              }}>
                <Text
                  style={{
                    //fontWeight: "bold",
                    fontSize: 14,
                    fontFamily: ConstantFontFamily.defaultFont
                  }}
                >
                  Set up a Stripe account to receive money from Weclikd.
                </Text>
                <Text
                  style={{
                    //fontWeight: "bold",
                    fontSize: 14,
                    fontFamily: ConstantFontFamily.defaultFont
                  }}
                >
                  Learn more about getting paid.
                </Text>
              </View>
              <View
                style={{
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Button
                  onPress={this.openWindow}
                  color="#fff"
                  title="Setup Stripe"
                  onMouseEnter={() => this.setState({ setupBtnColor: '#000', setupTextColor: '#fff' })}
                  onMouseLeave={() => this.setState({ setupBtnColor: '#fff', setupTextColor: "#000" })}
                  titleStyle={[ButtonStyle.titleStyle, { color: this.state.setupTextColor }]}
                  buttonStyle={[ButtonStyle.backgroundStyle, { backgroundColor: this.state.setupBtnColor }]}
                  containerStyle={[ButtonStyle.containerStyle, {
                    marginBottom: 0
                  }]}
                />
              </View>
            </View>
          ) : (
            <View style={{ padding: 10, paddingBottom: 5, flexDirection: "column", width: "100%", justifyContent: 'center' }}>
              <Text
                style={{
                  width: Dimensions.get("window").width <= 750 && "70%",
                  fontSize: 14,
                  fontFamily: ConstantFontFamily.defaultFont,
                  //fontWeight: "bold",
                  justifyContent: 'center',
                  textAlign: 'center'
                }}
              >
                Go to your Stripe dashboard to view payouts and change Stripe
                settings. Weclikd pays into your stripe account every month if
                your balance is over five dollars.
              </Text>

              <View
                style={{
                  justifyContent: "center",
                  width: Dimensions.get("window").width <= 750 && "30%",
                }}
              >
                <Button
                  onPress={this.openWindowDashboard}
                  color="#fff"
                  title="Go to Stripe"
                  onMouseEnter={() => this.setState({ stripeBtnColor: '#000', stripeTextColor: '#fff' })}
                  onMouseLeave={() => this.setState({ stripeBtnColor: '#fff', stripeTextColor: "#000" })}
                  titleStyle={[ButtonStyle.titleStyle, { color: this.state.stripeTextColor }]}
                  buttonStyle={[ButtonStyle.backgroundStyle, { backgroundColor: this.state.stripeBtnColor }]}
                  containerStyle={[ButtonStyle.containerStyle, {
                    marginBottom: 0
                  }]}
                />
              </View>
            </View>
          )}
        </View>
        {this.props.profileData &&
          this.props.profileData.subscription ==
          "BASIC" &&

          <View
            style={[
              ButtonStyle.normalFeedStyleForDashboard,
              // ButtonStyle.shadowStyle,
              {
                //marginLeft: 5,
                marginTop: 10,
                paddingVertical: 10,
                backgroundColor: "#fff",
                marginLeft: Dimensions.get("window").width >= 750 ? '2%' : 0,
                width: Dimensions.get("window").width <= 750 ? '100%' : '96%'
              }
            ]}
          >
            <TouchableOpacity style={{ margin: 5, paddingHorizontal: 20 }} onPress={async () => {
              await AsyncStorage.setItem('getSettingActiveSection', JSON.stringify('Upgrade'))
              this.props.navigation.navigate("settings")
            }}>
              <Text
                style={{
                  fontSize: 16,
                  fontFamily: ConstantFontFamily.defaultFont,
                  fontWeight: "bold",
                  textAlign: "center",
                  paddingHorizontal: 20
                }}
              >
                Weclikd Subscription
              </Text>
            </TouchableOpacity>


            {this.props.profileData &&
              this.props.profileData.subscription ==
              "BASIC" && (
                <View style={{ padding: 10, paddingBottom: 5, justifyContent: 'center', flexDirection: "column", width: "100%" }}>
                  <Text
                    style={{
                      width: Dimensions.get("window").width <= 750 && "100%",
                      fontSize: 14,
                      fontFamily: ConstantFontFamily.defaultFont,
                      //fontWeight: "bold",
                      justifyContent: 'center',
                      textAlign: 'center'
                    }}
                  >
                    To increase the amount Weclikd pays you at the beginning of
                    each month, consider getting a paid subscription. If other
                    subscribers are supporting your commentary, then we
                    incentivize you to do the same.
                  </Text>

                  <View
                    style={{
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Button
                      onPress={async () => {
                        await AsyncStorage.setItem('getSettingActiveSection', JSON.stringify('Upgrade'))
                        this.props.navigation.navigate("settings")
                      }}
                      color="#fff"
                      title="Upgrade"
                      onMouseEnter={() => this.setState({ upgradeBtnColor: '#000', upgradeTextColor: '#fff' })}
                      onMouseLeave={() => this.setState({ upgradeBtnColor: '#fff', upgradeTextColor: "#000" })}
                      titleStyle={[ButtonStyle.titleStyle, { color: this.state.upgradeTextColor }]}
                      buttonStyle={[ButtonStyle.backgroundStyle, { backgroundColor: this.state.upgradeBtnColor }]}
                      containerStyle={[ButtonStyle.containerStyle, {
                        marginBottom: 0
                      }]}
                    />
                  </View>
                </View>
              )}
          </View>}
      </>
    );
  }
}

const mapStateToProps = state => ({
  profileData: state.LoginUserDetailsReducer.userLoginDetails
});

const mapDispatchToProps = dispatch => ({
  saveLoginUser: payload => dispatch(saveUserLoginDaitails(payload))
});

// const StripePaymentContainerWrapper = graphql(UserLoginMutation, {
//   name: "Login",
//   options: { 
//     variables: {
//       id: "Account:" + "CurrentUser"
//     },
//     fetchPolicy: "no-cache" }
// })(StripePayment);

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  StripePayment
);
