// import { fromJS, List } from "immutable";
import React from "react";
import {
  Animated,
  Image,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Platform
} from "react-native";
import { Icon } from "react-native-elements";
import { Hoverable } from "react-native-web-hooks";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";
import { getTrendingUsers } from "../actionCreator/TrendingUsersAction";
import {
  saveUserLoginDaitails,
  setLoginStatus
} from "../actionCreator/UserAction";
import { getCurrentUserProfileDetails } from "../actionCreator/UserProfileDetailsAction";
import appolloClient from "../client";
import ConstantFontFamily from "../constants/FontFamily";
import { SearchUserMutation } from "../graphqlSchema/SearchMutation";
import { SearchUserVariables } from "../graphqlSchema/SearchVariables";
// import NavigationService from "../library/NavigationService";
// import UserStar from "./UserStar";
import TrendingUsers from "./TrendingUsers";

class UserListDrawerScreens extends React.PureComponent {
  constructor(props) {
    super(props);
    this.timer;
    this.state = {
      showEditIcon: true,
      showSearchIcon: true,
      search: "",
      userlistData: this.props.getUserFollowUserList,
      showTooltip: false,
      listTrending_users: this.props.listTrending_users
    };
  }

  goToProfile = async username => {
    await this.props.userId({
      username: username,
      type: "feed"
    });
    await this.props.navigation.navigate("profile", {
      username: username,
      type: "feed"
    });
    this.props.setPostCommentReset({
      payload: [],
      postId: "",
      title: "",
      loading: true
    });
  };
  updateUserSearch = search => {
    let tempData = [];
    let tempArray = [];
    this.setState({ search }, () => {
      if (this.state.search.length > 0 && this.props.current === 2) {
        SearchUserVariables.variables.prefix = search;
        appolloClient
          .query({
            query: SearchUserMutation,
            ...SearchUserVariables,
            fetchPolicy: "no-cache"
          })
          .then(res => {
            tempArray = res.data.search.users;
            for (let i = 0; i < tempArray.length; i++) {
              tempData.push({ user: tempArray[i] });
            }
            this.setState({
              userlistData: fromJS(tempData),
              listTrending_users: []
            });
          });
      } else {
        this.setState({
          userlistData: this.props.getUserFollowUserList,
          listTrending_users: this.props.listTrending_users
        });
      }
    });
  };

  componentDidUpdate = async prevProps => {
    if (prevProps.searchedWord != this.props.searchedWord) {
      await this.updateUserSearch(this.props.searchedWord);
    } else if (prevProps.searchedWord.length == 0) {
      this.setState({ userlistData: this.props.getUserFollowUserList });
    }
  };

  componentWillUnmount = () => {
    clearTimeout(this.timer);
  };

  toogle = () => {
    if (this.state.showTooltip == false) {
      this.setState({ showTooltip: true });
    } else {
      this.setState({ showTooltip: false });
    }
  };

  calHeightLeftPannel(height) {
    if (height > 0) {
      if (this.props.current == 2) {
        this.props.calHeightLeftPannel(height);
      }
    }
  }

  loginHandle = () => {
    this.props.setLoginModalStatus(true);
  };

  render() {
    const { showEditIcon, userlistData } = this.state;
    return (
      <View>
        {/* {this.props.loginStatus == 1 ? ( */}
          <View>
            {/* <ScrollView showsVerticalScrollIndicator={false}>
              {userlistData.map((item, indexx) => {
                return (
                  <View
                    key={indexx}
                    style={{
                      overflow: "hidden",
                      padding: 2,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%"
                    }}
                  >
                    {showEditIcon == false && (
                      <Animated.View
                        style={[
                          StyleSheet.absoluteFill,
                          { backgroundColor: "#D4D4D4", width: "50%" }
                        ]}
                      />
                    )}
                    <Hoverable>
                      {isHovered => (
                        <TouchableOpacity
                          onPress={() =>
                            this.goToProfile(item.getIn(["user", "username"]))
                          }
                          style={{
                            flexDirection: "row",
                            borderRadius: 4,
                            overflow: "hidden",
                            width: "80%"
                          }}
                        >
                          <View
                            style={{
                              margin: 0,
                              flexDirection: "row",
                              width: "20%",
                              backgroundColor: "rgba(255,255,255,0.5)"
                            }}
                          >
                            {item.getIn(["user", "profile_pic"]) ? (
                              <Image
                                source={{
                                  uri: item.getIn(["user", "profile_pic"])
                                }}
                                style={{
                                  width: 36,
                                  height: 36,
                                  borderRadius: 18,
                                  borderWidth: 1,
                                  borderColor: "#e1e1e1"
                                }}
                              />
                            ) : (
                              <Image
                                source={require("../assets/image/default-image.png")}
                                style={{
                                  width: 36,
                                  height: 36,
                                  borderRadius: 18,
                                  borderWidth: 1,
                                  borderColor: "#e1e1e1"
                                }}
                              />
                            )}
                          </View>
                          <View
                            style={{
                              width: "80%",
                              alignSelf: "center",
                              marginLeft: 7
                            }}
                          >
                            <Text
                              style={{
                                textAlign: "left",
                                fontSize: 14,
                                fontWeight: "bold",
                                fontFamily: ConstantFontFamily.defaultFont,
                                textDecorationLine:
                                  isHovered == true ? "underline" : "none"
                              }}
                            >
                              @{item.getIn(["user", "username"])}
                            </Text>
                          </View>
                        </TouchableOpacity>
                      )}
                    </Hoverable>
                    <UserStar
                      UserName={item.getIn(["user", "username"])}
                      UserId={item.getIn(["user", "id"])}
                      ContainerStyle={{
                        flex: 1,
                        justifyContent: "flex-end",
                        width: "20%",
                        paddingLeft: 15
                      }}
                      ImageStyle={{
                        height: 20,
                        width: 20,
                        alignSelf: "flex-end"
                      }}
                    />
                  </View>
                );
              })}
            </ScrollView> */}

            <TrendingUsers
              navigationRef={Platform.OS == 'web' ? this.props.navigationRef : this.props.navigationRef}
              searchedFollowText={this.props.searchedWord}
            />
            {/* {this.state.listTrending_users.map((item, indexx) => {
              let index = this.props.getUserFollowUserList.findIndex(
                i => i.getIn(["user", "username"]) == item.node.username
              );
              if (index == -1 && indexx < 10) {
                return (
                  <View
                    key={indexx}
                    style={{
                      overflow: "hidden",
                      padding: 2,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%"
                    }}
                  >
                    <Hoverable>
                      {isHovered => (
                        <TouchableOpacity
                          onPress={() => this.goToProfile(item.node.username)}
                          style={{
                            flexDirection: "row",
                            borderRadius: 4,
                            overflow: "hidden",
                            width: "80%"
                          }}
                        >
                          <View
                            style={{
                              width: "20%",
                              margin: 0,
                              flexDirection: "row",
                              backgroundColor: "rgba(255,255,255,0.5)"
                            }}
                          >
                            {item.node.profile_pic ? (
                              <Image
                                source={{
                                  uri: item.node.profile_pic
                                }}
                                style={{
                                  width: 36,
                                  height: 36,
                                  borderRadius: 18,
                                  borderWidth: 1,
                                  borderColor: "#e1e1e1"
                                }}
                              />
                            ) : (
                              <Image
                                source={require("../assets/image/default-image.png")}
                                style={{
                                  width: 36,
                                  height: 36,
                                  borderRadius: 18,
                                  borderWidth: 1,
                                  borderColor: "#e1e1e1"
                                }}
                              />
                            )}
                          </View>
                          <View
                            style={{
                              width: "80%",
                              alignSelf: "center",
                              marginLeft: 7
                            }}
                          >
                            <Text
                              style={{
                                textAlign: "left",
                                fontSize: 14,
                                fontWeight: "bold",
                                fontFamily: ConstantFontFamily.defaultFont,
                                textDecorationLine:
                                  isHovered == true ? "underline" : "none"
                              }}
                            >
                              @{item.node.username}
                            </Text>
                          </View>
                        </TouchableOpacity>
                      )}
                    </Hoverable>

                    <UserStar
                      UserName={item.node.username}
                      UserId={item.node.id}
                      ContainerStyle={{
                        flex: 1,
                        justifyContent: "flex-end",
                        width: "20%",
                        paddingLeft: 15
                      }}
                      ImageStyle={{
                        height: 20,
                        width: 20,
                        alignSelf: "flex-end"
                      }}
                    />
                  </View>
                );
              }
            })} */}
          </View>
        {/* ) : ( */}
          {/* <View
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Icon
              color={"#000"}
              iconStyle={{
                color: "#fff",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
              }}
              reverse
              name="user-times"
              type="font-awesome"
              size={20}
              containerStyle={{
                alignSelf: "center"
              }}
            />
            <Text
              style={{
                fontSize: 14,
                fontWeight: "bold",
                fontFamily: ConstantFontFamily.defaultFont,
                color: "#000",
                alignSelf: "center"
              }}
            >
              <Text
                onPress={() => this.loginHandle()}
                style={{ textDecorationLine: "underline",fontFamily: ConstantFontFamily.defaultFont }}
              >
                Login
              </Text>{" "}
              to follow users
            </Text>
          </View>
        )} */}
      </View>
    );
  }
}

const mapStateToProps = state => ({
  // getUserFollowUserList: state.LoginUserDetailsReducer.userFollowUserList
  //   ? state.LoginUserDetailsReducer.userFollowUserList
  //   : [],
  // listTrending_users: !state.TrendingUsersReducer.getIn(["Trending_users_List"])
  //   ? List()
  //   : state.TrendingUsersReducer.getIn(["Trending_users_List"]),
  // loginStatus: state.UserReducer.get("loginStatus")
});

const mapDispatchToProps = dispatch => ({
  // getTrendingUsers: payload => dispatch(getTrendingUsers(payload)),
  changeLoginStatus: payload => dispatch(setLoginStatus(payload)),
  // userId: payload => dispatch(getCurrentUserProfileDetails(payload)),
  // saveLoginUser: payload => dispatch(saveUserLoginDaitails(payload)),
  // setLoginModalStatus: payload => dispatch(setLOGINMODALACTION(payload)),
  // setPostCommentReset: payload =>
  // dispatch({ type: "POSTCOMMENTDETAILS_RESET", payload })
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  UserListDrawerScreens
);
