import React, { Component } from "react";
import {
  Image,
  Platform,
  Text,
  TouchableOpacity,
  View,
  Dimensions,
} from "react-native";
import { Icon } from "react-native-elements";
import { Hoverable } from "react-native-web-hooks";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import { saveUserLoginDaitails } from "../actionCreator/UserAction";
import applloClient from "../client";
// import "../components/Firebase";
import LoaderComponent from "./LoaderComponent";
import ConstantFontFamily from "../constants/FontFamily";
import { ChangeSubscriptionMutation } from "../graphqlSchema/UserMutation";
import { UserLoginMutation } from "../graphqlSchema/UserMutation";

class CancelSubscriptionModal extends Component {
  state = {
    loading: false,
  };

  constructor(props) {
    super(props);
  }

  handleSubmit = async (evt) => {
    let __self = this;
    this.setState({
      loading: true,
    });
    applloClient
      .query({
        query: ChangeSubscriptionMutation,
        variables: {
          payment_id: null,
          subscription: "BASIC",
          pricing: null,
        },
        fetchPolicy: "no-cache",
      })
      .then(async (r) => {
        let loginData = applloClient
          .query({
            query: UserLoginMutation,
            variables: {
              id: "Account:" + "CurrentUser",
            },
            fetchPolicy: "no-cache",
          })
          .then((res) => {
            return res.data.node;
          });

        let userData = {
          data: loginData,
          externalfollow: this.props.getUserFollowFeedList,
          clikfollow: this.props.getUserFollowCliksList,
          topicfollow: this.props.getUserFollowTopicList,
          userfollow: this.props.getUserFollowUserList,
        };
        await __self.props.saveLoginUser(userData);
        __self.props.onClose();
        __self.setState({
          loading: false,
        });
      })
      .catch((e) => {
        __self.props.onClose();
        __self.setState({
          loading: false,
        });
        console.log(e);
      });
  };

  render() {
    const { loading } = this.state;

    return (
      <View
        style={{
          backgroundColor: "#f4f4f4",
          borderColor: "#c5c5c5",
          borderRadius: 6,
          // borderWidth: 1,
          width: Dimensions.get("window").width >= 750 ? 450 : "100%",
          alignSelf: "center",
          // height: Dimensions.get("window").width >= 750 ? 480 : 500,
        }}
      >
        <View>
          <Hoverable>
            {(isHovered) => (
              <TouchableOpacity
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  flex: 1,
                  position: "absolute",
                  zIndex: 999999,
                  left: 0,
                  top: 0,
                }}
                onPress={this.props.onClose}
              >
                <Icon
                  color={isHovered == true ? "rgba(256,256,256,0.4)" : "#000"}
                  iconStyle={{
                    color: "#fff",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  reverse
                  name="close"
                  type="antdesign"
                  size={16}
                />
              </TouchableOpacity>
            )}
          </Hoverable>

          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              backgroundColor: "#000",
              alignItems: "center",
              height: 50,
              borderTopLeftRadius: 6,
              borderTopRightRadius: 6,
              width: "100%",
            }}
          >
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
              }}
            >
              <Image
                source={require("../assets/image/logolastOne.png")}
                style={{
                  height: 35,
                  width: 35,
                  marginRight: 5,
                  // borderRadius: 8
                }}
                resizeMode={"contain"}
              />
              {/* <Text
              style={[ButtonStyle.profileTitleStyle, {
                fontSize: 20,
                textAlign: "center",
                color: "white",
                fontWeight: 'bold'
              }]}
            >
              weclikd
            </Text> */}
            </View>
          </View>
        </View>

        <View
          style={{
            alignItems: "center",
            flex: 1,
            paddingVertical: 20,
            backgroundColor: "#fff",
            borderBottomLeftRadius: 6,
            borderBottomRightRadius: 6,
            borderColor: "#c5c5c5",
            width: "100%",
          }}
        >
          <View
            style={{
              flex: 1,
              backgroundColor: "#fff",
              padding: 20,
            }}
          >
            <View>
              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{
                    color: "#000",
                    fontSize: 18,
                    fontFamily: ConstantFontFamily.defaultFont,
                  }}
                >
                  Cancel Subscription
                </Text>
              </View>

              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  borderColor: "#000",
                  backgroundColor: "#000",
                  height: 2,
                  marginTop: 10,
                }}
              />
            </View>

            <View
              style={{
                flexDirection: "row",
                width: "100%",
                marginTop: 20,
                marginBottom: 10,
              }}
            >
              <Text
                style={{
                  color: "#000",
                  fontSize: 16,
                  fontFamily: ConstantFontFamily.defaultFont,
                }}
              >
                If you cancel your subcription,you will keep the benefits of the
                Gold membership until 3/31.
              </Text>
            </View>

            {loading == false ? (
              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <View
                  style={{
                    flexDirection: "row",
                    width: "50%",
                  }}
                >
                  <TouchableOpacity
                    block
                    style={{
                      borderRadius: 5,
                      height: 40,
                      alignItems: "center",
                      justifyContent: "center",
                      padding: 5,
                      borderWidth: 1,
                      borderColor: "#000",
                      marginTop: 10,
                      backgroundColor: "#000",
                    }}
                    onPress={this.props.onClose}
                  >
                    <Text
                      style={{
                        fontSize: 16,
                        color: "#fff",
                        fontFamily: ConstantFontFamily.defaultFont,
                        marginLeft: 5,
                      }}
                    >
                      {"Keep Subscription"}
                    </Text>
                  </TouchableOpacity>
                </View>

                <View
                  style={{
                    flexDirection: "row",
                  }}
                >
                  {loading == false ? (
                    <TouchableOpacity
                      block
                      style={{
                        borderRadius: 5,
                        height: 40,
                        alignItems: "center",
                        justifyContent: "center",
                        padding: 5,
                        borderWidth: 1,
                        borderColor: "#000",
                        marginTop: 10,
                        backgroundColor: "#000",
                      }}
                      onPress={this.handleSubmit}
                    >
                      <Text
                        style={{
                          fontSize: 16,
                          color: "#fff",
                          fontFamily: ConstantFontFamily.defaultFont,
                          marginLeft: 5,
                        }}
                      >
                        {"Cancel Subscription"}
                      </Text>
                    </TouchableOpacity>
                  ) : (
                    <View
                      style={{
                        borderRadius: 5,
                        height: 40,
                        alignItems: "center",
                        justifyContent: "center",
                        padding: 5,
                        marginTop: 10,
                        backgroundColor: "#fff",
                      }}
                    >
                      <LoaderComponent />
                    </View>
                  )}
                </View>
              </View>
            ) : (
              <View
                style={{
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <LoaderComponent />
              </View>
            )}
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = (state) => ({
  profileData: state.LoginUserDetailsReducer.userLoginDetails,
  // getCurrentDeviceWidthAction: state.CurrentDeviceWidthReducer.get("dimension"),
  getUserFollowCliksList: state.LoginUserDetailsReducer.userFollowCliksList
    ? state.LoginUserDetailsReducer.userFollowCliksList
    : [],
  getUserFollowFeedList: state.LoginUserDetailsReducer.userFollowFeedList
    ? state.LoginUserDetailsReducer.userFollowFeedList
    : [],
  getUserFollowTopicList: state.LoginUserDetailsReducer.userFollowTopicsList
    ? state.LoginUserDetailsReducer.userFollowTopicsList
    : [],
  getUserFollowUserList: state.LoginUserDetailsReducer.userFollowUserList
    ? state.LoginUserDetailsReducer.userFollowUserList
    : [],
});

const mapDispatchToProps = (dispatch) => ({
  saveLoginUser: (payload) => dispatch(saveUserLoginDaitails(payload)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CancelSubscriptionModal
);
