import { call, put, takeEvery } from "redux-saga/effects";
import applloClient from "../client";
import {
  CLIKS_PROFILE_DETAILS,
  // CLIKS_PROFILE_DETAILS_FAILURE,
  // CLIKS_PROFILE_DETAILS_SUCCESS
} from "../constants/Action";
import { clikProfileDetailsSuccess, clikProfileDetailsfailure } from '../reducers/TrendingCliksProfileReducer';
import { ClikQuery } from "../graphqlSchema/TrendingMutation";
// import NavigationService from "../library/NavigationService";
// import {
//   AsyncStorage
// } from "react-native";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { get404 } from '../reducers/action/Completed';



const getResponse = async req => {
  return await applloClient
    .query({
      query: ClikQuery,
      variables: {
        id: "Clik:" + req.id
      },
      fetchPolicy: "no-cache"
    })
    .then(res => res);
};

const getPostId = async () => {
  let asyncId;
  await AsyncStorage.getItem('PostId').then(houses => { asyncId = JSON.parse(houses); });
  //console.log(asyncId.replace("Post:", ""));
  return asyncId.replace("Post:", "")
}

export function* TrendingCliksProfileMiddleware({ payload }) {
  console.log(payload,'payloadpayloadpayload');
  
  try {
    const response = yield call(getResponse, payload);
    console.log(response,'responseresponse');

    if (response.data.node == null) {
      // NavigationService.navigate("404");
      yield put(get404(true))
    } else {
      if (response.data.node.id == "Clik:None") {
        // NavigationService.navigate("404");
        yield put(get404(true))
      } else {
        // if (LoginStatus == 0) {
        //   NavigationService.navigate("username");
        // }

        if (
          payload.type == "feed" ||
          payload.type == "wiki" ||
          payload.type == "users" ||
          payload.type == "join" ||
          payload.type == "invite" ||
          payload.type == "members" ||
          payload.type == "applications"
        ) {
          yield put(clikProfileDetailsSuccess(response));
          //let asyncId;
          //yield AsyncStorage.getItem('PostId').then(houses => { asyncId = JSON.parse(houses); });
          //console.log(payload.type + '/' +asyncId.replace("Post:", ""));
          //let postId=asyncId.replace("Post:", "")

          let clikData = { type: payload.type, clickTitle: payload.id }
          AsyncStorage.setItem(
            'ClickTitle',
            JSON.stringify(clikData)
          );
          let postId;
          yield AsyncStorage.getItem('PostId').then(houses => { postId = JSON.parse(houses); });
          // console.log(postId,payload.id, '-------------------------------->');
          // NavigationService.navigate("cliksprofileId", {
          //   id: payload.id,
          //   type: payload.type,
          //   postId:postId
          // });        
          // NavigationService.navigate("cliksprofile", {
          //   id: payload.name ? payload.name : payload.id,
          //   type: payload.type,
          //   postId: ' '
          //   //,
          //   //postId:postId
          // });
        } else {
          // NavigationService.navigate("404");
          yield put(get404(true))
        }
      }
    }

  } catch (err) {
    yield put(clikProfileDetailsfailure());
    console.log(err);
  }
}

export default function* TrendingCliksProfileMiddlewarewareWatcher() {
  yield takeEvery(CLIKS_PROFILE_DETAILS, TrendingCliksProfileMiddleware);
}
