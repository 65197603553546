import React from "react";
import { View, StyleSheet, Dimensions, } from "react-native";
import RNPickerSelect from "react-native-picker-select";
import ConstantFontFamily from "../constants/FontFamily";
import ButtonStyle from "../constants/ButtonStyle";
// import {
//   BarChart,
// } from "react-native-chart-kit";
const data = {
  labels: ["January", "February", "March", "April", "May", "June"],
  datasets: [
    {
      data: [40, 45, 28, 80, 99, 43]
    }
  ]
};
// const chartConfig = {
//   fillShadowGradient: "#009B1A",
//   fillShadowGradientFrom:"#009B1A",
//   fillShadowGradientTo:"#009B1A",
//   fillShadowGradientOpacity: 1, 
//   fillShadowGradientFromOpacity:1,
//   fillShadowGradientToOpacity:1,
//   backgroundGradientFrom: "#fff",
//   backgroundGradientTo: "#fff",
//   // color: (opacity = 0) => `#009B1A, ${opacity})`
//   color: (opacity = 1) => `rgba(${0}, ${0}, ${0}, ${opacity})`
// };
export default class AnalyticsGraph extends React.Component {
  constructor(props) {
    super(props);
    // this.inputRefs = {};
    this.Pagescrollview = null;
    this.state = {
      showsVerticalScrollIndicatorView: false,
      currentScreentWidth: 0,
      durationDropdownItems: [
        {
          label: "Earnings per Month",
          value: "Earnings per Month"
        }
      ]
    };
  }
  render() {
    return (
      <View
        style={[
          ButtonStyle.normalFeedStyleForDashboard,
          {
            marginTop: 10,
            marginBottom: Dimensions.get('window').width > 750 ? 10 : 0,
            paddingVertical: 5,
            backgroundColor: "#fff",
            marginLeft: Dimensions.get('window').width >= 750 ? '2%' : 0,
            paddingHorizontal: 5,
            width: Dimensions.get('window').width > 750 ? '96%' : '100%',
          }
        ]}
      >
        <View
          style={{
            marginVertical: 10,
            width: 300,
            alignSelf: "center"
          }}
        >
          <RNPickerSelect
            placeholder={{}}
            items={this.state.durationDropdownItems}
            value="Earnings per Month"
            style={{ ...styles }}
          />
        </View>

        {/* <BarChart
          data={data}
          width={Dimensions.get('window').width > 756 ? 800 : Dimensions.get('window').width - 35}
          height={400}
          yAxisLabel="$"
          chartConfig={chartConfig}
        /> */}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  inputIOS: {
    paddingTop: 13,
    paddingHorizontal: 5,
    paddingBottom: 12,
    borderWidth: 1,
    borderColor: "gray",
    borderRadius: 4,
    backgroundColor: "white",
    color: "black",
    fontSize: 16,
    fontWeight: "bold",
    fontFamily: ConstantFontFamily.defaultFont
  },
  inputAndroid: {
    paddingHorizontal: 3,
    paddingVertical: 5,
    borderWidth: 0.5,
    borderColor: "grey",
    borderRadius: 8,
    color: "#000",
    backgroundColor: "white",
    paddingRight: 10,
    fontSize: 16,
    fontWeight: "bold",
    fontFamily: ConstantFontFamily.defaultFont
  }
});
