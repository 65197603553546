import React from "react";
import { Hoverable } from "react-native-web-hooks";
import { Text, TouchableOpacity, View, Image } from "react-native";
import { Icon } from "react-native-elements";
import ConstantFontFamily from "../constants/FontFamily";

const TopicStructure = props => {
  const {
    item,
    isRoot,
    topicName,
    handleTopicSelectInput,
    openTopics,
    childrenByTopics,
    getTopicStar,
    goToProfile,
    unfollowTopics,
    followTopics,
    favroiteTopics
  } = props;

  return (
    <View style={{ paddingLeft: !isRoot ? 25 : 0 }}>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          width: "100%"
        }}
      >
        <View
          style={{
            alignSelf: "center",
            marginTop: 10,
            marginRight: 5
          }}
        >
          <Icon
            color={"#000"}
            iconStyle={{ alignSelf: "center" }}
            name={openTopics.indexOf(item.id) !== -1 ? "minus" : "plus"}
            type="font-awesome"
            size={18}
            containerStyle={{
              alignSelf: "center"
            }}
            onPress={() =>
              handleTopicSelectInput(item.name, item.parents, item)
            }
          />
        </View>
        <View
          style={{
            width: "70%",
            backgroundColor: "#fff"
          }}
        >
          <TouchableOpacity
            onPress={() => goToProfile(item.name)}
            style={{
              marginTop: 10,
              height: 30,
              alignSelf: "flex-start",
              padding: 5,
              backgroundColor: !isRoot ? "#e3f9d5" : "#e3f9d5",
              borderRadius: 6
            }}
          >
            <Hoverable>
              {isHovered => (
                <Text
                  style={{
                    width: "100%",
                    color: item.parents ? "#009B1A" : "#009B1A",
                    fontSize: 15,
                    fontWeight: "bold",
                    fontFamily: ConstantFontFamily.defaultFont,
                    textDecorationLine: isHovered == true ? "underline" : "none"
                  }}
                >
                  /{item.name.toLowerCase()}
                </Text>
              )}
            </Hoverable>
          </TouchableOpacity>
        </View>

        <TouchableOpacity
          style={{
            height: 30,
            justifyContent: "center",
            width: "20%",
            alignItems: "center",
            marginTop: 10,
            paddingLeft: 10
          }}
          onPress={() =>
            getTopicStar(item.name) == "FOLLOW"
              ? favroiteTopics(item.id)
              : getTopicStar(item.name) == "FAVORITE"
                ? unfollowTopics(item.id)
                : followTopics(item.id)
          }
        >
          <Image
            source={
              getTopicStar(item.name) == "FOLLOW"
                ? require("../assets/image/gstar.png")
                : getTopicStar(item.name) == "FAVORITE"
                  ? require("../assets/image/ystar.png")
                  : require("../assets/image/wstar.png")
            }
            style={{
              height: 20,
              width: 20,
              alignSelf: "flex-end",
              marginRight: 2
            }}
          />
        </TouchableOpacity>
      </View>
      {openTopics.indexOf(item.id) !== -1 ? (
        childrenByTopics[item.id].length > 0 ? (
          childrenByTopics[item.id].map((el, index) => {
            return (
              <TopicStructure
                item={el}
                childrenByTopics={childrenByTopics}
                openTopics={openTopics}
                handleTopicSelectInput={handleTopicSelectInput}
                getTopicStar={getTopicStar}
                unfollowTopics={unfollowTopics}
                followTopics={followTopics}
                favroiteTopics={favroiteTopics}
                goToProfile={goToProfile}
              />
            );
          })
        ) : (
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              width: "100%"
            }}
          >
            <View
              style={{
                width: "70%",
                backgroundColor: "#fff",
                marginTop: 5,
                marginLeft: 50
              }}
            >
              <Text
                style={{
                  width: "100%",
                  color: "#009B1A",
                  fontSize: 15,
                  fontWeight: "bold",
                  padding: 3,
                  fontFamily: ConstantFontFamily.defaultFont
                }}
              >
                No Child Topics
              </Text>
            </View>
          </View>
        )
      ) : null}
    </View>
  );
};

export default TopicStructure;
