import React, { Component } from "react";
import { Text, View, StyleSheet, Image, TouchableOpacity } from "react-native";
import { Icon } from "react-native-elements";
import ConstantColor from "../constants/Colors";

class TooltipBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCaption: "Select an Emoji",
    };
  }
  increaseLikeCount = (data, Color) => {
    this.props.increaseLikeCount(data, Color);
  };
  reportContent = (reportType) => {
    this.props.reportContent(reportType);
  };
  render() {
    return (
      <View style={styles.toolMain}>
        <View style={styles.mainView}>
          {/* <View style={{flexDirection:'row'}}> */}
          <TouchableOpacity
            onPress={() => {
              if (this.props.profileData.data.subscription == "BASIC") {
                this.setState({
                  showCaption: "*This is a paid premium feature",
                });
              } else {
                if (this.props.type == "comment") {
                  this.increaseLikeCount(this.props.commentId, "DIAMOND");
                } else {
                  this.increaseLikeCount(this.props.postId, "DIAMOND");
                }
              }
            }}
            // onMouseEnter={() => this.setState({ showCaption: "Mind-blowing" })}
            // onMouseLeave={() => this.setState({ showCaption: "" })}
          >
            <Icon
              color={ConstantColor.blueColor}
              name={"heart"}
              type="font-awesome"
              size={16}
              iconStyle={{ alignSelf: "center" }}
              containerStyle={{ alignSelf: "center" }}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              if (this.props.profileData.data.subscription == "BASIC") {
                this.setState({
                  showCaption: "*This is a paid premium feature",
                });
              } else {
                // this.setState({ showCaption: "*This is a paid premium feature" })
                if (this.props.type == "comment") {
                  this.increaseLikeCount(this.props.commentId, "GOLD");
                } else {
                  this.increaseLikeCount(this.props.postId, "GOLD");
                }
              }
            }}
            // onMouseEnter={() => this.setState({ showCaption: "" })}
            // onMouseLeave={() => this.setState({ showCaption: "" })}
          >
            <Icon
              color={"#ffce44"}
              name={"heart"}
              type="font-awesome"
              size={16}
              iconStyle={{ alignSelf: "center" }}
              containerStyle={{ alignSelf: "center" }}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              if (this.props.profileData.data.subscription == "BASIC") {
                this.setState({
                  showCaption: "*This is a paid premium feature",
                });
              } else {
                if (this.props.type == "comment") {
                  this.increaseLikeCount(this.props.commentId, "SILVER");
                } else {
                  this.increaseLikeCount(this.props.postId, "SILVER");
                }
              }
            }}
            onMouseEnter={() => this.setState({ showCaption: "Great" })}
            // onMouseLeave={() => this.setState({ showCaption: "" })}
          >
            <Icon
              color={"#b0b0b0"}
              name={"heart"}
              type="font-awesome"
              size={16}
              iconStyle={{ alignSelf: "center" }}
              containerStyle={{ alignSelf: "center" }}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => {
              if (this.props.type == "comment") {
                this.increaseLikeCount(this.props.commentId, "RED");
              } else {
                this.increaseLikeCount(this.props.postId, "RED");
              }
            }}
            onMouseEnter={() => this.setState({ showCaption: "Good" })}
            // onMouseLeave={() => this.setState({ showCaption: "" })}
          >
            <Icon
              color={"#de5246"}
              name={"heart"}
              type="font-awesome"
              size={16}
              iconStyle={{ alignSelf: "center" }}
              containerStyle={{ alignSelf: "center" }}
            />
          </TouchableOpacity>
        </View>

        <View
          style={[
            styles.mainView,
            {
              borderBottomWidth: 1,
              borderColor: "#d3d3d3",
              paddingBottom: 10,
              paddingTop: 10,
            },
          ]}
        >
          <TouchableOpacity
            onMouseEnter={() =>
              this.setState({ showCaption: "Flag for Removal" })
            }
            onPress={() => this.reportContent("SPAM")}

            // onMouseLeave={() => this.setState({ showCaption: "" })}
          >
            <Icon
              color={"#de5246"}
              name={"flag"}
              type="font-awesome"
              size={16}
              iconStyle={{ alignSelf: "center" }}
              containerStyle={{ alignSelf: "center" }}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onMouseEnter={() => this.setState({ showCaption: "Low Quality" })}
            onMouseLeave={() => this.setState({ showCaption: "" })}
            onPress={() => this.reportContent("LOW_QUALITY")}
          >
            <Image
              source={require("../assets/image/bugIcon.png")}
              style={{
                width: 18,
                height: 18,
                // marginLeft: 10
              }}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onMouseEnter={() => this.setState({ showCaption: "Off-Topic" })}
            onPress={() => this.reportContent("HATE")}

            // onMouseLeave={() => this.setState({ showCaption: "" })}
          >
            <Image
              source={require("../assets/image/hate.png")}
              style={{
                width: 18,
                height: 18,
              }}
            />
          </TouchableOpacity>
          <TouchableOpacity
            onMouseEnter={() => this.setState({ showCaption: "Disagree" })}
            // onMouseLeave={() => this.setState({ showCaption: "" })}
            onPress={() => this.reportContent("OTHER")}
          >
            <Icon
              color={"#ffce44"}
              name={"thumbs-down"}
              type="font-awesome"
              size={18}
              iconStyle={{ alignSelf: "center" }}
              containerStyle={{ alignSelf: "center" }}
            />
          </TouchableOpacity>
        </View>
        <Text
          style={{
            textAlign: "center",
            paddingVertical: 5,
            fontWeight:
              this.state.showCaption == "*This is a paid premium feature"
                ? "bold"
                : "200",
            color:
              this.state.showCaption == "*This is a paid premium feature"
                ? "#D3B036"
                : "#000",
          }}
        >
          {this.state.showCaption}{" "}
        </Text>
      </View>
    );
  }
}
export default TooltipBox;

const styles = StyleSheet.create({
  toolMain: {
    position: "absolute",
    top: -100,
    left: 15,
    right: "auto",
    // padding:20,
    borderRadius: 5,
    borderRadius: 20,
    // display:flex,
    backgroundColor: "white",
    width: 170,
    // height: 95,
    padding: 10,
    paddingBottom: 0,
    borderColor: "#d3d3d3",
    borderWidth: 2,
    // flexWrap:'wrap',
    // textAlign:'center',
    // wordBreak:'break-all',
    // overflowX:'hidden',
    // height:100,
    zIndex: 9999,
    flex: 1,
    flexDirection: "column",
  },
  mainView: {
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-between",
    width: 150,
    flex: 1,
    // height: 25,
    // padding: 20
  },
});
