// import "@expo/browser-polyfill";
import _ from "lodash";
import React, { Component } from "react";
import {
  Platform,
  Dimensions,
  Image,
  TouchableOpacity,
} from "react-native";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";
import { saveUserLoginDaitails } from "../reducers/LoginUserDetailsReducer";
import appolloClient from "../client";
import {
  FollowMutation,
  UnFollowMutation,
  leaveClikMutation,
} from "../graphqlSchema/FollowandUnFollowMutation";
import { ClikFollowingMutation } from "../graphqlSchema/UserMutation";
import {
  FollowVariables,
  UnFollowVariables,
  ClikLeaveVariables,
} from "../graphqlSchema/FollowandUnfollowVariables";
// import LeaveCliksModal from "../components/LeaveCliksModal";
// import Modal from "modal-enhanced-react-native-web";
import Overlay from "react-native-modal-overlay";

class ClikStar extends Component {
  constructor(props) {
    super(props);
    const gstar = require("../assets/image/gstar.png");
    const ystar = require("../assets/image/ystar.png");
    const wstar = require("../assets/image/wstar.png");

    this.state = {
      index: 0,
      starList: [wstar, gstar, ystar],
      followList: ["TRENDING", "FOLLOW", "FAVORITE"],
      showLeaveCliksModal: false,
    };
  }

  async componentDidMount() {
    await this.getClikStar(this.props.ClikName);

  }

  componentDidUpdate = async (prevProps) => {
    if (prevProps.ClikName !== this.props.ClikName) {
      await this.getClikStar(this.props.ClikName);
    }
    if (prevProps.star !== this.props.star) {
      await this.changeStar();
    }
    if (
      prevProps.getUserFollowCliksList !== this.props.getUserFollowCliksList
    ) {
      await this.getClikStar(this.props.ClikName);
    }
  };

  getClikStar = async (ClikName) => {
    if (this.props.loginStatus == 0) {
      await this.setState({
        index: 0,
      });
      return false;
    }

    let index = 0;
    index = this.props.getUserFollowCliksList.findIndex(
      (i) => i.node.clik.id.toLowerCase() == ClikName && ClikName.toLowerCase()
    );
    if (index == -1) {
      await this.setState({
        index: 0,
      });

    } else if (
      this.props.getUserFollowCliksList[index].node.follow_type == "FOLLOW"
    ) {
      await this.setState({
        index: 1,
      });
    } else {
      await this.setState({
        index: 2,
      });
    }


  };

  changeStar = async () => {
    if (this.props.loginStatus == 0) {
      this.props.setLoginModalStatus(true);
      return false;
    }
    if (this.state.index + 1 === this.state.starList.length) {
      await this.setState({
        index: 0,
      });
      await this.changeStarApi(this.state.followList[this.state.index]);
    } else {
      await this.setState({
        index: this.state.index + 1,
      });
      await this.changeStarApi(this.state.followList[this.state.index]);
    }
  };

  changeStarApi = _.debounce((typeIndex) => {
    typeIndex == "FAVORITE"
      ? this.favroiteCliks(this.props.ClikName)
      : typeIndex == "TRENDING"
        ? this.unfollowCliks(this.props.ClikName)
        : this.followCliks(this.props.ClikName);
  }, 1000);

  followCliks = (cliksId) => {

    if (this.props.loginStatus == 0) {
      this.props.setLoginModalStatus(true);
      return false;
    }
    FollowVariables.variables.id = "Clik:" + cliksId.replace("clik:", "");
    FollowVariables.variables.follow_type = "FOLLOW";
    appolloClient
      .query({
        query: FollowMutation,
        ...FollowVariables,
        fetchPolicy: "no-cache",
      })
      .then(async (res) => {


        await appolloClient
          .query({
            query: ClikFollowingMutation,
            variables: {
              id: "User:CurrentUser",
              first: 50
            },
            fetchPolicy: "no-cache"
          }).then(e => {


            let userData = {
              data: this.props.profileData,
              externalfollow: this.props.getUserFollowFeedList,
              clikfollow: e.data.node.cliks_following.edges,
              topicfollow: this.props.getUserFollowTopicList,
              userfollow: this.props.getUserFollowUserList

            }
            this.props.saveLoginUser(userData);

            // setTimeout(async () => {
            this.props.updateStar();

            // }, 1);

          })




      });
  };

  favroiteCliks = (cliksId) => {
    if (this.props.loginStatus == 0) {
      this.props.setLoginModalStatus(true);
      return false;
    }
    FollowVariables.variables.id = "Clik:" + cliksId.replace("clik:", "");
    FollowVariables.variables.follow_type = "FAVORITE";
    appolloClient
      .query({
        query: FollowMutation,
        ...FollowVariables,
        fetchPolicy: "no-cache",
      })
      .then(async (res) => {
        await appolloClient
          .query({
            query: ClikFollowingMutation,
            variables: {
              id: "User:CurrentUser",
              first: 50
            },
            fetchPolicy: "no-cache"
          }).then(e => {
            // console.log(e, 'clikfollow');

            let userData = {
              data: this.props.profileData,
              externalfollow: this.props.getUserFollowFeedList,
              clikfollow: e.data.node.cliks_following.edges,
              topicfollow: this.props.getUserFollowTopicList,
              userfollow: this.props.getUserFollowUserList

            }
            this.props.saveLoginUser(userData);
            // setTimeout(async () => {
            this.props.updateStar();

            // }, 1);

          })


      });
  };

  unfollowCliks = async (cliksId) => {
    if (this.props.loginStatus == 0) {
      this.props.setLoginModalStatus(true);
      return false;
    }
    if (
      this.props.MemberType == "SUPER_ADMIN" ||
      this.props.MemberType == "ADMIN" ||
      this.props.MemberType == "MEMBER"
    ) {
      this.setState({
        showLeaveCliksModal: true,
      });
      return false;
    }

    UnFollowVariables.variables.id = "Clik:" + cliksId.replace("clik:", "");
    appolloClient
      .query({
        query: UnFollowMutation,
        ...UnFollowVariables,
        fetchPolicy: "no-cache",
      })
      .then(async (res) => {
        await appolloClient
          .query({
            query: ClikFollowingMutation,
            variables: {
              id: "User:CurrentUser",
              first: 50
            },
            fetchPolicy: "no-cache"
          }).then(e => {

            let userData = {
              data: this.props.profileData,
              externalfollow: this.props.getUserFollowFeedList,
              clikfollow: e.data.node.cliks_following.edges,
              topicfollow: this.props.getUserFollowTopicList,
              userfollow: this.props.getUserFollowUserList

            }
            this.props.saveLoginUser(userData);
          })

        this.props.updateStar();
      });
  };

  leaveClik = async () => {
    ClikLeaveVariables.variables.clik_id = "Clik:" + this.props.ClikName.replace("clik:", "");
    appolloClient
      .query({
        query: leaveClikMutation,
        ...ClikLeaveVariables,
        fetchPolicy: "no-cache",
      })
      .then(async (res) => {
        await appolloClient
          .query({
            query: ClikFollowingMutation,
            variables: {
              id: "User:CurrentUser",
              first: 50
            },
            fetchPolicy: "no-cache"
          }).then(e => {


            let userData = {
              data: this.props.profileData,
              externalfollow: this.props.getUserFollowFeedList,
              clikfollow: e.data.node.cliks_following.edges,
              topicfollow: this.props.getUserFollowTopicList,
              userfollow: this.props.getUserFollowUserList

            }
            this.props.saveLoginUser(userData);
          })
        this.setState({
          showLeaveCliksModal: false,
          index: 0,
        });
        this.props.updateStar();
      });
  };

  onClose = () => {
    this.setState({
      showLeaveCliksModal: false,
      index: 2,
    });
  };

  render() {
    return (
      <TouchableOpacity
        style={this.props.ContainerStyle}
        onPress={() => this.changeStar()}
      >
        {(
          this.props.MemberType == "SUPER_ADMIN" ||
          this.props.MemberType == "ADMIN" ||
          this.props.MemberType == "MEMBER"
        ) && this.state.showLeaveCliksModal == true ? (
          Platform.OS !== "web" ? (
            <Overlay
              animationType="zoomIn"
              visible={this.state.showLeaveCliksModal}
              onClose={() => this.onClose()}
              closeOnTouchOutside
              children={
                <LeaveCliksModal
                  onClose={() => this.onClose()}
                  {...this.props}
                />
              }
              childrenWrapperStyle={{
                padding: 0,
                margin: 0,
              }}
            />
          ) : (
            <Modal
              isVisible={this.state.showLeaveCliksModal}
              onBackdropPress={() => this.onClose()}
              style={{
                marginHorizontal:
                  Dimensions.get("window").width > 750 ? "30%" : 10,
                padding: 0,
              }}
            >
              <LeaveCliksModal
                onClose={() => this.onClose()}
                leaveClik={() => this.leaveClik()}
                {...this.props}
              />
            </Modal>
          )
        ) : null}
        <Image
          source={this.state.starList[this.state.index]}
          style={this.props.ImageStyle}
        />
      </TouchableOpacity>
    );
  }
}

const mapStateToProps = (state) => ({
  loginStatus: state.UserReducer.loginStatus,
  profileData: state.LoginUserDetailsReducer.userLoginDetails,
  getUserFollowCliksList: state.LoginUserDetailsReducer.userFollowCliksList
    ? state.LoginUserDetailsReducer.userFollowCliksList
    : [],
  getUserFollowFeedList: state.LoginUserDetailsReducer.userFollowFeedList
    ? state.LoginUserDetailsReducer.userFollowFeedList
    : [],
  getUserFollowTopicList: state.LoginUserDetailsReducer.userFollowTopicsList
    ? state.LoginUserDetailsReducer.userFollowTopicsList
    : [],
  getUserFollowUserList: state.LoginUserDetailsReducer.userFollowUserList
    ? state.LoginUserDetailsReducer.userFollowUserList
    : [],
});

const mapDispatchToProps = (dispatch) => ({
  setLoginModalStatus: (payload) => dispatch(setLOGINMODALACTION(payload)),
  saveLoginUser: (payload) => dispatch(saveUserLoginDaitails(payload)),

});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  ClikStar
);
