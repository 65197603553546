import { useEffect, useState } from 'react';
import { ScrollView, View, Text, Image, TouchableOpacity } from 'react-native';
import { connect } from "react-redux";
import { compose } from "react-recompose";
import Colors from "../constants/Colors";
import ButtonStyle from "../constants/ButtonStyle";
import CreateCommentStyle from './Style/CreateCommentStyle';

const SearchinputComment = (props) => {

    // useEffect(()=>{
    //     console.log(props.profileData,'prr');
    // })
    let clik = []
    let user = []
    const handleSelectClik = (id) => {
        if (clik.length < 3) {

            if (!clik.includes(id)) {
                clik = clik.concat([id])
                props.setClikData(clik)
            } else {
                alert('you cannot add same clik')
            }
        } else {
            alert('only 3 clik allowed')
        }


    }

    const handleSelectUser = (id) => {
        if (user.length < 3) {

            if (!user.includes(id)) {
                user = user.concat([id])
                props.setUserData(user)
            } else {
                alert('you cannot add same clik')
            }
        } else {
            alert('only 3 clik allowed')
        }


    }
    return (
        <View>
            {props.input.length > 0 && props.input.startsWith('#') && (
                <ScrollView
                    nestedScrollEnabled
                    style={CreateCommentStyle.tagsDropdownStyle}
                    showsVerticalScrollIndicator={false}
                >



                    {props.profileData.clikfollow.map((item, index) => {
                        // console.log(item, 'itititi');

                        return (
                            <TouchableOpacity
                                key={item.node.clik.id}
                                style={{
                                    width: "100%",
                                    // paddingVertical: 5,
                                    flexDirection: 'row'
                                }}
                                onPress={() => {
                                    handleSelectClik(item.node.clik)
                                }
                                }
                            >

                                {item.node.clik.profile_pic ? (<Image
                                    source={item.node.clik.profile_pic}
                                    style={{
                                        width: 30,
                                        height: 30,
                                        borderRadius: 15
                                    }}
                                />) : (<Image
                                    source={require("../assets/image/default-image.png")}
                                    style={{
                                        width: 30,
                                        height: 30,
                                        borderRadius: 15
                                    }}
                                />)}
                                <View style={[ButtonStyle.clikNameBackgroundStyle, {
                                    marginLeft: 10,
                                    backgroundColor:"#E8F5FA",

                                }]}>
                                    <Text
                                        style={[ButtonStyle.clikNameTitleStyle,
                                        {
                                            paddingHorizontal: 5,
                                            color: Colors.blueColor,

                                        }]}
                                    // onPress={() => {
                                    //     console.log(' text pressed=================');
                                    // }
                                    // }
                                    >
                                        #{item.node.clik.name}
                                    </Text>
                                </View>

                            </TouchableOpacity>
                        );
                    })}
                </ScrollView>)
            }


            {props.input.length > 0 && props.input.startsWith('@') && (
                <ScrollView
                    nestedScrollEnabled
                    style={CreateCommentStyle.tagsDropdownStyle}
                    showsVerticalScrollIndicator={false}
                >



                    {props.profileData.userfollow.map((item, index) => {
                        // console.log(item, 'itititi');

                        return (
                            <TouchableOpacity
                                key={item.node.user.id}
                                style={{
                                    width: "100%",
                                    // paddingVertical: 5,
                                    flexDirection: 'row'
                                }}
                                onPress={() => {
                                    handleSelectUser(item.node.user)
                                }
                                }
                            >

                                {item.node.user.profile_pic ? (<Image
                                    source={item.node.user.profile_pic}
                                    style={{
                                        width: 30,
                                        height: 30,
                                        borderRadius: 15
                                    }}
                                />) : (<Image
                                    source={require("../assets/image/default-image.png")}
                                    style={{
                                        width: 30,
                                        height: 30,
                                        borderRadius: 15
                                    }}
                                />)}
                                <View style={[ButtonStyle.userNameBackgroundStyle, {
                                    marginLeft: 10,
                                }]}>
                                    <Text
                                        style={[ButtonStyle.userNameTitleStyle,
                                        {
                                            paddingHorizontal: 5
                                        }]}
                                    // onPress={() => {
                                    //     console.log(' text pressed=================');
                                    // }
                                    // }
                                    >
                                        @{item.node.user.username}
                                    </Text>
                                </View>

                            </TouchableOpacity>
                        );
                    })}
                </ScrollView>)
            }

        </View>
    )
}

const mapStateToProps = (state) => ({
    profileData: state.LoginUserDetailsReducer.userLoginDetails,




});

const mapDispatchToProps = (dispatch) => ({



});


export default compose(connect(mapStateToProps, mapDispatchToProps))(
    SearchinputComment
);

// export default SearchinputComment;