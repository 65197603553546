import { createSlice } from '@reduxjs/toolkit';
// import { CLIKS_PROFILE_DETAILS_FAILURE, CLIKS_PROFILE_DETAILS_SUCCESS } from "../constants/Action";




const initialState = {
    getTrendingTopicsProfileDetails: {},
};


const TopicProfileDetailsReducer = createSlice({
    name: 'topicProfileDetails',
    initialState,
    reducers: {
        topicProfileDetailsSuccess(state, action) {
            return { ...state, getTrendingTopicsProfileDetails: action.payload.data.node }
        },
        topicProfileDetailsfailure(state, action) {
            // state.getTrendingCliksProfileDetails=action.payload.data.node
        },

    },
})

export const { topicProfileDetailsSuccess, topicProfileDetailsfailure } = TopicProfileDetailsReducer.actions
export default TopicProfileDetailsReducer.reducer