import { takeEvery, put } from 'redux-saga/effects';
import { SHARELINKMODALACTION } from "../constants/Action";

export function* ShareLinkModalMiddleware({ payload }) {
    if (payload == true) {
        yield put(shareLinkModalSuccess(payload));
    } else {
        yield put(shareLinkModalSuccess(payload));
    }
}

export default function* ShareLinkModalMiddlewareWatcher() {
    yield takeEvery(SHARELINKMODALACTION, ShareLinkModalMiddleware);
}