import React, { Component } from 'react';
import { View, TouchableOpacity, Text, Dimensions, Platform, Image } from 'react-native';
import { connect } from "react-redux";
import { compose } from "react-recompose";
import {
    ClikFollowMutation,
    ClikUnfollowMutation,
    FeedFollowMutation,
    FeedUnFollowMutation,
    UserFollowMutation,
    UserUnfollowMutation
} from "../graphqlSchema/FollowandUnFollowMutation";
import {
    SearchClikMutation,
    SearchEveryThingMutation,
    SearchFeedMutation,
    SearchTopicMutation,
    SearchUserMutation
} from "../graphqlSchema/SearchMutation";
import { UserLoginMutation } from "../graphqlSchema/UserMutation";
import {
    ClikFollowVariables,
    ClikUnfollowVariables,
    FeedFollowVariables,
    FeedUnFollowVariables,
    TopicFollowVariables,
    TopicUnFollowVariables,
    UserFollowVariables,
    UserUnfollowVariables
} from "../graphqlSchema/FollowandUnfollowVariables";
import {TopicFollowingMutation } from "../graphqlSchema/UserMutation";
import {
    SearchClikVariables,
    SearchEveryVariables,
    SearchFeedVariables,
    SearchTopicVariables,
    SearchUserVariables
} from "../graphqlSchema/SearchVariables";
import appolloClient from "../client";
import ConstantFontFamily from "../constants/FontFamily";
// import { IsCompleted, Focus, AddTerm, TermConcat } from '../reducers/action/Completed';
import { AddTerm, TermConcat } from "../reducers/AddtermReducer";
import { IsCompleted, searchOpenBarStatus, setDisplayType } from '../reducers/AdminTrueFalseReducer';
import { setTermWeb, setTerm } from '../reducers/ScreenLoadingReducer'
import ButtonStyle from '../constants/ButtonStyle';

class SearchSuggestion extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            TopicSuggestList: [],
            ClikSuggestList: [],
            UserSuggestList: [],
            FeedSuggestList: [],
            selectedItems: [],
            addArray: [],
            completed: false

        }
    }

    ComponentDidMount() {
        this.getRespectiveSuggestions(this.props.termWeb);
        // this.props.IsCompleted(this.state.completed)
        // this.setState({completed:this.state})
        this.setState({ completed: this.props.isCompleted })
        if (this.props.term && this.props.term.length > 0) {
            this.setState({ addArray: this.props.termArray.termArray })
        } else {
            this.setState({ addArray: [] })
            this.props.TermConcat([])
        }

    }

    componentDidUpdate(prevProps) {
        if (prevProps.termWeb != this.props.termWeb) {
            this.getRespectiveSuggestions(this.props.termWeb);
            // this.setState({completed:this.props.Completed.isCompleted})
            if (this.props.term && this.props.term.length > 0) {
                this.setState({ addArray: this.props.termArray.termArray })
            } else {
                this.setState({ addArray: [] })
                this.props.TermConcat([])
            }
        }

    }

    getRespectiveSuggestions = async value => {
        console.log(value,'nextt');
        var TotalTerm = value.split(" ");

        for (var i = 0; i < TotalTerm.length; i++) {
            if (TotalTerm[i].length > 1) {
                if (TotalTerm[i].slice(-5).startsWith("#") == true || this.state.index == 1) {
                    await this.setState({
                        TopicSuggestList: [],
                        UserSuggestList: [],
                        FeedSuggestList: [],
                        completed: false
                    });
                    await this.props.IsCompleted(this.state.completed)
                    await this.getClikSuggestion(TotalTerm[i].replace("#", ""));
                } else if (
                    TotalTerm[i].slice(-5).startsWith("/") == true ||
                    this.state.index == 2
                ) {
                    await this.setState({
                        ClikSuggestList: [],
                        UserSuggestList: [],
                        FeedSuggestList: [],
                        completed: false
                    });
                    await this.props.IsCompleted(this.state.completed)
                    await this.getTopicSuggestion(TotalTerm[i].replace("/", ""));
                } else if (
                    TotalTerm[i].slice(-5).startsWith("@") == true || this.state.index == 3
                ) {

                    await this.setState({
                        TopicSuggestList: [],
                        ClikSuggestList: [],
                        FeedSuggestList: [],
                        completed: false
                    });
                    await this.props.IsCompleted(this.state.completed)
                    await this.getUserSuggestion(TotalTerm[i].replace("@", ""));
                } else if (
                    TotalTerm[i].slice(-5).startsWith("%") == true ||
                    this.state.index == 4
                ) {
                    // this.props.IsCompleted(false)
                    await this.setState({
                        TopicSuggestList: [],
                        ClikSuggestList: [],
                        UserSuggestList: [],
                        completed: false
                    });
                    await this.props.IsCompleted(this.state.completed)
                    await this.getFeedSuggestion(TotalTerm[i].replace("%", ""));
                }
                else {
                    // this.props.IsCompleted(true)
                    await this.setState({
                        TopicSuggestList: [],
                        ClikSuggestList: [],
                        UserSuggestList: [],
                        FeedSuggestList: [],
                        completed: true
                        // selectedItems:[]
                    });
                    await this.props.IsCompleted(this.state.completed)
                }

            } else {
                await this.setState({ completed: true })
                await this.props.IsCompleted(this.state.completed)


            }


        }
    };

    getFeedSuggestion = value => {
        SearchFeedVariables.variables.prefix = value.replace("-", " ");
        appolloClient
            .query({
                query: SearchFeedMutation,
                ...SearchFeedVariables,
                fetchPolicy: "no-cache"
            })
            .then(res => {
                let data = res.data.search.feeds;
                this.setState({
                    FeedSuggestList: data,
                    TopicSuggestList: [],
                    ClikSuggestList: [],
                    UserSuggestList: []
                });
            });
    };

    getTopicSuggestion = value => {
        SearchTopicVariables.variables.prefix = value;
        appolloClient
            .query({
                query: SearchTopicMutation,
                ...SearchTopicVariables,
                fetchPolicy: "no-cache"
            })
            .then(res => {
                let data = res.data.search.topics;
                this.setState({
                    TopicSuggestList: data,
                    ClikSuggestList: [],
                    UserSuggestList: [],
                    FeedSuggestList: [],
                    showdropDown: true
                });
            });
    };

    getClikSuggestion = value => {
        SearchClikVariables.variables.prefix = value;
        appolloClient
            .query({
                query: SearchClikMutation,
                ...SearchClikVariables,
                fetchPolicy: "no-cache"
            })
            .then(res => {
                let data = res.data.search.cliks;
                this.setState({
                    ClikSuggestList: data,
                    TopicSuggestList: [],
                    UserSuggestList: [],
                    FeedSuggestList: [],
                    showdropDown: true
                });
            });
    };

    getUserSuggestion = value => {
        SearchUserVariables.variables.prefix = value;
        appolloClient
            .query({
                query: SearchUserMutation,
                ...SearchUserVariables,
                fetchPolicy: "no-cache"
            })
            .then(res => {

                let data = res.data.search.users;
                this.setState({
                    UserSuggestList: data,
                    TopicSuggestList: [],
                    ClikSuggestList: [],
                    FeedSuggestList: [],
                    showdropDown: true
                });
            });
        // this.props.IsCompleted(true)
    };

    // ---------------------------------------------

    getTopicStar = (TopicName) => {
        let index = 0;
        index = this.props.getUserFollowTopicList.findIndex(
            (i) =>
                i
                    .getIn(["topic", "name"])
                    .toLowerCase()
                    .replace("topic:", "") ==
                TopicName.toLowerCase().replace("topic:", "")
        );
        if (index == -1) {
            return "TRENDING";
        } else {
            return this.props.getUserFollowTopicList.getIn([
                index,
                "settings",
                "follow_type",
            ]);
        }
    };

    getClikStar = (ClikName) => {
        let index = 0;
        index = this.props.getUserFollowCliksList.findIndex(
            (i) =>
                i
                    .getIn(["clik", "name"])
                    .toLowerCase()
                    .replace("clik:", "") == ClikName.toLowerCase().replace("clik:", "")
        );
        if (index == -1) {
            return "TRENDING";
        } else {
            return this.props.getUserFollowCliksList.getIn([
                index,
                "settings",
                "follow_type",
            ]);
        }
    };

    favroiteCliks = async (cliksId) => {
        ClikFollowVariables.variables.clik_id = cliksId;
        ClikFollowVariables.variables.follow_type = "FAVORITE";
        appolloClient
            .query({
                query: ClikFollowMutation,
                ...ClikFollowVariables,
                fetchPolicy: "no-cache",
            })
            .then(async (res) => {
                // let resDataLogin = await this.props.Login();
                // await this.props.saveLoginUser(resDataLogin.data.login);
            });
    };

    unfollowCliks = async (cliksId) => {
        ClikUnfollowVariables.variables.clik_id = cliksId;
        appolloClient
            .query({
                query: ClikUnfollowMutation,
                ...ClikUnfollowVariables,
                fetchPolicy: "no-cache",
            })
            .then(async (res) => {
                // let resDataLogin = await this.props.Login();
                // await this.props.saveLoginUser(resDataLogin.data.login);
            });
    };

    getUserStar = (UserName) => {
        let index = 0;
        index = this.props.getUserFollowUserList.findIndex(
            (i) =>
                i
                    .getIn(["user", "username"])
                    .toLowerCase()
                    .replace("user:", "") == UserName.toLowerCase().replace("user:", "")
        );
        if (index == -1) {
            return "TRENDING";
        } else {
            return this.props.getUserFollowUserList.getIn([
                index,
                "settings",
                "follow_type",
            ]);
        }
    };

    getFeedStar = (Name) => {
        let index = 0;
        index = this.props.getUserFollowFeedList.findIndex(
            (i) =>
                i
                    .getIn(["feed", "name"])
                    .toLowerCase()
                    .replace("ExternalFeed:", "") ==
                Name.toLowerCase().replace("ExternalFeed:", "")
        );
        if (index == -1) {
            return "TRENDING";
        } else {
            return this.props.getUserFollowFeedList.getIn([
                index,
                "settings",
                "follow_type",
            ]);
        }
    };

    favroiteFeed = async (Id) => {
        FeedFollowVariables.variables.feed_id = Id;
        FeedFollowVariables.variables.follow_type = "FAVORITE";
        appolloClient
            .query({
                query: FeedFollowMutation,
                ...FeedFollowVariables,
                fetchPolicy: "no-cache",
            })
            .then(async (res) => {
                // let resDataLogin = await this.props.Login();
                // await this.props.saveLoginUser(resDataLogin.data.login);
            });
    };

    unfollowFeed = async (Id) => {
        FeedUnFollowVariables.variables.feed_id = Id;
        appolloClient
            .query({
                query: FeedUnFollowMutation,
                ...FeedUnFollowVariables,
                fetchPolicy: "no-cache",
            })
            .then(async (res) => {
                // let resDataLogin = await this.props.Login();
                // await this.props.saveLoginUser(resDataLogin.data.login);
            });
    };

    favroiteTopics = async (topicId) => {
        TopicFollowVariables.variables.topic_id = topicId;
        TopicFollowVariables.variables.follow_type = "FAVORITE";
        appolloClient
            .query({
                query: TopicFollowingMutation,
                ...TopicFollowVariables,
                fetchPolicy: "no-cache",
            })
            .then(async (res) => {
                // let resDataLogin = await this.props.Login();
                // await this.props.saveLoginUser(resDataLogin.data.login);
            });
    };

    unfollowTopics = async (topicId) => {
        TopicUnFollowVariables.variables.topic_id = topicId;
        appolloClient
            .query({
                query: TopicFollowingMutation,
                ...TopicUnFollowVariables,
                fetchPolicy: "no-cache",
            })
            .then(async (res) => {
                // let resDataLogin = await this.props.Login();
                // await this.props.saveLoginUser(resDataLogin.data.login);
            });
    };

    followTopics = (topicId) => {
        if (this.props.loginStatus == 0) {
            this.props.setLoginModalStatus(true);
            return false;
        }
        TopicFollowVariables.variables.topic_id = topicId;
        TopicFollowVariables.variables.follow_type = "FOLLOW";
        appolloClient
            .query({
                query: TopicFollowingMutation,
                ...TopicFollowVariables,
                fetchPolicy: "no-cache",
            })
            .then(async (res) => {
                // let resDataLogin = await this.props.Login();
                // await this.props.saveLoginUser(resDataLogin.data.login);
            });
    };

    putValue = async data => {
        let value = [];
        // this.props.AddTerm(this.props.addTermArray.termArr.push(data))

        // for (let i = 0; i < this.state.selectedItems.length; i++) {
        //     value.push(" " + this.state.selectedItems[i].name + " ");
        // }
        // await this.setState({
        //     term: value.toString().replace(",", "")
        // });
        // this.input.focus();
    };

    followUser = userId => {
        if (this.props.loginStatus == 0) {
            this.props.setLoginModalStatus(true);
            return false;
        }
        UserFollowVariables.variables.user_id = userId;
        UserFollowVariables.variables.follow_type = "FOLLOW";
        appolloClient
            .query({
                query: UserFollowMutation,
                ...UserFollowVariables,
                fetchPolicy: "no-cache"
            })
            .then(async res => {
                // let resDataLogin = await this.props.Login();
                // await this.props.saveLoginUser(resDataLogin.data.login);
            });
    };

    unfollowUser = async userId => {
        UserUnfollowVariables.variables.user_id = userId;
        appolloClient
            .query({
                query: UserUnfollowMutation,
                ...UserUnfollowVariables,
                fetchPolicy: "no-cache"
            })
            .then(async res => {
                // let resDataLogin = await this.props.Login();
                // await this.props.saveLoginUser(resDataLogin.data.login);
            });
    };



    handlieClick = async value => {
        // this.props.nameInput.current.focus()
        this.state.addArray.push(value)
        this.props.TermConcat(this.state.addArray)
        this.props.setTermWeb(this.props.termArray.termArray.join(' '));
        this.props.term != ""
            ? this.props.setTerm(this.props.termArray.termArray.join(' '))
            : this.props.setTermWeb(this.props.termArray.termArray.join(' '));
        this.setState({ completed: true })
        await this.props.IsCompleted(this.state.completed)

    }
    render() {
        // console.log(this.props.termWeb,this.props.term,'termmm');
        return (
            <>
                {this.state.showdropDown ?
                    // this.props.term && this.state.showdropDown || this.props.termWeb != '' && 
                    <View
                        style={{
                            borderRadius: 0,
                            borderWidth: 1,
                            borderColor: '#d7d7d7'

                        }}
                    >
                        {this.state.TopicSuggestList && this.state.completed == false && this.state.TopicSuggestList.map((item, index) => {
                            // console.log(item)
                            return ( 
                                <View
                                    key={item.name}
                                    style={{
                                        backgroundColor: "#FEFEFA",
                                        width: "100%",
                                        padding: 5,
                                        justifyContent: "space-between",
                                        alignSelf: "flex-start",
                                        flexDirection: "row",
                                        height: 30
                                    }}
                                >
                                    <View
                                        style={{
                                            padding: 5,
                                            backgroundColor: item.parents ? "#e3f9d5" : "#e3f9d5",
                                            borderRadius: 6,
                                            alignSelf: "center",
                                            alignItems: "center"
                                        }}
                                    >
                                        <Text
                                            style={[ButtonStyle.profileTitleStyle, {
                                                color: item.parents ? "#009B1A" : "#009B1A",
                                            }]}
                                            onPress={async () => {
                                                await this.setState({
                                                    showdropDown: false,
                                                    selectedItems: this.state.selectedItems.concat([
                                                        { name: "/" + item.name }
                                                    ]),
                                                    TopicSuggestList: [],
                                                    ClikSuggestList: [],
                                                    UserSuggestList: [],
                                                    FeedSuggestList: [],


                                                }),
                                                    // await this.putValue("/" + item.name);
                                                    // this.props.term != ""
                                                    //     ? this.props.setTerm("/" + item.name+" ")
                                                    //     : this.props.setTermWeb("/" + item.name+" ");
                                                    // this.getSuggestion(
                                                    //     this.props.term != ""
                                                    //         ? this.props.term
                                                    //         : this.props.termWeb
                                                    // );
                                                    // this.props.IsCompleted(true)
                                                    // this.props.nameInput.current.focus()
                                                    // this.state.addArray.concat("/" + tem.name+" ")
                                                    // this.props.AddTerm('')
                                                    // this.props.AddTerm(this.props.addTermArray.termArr.concat(this.props.termWeb))
                                                    // this.state.addArray.push("/" + item.name + " ")
                                                    // this.props.TermConcat(this.state.addArray)
                                                    // this.props.setTermWeb(this.props.termArray.termArray.join(' '));
                                                    // this.props.term != ""
                                                    //     ? this.props.setTerm(this.props.termArray.termArray.join(' '))
                                                    //     : this.props.setTermWeb(this.props.termArray.termArray.join(' '));
                                                    this.handlieClick("/" + item.name + " ")


                                            }}
                                        >
                                            /{item.name.toLowerCase()}
                                        </Text>
                                    </View>
                                    {/* <TouchableOpacity
                                        onPress={() =>
                                            this.getTopicStar(item.name) == "FOLLOW"
                                                ? this.favroiteTopics(item.id)
                                                : this.getTopicStar(item.name) == "FAVORITE"
                                                    ? this.unfollowTopics(item.id)
                                                    : this.followTopics(item.id)
                                        }
                                    >
                                        <Image
                                            source={
                                                this.getTopicStar(item.name) == "FOLLOW"
                                                    ? require("../assets/image/gstar.png")
                                                    : this.getTopicStar(item.name) == "FAVORITE"
                                                        ? require("../assets/image/ystar.png")
                                                        : require("../assets/image/wstar.png")
                                            }
                                            style={{
                                                height: 20,
                                                width: 20,
                                                alignSelf: "center"
                                            }}
                                        />
                                    </TouchableOpacity> */}
                                </View>
                            );
                        })}
                        {this.state.ClikSuggestList && this.state.completed == false && this.state.ClikSuggestList.map((item, index) => {
                            return (
                                <View
                                    key={item.name}
                                    style={{
                                        backgroundColor: "#FEFEFA",
                                        width: "100%",
                                        padding: 5,
                                        justifyContent: "space-between",
                                        alignSelf: "flex-start",
                                        flexDirection: "row",
                                        height: 30
                                    }}
                                >
                                    <View
                                        style={{
                                            padding: 5,
                                            backgroundColor: "#E8F5FA",
                                            borderRadius: 6,
                                            alignSelf: "center",
                                            alignItems: "center"
                                        }}
                                    >
                                        <Text
                                            style={[ButtonStyle.profileTitleStyle, {
                                                color: "#4169e1",
                                            }]}
                                            onPress={async () => {
                                                await this.setState({
                                                    selectedItems: this.state.selectedItems.concat([
                                                        { name: "#" + item.name }
                                                    ]),
                                                    TopicSuggestList: [],
                                                    ClikSuggestList: [],
                                                    UserSuggestList: [],
                                                    FeedSuggestList: [],
                                                    showdropDown: false
                                                }),
                                                    // await this.putValue("#" + item.name);
                                                    // this.props.term != ""
                                                    //     ? this.props.setTerm("#" + item.name+" ")
                                                    //     : this.props.setTermWeb("#" + item.name+" ");
                                                    // this.props.setTermWeb("#" + item.name+" ");
                                                    // this.props.IsCompleted(true)
                                                    // this.state.addArray.concat("#" + item.name+" ")
                                                    // this.props.AddTerm(this.state.addArray)
                                                    // this.props.AddTerm('')
                                                    // this.props.AddTerm(this.props.addTermArray.termArr.concat(this.props.termWeb))

                                                    // this.state.addArray.push("#" + item.name + " ")
                                                    // this.props.TermConcat(this.state.addArray)
                                                    // this.props.setTermWeb(this.props.termArray.termArray.join(' '));
                                                    // this.props.term != ""
                                                    //     ? this.props.setTerm(this.props.termArray.termArray.join(' '))
                                                    //     : this.props.setTermWeb(this.props.termArray.termArray.join(' '));
                                                    this.handlieClick("#" + item.name + " ")

                                            }}
                                        >
                                            #{item.name}
                                        </Text>
                                    </View>
                                    {/* <TouchableOpacity
                                        onPress={() =>
                                            this.getClikStar(item.name) == "FOLLOW"
                                                ? this.favroiteCliks(item.id)
                                                : this.getClikStar(item.name) == "FAVORITE"
                                                    ? this.unfollowCliks(item.id)
                                                    : this.followCliks(item.id)
                                        }
                                    >
                                        <Image
                                            source={
                                                this.getClikStar(item.name) == "FOLLOW"
                                                    ? require("../assets/image/gstar.png")
                                                    : this.getClikStar(item.name) == "FAVORITE"
                                                        ? require("../assets/image/ystar.png")
                                                        : require("../assets/image/wstar.png")
                                            }
                                            style={{
                                                height: 20,
                                                width: 20,
                                                alignSelf: "center"
                                            }}
                                        />
                                    </TouchableOpacity> */}
                                </View>
                            );
                        })}

                        {this.state.UserSuggestList && this.state.completed == false && this.state.UserSuggestList.map((item, index) => {
                            return (
                                <View
                                    key={item.username}
                                    style={{
                                        backgroundColor: "#FEFEFA",
                                        width: "100%",
                                        padding: 5,
                                        justifyContent: "space-between",
                                        alignSelf: "flex-start",
                                        flexDirection: "row",
                                        height: 30
                                    }}
                                >
                                    <Text
                                        style={[ButtonStyle.profileTitleStyle, {
                                            color: "#000",
                                        }]}
                                        onPress={async () => {
                                            await this.setState({
                                                showdropDown: false,
                                                selectedItems: this.state.selectedItems.concat([
                                                    { name: "@" + item.username }
                                                ]),
                                                TopicSuggestList: [],
                                                ClikSuggestList: [],
                                                UserSuggestList: [],
                                                FeedSuggestList: [],

                                            }),
                                                // await this.putValue("@" + item.username+" ");
                                                // this.props.term != ""
                                                //     ? this.props.setTerm("@" + item.username+" ")
                                                //     : this.props.setTermWeb("@" + item.username+" ");
                                                // this.props.setTermWeb("@" + item.username+" ");
                                                // this.props.IsCompleted(true)
                                                // this.props.nameInput.current.focus()

                                                this.handlieClick("@" + item.username + " ")
                                            // this.props.TermConcat(this.state.addArray)
                                            // this.props.setTermWeb(this.props.termArray.termArray.join(' '));
                                            // this.props.term != ""
                                            //     ? this.props.setTerm(this.props.termArray.termArray.join(' '))
                                            //     : this.props.setTermWeb(this.props.termArray.termArray.join(' '));




                                        }}
                                    >
                                        @{item.username}
                                    </Text>
                                    {/* <TouchableOpacity
                                        onPress={() =>
                                            this.getUserStar(item.username) == "FOLLOW"
                                                ? this.favroiteUser(item.id)
                                                : this.getUserStar(item.username) == "FAVORITE"
                                                    ? this.unfollowUser(item.id)
                                                    : this.followUser(item.id)
                                        }
                                    >
                                        <Image
                                            source={
                                                this.getUserStar(item.username) == "FOLLOW"
                                                    ? require("../assets/image/gstar.png")
                                                    : this.getUserStar(item.username) == "FAVORITE"
                                                        ? require("../assets/image/ystar.png")
                                                        : require("../assets/image/wstar.png")
                                            }
                                            style={{
                                                height: 20,
                                                width: 20,
                                                alignSelf: "center"
                                            }}
                                        />
                                    </TouchableOpacity> */}
                                </View>
                            );
                        })}

                        {this.state.FeedSuggestList && this.state.completed == false && this.state.FeedSuggestList.map((item, index) => {
                            return (
                                <View
                                    key={item.id}
                                    style={{
                                        backgroundColor: "#FEFEFA",
                                        width: "100%",
                                        padding: 5,
                                        justifyContent: "space-between",
                                        alignSelf: "flex-start",
                                        flexDirection: "row",
                                        height: 30
                                    }}
                                >
                                    <View style={{ flexDirection: "row", alignSelf: "center" }}>
                                        <Image
                                            source={{
                                                uri: item.icon_url
                                                    ? item.icon_url
                                                    : null
                                            }}
                                            style={{
                                                width: 24,
                                                height: 24,
                                                borderRadius: 12
                                            }}
                                        />
                                        <Text
                                            style={[ButtonStyle.profileTitleStyle, {
                                                alignSelf: "center",
                                                textAlign: "center",
                                                color: "#000",
                                                marginLeft: 10
                                            }]}
                                            onPress={async () => {
                                                await this.setState({
                                                    selectedItems: this.state.selectedItems.concat([
                                                        { name: "%" + item.name }
                                                    ]),
                                                    TopicSuggestList: [],
                                                    ClikSuggestList: [],
                                                    UserSuggestList: [],
                                                    FeedSuggestList: [],
                                                    showdropDown: false
                                                }),
                                                    // await this.putValue("%" + item.name);
                                                    //this.props.setTerm({term:"%" + item.name});
                                                    // this.props.term != ""
                                                    //     ? this.props.setTerm("%" + item.name+" ")
                                                    //     : this.props.setTermWeb("%" + item.name+" ");
                                                    // this.props.setTermWeb("%" + item.name+" ");

                                                    // this.getSuggestion(
                                                    //     this.props.term != ""
                                                    //         ? this.props.term
                                                    //         : this.props.termWeb
                                                    // );
                                                    // this.props.IsCompleted(true)
                                                    // this.props.nameInput.current.focus()
                                                    // this.state.addArray.push("%" + item.name+" ")
                                                    // this.props.AddTerm(this.state.addArray)
                                                    // this.props.AddTerm(this.props.addTermArray.termArr.concat(this.props.termWeb))
                                                    // this.state.addArray.push("%" + item.name + " ")
                                                    // this.props.TermConcat(this.state.addArray)
                                                    // this.props.setTermWeb(this.props.termArray.termArray.join(' '));
                                                    // this.props.term != ""
                                                    //     ? this.props.setTerm(this.props.termArray.termArray.join(' '))
                                                    //     : this.props.setTermWeb(this.props.termArray.termArray.join(' '));
                                                    this.handlieClick("%" + item.name + " ")
                                            }}
                                        >
                                            {item.name}
                                        </Text>
                                    </View>
                                </View>
                            )
                        })}
                    </View>
                    :
                    null
                }
            </>

        )
    }
}


const mapStateToProps = (state) => {
    return {
        termArray: state.TermArrayReducer,
        addTermArray: state.TermArrayReducer,
        // focus: state.FocusReducer,
        term: state.ScreenLoadingReducer.setTermWeb,
        termWeb: state.ScreenLoadingReducer.setTermWeb,
        getsearchBarStatus: state.AdminTrueFalseReducer.searchBarOpenStatus,
        getUserFollowTopicList: state.LoginUserDetailsReducer.userFollowTopicsList
            ? state.LoginUserDetailsReducer.userFollowTopicsList
            : [],
        getUserFollowCliksList: state.LoginUserDetailsReducer.userFollowCliksList
            ? state.LoginUserDetailsReducer.userFollowCliksList
            : [],
        getUserFollowUserList: state.LoginUserDetailsReducer.userFollowUserList
            ? state.LoginUserDetailsReducer.userFollowUserList : [],
        getUserFollowFeedList: state.LoginUserDetailsReducer.userFollowFeedList
            ? state.LoginUserDetailsReducer.userFollowFeedList
            : [],
        getDisplayType: state.AdminTrueFalseReducer.setDisplayType,
        isCompleted: state.AdminTrueFalseReducer.isCompleted

    };
};

const mapDispatchToProps = (dispatch) => ({
    setKeyEvent: (payload) => dispatch({ type: "SET_KEY_EVENT_WEB", payload }),
    setDisplayType: (payload) => dispatch(setDisplayType(payload)),
    searchOpenBarStatus: (payload) => dispatch(searchOpenBarStatus(payload)),
    IsCompleted: (payload) => dispatch(IsCompleted(payload)),
    AddTerm: (payload) => dispatch(AddTerm(payload)),
    TermConcat: (payload) => dispatch(TermConcat(payload)),
    setTerm: (payload) => dispatch(setTerm(payload)),
    setTermWeb: (payload) => dispatch(setTermWeb(payload)),


});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    SearchSuggestion
);