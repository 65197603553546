// import "@expo/browser-polyfill";
// import { retry } from "../library/Helper";
import React, { PureComponent } from "react";
import { TouchableOpacity, View, Text, Image, Platform, Dimensions } from "react-native";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import { getTrendingCliksProfileDetails } from "../actionCreator/TrendingCliksProfileAction";
import AppHelper from "../constants/AppHelper";
import { Hoverable } from "react-native-web-hooks";
import { listClikUserRequest } from "../actionCreator/ClikUserRequestAction";
import { clikMemberSuccess } from "../reducers/TrendingCliksProfileReducer";
import { Icon } from "react-native-elements";

import ButtonStyle from "../constants/ButtonStyle";
import FeedImageDisplayClick from "./FeedImageDisplayClick";
import ConstantFontFamily from "../constants/FontFamily";
import ConstantColor from '../constants/Colors';
import { showMoreId, showMoreNestedId, MinimizeId } from '../reducers/ShowCommentReducer'
// import { MinimizeId, MoreIconId, showMoreId, showMoreNestedId } from '../reducers/action/Completed';
import {
  Menu,
  MenuOption,
  MenuOptions,
  MenuTrigger
} from "react-native-popup-menu";
import moment from "moment";
import getEnvVars from "../environment";
import { DeleteContentMutation } from "../graphqlSchema/FeedMutation";
import { GetCommentMutation } from '../graphqlSchema/PostMutation'

// import { getLocalStorage } from "../library/Helper";
import EditCommentCard from "./EditCommentCard";

// import ViewMoreText from 'react-native-view-more-text';
// import { subscriptionAlertOpen } from '../reducers/action/Completed';
import { setFEEDREPORTMODALACTION } from "../actionCreator/FeedReportModalAction";
import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";
import { setPostCommentDetails } from "../actionCreator/PostCommentDetailsAction";
import { setSIGNUPMODALACTION } from "../actionCreator/SignUpModalAction";
import { getCurrentUserProfileDetails } from "../actionCreator/UserProfileDetailsAction";
import appolloClient from "../client";
// import FeedReportModal from "../components/FeedReportModal";
import Colors from "../constants/Colors";
// import CommentSwapper from './CommentSwiper';
import CommonTooltipBottomComment from './CommonTooltipBottomComment';
import CreateCommentCardWeb from "./CreateCommentCardWeb";
import * as Clipboard from 'expo-clipboard';
import { WriteToClipboard } from '../constants/CommonFunction'
// const ChildCommentSwaper = lazy(() =>
//   retry(() => import("./ChildCommentSwaper"))
// );

import ChildCommentSwaper from "./ChildCommentSwaper";

import { setArrowkeys } from '../reducers/AdminTrueFalseReducer'



class PostDetailsComment extends PureComponent {
  state = {
    modalVisible: false,
    setindex: 0,
    activeIndex: 0,
    item: [],
    minimizedComments: [],
    copiedArray: [],
    miniimize: false,
    minimizedArray: [],
    showMore: false,
    maximizeIcon: false,
    delete: false,
    editModalVisible: false,
    editId: "",
    commentId: "",
    swiperIndex: 0,
    minimizeId: ''

  };

  constructor(props) {
    super(props);

    moment.updateLocale("en", {
      relativeTime: {
        future: "in %s",
        past: "%s ago",
        s: "a few seconds",
        ss: "%ds",
        m: "1m",
        mm: "%dm",
        h: "1h",
        hh: "%dh",
        d: "1d",
        dd: "%dd",
        w: "1w",
        ww: "%dw",
        M: "1M",
        MM: "%dM",
        y: "1y",
        yy: "%dy"
      }
    });

    this.swiperRef = React.createRef();
  }




  onSubmit = (position, data) => {
    this.setState({
      modalVisible: false,
      editModalVisible: false
    });
  };




  goToProfile = (id) => {
    this.props.clikId({
      id: id,
      type: "feed",
    });
    this.props.setClikUserRequest({
      id: id,
      currentPage: AppHelper.PAGE_LIMIT,
    });
    this.props.setClikMembers({
      id: id,
    });
  };





  setIndex = (e) => {
    this.setState({
      activeIndex: e,
    });
  };







  componentDidMount = async () => {
    // console.log(this.props.item, 'this.props.item');
    let parentId = []
    // if (this.props.item.length > 0) {
    this.props.setArrowkeys(false)
    await this.setState({ item: this.props.item, copiedArray: this.props.item })

    // }

    if (Platform == "web" && window.location.href.includes("comment")) {
      var url = window.location.href.split('/')
      let id = url[url.length - 1]
      this.setState({ commentId: id })
      // console.log(id);
    }

  }

  // checkItem=(prevState,item)=>{
  //   if(prevState.item.length!=item.length){
  //     this.setState({item:this.props.item})
  //   } else {
  //     if(item)
  //   }
  // }

  componentDidUpdate = (prevState) => {
    // console.log(this.state.item, 'updating');
    // this.setState({ item: this.props.item })
    // this.checkItem(prevState,this.state.item)t
    // console.log(this.props.item, 'this.props.item')
    if (this.props.arrowkeys == false) {
      // console.log(this.props.item, 'Arrow keys false')
      this.setState({ item: this.props.item, copiedArray: this.props.item })

    }

    if (prevState != this.state) {

      // this.setState({ item: this.props.item })
      // if (this.props.item.length > 0 && !this.props.getNotificationId) {
      //   let newArray = Object.assign([], this.props.item);
      //   this.setState({ item: newArray });
      // }
      // if (this.props.item.length > 0 && this.props.getNotificationId) {
      //   let newArray = Object.assign([], this.props.item);
      //   let newEle;
      //   this.props.item.forEach((ele, index) => {
      //     if (ele.node.id == this.props.getNotificationId) {
      //       newEle = ele;
      //       newArray.splice(index, 1);
      //       newArray.splice(0, 0, newEle);
      //     }
      //   });
      //   this.setState({ item: newArray });
      // }
      if (prevState.commentId != this.state.commentId) {
        if (Platform == "web" && window.location.href.includes("comment")) {
          var url = window.location.href.split('/')
          let id = url[url.length - 1]
          this.setState({ commentId: id })
          // console.log(id);
        }
      }

    }

  };
  stopScrolling = (data) => {
    this.props.stopScrolling(data);
  };



  minimizeComments = async (item, type) => {
    console.log(type)
    this.props.setArrowkeys(true)

    if (type == "minimize") {
      this.setState({ miniimize: true })
      let minimizedArray = [...this.state.item]
      minimizedArray.map(e => {
        if (e.node.comment.id == item.node.comment.id) {
          let newObj = {
            ...e, node: {
              ...e.node, minimize: true, comment: {
                ...e.node.comment, comments: {
                  ...e.node.comment.comments, edges: []
                }
              }
            }
          }
          minimizedArray.splice(minimizedArray.indexOf(e), 1, newObj)
        }
      })
      this.setState({ item: [...minimizedArray] })
    } else {
      this.setState({ miniimize: false })
      let minimizedArray = [...this.state.item]
      let revokeArray = []
      this.state.copiedArray.map(i => {
        if (i.node.comment.id == item.node.comment.id) {
          revokeArray = i.node.comment.comments.edges
        }
      })
      console.log(revokeArray, 'revokeArray');
      minimizedArray.map(e => {
        if (e.node.comment.id == item.node.comment.id) {
          let newObj = {
            ...e, node: {
              ...e.node, minimize: false, comment: {
                ...e.node.comment, comments: {
                  ...e.node.comment.comments, edges: [...revokeArray]
                }
              }
            }
          }
          minimizedArray.splice(minimizedArray.indexOf(e), 1, newObj)
        }
      })
      this.setState({ item: [...minimizedArray] })

    }
  }

  onOpen = (id) => {

  }




  handleDeleteMenu = () => {
    let value = false;
    if (this.username == this.UserId || this.props.isAdmin == true) {
      value = true;
    } else {
      value = false;
    }
    return value;
  };


  getCommentList = async (id) => {
    appolloClient
      .query({
        query:
          GetCommentMutation,
        variables: {
          id: id
        },
        fetchPolicy: "no-cache"
      }).then(data => {
        let commentData = JSON.parse(data.data.node.nested_comments)
        let l1 = commentData.edges.reverse();
        this.props.setPostCommentDetails(l1);
        this.setState({ delete: !this.state.delete })
      })

  };

  deleteComment = (commentId) => {
    let id = commentId.replace("Comment:", '')
    appolloClient
      .query({
        query: DeleteContentMutation,
        variables: {
          id: commentId,
        },
        fetchPolicy: "no-cache",
      })
      .then((response) => {
        if (response.data.node_delete.status.success == true) {
          this.getCommentList(this.props.PostId);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };


  handleSharePost = (id) => {
    this.props.setPostShareModel(true)
    this.props.sharePostId(id)
  }

  copyCommentLink = (id,postid) => {
    WriteToClipboard(id,postid[0], 'comment')

  }


  editCommentField = async (id) => {
    // if (this.props.profileData.subscription == "GOLD") {
    await this.setState(
      {
        editModalVisible: !this.state.editModalVisible,
        editId: id

      }

    );


  };


  updateSwiper = (arr, obj) => {

    let nArr = [...arr]

    nArr.forEach((e, ind) => {
      if (e.node.id == obj.node.id) {
        let newob = {
          ...e, swipe: true
        }
        nArr.splice(nArr.indexOf(e), 1, newob)

      } else {
        let newob = {
          ...e, swipe: false
        }
        nArr.splice(nArr.indexOf(e), 1, newob)

      }
    })

    return nArr

  }


  swipe = (obj, arrow, pid) => {
    console.log(obj, 'objjjjj');

    this.props.setArrowkeys(true)

    let newArr = [...this.state.item]

    newArr.forEach((e, ind) => {
      if (e.node.comment.id == pid) {
        let updateNArr = this.updateSwiper(e.node.comment.comments.edges, obj)
        // console.log(updateNArr, 'updateNArr');
        let newObj = {
          ...e, node: {
            ...e.node, comment: {
              ...e.node.comment, comments: {
                ...e.node.comment.comments, edges: [...updateNArr]
              }
            }
          }
        }
        newArr.splice(newArr.indexOf(e), 1, newObj)
      }
    })

    this.setState({ item: [...newArr] })


  }



  render() {
    let commentItem = this.props.item
    let regSpace = /^[a-zA-Z0-9_ ]*$/
    const regex = /(<([^>]+)>)/ig;
    // let commentId = ''
    // if (window.location.href.includes("comment")) {
    //   var url = window.location.href.split('/')
    //   let id = url[url.length - 1]
    //   commentId = id
    // }
    // console.log(id);

    // console.log(this.state.commentId, 'commentidd');

    return (
      <View nativeID="PostDetails" style={{ zIndex: -1 }}
      >
        {
          this.state?.item?.map((item, index) => {
            // console.log(item, 'copieddddddd');
            let id = [item][0]?.node?.comment?.id ? [item][0].node.comment.id : [item][0]?.node?.id
            return (
              <View nativeID={"PostComment" + index} onMouseEnter={() => {
                // this.setState({ maximizeIcon: true })

                this.props.MinimizeId(item.node.comment.id)
                this.props.showMoreNested(item.node.comment.id)
              }}
                onMouseLeave={() => {
                  // this.setState({ maximizeIcon: false })
                  // this.props.MinimizeId('')
                  // this.props.showMoreNested('')
                }}
                key={index}

              >
                <View
                  style={[
                    ButtonStyle.normalFeedStyleForDashboard,
                    {
                      marginBottom: 10,
                      backgroundColor: '#fff',

                      borderColor: '#d3d3d3',
                      borderWidth: 1,
                      borderRadius: 15,
                      width: Dimensions.get('window').width <= 750 ? '95%' : '100%',
                      marginLeft: Dimensions.get('window').width <= 750 ? '2%' : 0
                    },
                  ]}
                >
                  {this.state.editModalVisible == false ? <View style={{ justifyContent: "space-between", alignItems: "center", flexDirection: "row", height: 40 }} onMouseEnter={() => {

                    this.props.showMoreNested(item.node.comment.id)
                  }}
                    onMouseLeave={() => {

                      // this.props.MinimizeId('')

                    }}>
                    {/* {this.props.minimizeId == item.node.comment.id ? <> */}
                    {item.node.minimize == false ? <TouchableOpacity onPress={() => this.minimizeComments(item, "minimize")} style={{ alignSelf: "flex-start", marginLeft: 10 }} >

                      <Icon
                        color={"#000"}
                        name="angle-down"
                        type="font-awesome"
                        size={25}
                      />
                      
                    </TouchableOpacity> : null}
                    {item.node.minimize == true && <TouchableOpacity onPress={() => this.minimizeComments(item, "maximize")} style={{ alignSelf: "flex-start", marginLeft: 10 }}>

                      <Icon
                        color={"#000"}
                        name="angle-up"
                        type="font-awesome"
                        size={25}
                      />
                    </TouchableOpacity>}
                    {/* </> : null} */}
                    <View></View>

                    <View style={{ alignSelf: "flex-end", marginBottom: 10 }}>
                      <Menu>
                        <MenuTrigger>

                          {this.props.nestedId == item.node.comment.id ?
                            <Image
                              source={require("../assets/image/menu.png")}
                              style={{
                                height: 20,
                                width: 20,
                                alignSelf: "flex-end",
                                marginRight: 15,
                                position: 'relative',
                                transform: [{ rotate: "90deg" }]
                              }}
                            />
                            : null}
                        </MenuTrigger>


                        <MenuOptions
                          optionsContainerStyle={{
                            borderRadius: 6,
                            borderWidth: 1,
                            borderColor: "#d3d3d3",
                            shadowColor: "transparent",
                          }}
                          customStyles={{
                            optionsContainer: {
                              width: 150,

                              marginLeft: Dimensions.get('window').width < 1600 ? -25 : -145,

                            },
                          }}
                        >
                          <MenuOption
                            onSelect={async () => {

                              this.props.loginStatus == 1
                                ? await this.handleSharePost(item.node.comment.id)
                                : this.props.setLoginModalStatus(true);
                            }}
                          >
                            <Hoverable>
                              {isHovered => (
                                <Text
                                  style={{
                                    textAlign: "center",
                                    color: isHovered == true ? Colors.blueColor : "#000",
                                    fontFamily: ConstantFontFamily.defaultFont
                                  }}
                                >
                                  Share Comment
                                </Text>
                              )}
                            </Hoverable>
                          </MenuOption>
                          <MenuOption
                            onSelect={async () => {
                              this.props.loginStatus == 1
                                ? this.copyCommentLink(item.node.comment.id, item.node.comment.path.split('/'))
                                : this.props.setLoginModalStatus(true);
                            }}
                          >
                            <Hoverable>
                              {isHovered => (
                                <Text
                                  style={{
                                    textAlign: this.props.margin == 30 ? null : "center",
                                    color: isHovered == true ? Colors.blueColor : "#000",
                                    fontFamily: ConstantFontFamily.defaultFont
                                  }}
                                >
                                  Copy Link
                                </Text>
                              )}
                            </Hoverable>
                          </MenuOption>
                          {

                            <MenuOption
                              onSelect={() => {
                                this.editCommentField(item.node.comment.id);
                              }}
                            >
                              <Hoverable>
                                {(isHovered) => (
                                  <Text
                                    style={{
                                      textAlign: this.props.margin == 30 ? null : "center",
                                      color:
                                        isHovered == true ? Colors.blueColor : "#000",
                                      fontFamily:
                                        ConstantFontFamily.defaultFont,
                                    }}
                                  >
                                    Edit
                                  </Text>
                                )}
                              </Hoverable>
                            </MenuOption>
                          }
                          {this.handleDeleteMenu() == true && (
                            <MenuOption
                              onSelect={() => {
                                this.deleteComment(item.node.comment.id);
                              }}
                            >
                              <Hoverable>
                                {(isHovered) => (
                                  <Text
                                    style={{
                                      textAlign: this.props.margin == 30 ? null : "center",
                                      color: isHovered == true ? Colors.blueColor : "#000",
                                      fontFamily:
                                        ConstantFontFamily.defaultFont,
                                    }}
                                  >
                                    Delete
                                  </Text>
                                )}
                              </Hoverable>
                            </MenuOption>
                          )}
                        </MenuOptions>
                      </Menu>
                    </View>

                  </View> : null}

                  {this.state.editModalVisible == false ? <>    <View style={{
                    width: "100%", flexDirection: 'row', alignItems: 'center', justifyContent: "space-between", borderLeftWidth: 0, borderRightWidth: 0,
                    // borderColor: this.props.HighlightId == [item][0].node?.comment?.id ? ConstantColor.blueColor : "transparent" 
                  }}>



                    {[item][0]?.node?.cliks?.length != 0 ? (
                      <View style={{
                        flexWrap: "wrap",
                        overflow: "hidden",
                        // width: "100%",
                        flexDirection: 'row', alignItems: 'center', justifyContent: "center",
                        marginLeft: 90,
                      }} onMouseEnter={() => {

                        this.props.showMoreNested(item.node.comment.id)
                      }}>
                        <FeedImageDisplayClick item={[item][0]?.node?.cliks} />
                        {[item][0]?.node?.cliks?.map((item, i) => {
                          return (
                            <TouchableOpacity
                              // key={i}
                              style={[ButtonStyle.clikNameBackgroundStyle, {
                                alignSelf: "center",
                                backgroundColor: Colors.clikNamebackgroundColor


                              }]}
                            >

                              <Hoverable>
                                {(isHovered) => (
                                  <TouchableOpacity onPress={() => this.goToProfile(item.name)} style={{
                                    flexDirection: 'row', alignItems: 'center',

                                  }}>

                                    <Text
                                      style={[ButtonStyle.clikNameTitleStyle, {
                                        width: "100%",
                                        color: ConstantColor.blueColor,
                                        fontSize: 17,
                                        textDecorationLine:
                                          isHovered == true ? "underline" : "none",
                                      }]}
                                    >
                                      {" "}
                                      #{item.name}

                                    </Text>



                                  </TouchableOpacity>
                                )}
                              </Hoverable>
                            </TouchableOpacity>
                          )
                        })}

                      </View>

                    ) : null}
                    <View>
                      {item.locked == true ? <Icon
                        size={20}
                        name="lock"
                        type="font-awesome"
                        iconStyle={{ cursor: "pointer" }}
                        color="black"
                        underlayColor="#fff"
                      /> : null
                      }

                    </View>
                  </View></> : null}



                  <TouchableOpacity style={{

                    paddingBottom: 15,
                    borderColor: '#d3d3d3',

                    justifyContent: "center",
                    alignItems: "center"
                  }}
                    onMouseEnter={() => {
                      // this.setState({ maximizeIcon: true })

                      this.props.MinimizeId(item.node.comment.id)
                    }}

                    onMouseLeave={() => {
                      // this.setState({ maximizeIcon: true })

                      // this.props.MinimizeId('')
                    }}


                  >

                    {this.state.editModalVisible == false ? <><Text
                      onMouseEnter={() => {

                        this.props.showMoreNested(item.node.comment.id)
                      }}




                      style={{
                        color: '#000',
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 20,
                        fontWeight: 'bold',
                        justifyContent: "center",
                        textAlign: "left",
                        marginBottom: 20,
                        width: item.node.comment.text.indexOf(' ') >= 0 ? '100%' : '50%',
                        paddingHorizontal: 20,
                        backgroundColor: this.state.commentId == item.node.comment.id.replace("Comment:", "") ? Colors.leftPanelColor : '#fff',
                        fontStyle: item.node.comment.state != "DELETED" ? 'normal' : 'italic'
                      }}>{item.node.comment.state != "DELETED" ? item.node?.comment.text.replace(regex, '') : "This Comment is Deleted"}</Text>

                      <View style={{ width: '100%', paddingHorizontal: 20, marginBottom: 20 }} onMouseEnter={() => {

                        this.props.showMoreNested(item.node.comment.id)
                      }}>

                        <CommonTooltipBottomComment props={this.props}
                          item={item.node.comment}
                          heartCount={0}
                          type={'comment'}
                          openComment={this.onOpen}
                          margin={0}
                          lastcomment={'no'}
                          parentIdd={item}

                        />

                      </View></> : null}


                    {this.props.showId == item.node.comment.id ? <CreateCommentCardWeb
                      onClose={() => { }}
                      parent_content_id={item.node.comment.id}
                      closeModalBySubmit={this.onSubmit}
                      clickList={[item][0]?.node?.cliks?.length > 0 ? [item][0]?.node?.cliks : null}
                      initial="child"
                      topComment={''}
                      navigation={this.props.navigation}
                      outSideClick={null}
                      showMore={'no'}

                    /> : this.props.loginStatus == 1 && this.state.editModalVisible && this.state.editId == item.node.comment.id ?

                      <View style={{ borderRadius: 20, marginBottom: 2, width: "90%" }}>
                        {
                          <EditCommentCard
                            onClose={this.onSubmit}
                            parent_content_id={item.node.comment.id}
                            closeModalBySubmit={this.onSubmit}
                            clickList={[item][0]?.node?.cliks?.length > 0 ? [item][0]?.node?.cliks : null}
                            initial={"main"}
                            topComment={item.node.comment}
                            navigation={this.props.navigation}
                            outSideClick={null}
                            closeCreateComment={() => { }}
                          />
                        }
                      </View>
                      :
                      <>
                        {item?.node?.comment?.comments?.edges?.map((e, i) => {
                          

                          if (e.swipe == true) {
                            return (
                      
                                <ChildCommentSwaper
                                  commentData={item.node.comment.comments?.edges}
                                  commentLength={
                                    item.node?.comment.comments?.edges?.length ? item.node?.comment?.comments?.edges.length
                                      : 0
                                  }
                                  postCommentStatus={''}
                                  key={e.node.id}
                                  currentIndex={this.state.setindex}
                                  closeModalBySubmit={this.onSubmit}
                                  navigation={this.props.navigation}
                                  isFirstComment={true}
                                  gestureStatus={this.props.gestureStatus}
                                  stopScrolling={this.stopScrolling}
                                  left={this.props.left}
                                  top={this.props.top}
                                  clickList={item?.node?.cliks?.length > 0 ? item?.node?.cliks : null}
                                  userList={item?.node?.users?.length > 0 ? item?.node?.users : null}
                                  locked={item.locked}
                                  props={this.props}
                                  minimizeComments={this.minimizeComments}
                                  parentIdd={e}
                                  commentId={this.state.commentId}
                                  margin={true}

                                />
                              
                            )
                          }
                        })}</>}






                  </TouchableOpacity>

                </View>

              </View>
            );
          })
        }


      </View >
    );



  }
}
const mapStateToProps = (state) => ({
  loginStatus: state.UserReducer.loginStatus,
  profileData: state.LoginUserDetailsReducer.userLoginDetails,
  // getNotificationId: state.PostDetailsReducer.get("getNotificationId"),
  // HighlightId: state.commentIdReducer.id,
  // postCommentStatus: state.postCommentStatus.postCommentStatus,
  minimizeId: state.ShowCommentReducer.minimizeId,
  show: state.ShowCommentReducer.show,
  showId: state.ShowCommentReducer.id,
  showmoreId: state.ShowCommentReducer.showMoreId,
  nestedId: state.ShowCommentReducer.nestedId,
  PostId: state.PostCommentDetailsReducer.PostId,
  arrowkeys: state.AdminTrueFalseReducer.arrowkeys


});

const mapDispatchToProps = (dispatch) => ({



  clikId: (payload) => dispatch(getTrendingCliksProfileDetails(payload)),
  setClikUserRequest: (payload) => dispatch(listClikUserRequest(payload)),
  setClikMembers: (payload) => dispatch(clikMemberSuccess(payload)),
  MinimizeId: (payload) => dispatch(MinimizeId(payload)),
  setLoginModalStatus: (payload) => dispatch(setLOGINMODALACTION(payload)),
  userId: (payload) => dispatch(getCurrentUserProfileDetails(payload)),
  setSignUpModalStatus: (payload) => dispatch(setSIGNUPMODALACTION(payload)),
  setFeedReportModalAction: (payload) =>
    dispatch(setFEEDREPORTMODALACTION(payload)),
  sharePostId: (payload) => dispatch({ type: "SHAREDPOSTID", payload }),
  setPostShareModel: (payload) =>
    dispatch({ type: "POSTSHARESTATUS", payload }),
  showMoreId: (payload) => dispatch(showMoreId(payload)),
  showMoreNested: (payload => dispatch(showMoreNestedId(payload))),
  setPostCommentDetails: (payload) => dispatch(setPostCommentDetails(payload)),
  setArrowkeys: (payload) => dispatch(setArrowkeys(payload))


});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PostDetailsComment
);
