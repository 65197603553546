import { createSlice } from '@reduxjs/toolkit';
// import { CLIKS_PROFILE_DETAILS_FAILURE, CLIKS_PROFILE_DETAILS_SUCCESS } from "../constants/Action";




const initialState = {
    openId: "",
};

const openTookitReducer = createSlice({
    name: 'openTookitReducer',
    initialState,
    reducers: {
        openToolkit(state, action) {
            console.log(action,'jkjacttiioon');
            return { ...state, openId: action.payload }
        },
        

    },
})

export const { openToolkit } = openTookitReducer.actions
export default openTookitReducer.reducer


