const CreatePost = [{
    TopicTooltip: 'Topic Tooltip Info Goes Here',
    ClikTooltip: 'Clik Tooltip Info Goes Here'
}];

const CreateClik = [{
    AddMemberTooltip: 'AddMember Tooltip Info Goes Here',
    ClikTooltip: 'Clik Tooltip Info Goes Here',
}];

export default {
    CreatePost,
    CreateClik
};
