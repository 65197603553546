// import { List } from "immutable";
import React, { Profiler, useEffect, useState } from "react";
// import { graphql } from "react-apollo";
import {
  Alert,
  Dimensions,
  ImageBackground,
  Image,
  Platform,
  Text,
  TouchableOpacity,
  View
} from "react-native";
import { Icon } from "react-native-elements";
import { Hoverable } from "react-native-web-hooks";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import { editTopic } from "../actionCreator/TopicEditAction";
import { getTrendingTopicsProfileDetails } from "../actionCreator/TrendingTopicsProfileAction";
import { saveUserLoginDaitails } from "../actionCreator/UserAction";
import applloClient from "../client";
import ConstantFontFamily from "../constants/FontFamily";
import { rootTopics } from "../constants/RootTopics";
import { DeleteTopicMutation } from "../graphqlSchema/FeedMutation";
import {
  TopicFollowMutation,
  TopicUnFollowMutation
} from "../graphqlSchema/FollowandUnFollowMutation";
import { UserLoginMutation, UserFollowingMutation, ExternalFeedFollowingMutation, TopicFollowingMutation, ClikFollowingMutation } from "../graphqlSchema/UserMutation";
import {
  TopicFollowVariables,
  TopicUnFollowVariables
} from "../graphqlSchema/FollowandUnfollowVariables";
import { DeleteTopicVariables } from "../graphqlSchema/LikeContentVariables";
import TopicStar from "../components/TopicStar";
import ButtonStyle from "../constants/ButtonStyle";
import {
  Menu,
  MenuOption,
  MenuOptions,
  MenuTrigger,
} from "react-native-popup-menu";
import Colors from "../constants/Colors";
import ProifileCardStyle from "./Style/ProifileCardStyle";

const TopicProfileCard = props => {
  let [Height, setHeight] = useState(0);

  const topic = props.item;

  const [followBtnActive, setfollowBtnActive] = useState(0);
  let [MenuHover, setMenuHover] = useState(false);
  let banner_pic = topic ? topic.banner_pic : "";

  // async function followTopics(cliksId) {
  //   let itemId = props.navigation.getParam("id", "NO-ID");
  //   if (itemId == "NO-ID") {
  //     props.navigation.navigate("home");
  //     return false;
  //   }
  //   TopicFollowVariables.variables.topic_id = "Topic:" + cliksId;
  //   TopicFollowVariables.variables.follow_type = "FOLLOW";
  //   applloClient
  //     .query({
  //       query: TopicFollowMutation,
  //       ...TopicFollowVariables,
  //       fetchPolicy: "no-cache"
  //     })
  //     .then(async res => {
  //       let resDataLogin = await applloClient
  //         .query({
  //           query: UserLoginMutation,
  //           variables: {
  //             id: "Account:" + "CurrentUser"
  //           },
  //           fetchPolicy: "no-cache"
  //         }).then(res => {
  //           return res
  //         })

  //       let userfollow = await applloClient
  //         .query({
  //           query: UserFollowingMutation,
  //           variables: {
  //             id: "User:CurrentUser",
  //             first: 20
  //           },
  //           fetchPolicy: "no-cache"
  //         }).then(e => {
  //           return e.data.node.users_following.edges
  //         })

  //       let topicfollow = await applloClient
  //         .query({
  //           query: TopicFollowingMutation,
  //           variables: {
  //             id: "User:CurrentUser",
  //             first: 20
  //           },
  //           fetchPolicy: "no-cache"
  //         }).then(e => {
  //           return e.data.node.topics_following.edges
  //         })
  //       let clikfollow = await applloClient
  //         .query({
  //           query: ClikFollowingMutation,
  //           variables: {
  //             id: "User:CurrentUser",
  //             first: 20
  //           },
  //           fetchPolicy: "no-cache"
  //         }).then(e => {
  //           return e.data.node.cliks_following.edges
  //         })
  //       let externalfollow = await applloClient
  //         .query({
  //           query: ExternalFeedFollowingMutation,
  //           variables: {
  //             id: "User:CurrentUser",
  //             first: 20
  //           },
  //           fetchPolicy: "no-cache"
  //         }).then(e => {
  //           return e.data.node.external_feeds_following.edges
  //         })
  //       let userData = {
  //         data: resDataLogin.data.node,
  //         externalfollow: externalfollow,
  //         clikfollow: clikfollow,
  //         topicfollow: topicfollow,
  //         userfollow: userfollow

  //       }
  //       await props.saveLoginUser(userData);
  //       setfollowBtnActive(1);
  //       props.topicId({
  //         id: itemId.replace("%3A", ":"),
  //         type: "feed"
  //       });
  //       props.icon("#E1E1E1");
  //     });
  // }

  // async function favroiteTopics(topicId) {
  //   let itemId = props.navigation.getParam("id", "NO-ID");
  //   if (itemId == "NO-ID") {
  //     props.navigation.navigate("home");
  //     return false;
  //   }
  //   TopicFollowVariables.variables.topic_id = "Topic:" + topicId;
  //   TopicFollowVariables.variables.follow_type = "FAVORITE";
  //   applloClient
  //     .query({
  //       query: TopicFollowMutation,
  //       ...TopicFollowVariables,
  //       fetchPolicy: "no-cache"
  //     })
  //     .then(async res => {
  //       let resDataLogin = await applloClient
  //         .query({
  //           query: UserLoginMutation,
  //           variables: {
  //             id: "Account:" + "CurrentUser"
  //           },
  //           fetchPolicy: "no-cache"
  //         }).then(res => {
  //           return res
  //         })

  //       let userfollow = await applloClient
  //         .query({
  //           query: UserFollowingMutation,
  //           variables: {
  //             id: "User:CurrentUser",
  //             first: 20
  //           },
  //           fetchPolicy: "no-cache"
  //         }).then(e => {
  //           return e.data.node.users_following.edges
  //         })

  //       let topicfollow = await applloClient
  //         .query({
  //           query: TopicFollowingMutation,
  //           variables: {
  //             id: "User:CurrentUser",
  //             first: 20
  //           },
  //           fetchPolicy: "no-cache"
  //         }).then(e => {
  //           return e.data.node.topics_following.edges
  //         })
  //       let clikfollow = await applloClient
  //         .query({
  //           query: ClikFollowingMutation,
  //           variables: {
  //             id: "User:CurrentUser",
  //             first: 20
  //           },
  //           fetchPolicy: "no-cache"
  //         }).then(e => {
  //           return e.data.node.cliks_following.edges
  //         })
  //       let externalfollow = await applloClient
  //         .query({
  //           query: ExternalFeedFollowingMutation,
  //           variables: {
  //             id: "User:CurrentUser",
  //             first: 20
  //           },
  //           fetchPolicy: "no-cache"
  //         }).then(e => {
  //           return e.data.node.external_feeds_following.edges
  //         })
  //       let userData = {
  //         data: resDataLogin.data.node,
  //         externalfollow: externalfollow,
  //         clikfollow: clikfollow,
  //         topicfollow: topicfollow,
  //         userfollow: userfollow

  //       }
  //       await props.saveLoginUser(userData);
  //       setfollowBtnActive(2);
  //       props.topicId({
  //         id: itemId.replace("%3A", ":"),
  //         type: "feed"
  //       });
  //       props.icon("#FADB4A");
  //     });
  // }

  // async function unfollowTopics(cliksId) {
  //   let itemId = props.navigation.getParam("id", "NO-ID");
  //   if (itemId == "NO-ID") {
  //     props.navigation.navigate("home");
  //     return false;
  //   }
  //   TopicUnFollowVariables.variables.topic_id = cliksId;
  //   applloClient
  //     .query({
  //       query: TopicUnFollowMutation,
  //       ...TopicUnFollowVariables,
  //       fetchPolicy: "no-cache"
  //     })
  //     .then(async res => {
  //       let resDataLogin = await applloClient
  //         .query({
  //           query: UserLoginMutation,
  //           variables: {
  //             id: "Account:" + "CurrentUser"
  //           },
  //           fetchPolicy: "no-cache"
  //         }).then(res => {
  //           return res
  //         })

  //       let userfollow = await applloClient
  //         .query({
  //           query: UserFollowingMutation,
  //           variables: {
  //             id: "User:CurrentUser",
  //             first: 20
  //           },
  //           fetchPolicy: "no-cache"
  //         }).then(e => {
  //           return e.data.node.users_following.edges
  //         })

  //       let topicfollow = await applloClient
  //         .query({
  //           query: TopicFollowingMutation,
  //           variables: {
  //             id: "User:CurrentUser",
  //             first: 20
  //           },
  //           fetchPolicy: "no-cache"
  //         }).then(e => {
  //           return e.data.node.topics_following.edges
  //         })
  //       let clikfollow = await applloClient
  //         .query({
  //           query: ClikFollowingMutation,
  //           variables: {
  //             id: "User:CurrentUser",
  //             first: 20
  //           },
  //           fetchPolicy: "no-cache"
  //         }).then(e => {
  //           return e.data.node.cliks_following.edges
  //         })
  //       let externalfollow = await applloClient
  //         .query({
  //           query: ExternalFeedFollowingMutation,
  //           variables: {
  //             id: "User:CurrentUser",
  //             first: 20
  //           },
  //           fetchPolicy: "no-cache"
  //         }).then(e => {
  //           return e.data.node.external_feeds_following.edges
  //         })
  //       let userData = {
  //         data: resDataLogin.data.node,
  //         externalfollow: externalfollow,
  //         clikfollow: clikfollow,
  //         topicfollow: topicfollow,
  //         userfollow: userfollow

  //       }
  //       await props.saveLoginUser(userData);
  //       setfollowBtnActive(0);
  //       props.topicId({
  //         id: itemId.replace("%3A", ":"),
  //         type: "feed"
  //       });
  //       props.icon("#fff");
  //     });
  // }

  useEffect(() => {
    // const itemId = props.navigation.getParam("title", "NO-ID");
    // if (itemId == "NO-ID") {
    //   props.navigation.navigate("home");
    // }

    // const index = props.getUserFollowTopicList.findIndex(
    //   i =>
    //     i.node.topic.name.toLowerCase() == itemId
    //       .replace("%2F", "")
    //       .toLowerCase()
    //       .replace("topic:", "")

    //   //   .getIn(["topic", "name"])
    //   //   .toLowerCase()
    //   //   .replace("topic:", "") ==
    //   // itemId
    //   //   .replace("%2F", "")
    //   //   .toLowerCase()
    //   //   .replace("topic:", "")
    // );
    // if (index != -1) {
    //   if (
    //     props.getUserFollowTopicList[index].node.follow_type == "FAVORITE"

    //   ) {
    //     setfollowBtnActive(2);
    //     props.icon("#FADB4A");
    //   }
    //   if (
    //     props.getUserFollowTopicList[index].node.follow_type == "FOLLOW"

    //   ) {
    //     setfollowBtnActive(1);
    //     props.icon("#E1E1E1");
    //   }
    // } else {
    //   setfollowBtnActive(0);
    //   props.icon("#fff");
    // }
  });

  async function goToProfile(id) {
    props.topicId({
      id: id,
      type: "feed"
    });
  }

  async function showAlert() {
    if (Platform.OS === "web") {
      var result = confirm(
        "Are you sure you want to delete /" +
        topic
          .name
          .replace("%2F", "/")
          .toLowerCase()
      );
      if (result == true) {
        DeleteTopicVariables.variables.topic_id = topic.id;
        applloClient
          .query({
            query: DeleteTopicMutation,
            ...DeleteTopicVariables,
            fetchPolicy: "no-cache"
          })
          .then(async res => {
            let resDataLogin = await applloClient
              .query({
                query: UserLoginMutation,
                variables: {
                  id: "Account:" + "CurrentUser"
                },
                fetchPolicy: "no-cache"
              }).then(res => {
                return res
              })

            let userfollow = await applloClient
              .query({
                query: UserFollowingMutation,
                variables: {
                  id: "User:CurrentUser",
                  first: 20
                },
                fetchPolicy: "no-cache"
              }).then(e => {
                return e.data.node.users_following.edges
              })

            let topicfollow = await applloClient
              .query({
                query: TopicFollowingMutation,
                variables: {
                  id: "User:CurrentUser",
                  first: 20
                },
                fetchPolicy: "no-cache"
              }).then(e => {
                return e.data.node.topics_following.edges
              })
            let clikfollow = await applloClient
              .query({
                query: ClikFollowingMutation,
                variables: {
                  id: "User:CurrentUser",
                  first: 20
                },
                fetchPolicy: "no-cache"
              }).then(e => {
                return e.data.node.cliks_following.edges
              })
            let externalfollow = await applloClient
              .query({
                query: ExternalFeedFollowingMutation,
                variables: {
                  id: "User:CurrentUser",
                  first: 20
                },
                fetchPolicy: "no-cache"
              }).then(e => {
                return e.data.node.external_feeds_following.edges
              })
            let userData = {
              data: resDataLogin.data.node,
              externalfollow: externalfollow,
              clikfollow: clikfollow,
              topicfollow: topicfollow,
              userfollow: userfollow

            }
            await props.saveLoginUser(userData);
          });
      } else {
      }
    } else {
      Alert.alert(
        "Are you sure you want to delete /" +
        topic
          .name
          .replace("%2F", "/")
          .toLowerCase(),
        [
          {
            text: "NO",
            onPress: () => console.warn("NO Pressed"),
            style: "cancel"
          },
          {
            text: "YES",
            onPress: () => {
              DeleteTopicVariables.variables.topic_id = topic.id;
              applloClient
                .query({
                  query: DeleteTopicMutation,
                  ...DeleteTopicVariables,
                  fetchPolicy: "no-cache"
                })
                .then(async res => {
                  let resDataLogin = await applloClient
                    .query({
                      query: UserLoginMutation,
                      variables: {
                        id: "Account:" + "CurrentUser"
                      },
                      fetchPolicy: "no-cache"
                    }).then(res => {
                      return res
                    })

                  let userfollow = await applloClient
                    .query({
                      query: UserFollowingMutation,
                      variables: {
                        id: "User:CurrentUser",
                        first: 20
                      },
                      fetchPolicy: "no-cache"
                    }).then(e => {
                      return e.data.node.users_following.edges
                    })

                  let topicfollow = await applloClient
                    .query({
                      query: TopicFollowingMutation,
                      variables: {
                        id: "User:CurrentUser",
                        first: 20
                      },
                      fetchPolicy: "no-cache"
                    }).then(e => {
                      return e.data.node.topics_following.edges
                    })
                  let clikfollow = await applloClient
                    .query({
                      query: ClikFollowingMutation,
                      variables: {
                        id: "User:CurrentUser",
                        first: 20
                      },
                      fetchPolicy: "no-cache"
                    }).then(e => {
                      return e.data.node.cliks_following.edges
                    })
                  let externalfollow = await applloClient
                    .query({
                      query: ExternalFeedFollowingMutation,
                      variables: {
                        id: "User:CurrentUser",
                        first: 20
                      },
                      fetchPolicy: "no-cache"
                    }).then(e => {
                      return e.data.node.external_feeds_following.edges
                    })
                  let userData = {
                    data: resDataLogin.data.node,
                    externalfollow: externalfollow,
                    clikfollow: clikfollow,
                    topicfollow: topicfollow,
                    userfollow: userfollow

                  }
                  await props.saveLoginUser(userData);
                });
            }
          }
        ]
      );
    }
  }

  return (

    <TouchableOpacity
      style={[ProifileCardStyle.mainCardContainer,
      Dimensions.get('window').width > 750 ? null : ButtonStyle.profileShadowStyle,
      ]} onPress={() => props.openPopUp()}
      disabled={props.loginStatus == 1 && Platform.OS !='web'? false : true}
      >
      <View style={{ flexDirection: 'row', width: '100%', marginVertical: 15 }}>
        {topic && topic.profile_pic != null ?
          <View style={{ marginLeft: 15, }}>
            <Image
              source={{
                uri: topic && topic.profile_pic
              }}
              style={{
                height: Dimensions.get("window").width <= 750 ? 60 : 80,
                width: Dimensions.get("window").width <= 750 ? 60 : 80,
                padding: 0,
                margin: 0,
                borderRadius: 10,
                borderWidth: 1,
                borderColor: "#fff",
              }}
            />

          </View> : null}
        <View style={{
          flexDirection: 'row',
          marginLeft: 15,
          width: (Dimensions.get('window').width <= 750 && topic.profile_pic != null) ? '65%' :
            (Dimensions.get('window').width <= 750 && topic.profile_pic == null) ? '90%' :
              // (Dimensions.get('window').width > 1200 && topic.profile_pic == null) ? '90.65%' :
              (Dimensions.get('window').width > 1200) ? '90%' : '80%',
          alignItems: 'flex-start'
        }}>
          <View style={{ width: Dimensions.get('window').width > 1200 && topic.profile_pic == null ? '93.75%' : '85%' }}>
            <View
              style={[ButtonStyle.clikNameBackgroundStyle, {
                marginTop: 0,
                marginLeft: 0,
                padding: 0,
                paddingVertical: 5,
                // backgroundColor: Colors.topicBackgroundColor,
                alignSelf: 'flex-start'
              }]}
            >
              <Text
                style={[
                  ButtonStyle.clikNameTitleStyle,
                  {
                    color: Colors.topicColor,

                  },
                ]}
              >
                /
                {topic &&
                  topic
                    ?.name
                    ?.replace("%2F", "/")
                    ?.toLowerCase()}{Dimensions.get('window').width}
              </Text>


            </View>
            <View
              style={{
                marginBottom: 10,
                // marginTop: 10,
                flexDirection: Dimensions.get('window').width <= 750 ? 'column' : "row",
                alignItems: 'flex-end',
              }}
            >
              {/* <Hoverable>
                {isHovered => ( */}
              <TouchableOpacity style={{
                flexDirection: 'row', alignSelf: Dimensions.get('window').width <= 750 ? 'flex-start' : 'auto',
                marginBottom: Dimensions.get('window').width <= 750 ? 5 : 0
              }}
                onPress={() => props.showMembers()}>

                <Text
                  style={{
                    color: "grey",
                    fontSize: 12,
                    fontFamily: ConstantFontFamily.defaultFont
                  }}

                >
                  {props.item?.num_subtopics != null ? props.item?.num_subtopics : 0} Subtopics{" / "}
                  {props.item && props.item.num_followers}{" "}
                  Followers
                </Text>
              </TouchableOpacity>

            </View>
            <View
              onLayout={event => {
                let { x, y, width, height } = event.nativeEvent.layout;
                setHeight(height);
              }}
            >
              <Text
                style={{
                  marginBottom: 20,
                  color: "#000",
                  fontSize: 14,
                  fontFamily: ConstantFontFamily.defaultFont,
                  textAlign: 'left',
                }}
              >
                {topic && topic.description}
              </Text>
            </View>
          </View>

          <View style={{ alignItems: "flex-end", width: '15%', flexDirection: 'row', justifyContent: 'flex-end' }}>
            {/* {props.loginStatus == 1 ?
              <TouchableOpacity >
                <Image
                  source={require("../assets/image/menu.png")}
                  style={{
                    height: 16,
                    width: 16,
                    marginTop: 5,
                    marginRight: Dimensions.get('window').width <= 750 ? 10 : 10,
                    transform: [{ rotate: "90deg" }]
                  }}
                />
              </TouchableOpacity>
              : null} */}

            {props.loginStatus == 1 && Dimensions.get('window').width > 750 &&
              <TouchableOpacity
                onMouseEnter={() => setMenuHover(true)}
                onMouseLeave={() => setMenuHover(false)}
              >
                <Menu>
                  <MenuTrigger>
                    <Image
                      source={require("../assets/image/menu.png")}
                      style={{
                        height: 16,
                        width: 16,
                        marginTop: 5,
                        marginRight: 15,
                        transform: [{ rotate: "90deg" }]
                      }}
                    />
                  </MenuTrigger>
                  <MenuOptions
                    optionsContainerStyle={{
                      borderRadius: 6,
                      borderWidth: 1,
                      borderColor: "#e1e1e1",
                      shadowColor: "transparent"
                    }}
                    customStyles={{
                      optionsContainer: {
                        minHeight: 50,
                        width: 150,
                        marginTop: Platform.OS != 'web' ? -20 : 20,
                        // marginLeft: Dimensions.get('window').width < 1350 ? -25 : -140,
                      }
                    }}
                  >


                    <MenuOption>
                      <Hoverable>
                        {isHovered => (
                          <Text
                            style={{
                              textAlign: "center",
                              color: isHovered == true ? Colors.blueColor : "#000",
                              fontFamily: ConstantFontFamily.defaultFont
                            }}
                          >
                            Report
                          </Text>
                        )}
                      </Hoverable>
                    </MenuOption>
                    {props.isAdmin != null &&
                      <MenuOption
                        onSelect={async () => {
                          await props.editTopic({
                            description: topic.description,
                            topicName: topic
                              .get("name")
                              .toLowerCase(),
                            // setBackPic: banner_pic,
                            // changeBackPicEnable: banner_pic
                            //   .split("/")
                            // [banner_pic.split("/").length - 1].substring(
                            //   0,
                            //   banner_pic
                            //     .split("/")
                            //   [banner_pic.split("/").length - 1].indexOf(".")
                            // ),
                            profile_pic: topic.profile_pic,
                            topic_id: topic.id,
                            rtopicName: topic.parents
                              ? [...topic.parents][
                              [...topic.parents].length - 1
                              ]
                              : ""
                          });
                          // props.navigation.navigate("edittopic");
                        }}
                      >
                        <Hoverable>
                          {isHovered => (
                            <Text
                              style={{
                                textAlign: "center",
                                color: isHovered == true ? Colors.blueColor : "#000",
                                fontFamily:
                                  ConstantFontFamily.defaultFont
                              }}
                            >
                              Edit
                            </Text>
                          )}
                        </Hoverable>
                      </MenuOption>
                    }
                    {props.isAdmin &&
                      <MenuOption onSelect={() => showAlert()}>
                        <Hoverable>
                          {isHovered => (
                            <Text
                              style={{
                                textAlign: "center",
                                color: isHovered == true ? Colors.blueColor : "#000",
                                fontFamily:
                                  ConstantFontFamily.defaultFont
                              }}
                            >
                              Delete
                            </Text>
                          )}
                        </Hoverable>
                      </MenuOption>
                    }
                  </MenuOptions>
                </Menu>
              </TouchableOpacity>
            }

            <TopicStar
              TopicName={props.item?.id?.toLowerCase()}
              ImageStyle={{
                height: 20,
                width: 20,
                alignSelf: "center",
                marginLeft: 15
              }}
              updateStar={props.updateStar}
            />
          </View>
        </View>

      </View>
    </TouchableOpacity>
  );
};

const mapStateToProps = state => ({
  loginStatus: state.UserReducer.loginStatus,
  // getUserFollowTopicList: state.LoginUserDetailsReducer.userFollowTopicsList
  //   ? state.LoginUserDetailsReducer.userFollowTopicsList
  //   : [],
  // isAdmin: state.AdminTrueFalseReducer.get("isAdmin"),
  // isAdminView: state.AdminTrueFalseReducer.get("isAdminView"),
  // windowSize: state.windowResizeReducer.windowResize,

});

const mapDispatchToProps = dispatch => ({
  // saveLoginUser: payload => dispatch(saveUserLoginDaitails(payload)),
  // topicId: payload => dispatch(getTrendingTopicsProfileDetails(payload)),
  // editTopic: payload => dispatch(editTopic(payload))
});

// const TopicProfileCardContainerWrapper = graphql(UserLoginMutation, {
//   name: "Login",
//   options: {
//     variables: {
//       id: "Account:" + "CurrentUser"
//     },
//     fetchPolicy: "no-cache"
//   }
// })(TopicProfileCard);

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  TopicProfileCard
);
