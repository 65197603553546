// import "@expo/browser-polyfill";
import _ from "lodash";
import React, { Component } from "react";
import { Image, TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import { compose } from "react-recompose";

import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";

import { saveUserLoginDaitails } from "../reducers/LoginUserDetailsReducer";
import appolloClient from "../client";
import {
  FollowMutation,
  UnFollowMutation,
} from "../graphqlSchema/FollowandUnFollowMutation";
import { UserFollowingMutation } from "../graphqlSchema/UserMutation";
import {
  FollowVariables,
  UnFollowVariables,
} from "../graphqlSchema/FollowandUnfollowVariables";

class UserStar extends Component {
  constructor(props) {
    super(props);
    const gstar = require("../assets/image/gstar.png");
    const ystar = require("../assets/image/ystar.png");
    const wstar = require("../assets/image/wstar.png");

    this.state = {
      index: 0,
      starList: [wstar, gstar, ystar],
      followList: ["TRENDING", "FOLLOW", "FAVORITE"],
    };
  }

  async componentDidMount() {
    await this.getUserStar(this.props.UserName);
  }

  componentDidUpdate = async (prevProps) => {
    if (prevProps.UserName !== this.props.UserName) {
      await this.getUserStar(this.props.UserName);
    }
    if (prevProps.getUserFollowUserList !== this.props.getUserFollowUserList) {
      await this.getUserStar(this.props.UserName);
    }
  };

  getUserStar = async (UserName) => {
    
    if (this.props.loginStatus == 0) {
      await this.setState({
        index: 0,
      });
      return false;
    }
    // this.props.getUserFollowUserList.forEach(e => {
    //   if (e?.node?.user?.username.toLowerCase() == UserName && UserName.toLowerCase()) {
    //     if (e.node.follow_type == "FOLLOW") {
    //       this.setState({ index: 1 })
    //     } else if (e.node.follow_type == "FAVORITE") {
    //       this.setState({ index: 2 })
    //     }
    //   }
    // });

    let index = 0;
    index = this.props.getUserFollowUserList.findIndex(
      (i) => i.node.user.id.toLowerCase() == UserName && UserName.toLowerCase()
    );
    if (index == -1) {
      await this.setState({
        index: 0,
      });

    } else if (
      this.props.getUserFollowUserList[index].node.follow_type == "FOLLOW"
    ) {
      await this.setState({
        index: 1,
      });
    } else {
      await this.setState({
        index: 2,
      });
    }

  };

  changeStar = async () => {
    if (this.props.loginStatus == 0) {
      this.props.setLoginModalStatus(true);
      return false;
    }
    if (this.state.index + 1 === this.state.starList.length) {
      await this.setState({
        index: 0,
      });
      await this.changeStarApi(this.state.followList[this.state.index]);
    } else {
      await this.setState({
        index: this.state.index + 1,
      });
      await this.changeStarApi(this.state.followList[this.state.index]);
    }
  };

  changeStarApi = _.debounce((typeIndex) => {
    typeIndex == "FAVORITE"
      ? this.favroiteUser(this.props.UserId)
      : typeIndex == "TRENDING"
        ? this.unfollowUser(this.props.UserId)
        : this.followUser(this.props.UserId);
  }, 1000);

  followUser = (userId) => {
  
    if (this.props.loginStatus == 0) {
      this.props.setLoginModalStatus(true);
      return false;
    }
    FollowVariables.variables.id = userId.replace("user:", "");
    FollowVariables.variables.follow_type = "FOLLOW";
    appolloClient
      .query({
        query: FollowMutation,
        ...FollowVariables,
        fetchPolicy: "no-cache",
      })
      .then(async (res) => {
        await appolloClient
          .query({
            query: UserFollowingMutation,
            variables: {
              id: "User:CurrentUser",
              first: 50
            },
            fetchPolicy: "no-cache"
          }).then(e => {


            let userData = {
              data: this.props.profileData.data,
              externalfollow: this.props.getUserFollowFeedList,
              clikfollow: this.props.getUserFollowCliksList,
              topicfollow: this.props.getUserFollowTopicList,
              userfollow: e.data.node.users_following.edges

            }
            this.props.saveLoginUser(userData);
            setTimeout(async () => {
              await this.props.updateStar();

            }, 1);

          })
      });
  };

  favroiteUser = (userId) => {
    if (this.props.loginStatus == 0) {
      this.props.setLoginModalStatus(true);
      return false;
    }
    FollowVariables.variables.id =  userId.replace("user:", "");
    FollowVariables.variables.follow_type = "FAVORITE";
    appolloClient
      .query({
        query: FollowMutation,
        ...FollowVariables,
        fetchPolicy: "no-cache",
      })
      .then(async (res) => {
        await appolloClient
          .query({
            query: UserFollowingMutation,
            variables: {
              id: "User:CurrentUser",
              first: 50
            },
            fetchPolicy: "no-cache"
          }).then(e => {


            let userData = {
              data: this.props.profileData,
              externalfollow: this.props.getUserFollowFeedList,
              clikfollow: this.props.getUserFollowCliksList,
              topicfollow: this.props.getUserFollowTopicList,
              userfollow: e.data.node.users_following.edges

            }
            this.props.saveLoginUser(userData);
            setTimeout(async () => {
              await this.props.updateStar();

            }, 1);

          })
      });
  };

  unfollowUser = async (userId) => {
    if (this.props.loginStatus == 0) {
      this.props.setLoginModalStatus(true);
      return false;
    }
    UnFollowVariables.variables.id =  userId.replace("user:", "");
    appolloClient
      .query({
        query: UnFollowMutation,
        ...UnFollowVariables,
        fetchPolicy: "no-cache",
      })
      .then(async (res) => {
        await appolloClient
          .query({
            query: UserFollowingMutation,
            variables: {
              id: "User:CurrentUser",
              first: 50
            },
            fetchPolicy: "no-cache"
          }).then(e => {


            let userData = {
              data: this.props.profileData,
              externalfollow: this.props.getUserFollowFeedList,
              clikfollow: this.props.getUserFollowCliksList,
              topicfollow: this.props.getUserFollowTopicList,
              userfollow: e.data.node.users_following.edges

            }
            this.props.saveLoginUser(userData);
            setTimeout(async () => {
              await this.props.updateStar();

            }, 1);

          })
      });
  };

  render() {
    return (
      <TouchableOpacity
        style={this.props.ContainerStyle}
        onPress={() => this.changeStar()}
      >
        <Image
          source={ this.state.starList[this.state.index]}
          style={this.props.ImageStyle}
        />
      </TouchableOpacity>
    );
  }
}

const mapStateToProps = (state) => ({
  loginStatus: state.UserReducer.loginStatus,
  profileData: state.LoginUserDetailsReducer.userLoginDetails,
  getUserFollowCliksList: state.LoginUserDetailsReducer.userFollowCliksList
    ? state.LoginUserDetailsReducer.userFollowCliksList
    : [],
  getUserFollowFeedList: state.LoginUserDetailsReducer.userFollowFeedList
    ? state.LoginUserDetailsReducer.userFollowFeedList
    : [],
  getUserFollowTopicList: state.LoginUserDetailsReducer.userFollowTopicsList
    ? state.LoginUserDetailsReducer.userFollowTopicsList
    : [],
  getUserFollowUserList: state.LoginUserDetailsReducer.userFollowUserList
    ? state.LoginUserDetailsReducer.userFollowUserList
    : [],

});

const mapDispatchToProps = (dispatch) => ({

  setLoginModalStatus: (payload) => dispatch(setLOGINMODALACTION(payload)),
  saveLoginUser: (payload) => dispatch(saveUserLoginDaitails(payload)),

});



export default compose(connect(mapStateToProps, mapDispatchToProps))(
  UserStar
);
