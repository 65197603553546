import { launchImageLibraryAsync } from "expo-image-picker";
import { askAsync, CAMERA_ROLL } from "expo-permissions";
import React, { lazy, Component, Suspense } from "react";
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  Animated,
  // AsyncStorage,
  Dimensions,
  Image,
  ImageBackground,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  TouchableOpacity,
  View
} from "react-native";
import { Button, Icon, Tooltip } from "react-native-elements";
import RNPickerSelect from "react-native-picker-select";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import { setHASSCROLLEDACTION } from "../actionCreator/HasScrolledAction";
import { getTrendingClicks } from "../actionCreator/TrendingCliksAction";
import { saveUserLoginDaitails } from "../actionCreator/UserAction";
import appolloClient from "../client";
import AppHelper from "../constants/AppHelper";
import ConstantFontFamily from "../constants/FontFamily";
import ConstantTooltip from "../constants/Tooltip";
import { CreateClikMutation } from "../graphqlSchema/PostMutation";
import { SearchUserMutation } from "../graphqlSchema/SearchMutation";
import {

  UserQueryMutation,
  ClikFollowingMutation
} from "../graphqlSchema/UserMutation";
import { CreateClikVariables } from "../graphqlSchema/PostVariables";
import { SearchUserVariables } from "../graphqlSchema/SearchVariables";
import { UserDetailsVariables } from "../graphqlSchema/UserVariables";
// import {
//   uploadProfileImageAsync
// } from "../services/UserService";
import { getTrendingCliksProfileDetails } from "../actionCreator/TrendingCliksProfileAction";
import ButtonStyle from "../constants/ButtonStyle";
import {
  InviteToClik,
  FollowMutation,
} from '../graphqlSchema/FollowandUnFollowMutation';
import {
  FollowVariables,
} from "../graphqlSchema/FollowandUnfollowVariables";
import { subscriptionAlertOpen } from '../reducers/action/Completed';
import { Hoverable } from "react-native-web-hooks";
import Colors from "../constants/Colors";
// import { retry } from "../library/Helper";
// import NavigationService from "../library/NavigationService";

// const HeaderRight = lazy(() => import("../components/HeaderRight"))

// const LeftPanel = lazy(() =>
//   retry(() => import("../components/LeftPanel"))
// );

import LeftPanel from '../components/LeftPanel';
import { Picker } from '@react-native-picker/picker';


class CreateClikScreen extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.inputRefs = {};
    this.Pagescrollview = null;
    this.state = {
      switchOn: false,
      value: 50,
      textStyle: "WHITE",
      uploading: false,
      profilePic: "",
      title: "",
      text: "",
      getMemberDetails: [],
      changeBackPicEnable: null,
      setBackPic: null,
      setIcon: " ",
      showsVerticalScrollIndicatorView: false,
      currentScreentWidth: 0,
      focusnameInput: false,
      focusUsername: false,
      focusdescInput: false,

      RoleItems: [
        {
          label: "Member",
          value: "MEMBER",
          key: 0
        },
        {
          label: "Admin",
          value: "ADMIN",
          key: 1
        },
        {
          label: "Super Admin",
          value: "SUPER_ADMIN",
          key: 2
        }
      ],
      SelectRoleItems: "Member",
      clikName: "",
      description: "",
      MutipleUserList: [],
      showError: false,
      showcliktooltip: false,
      clikHover: false,
      descriptionHover: false,
      joinclikHover: false,
      website: "",
      UserList: [],
      switchValue: false,
      customQualificationArray: [{ text: "" }],
      customAddMemberArray: [
        {
          username: "",
          pic: "",
          showUserModal: false,
          roleName: "Member",
          member_type: "MEMBER"
        }
      ],
      MoreOptions: false,
      changeIconPicEnable: null,
      maxMember: 100,
      exitError: false,
      clikBtnColor: Colors.buttonbackground
    };
  }

  componentDidMount = async () => {
    this.props.searchOpenBarStatus(false);
    // this.props.screen('createClik')
    this._isMounted = true;
  };

  componentDidUpdate() {
    if (this.props.getHasScrollTop == true && this.Pagescrollview) {
      this.Pagescrollview.scrollTo({ x: 0, y: 0, animated: true });
      this.props.setHASSCROLLEDACTION(false);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _askPermission = async (type, failureMessage) => {
    const { status, permissions } = await askAsync(type);
    if (status === "denied") {
      alert(failureMessage);
    }
  };

  pickIcon = async () => {
    await this._askPermission(
      CAMERA_ROLL,
      "We need the camera-roll permission to read pictures from your phone..."
    );
    let pickerResult = await launchImageLibraryAsync({
      allowsEditing: true,
      aspect: [4, 3],
      base64: true
    });
    let size = pickerResult.uri.length * (3 / 4) - 2;
    this._handleIconImagePicked(pickerResult);
  };

  _handleIconImagePicked = async pickerResult => {
    let uploadResponse, uploadResult;
    try {
      this.setState({ uploading: true });
      if (!pickerResult.cancelled) {
        uploadResponse = await uploadProfileImageAsync(pickerResult.uri, true);

        uploadResult = await uploadResponse.json();

        this.setState({
          setIcon: pickerResult.uri
        });
        this.setState({ changeIconPicEnable: uploadResult.id });
      }
    } catch (e) {
      alert("Upload failed, sorry :(" + e + ")");
    } finally {
      this.setState({ uploading: false });
    }
  };

  checkSelectedUser = async (value, index) => {
    UserDetailsVariables.variables.id = "User:" + value;
    try {
      await appolloClient
        .query({
          query: UserQueryMutation,
          ...UserDetailsVariables,
          fetchPolicy: "no-cache"
        })
        .then(async res => {
          // console.log(res, 'resss');
          this.setState({
            MutipleUserList: this.state.MutipleUserList.concat([
              {
                name: res.data.node.username,
                pic: res.data.node.profile_pic,
                role: this.state.SelectRoleItems
              }
            ])
          });

          let inputArray = [...this.state.customAddMemberArray];
          inputArray[this.state.customAddMemberArray.length - 1].username =
            res.data.node.username;
          inputArray[
            this.state.customAddMemberArray.length - 1
          ].showUserModal = false;
          inputArray[this.state.customAddMemberArray.length - 1].pic =
            res.data.node.profile_pic;

          this.setState({
            showError: false,
            SelectRoleItems: "Member",
            title: ""
          });
        });
    } catch (e) {
      console.log(e);
      this.setState({
        showError: true
      });
    }
  };

  createCliks = async () => {
    let __self = this;
    let MemberArray = [];
    for (let i = 0; i < this.state.customAddMemberArray.length; i++) {
      MemberArray.push({
        userid_or_email: this.state.customAddMemberArray[i].username,
        member_type: this.state.customAddMemberArray[i].member_type
      });
    }
    let websites1 = []


    if (this.state.website != "") {
      websites1.push(this.state.website)
    } else {
      websites1 = null
    }
    CreateClikVariables.variables.name = this.state.clikName;
    CreateClikVariables.variables.description = this.state.description;
    CreateClikVariables.variables.profile_pic = this.state.changeIconPicEnable != null ? this.state.changeIconPicEnable : null;
    CreateClikVariables.variables.qualifications =
      this.state.customQualificationArray[0].text != ""
        ? this.state.customQualificationArray.map(value => value.text)
        : null;
    CreateClikVariables.variables.websites = websites1;
    CreateClikVariables.variables.max_members = parseInt(this.state.maxMember)
    CreateClikVariables.variables.invite_only = this.state.switchValue;
    CreateClikVariables.variables.invited_users =
      MemberArray[0].userid_or_email != "" ? MemberArray : null;
    try {
      let id = await AsyncStorage.getItem("UserId");
      await appolloClient
        .query({
          query: CreateClikMutation,
          ...CreateClikVariables,
          fetchPolicy: "no-cache"
        })
        .then(async res => {
          if (res.data.clik_create.status.status == "ALREADY_EXISTS") {
            this.setState({ exitError: true })


          } else {
            this.setState(this.baseState);
            this.setState({ exitError: false })
            let users = []
            this.state.customAddMemberArray.forEach(i => {
              users.push({ user_id: "User:" + i.username, member_type: i.member_type })
            })
            await appolloClient
              .query({
                query: InviteToClik,
                variables: {
                  clik_id: res.data.clik_create.clik.id,
                  invited_users: users
                },
                fetchPolicy: "no-cache"
              }).then(e => {
              })

            FollowVariables.variables.id = "Clik:" + res.data.clik_create.clik.name;
            FollowVariables.variables.follow_type = "FAVORITE";

            appolloClient
              .query({
                query: FollowMutation,
                ...FollowVariables,
                fetchPolicy: "no-cache",
              }).then(e => {
              })

            await appolloClient
              .query({
                query: ClikFollowingMutation,
                variables: {
                  id: "User:CurrentUser",
                  first: 50
                },
                fetchPolicy: "no-cache"
              }).then(e => {


                let userData = {
                  data: this.props.profileData,
                  externalfollow: this.props.getUserFollowFeedList,
                  clikfollow: e.data.node.cliks_following.edges,
                  topicfollow: this.props.getUserFollowTopicList,
                  userfollow: this.props.getUserFollowUserList

                }
                this.props.saveLoginUser(userData);
              })

            // setTimeout(async () => {
            if (this.props.profileData) {
              // await __self.props.getTrendingClicks({
              //   currentPage: AppHelper.PAGE_LIMIT
              // });
              let clikId = res.data?.clik_create?.clik?.name;
              this.props.userId({
                id: clikId,
                type: "feed"
              });
              this.props.navigation.navigate("clikProfile", { id: id, clikId: '' })

              // this.props.setPostCommentReset({
              //   payload: [],
              //   postId: "",
              //   title: "",
              //   loading: true
              // });
            }

            // }, 100);

          }

        });

    } catch (e) {
      console.log(e);
    }
  };

  tooglecliktooltip = () => {
    if (this.state.showcliktooltip == false) {
      this.setState({ showcliktooltip: true });
    } else {
      this.setState({ showcliktooltip: false });
    }
  };

  toogletooltip = () => {
    if (this.state.showtooltip == false) {
      this.setState({ showtooltip: true });
    } else {
      this.setState({ showtooltip: false });
    }
  };

  checkClikname = async name => {
    var letters = /^[0-9a-zA-Z-]+$/;
    if (name.match(letters) || name == "") {
      if (name[0] == "-") {
        alert("- not allowed at initial of clik name");
        return false;
      }
      this.setState({ clikName: name });
      return true;
    } else {
      alert("Please input alphanumeric characters only");
      return false;
    }
  };

  customRenderUserSuggestion = (value, index) => {
    let inputArray = [...this.state.customAddMemberArray];
    inputArray[index].username = value;
    inputArray[index].showUserModal = true;
    this.setState({ customAddMemberArray: inputArray });
    SearchUserVariables.variables.prefix = value;
    appolloClient
      .query({
        query: SearchUserMutation,
        ...SearchUserVariables,
        fetchPolicy: "no-cache"
      })
      .then(res => {
        // console.log(res,'resss');
        this.setState({
          UserList: res.data.search.users,
          getMemberDetails: Object.assign([], inputArray)
        });
      });
  };

  onPress = () => {
    this.setState({ switchOn: !this.state.switchOn });
  };

  addcustomQualificationArray = e => {
    this.setState({
      customQualificationArray: [
        ...this.state.customQualificationArray,
        { text: "" }
      ]
    });
  };

  handleCustomQualification = (value, index) => {
    let inputArray = [...this.state.customQualificationArray];
    inputArray[index].text = value;
    this.setState({ customQualificationArray: inputArray }, () => { });
  };

  removecustomQualificationArray = (index, e) => {
    this.state.customQualificationArray.splice(index, 1);
    this.setState({
      customQualificationArray: this.state.customQualificationArray
    });
  };
  addcustomMember() {
    // console.log('addcostommember');
    this.setState({
      customAddMemberArray: [
        ...this.state.customAddMemberArray,
        {
          username: "",
          pic: "",
          showUserModal: false,
          roleName: "Member",
          member_type: "MEMBER"
        }
      ]
    });
  }
  removeCustomMember = index => {
    this.state.customAddMemberArray.splice(index, 1);
    this.setState({ customAddMemberArray: this.state.customAddMemberArray });
  };
  setRoleType = (value, name, index) => {
    let inputArray = [...this.state.customAddMemberArray];
    inputArray[index].roleName = name;
    inputArray[index].member_type = value;
    this.setState({ customAddMemberArray: inputArray });
  };

  addmemeberBlur = (item, index) => {
    // console.log(this.state.customAddMemberArray,'...this.state.customAddMemberArray');
    let inputArray = [...this.state.customAddMemberArray];
    // console.log(item,'inputArray');
    inputArray[index].showUserModal = false;
    this.setState({ customAddMemberArray: inputArray })
  }

  render() {
    const { setBackPic, setIcon } = this.state;
    const textStyle = styles.usertext;
    return (

      <View
        style={[Dimensions.get('window').width > 750 ? ButtonStyle.threeColStruc : null, {
          width: Dimensions.get('window').width > 750 ? Dimensions.get('window').width : '100%',
          alignItems: Dimensions.get('window').width > 750 ? 'center' : 'normal',
        }]}
      >
        {Dimensions.get('window').width > 750 ?


          <LeftPanel
            // ref={navigatorRef => {
            //   NavigationService.setTopLevelNavigator(navigatorRef);
            // }}
            navigation={this.props.navigation}
          />
          : null}
        <View
          style={{
            // flex: 1,
            backgroundColor: Colors.whiteBackground,
            borderLeftWidth: 1,
            borderColor: Colors.lightGray,
            width: this.props.windowSize.width > 1200 && this.props.windowSize.width < 1600 ? 900 :
              this.props.windowSize.width > 1600 ? 1200 : (this.props.windowSize.width - 310),
          }}
        >
          <ScrollView
            ref={scrollview => {
              this.Pagescrollview = scrollview;
            }}
            showsVerticalScrollIndicator={false}
            onLayout={event => {
              let { x, y, width, height } = event.nativeEvent.layout;
              if (width < 1024) {
                this.setState({
                  showsVerticalScrollIndicatorView: true,
                  currentScreentWidth: width
                });
              } else {
                this.setState({
                  showsVerticalScrollIndicatorView: false,
                  currentScreentWidth: width
                });
              }
            }}
            style={{
              height:
                Platform.OS !== "web"
                  ? null
                  : Dimensions.get("window").height - 80,
            }}
          >
            <View>
              {Dimensions.get("window").width <= 750 && Platform.OS != "web" ? (
                <Animated.View
                  style={{
                    position: Platform.OS == "web" ? "sticky" : null,
                    top: 0,
                    left: 0,
                    right: 0,
                    zIndex: 10,
                    overflow: "hidden"
                  }}
                >
                  <View
                    style={{
                      alignItems: "center",
                      justifyContent: "center"
                    }}
                  >
                    <View
                      style={{
                        width: "100%",
                        flexDirection: "row",
                        backgroundColor: "#000",
                        height: 50
                      }}
                    >
                      <TouchableOpacity
                        style={ButtonStyle.headerBackStyle}
                        onPress={() => {
                          this.props.searchOpenBarStatus(false);
                          let nav = this.props.navigation.dangerouslyGetParent()
                            .state;
                          if (nav.routes.length > 1) {
                            this.props.navigation.goBack();
                            return;
                          } else {
                            this.props.navigation.navigate("home");
                          }
                        }}
                      >
                        <Icon
                          color={"#fff"}
                          name="angle-left"
                          type="font-awesome"
                          size={40}
                        />
                      </TouchableOpacity>
                      {!this.props.getsearchBarStatus &&
                        <TouchableOpacity
                          style={[ButtonStyle.headerTitleStyle, { backgroundColor: "#000" }]}
                        >
                          <Text
                            style={{
                              color: "white",
                              textAlign: "center",
                              fontWeight: "bold",
                              fontSize: 18,
                              fontFamily: ConstantFontFamily.defaultFont
                            }}
                          >
                            Create Clik
                          </Text>
                        </TouchableOpacity>}
                      {/* <View
                      style={ButtonStyle.headerRightStyle}
                    >
                      
                        <HeaderRight navigation={this.props.navigation} />
                      
                    </View> */}
                    </View>
                  </View>
                </Animated.View>
              ) : null}
              <View style={[
                // Dimensions.get("window").width >= 1200 ? ButtonStyle.normalFeedStyleForDashboard : null,
                {
                  paddingHorizontal: Dimensions.get("window").width <= 1200 ? 5 : 10,
                  marginLeft: Dimensions.get("window").width <= 1200 ? 0 : 2,
                  alignItems: 'center',
                  marginTop: Dimensions.get("window").width <= 1200 ? 0 : 15,
                  paddingTop: 10,
                  borderBottomWidth: 0
                }]}>
                <View
                  style={[
                    ButtonStyle.borderStyle, ButtonStyle.textAreaShadowStyle,
                    {
                      width: "100%",
                      borderRadius: 5,
                      backgroundColor: "#fff",
                      paddingVertical: Dimensions.get("window").width <= 750 ? 5 : 10,
                    }
                  ]}
                >
                  <Text
                    numberOfLines={2}
                    style={{
                      fontWeight: "bold",
                      fontSize: 16,
                      textAlign: "center",
                      alignSelf: "center",
                      justifyContent: "center",
                      fontFamily: ConstantFontFamily.defaultFont
                    }}
                  >
                    A clik is a discussion group. Share and discuss posts exclusively among members of your clik.
                  </Text>
                </View>

                <View
                  style={[
                    {
                      backgroundColor: "#fff",
                      marginVertical: 10,
                      width: '100%'
                    }
                  ]}
                >

                  <Text
                    style={{
                      fontWeight: "bold",
                      marginTop: 5,
                      marginRight: 20,
                      fontSize: 16,
                      fontFamily: ConstantFontFamily.defaultFont
                    }}
                  >
                    Profile Icon
                  </Text>
                  <View
                    style={[ButtonStyle.textAreaShadowStyle, {
                      height: 120,
                      backgroundColor: "#fff",
                      borderRadius: 5,
                      width: 120,
                      shadowOpacity: 0.15,
                    }]}
                  >
                    <ImageBackground
                      style={styles.image}
                      imageStyle={{
                        borderRadius: 5,

                      }}
                      source={{
                        uri: setIcon
                      }}

                      resizeMode={"cover"}
                    >
                      <Icon
                        color={"#000"}
                        iconStyle={{
                          color: "#fff",
                          justifyContent: "center",
                          alignItems: "center",

                        }}
                        reverse
                        name="camera"
                        type="font-awesome"
                        size={16}
                        containerStyle={{
                          alignItems: "center",
                          justifyContent: "center",
                          flex: 1,
                          width: 100
                        }}
                        onPress={this.pickIcon}
                      />
                    </ImageBackground>
                  </View>

                  <View style={{ flexDirection: "row", width: "100%", marginTop: 10 }}>
                    <View
                      style={{
                        width: "70%",
                        justifyContent: "flex-start",
                        flexDirection: "row",
                      }}
                    >
                      <Text
                        style={{
                          fontWeight: "bold",
                          marginTop: 10,
                          marginRight: 10,
                          fontSize: 16,
                          fontFamily: ConstantFontFamily.defaultFont
                        }}
                      >
                        Clik Name
                      </Text>
                      {Platform.OS == "web" ? (
                        <View>
                          <Icon
                            color={"#000"}
                            iconStyle={{
                              marginTop: 10,
                              justifyContent: "center",
                              alignItems: "center"
                            }}
                            name="info-circle"
                            type="font-awesome"
                            size={16}
                            onPress={() => this.tooglecliktooltip()}
                          />
                          {this.state.showcliktooltip == true && (
                            <Tooltip
                              withPointer={false}
                              withOverlay={false}
                              toggleOnPress={true}
                              containerStyle={{
                                left: -40,
                                top: -60,
                                width: 100
                              }}
                              popover={
                                <Text
                                  style={{
                                    fontFamily: ConstantFontFamily.defaultFont
                                  }}
                                >
                                  {ConstantTooltip.CreateClik[0].ClikTooltip}
                                </Text>
                              }
                            />
                          )}
                        </View>
                      ) : (
                        <Tooltip
                          withOverlay={false}
                          popover={
                            <Text
                              style={{ fontFamily: ConstantFontFamily.defaultFont }}
                            >
                              {ConstantTooltip.CreateClik[0].ClikTooltip}
                            </Text>
                          }
                        >
                          <Icon
                            color={"#000"}
                            iconStyle={{
                              marginTop: 10,
                              justifyContent: "center",
                              alignItems: "center"
                            }}
                            name="info-circle"
                            type="font-awesome"
                            size={16}
                          />
                        </Tooltip>
                      )}
                    </View>
                    <TouchableOpacity
                      style={{
                        width: "30%",
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        flexDirection: 'row'
                      }}
                      onMouseEnter={() => this.setState({ clikHover: true })}
                      onMouseLeave={() => this.setState({ clikHover: false })}
                    >
                      {(this.state.clikName.length > 1 && this.state.clikName.length < 3) ||
                        this.state.clikName.length > 24 ?
                        (

                          <Text
                            style={{ fontFamily: ConstantFontFamily.defaultFont, color: 'red', marginRight: 5 }}
                          >
                            ClikName should be 3 to 24 Characters
                          </Text>

                        ) : null}
                      {(this.state.clikName.length < 3 ||
                        this.state.clikName.length > 24 ||
                        this.state.clikName[this.state.clikName.length - 1] ==
                        "-") && (
                          <Icon
                            color={"#f80403"}
                            iconStyle={{
                              justifyContent: "center",
                              alignItems: "center"
                            }}
                            name="times"
                            type="font-awesome"
                            size={16}
                          />
                        )}



                    </TouchableOpacity>
                  </View>
                  <View style={{ flexDirection: "row" }}>
                    <TextInput
                      testID="clikName"
                      value={this.state.clikName}
                      placeholder="EnterNameWithOutSpaces"
                      placeholderTextColor="#6D757F"
                      style={[
                        textStyle,
                        this.state.focusnameInput ? ButtonStyle.selecttextAreaShadowStyle : ButtonStyle.textAreaShadowStyle,
                        {
                          height: 45,
                          paddingHorizontal: 5,
                          fontFamily: ConstantFontFamily.defaultFont,
                          backgroundColor: "#fff",
                        }
                      ]}
                      onChangeText={clikName => this.checkClikname(clikName)}
                      onFocus={() => this.setState({ focusnameInput: true })}
                      onBlur={() => this.setState({ focusnameInput: false })}
                    />
                  </View>

                  <View style={{ flexDirection: "row", width: "100%", marginTop: 10, alignItems: 'flex-end' }}>
                    <View
                      style={{
                        width: "70%",
                        justifyContent: "flex-start",
                        flexDirection: "row"
                      }}
                    >
                      <Text
                        style={{
                          fontWeight: "bold",
                          marginRight: 20,
                          fontSize: 16,
                          fontFamily: ConstantFontFamily.defaultFont
                        }}
                      >
                        Description
                      </Text>
                    </View>
                    <TouchableOpacity
                      style={{
                        width: "30%",
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        flexDirection: 'row'
                      }}
                      onMouseEnter={() => this.setState({ descriptionHover: true })}
                      onMouseLeave={() =>
                        this.setState({ descriptionHover: false })
                      }
                    >
                      {((this.state.description.length > 1 && this.state.description.length < 50) ||
                        this.state.description.length > 300) &&
                        <Text
                          style={{ fontFamily: ConstantFontFamily.defaultFont, color: 'red', marginRight: 5 }}
                        >
                          Description should be 50 to 300 Characters
                        </Text>
                      }
                      {(this.state.description.length < 50 ||
                        this.state.description.length > 300) && (
                          <Icon
                            color={"#f80403"}
                            iconStyle={{
                              justifyContent: "center",
                              alignItems: "center"
                            }}
                            name="times"
                            type="font-awesome"
                            size={16}
                          />
                        )}

                    </TouchableOpacity>
                  </View>
                  <TextInput
                    testID="clikDescription"
                    value={this.state.description}
                    multiline={true}
                    numberOfLines={5}
                    placeholder="Enter a description"
                    placeholderTextColor="#6D757F"
                    style={[
                      textStyle,
                      this.state.focusdescInput ? ButtonStyle.selecttextAreaShadowStyle : ButtonStyle.textAreaShadowStyle,
                      {
                        paddingHorizontal: 5,
                        marginVertical: 5,
                        paddingTop: 15,
                        height: Platform.OS == "ios" ? 100 : 45,
                        fontFamily: ConstantFontFamily.defaultFont,
                        backgroundColor: "#fff",
                      }
                    ]}
                    onChangeText={description => this.setState({ description })}
                    onFocus={() => this.setState({ focusdescInput: true })}
                    onBlur={() => this.setState({ focusdescInput: false })}
                  />

                  <View
                    style={{ flexDirection: "row", width: "100%", marginTop: 10 }}
                  >
                    <Text
                      style={{
                        fontWeight: "bold",
                        marginTop: 5,
                        marginRight: 20,
                        fontSize: 16,
                        fontFamily: ConstantFontFamily.defaultFont
                      }}
                    >
                      Invite Only
                    </Text>
                    <View
                      style={{
                        alignItems: "center",
                        flexDirection: "row",
                        alignSelf: "center",
                        justifyContent: "center"
                      }}
                    >
                      <Button
                        title="Yes"
                        buttonStyle={{
                          backgroundColor:
                            this.state.switchValue == true ? "#009B1A" : "#fff",
                          borderColor: "#e1e1e1",
                          borderWidth: 1,
                          height: Dimensions.get('window').width <= 750 ? 35 : 30,
                          borderBottomLeftRadius: 6,
                          borderTopLeftRadius: 6
                        }}
                        titleStyle={{
                          fontFamily: ConstantFontFamily.defaultFont,
                          paddingHorizontal: 10,
                          fontSize: 14,
                          color: this.state.switchValue == true ? "#fff" : "#e1e1e1"
                        }}
                        onPress={() => {
                          if (this.props.profileData.data.subscription == "GOLD") {

                            this.setState({ switchValue: true })

                          } else {
                            this.props.subscriptionAlert(true)

                          }
                        }}
                      />
                      <Button
                        title="No"
                        buttonStyle={{
                          backgroundColor:
                            this.state.switchValue == false ? "#009B1A" : "#fff",
                          borderColor: "#e1e1e1",
                          borderWidth: 1,
                          height: Dimensions.get('window').width <= 750 ? 35 : 30,
                          borderTopRightRadius: 6,
                          borderBottomRightRadius: 6
                        }}
                        titleStyle={{
                          fontFamily: ConstantFontFamily.defaultFont,
                          paddingHorizontal: 10,
                          fontSize: 14,
                          color:
                            this.state.switchValue == false ? "#fff" : "#e1e1e1"
                        }}
                        onPress={() => this.setState({ switchValue: false })}
                      />
                    </View>
                  </View>

                  <View
                    style={{
                      width: "100%",
                      marginTop: 10,
                      flexDirection: "row",
                      justifyContent: "space-between"
                    }}
                  >
                    <View
                      style={{
                        width: "70%",
                        justifyContent: "flex-start",
                        flexDirection: "row",
                        paddingBottom: 5
                      }}
                    >
                      <Text
                        style={{
                          color: "#000",
                          fontFamily: ConstantFontFamily.defaultFont,
                          fontSize: 16,
                          fontWeight: "bold",
                          marginTop: 10
                        }}
                      >
                        Add Members
                      </Text>
                      {Platform.OS == "web" ? (
                        <View>
                          <Icon
                            color={"#000"}
                            iconStyle={{
                              marginTop: 10,
                              justifyContent: "center",
                              alignItems: "center",
                              marginLeft: 10
                            }}
                            name="info-circle"
                            type="font-awesome"
                            size={16}
                            onPress={() => this.toogletooltip()}
                          />
                          {this.state.showtooltip == true && (
                            <Tooltip
                              withPointer={false}
                              withOverlay={false}
                              toggleOnPress={true}
                              containerStyle={{
                                left: -20,
                                width: 100
                              }}
                              popover={
                                <Text
                                  style={{
                                    fontFamily: ConstantFontFamily.defaultFont
                                  }}
                                >
                                  Info here
                                </Text>
                              }
                            />
                          )}
                        </View>
                      ) : (
                        <Tooltip
                          withOverlay={false}
                          popover={
                            <Text
                              style={{ fontFamily: ConstantFontFamily.defaultFont }}
                            >
                              Info here
                            </Text>
                          }
                        >
                          <Icon
                            color={"#000"}
                            iconStyle={{
                              marginTop: 10,
                              justifyContent: "center",
                              alignItems: "center",
                              marginLeft: 20
                            }}
                            name="info-circle"
                            type="font-awesome"
                            size={16}
                          />
                        </Tooltip>
                      )}
                    </View>

                  </View>

                  <View
                    style={{
                      flexDirection: "column"
                    }}
                  >
                    {this.state.customAddMemberArray.map((item, index) => {
                      // console.log(item,'item');
                      return (
                        <View style={{ flexDirection: "column", marginTop: 5 }}>
                          <View
                            style={{
                              flexDirection: "row",
                              width: "100%",
                              justifyContent: Dimensions.get('window').width >= 750 ? "center" : 'space-evenly'
                            }}
                          >
                            <View
                              style={{
                                width: Dimensions.get('window').width >= 750 ? "65%" : '55%',
                                justifyContent: "flex-start",
                                flexDirection: "row"
                              }}
                            >
                              {item.pic == "" || item.pic == null ? (
                                <Image
                                  source={require("../assets/image/default-image.png")}
                                  style={{
                                    width: 40,
                                    height: 40,
                                    padding: 0,
                                    marginVertical: 5,
                                    marginHorizontal: 10,
                                    borderRadius: 20
                                  }}
                                />
                              ) : (
                                <Image
                                  source={{ uri: item.pic }}
                                  style={{
                                    width: 40,
                                    height: 40,
                                    padding: 0,
                                    marginVertical: 5,
                                    marginRight: 10,
                                    borderRadius: 20
                                  }}
                                />
                              )}
                              <TextInput
                                testID="userName7"
                                value={item.username}
                                placeholder="Username or Email"
                                placeholderTextColor="#6D757F"
                                style={[
                                  this.state.focusUsername ? ButtonStyle.selecttextAreaShadowStyle : ButtonStyle.textAreaShadowStyle,
                                  {
                                    color: "#000",
                                    fontSize: 14,
                                    fontWeight: "bold",
                                    fontFamily: ConstantFontFamily.defaultFont
                                  },
                                  {
                                    height: 45,
                                    padding: 5,
                                    // outline: 'none',
                                    backgroundColor: "#fff",
                                  }
                                ]}
                                onChangeText={username => {
                                  this.setState({ username: username }, () => {
                                    this.customRenderUserSuggestion(
                                      this.state.username,
                                      index
                                    );
                                  });
                                }}
                                onFocus={() => this.setState({ focusUsername: true })}
                              // onBlur={() =>
                              //   this.addmemeberBlur(item, index)
                              // }
                              />
                            </View>
                            {/* <View
                              style={[ButtonStyle.shadowStyle, {
                                width: "30%",
                                justifyContent: "flex-end",
                                borderRadius: 5,
                                borderColor: "#e1e1e1",
                                borderWidth: 0,
                                marginLeft: 20,
                                height: 45,
                                marginTop: 5,
                                alignSelf: "center",
                                marginTop: 0,
                                shadowOpacity: 0.15,
                              }]}
                            > */}
                            {/* <RNPickerSelect
                                placeholder={{}}
                                items={this.state.RoleItems}
                                onValueChange={(itemValue, itemIndex) => {
                                  this.setRoleType(
                                    itemValue,
                                    this.state.RoleItems[itemIndex].label,
                                    index
                                  );
                                  this.setState(
                                    {
                                      SelectRoleItems: itemValue
                                    }
                                  );
                                }}
                                onUpArrow={() => {
                                  this.inputRefs.name.focus();
                                }}
                                onDownArrow={() => {
                                  this.inputRefs.picker2.togglePicker();
                                }}
                                style={{ ...styles }}
                                ref={el => {
                                  this.inputRefs.picker = el;
                                }}
                              /> */}
                            <Picker
                              style={[ButtonStyle.dropdownBorder, { marginLeft: 20, width: '30%', backgroundColor: '#fff' }
                              ]}
                              color={'#fff'}
                              // ref={pickerRef}
                              selectedValue={this.state.SelectRoleItems}
                              onValueChange={(itemValue, itemIndex) =>
                                this.setState({ SelectRoleItems: itemValue })
                              }>
                              {this.state.RoleItems.map((item, index) => {
                                return (
                                  <Picker.Item label={item.label} value={item.value} />

                                )
                              })
                              }
                            </Picker>
                            {/* </View> */}
                            <View
                              style={{
                                width: "5%",
                                alignSelf: "center",
                                justifyContent: "flex-end"
                              }}
                            >
                              <Icon
                                color={"#000"}
                                name="trash"
                                type="font-awesome"
                                size={18}
                                iconStyle={{
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginLeft: 20
                                }}
                                onPress={() => this.removeCustomMember()}
                              />
                            </View>
                          </View>
                          {/* ======================== */}

                          {item.showUserModal &&
                            this.state.UserList.map((item, index) => {
                              return (
                                <TouchableOpacity
                                  key={item.username}
                                  style={{
                                    backgroundColor: "#FEFEFA",
                                    width: "100%",
                                    padding: 5,
                                    marginLeft: 30,
                                    flexDirection: "row",
                                    justifyContent: "flex-start",
                                    alignItems: "center"
                                  }}
                                  onPress={() => {
                                    // console.log('hitting')
                                    this.checkSelectedUser(item.username, index)
                                  }
                                  }
                                >
                                  <Image
                                    source={item.profile_pic != null ? { uri: item.profile_pic } : require("../assets/image/default-image.png")}
                                    style={{
                                      width: 30,
                                      height: 30,
                                      padding: 0,
                                      margin: 5,
                                      borderRadius: 20
                                    }}
                                  />
                                  <Text
                                    style={{
                                      color: "#000",
                                      fontFamily: ConstantFontFamily.defaultFont,
                                      fontWeight: "bold"
                                    }}

                                  >
                                    {item.username}
                                  </Text>
                                </TouchableOpacity>
                              );
                            })}
                        </View>
                      );
                    })}
                    <TouchableOpacity
                      style={{
                        alignSelf: 'flex-start',
                        flexDirection: 'row',
                        alignItems: 'center',
                        flexDirection: "row",
                        width: "100%",
                        marginTop: 10
                      }}
                      onPress={() => this.addcustomMember()}
                      onMouseEnter={() => this.setState({ joinclikHover: true })}
                      onMouseLeave={() => this.setState({ joinclikHover: false })}
                    >
                      <Icon
                        name="plus"
                        type="font-awesome"
                        size={30}
                        cursor="pointer"
                        containerStyle={{
                          marginHorizontal: 5,
                          width: 40,
                          alignItems: 'center',
                          cursor: "pointer"
                        }}

                      />
                      <Text style={{
                        color: "grey",
                        fontSize: 14,
                        fontWeight: "bold",
                        fontFamily: ConstantFontFamily.defaultFont
                      }}>Add another member</Text>
                    </TouchableOpacity>
                  </View>

                  {this.state.MoreOptions == false && (
                    <Hoverable>
                      {isHovered => (
                        <TouchableOpacity
                          style={{
                            marginTop: 5
                          }}
                          onPress={() =>
                            this.setState({
                              MoreOptions: true
                            })
                          }
                        >
                          <Text
                            style={{
                              fontWeight: "bold",
                              marginTop: 10,
                              marginRight: 20,
                              fontSize: 14,
                              fontFamily: ConstantFontFamily.defaultFont,
                              textDecorationLine: "underline",
                              fontStyle: "italic",
                              color: isHovered == true ? Colors.blueColor : '#000'
                            }}
                          >
                            Click Here For More Options
                          </Text>
                        </TouchableOpacity>
                      )}
                    </Hoverable>
                  )}

                  {this.state.MoreOptions == true && (
                    <View>
                      <Text
                        style={{
                          fontWeight: "bold",
                          marginTop: 10,
                          marginRight: 20,
                          fontSize: 16,
                          fontFamily: ConstantFontFamily.defaultFont
                        }}
                      >
                        Max Number Of Members
                      </Text>
                      <TextInput
                        placeholder="100"
                        placeholderTextColor="#000"
                        value={this.state.maxMember}
                        style={[
                          textStyle,
                          this.state.focusMaxNum ? ButtonStyle.selecttextAreaShadowStyle : ButtonStyle.textAreaShadowStyle,
                          {
                            height: 45,
                            padding: 5,
                            fontFamily: ConstantFontFamily.defaultFont,
                            backgroundColor: "#fff",
                          }
                        ]}
                        onChangeText={summary => this.setState({ maxMember: summary })}
                        onFocus={() => this.setState({ focusMaxNum: true })}
                        onBlur={() => this.setState({ focusMaxNum: false })}
                      />

                      <Text
                        style={{
                          fontWeight: "bold",
                          marginTop: 10,
                          marginRight: 20,
                          fontSize: 16,
                          fontFamily: ConstantFontFamily.defaultFont
                        }}
                      >
                        Website
                      </Text>
                      <TextInput
                        value={this.state.website}
                        placeholder="Optional"
                        style={[
                          textStyle,
                          this.state.focusWeb ? ButtonStyle.selecttextAreaShadowStyle : ButtonStyle.textAreaShadowStyle,
                          {
                            height: 45,
                            padding: 5,
                            // outline: 'none',
                            backgroundColor: "#fff",
                          }
                        ]}
                        onChangeText={website => this.setState({ website })}
                        onFocus={() => this.setState({ focusWeb: true })}
                        onBlur={() => this.setState({ focusWeb: false })}
                      />

                      <View style={{ flexDirection: "row", width: "100%" }}>
                        <View
                          style={{
                            width: "70%",
                            justifyContent: "flex-start",
                            flexDirection: "row"
                          }}
                        >
                          <Text
                            style={{
                              fontWeight: "bold",
                              marginTop: 10,
                              marginRight: 20,
                              fontSize: 16,
                              fontFamily: ConstantFontFamily.defaultFont
                            }}
                          >
                            Prerequisites to Join Clik
                          </Text>
                        </View>
                        <TouchableOpacity
                          style={{
                            width: "30%",
                            justifyContent: "flex-end",
                            alignItems: "flex-end"
                          }}
                          onMouseEnter={() =>
                            this.setState({ joinclikHover: true })
                          }
                          onMouseLeave={() =>
                            this.setState({ joinclikHover: false })
                          }
                        >
                          <Icon
                            name="plus"
                            type="font-awesome"
                            size={18}
                            cursor="pointer"
                            containerStyle={{
                              marginLeft: 5,
                              justifyContent: "center",
                              alignItems: "center",
                              cursor: "pointer"
                            }}
                            onPress={() => this.addcustomQualificationArray()}
                          />
                        </TouchableOpacity>
                      </View>

                      {this.state.customQualificationArray &&
                        this.state.customQualificationArray.map((item, index) => {
                          return (
                            <View
                              style={{
                                width: "100%",
                                flexDirection: "row",
                                alignItems: "center"
                              }}
                            >
                              <Text style={{ width: "2%" }}>{index + 1})</Text>
                              <TextInput
                                style={[
                                  textStyle,
                                  this.state.focusQualification ? ButtonStyle.selecttextAreaShadowStyle : ButtonStyle.textAreaShadowStyle,
                                  {
                                    height: 45,
                                    padding: 5,
                                    fontFamily: ConstantFontFamily.defaultFont,
                                    backgroundColor: "#fff",
                                  }]}
                                value={item.text}
                                placeholder="Enter a qualification"
                                onChangeText={text =>
                                  this.handleCustomQualification(text, index)
                                }
                                onFocus={() => this.setState({ focusQualification: true })}
                                onBlur={() => this.setState({ focusQualification: false })}
                              />
                              <TouchableOpacity
                                style={{ width: "5%" }}
                                onPress={() =>
                                  this.removecustomQualificationArray(index)
                                }
                              >
                                <Icon
                                  name="trash"
                                  type="font-awesome"
                                  size={18}
                                  cursor="pointer"
                                  containerStyle={{
                                    marginLeft: "auto",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: "pointer"
                                  }}
                                />
                              </TouchableOpacity>
                            </View>
                          );
                        })}
                    </View>
                  )}

                  {this.state.exitError == true ?
                    <View style={{ marginTop: 10 }}>
                      <Text style={{ color: "red", textAlign: "center" }}> clik with name {this.state.clikName} is already exists</Text>
                    </View> : null}

                  <View
                    style={{
                      marginTop: 10,
                      alignSelf: "center"
                    }}
                  >
                    <Button
                      testID="createClik1"
                      title="CREATE CLIK"
                      onMouseEnter={() => this.setState({ clikBtnColor: Colors.mouseEnterColor })}
                      onMouseLeave={() => this.setState({ clikBtnColor: Colors.buttonbackground })}
                      titleStyle={ButtonStyle.allButtonTextStyle}
                      buttonStyle={[ButtonStyle.allButtonbackgroundStyle, {
                        backgroundColor: this.state.clikBtnColor,
                        borderColor: this.state.clikName.length < 3 ||
                          this.state.clikName.length > 24 ||
                          this.state.clikName[this.state.clikName.length - 1] ==
                          "-" ||
                          this.state.description.length < 50 ||
                          this.state.description.length > 300 ? '#d3d3d3' : Colors.blueColor
                      }]}
                      containerStyle={ButtonStyle.containerStyle}
                      disabled={
                        this.state.clikName.length < 3 ||
                          this.state.clikName.length > 24 ||
                          this.state.clikName[this.state.clikName.length - 1] ==
                          "-" ||
                          this.state.description.length < 50 ||
                          this.state.description.length > 300
                          ? true
                          : false
                      }
                      onPress={() => this.createCliks()}
                    />
                  </View>

                </View>
              </View>
            </View>
          </ScrollView>
        </View>
      </View>
    );
  }
}

const mapStateToProps = state => ({
  profileData: state.LoginUserDetailsReducer.userLoginDetails,
  // getHasScrollTop: state.HasScrolledReducer.get("hasScrollTop"),
  // getsearchBarStatus: state.AdminReducer.get("searchBarOpenStatus"),
  // getUserFollowFeedList: state.LoginUserDetailsReducer.userFollowFeedList
  //   ? state.LoginUserDetailsReducer.userFollowFeedList
  //   : [],
  // getUserFollowTopicList: state.LoginUserDetailsReducer.userFollowTopicsList
  //   ? state.LoginUserDetailsReducer.userFollowTopicsList
  //   : [],
  // getUserFollowUserList: state.LoginUserDetailsReducer.userFollowUserList
  //   ? state.LoginUserDetailsReducer.userFollowUserList
  //   : [],
  windowSize: state.windowResizeReducer.windowResize,

});



const mapDispatchToProps = dispatch => ({
  getTrendingClicks: payload => dispatch(getTrendingClicks(payload)),
  // setHASSCROLLEDACTION: payload => dispatch(setHASSCROLLEDACTION(payload)),
  saveLoginUser: payload => dispatch(saveUserLoginDaitails(payload)),
  userId: payload => dispatch(getTrendingCliksProfileDetails(payload)),
  // setPostCommentReset: payload =>
  //   dispatch({ type: "POSTCOMMENTDETAILS_RESET", payload }),
  searchOpenBarStatus: payload => dispatch({ type: "SEARCHBAR_STATUS", payload }),
  subscriptionAlert: (payload) => dispatch(subscriptionAlertOpen(payload)),

  // subscriptionAlert: (payload) => dispatch(subscriptionAlertOpen(payload)),
  // screen: (payload) => dispatch(screen(payload))

});


export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CreateClikScreen
);

const styles = StyleSheet.create({
  image: {
    width: "100%",
    height: "100%"
  },
  usertext: {
    color: "#000",
    fontSize: 14,
    fontWeight: "bold",
    fontFamily: ConstantFontFamily.defaultFont,
    outline: 'none',
    borderWidth: 0,
    borderColor: "#fff",
  },
  inputIOS: {
    paddingTop: 13,
    paddingHorizontal: 10,
    paddingBottom: 12,
    borderWidth: 0,
    // borderColor: "#d7d7d7",
    // borderRadius: 5,
    backgroundColor: "white",
    color: "black",
    fontSize: 15,
    fontWeight: "bold",
    fontFamily: ConstantFontFamily.defaultFont,
    outline: 'none'
  },
  inputAndroid: {
    paddingHorizontal: 10,
    paddingVertical: 8,
    borderWidth: 0,
    borderColor: "#fff",
    // borderRadius: 0,
    // color: "#000",
    backgroundColor: "white",
    paddingRight: 30,
    fontSize: 15,
    fontWeight: "bold",
    height: 40,
    fontFamily: ConstantFontFamily.defaultFont,
    outline: 'none'
  }



});