import React, { Component, lazy, Suspense, createRef } from "react";
import {
  Animated,
  Dimensions,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from "react-native";
import { heightPercentageToDP as hp } from "react-native-responsive-screen";
import { Icon } from "react-native-elements";
// import { TabBar, TabView } from "react-native-tab-view";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";
import { getCurrentUserProfileDetails } from "../actionCreator/UserProfileDetailsAction";
import ConstantFontFamily from "../constants/FontFamily";
import ShadowSkeleton from "../components/ShadowSkeleton";
import ButtonStyle from "../constants/ButtonStyle";
// import NavigationService from "../library/NavigationService";
// import ShadowSkeletonForProfile from "../components/ShadowSkeletonForProfile";
import Colors from "../constants/Colors";
// import { feedFluctuation, showDiscussion, screen } from '../reducers/action/Completed';
import { setPostCommentDetails } from "../actionCreator/PostCommentDetailsAction";
import { screen } from "../reducers/ScreenNameReducer";
// import CreateCommentCard from "../components/CreateCommentCard";
import Overlay from "react-native-modal-overlay";
import { Hoverable } from "react-native-web-hooks";
import ConstantColors from "../constants/Colors";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import { saveUserLoginDaitails, setLoginStatus } from "../reducers/UserReducer";
import {
  setAdminStatus,
  showReportUserModal,
} from "../reducers/AdminTrueFalseReducer";
import HeaderRightDashboard from "../components/HeaderRightDashboard";
// import { retry } from "../library/Helper";
import Header from "../components/Header";

// const UserProfileCard = lazy(() => import("../components/UserProfileCard"))

// const TrendingHomeFeed = lazy(() => import("../components/TrendingHomeFeed"))

// const DiscussionHomeFeed = lazy(() => import("../components/DiscussionHomeFeed"))

// const NewHomeFeed = lazy(() => import("../components/NewHomeFeed"))

// const SEOMetaData = lazy(() => import("../components/SEOMetaData"))

// const HeaderRight = lazy(() => import("../components/HeaderRight"))

// const CommentDetailScreen = lazy(() => import("./CommentDetailScreen"))

// const BottomScreen = lazy(() => import("../components/BottomScreen"))

// const LeftPanel = lazy(() =>
//   retry(() => import("../components/LeftPanel"))
// );

import UserProfileCard from "../components/UserProfileCard";

import TrendingHomeFeed from "../components/TrendingHomeFeed";

import DiscussionHomeFeed from "../components/DiscussionHomeFeed";

import NewHomeFeed from "../components/NewHomeFeed";

// import SEOMetaData from "../components/SEOMetaData";

import CommentDetailScreen from "./CommentDetailScreen";

import LeftPanel from "../components/LeftPanel";
import FeedCommentStyle from "./Style/FeedCommentStyle";
import { showDiscussionReducer } from "../reducers/AdminTrueFalseReducer";

let lastTap = null;
class ProfileScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tabPost: false,
      id: "",
      showIcon: "#fff",
      routes: [
        { key: "first", title: "New", icon: "clock-o", type: "font-awesome" },
        {
          key: "second",
          title: "Trending",
          icon: "fire",
          type: "simple-line-icon",
        },
        {
          key: "third",
          title: "Bookmarks",
          icon: "bookmark",
          type: "font-awesome",
        },
      ],
      index: 1,
      ViewMode: "Default",
      scrollY: 0,
      ProfileHeight: 0,
      feedY: 0,
      opentool: false,
      showEditProfile: false,
      focused: "Trending",
      reportUserModal: false,
    };
    this.flatListRefNew = createRef();
    this.flatListRefDiscussion = createRef();
    this.flatListRefTrending = createRef();
    this.UserProfilescrollview = createRef();
  }

  showIcon = (data) => {
    this.setState({
      showIcon: data,
    });
  };

  setOpen = () => {
    this.setState({ opentool: true });
  };

  onClose = () => {
    this.setState({ opentool: false });
  };
  openPopUp = () => {
    this.setState({ opentool: true });
  };
  async componentDidMount() {
    // this.props.showDiscussion(false)
    // await this.props.searchOpenBarStatus(false);
    // const { navigation } = this.props;
    // const itemId = navigation.getParam("username", "NO-ID");
    // console.log(itemId, 'iii');
    // this.setState({
    //   id: itemId,
    // });
    // if (itemId) {
    //   this.props.userId({
    //     username: itemId,
    //     type: this.props.navigation.getParam("type", "NO-ID"),
    //   });
    // }
    // this.props.screen("profileScreen")
  }

  componentDidUpdate(prevProps) {
    // if (
    //   prevProps.navigation.getParam("username", "NO-ID").toUpperCase() !=
    //   this.props.navigation.getParam("username", "NO-ID").toUpperCase()
    // ) {
    //   this.setState({
    //     scrollY: 0,
    //   });
    //   this.UserProfilescrollview.scrollTo({ x: 0, y: 0, animated: true });
    // }
  }
  doScroll = (value, name) => {
    if (name == "new") {
      this.flatListRefNew = value;
    } else if (name == "trending") {
      this.flatListRefTrending = value;
    } else if (name == "discussion") {
      this.flatListRefDiscussion = value;
    }
  };

  scrollFunc = () => {
    {
      this.flatListRefNew.current &&
        this.flatListRefNew.current.scrollToOffset({
          x: 0,
          y: 0,
          animated: true,
        }),
        this.flatListRefTrending.current &&
          this.flatListRefTrending.current.scrollToOffset({
            x: 0,
            y: 0,
            animated: true,
          }),
        this.flatListRefDiscussion.current &&
          this.flatListRefDiscussion.current.scrollToOffset({
            x: 0,
            y: 0,
            animated: true,
          });
    }
  };

  changeTabStatus = () => {
    this.setState({ tabPost: false });
  };

  logout = async () => {
    AsyncStorage.removeItem("userIdTokenFirebase");
    AsyncStorage.getAllKeys()
      .then((keys) => AsyncStorage.multiRemove(keys))
      .then(() => console.log("success"));
    const auth = getAuth();
    await signOut(auth);

    await this.props.changeLoginStatus(0);
    await this.props.changeAdminStatus(false);
    // if (
    //   (await props.navigation.getCurrentRoute().routeName) ==
    //   "notification" ||
    //   (await props.navigation.getCurrentRoute().routeName) == "settings"
    // ) {
    await this.props.navigation.navigate("home");
    // }
    await this.props.resetLoginUserDetails();
    await this.props.resetUserProfileDetails();

    // var req = indexedDB.deleteDatabase("firebaseLocalStorageDb");
  };

  listScroll = (value) => {
    this.setState({
      feedY: value,
    });
  };

  onLoadingComment = () => {
    this.setState({ commentDelay: true });
    setTimeout(() => {
      if (this.props.PostCommentDetails) {
        this.setState({ commentDelay: false });
      }
    }, 500);
  };
  _renderScene = () => {
    switch (this.state.focused) {
      case "Discussion":
        return (
          <View>
            {this.props.loginStatus == 1 ? (
              <ScrollView
                style={
                  {
                    // height: hp("100%") - 50,
                    // paddingBottom: 300,
                    // borderWidth: 3,
                    // borderColor: '#000',
                    // borderBottomWidth: 0,
                    // borderTopWidth: 0
                  }
                }
                showsVerticalScrollIndicator={false}
                scrollEnabled={this.state.scrollY >= 110 ? true : false}
              >
                <DiscussionHomeFeed
                  navigation={this.props.navigation}
                  listType={"User"}
                  data={this.props.userDetails}
                  ViewMode={this.state.ViewMode}
                  changeTabStatus={this.changeTabStatus}
                  tabPost={this.state.tabPost}
                  listScroll={this.listScroll}
                  ActiveTab={this.state.routes[this.state.index].title}
                  doScroll={this.doScroll}
                  onLoadingComment={this.onLoadingComment}
                />
              </ScrollView>
            ) : (
              <View>
                <View
                  style={{
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Icon
                    color={"#000"}
                    iconStyle={{
                      color: "#fff",
                      justifyContent: "center",
                      alignItems: "center",
                      alignSelf: "center",
                    }}
                    reverse
                    name="sticky-note"
                    type="font-awesome"
                    size={20}
                    containerStyle={{
                      alignSelf: "center",
                    }}
                  />
                  <Text
                    style={{
                      fontSize: 12,
                      fontFamily: ConstantFontFamily.defaultFont,
                      color: "#000",
                      alignSelf: "center",
                    }}
                  >
                    <Text
                      onPress={() => this.loginHandle()}
                      style={{
                        textDecorationLine: "underline",
                        fontFamily: ConstantFontFamily.defaultFont,
                      }}
                    >
                      Login
                    </Text>{" "}
                    to see bookmarked posts
                  </Text>
                </View>
              </View>
            )}
          </View>
        );
      case "New":
        return (
          <ScrollView
            style={
              {
                // borderWidth: 3,
                // borderColor: '#000',
                // borderBottomWidth: 0,
                // borderTopWidth: 0
              }
            }
            showsVerticalScrollIndicator={false}
            scrollEnabled={this.state.scrollY >= 110 ? true : false}
          >
            <NewHomeFeed
              navigation={this.props.navigation}
              listType={"User"}
              data={this.props.userDetails.id}
              ViewMode={this.state.ViewMode}
              listScroll={this.listScroll}
              changeTabStatus={this.changeTabStatus}
              tabPost={this.state.tabPost}
              ActiveTab={this.state.routes[this.state.index].title}
              doScroll={this.doScroll}
              onLoadingComment={this.onLoadingComment}
            />
          </ScrollView>
        );
      case "Trending":
        return (
          <ScrollView
            style={
              {
                // height: hp("100%") - 50,
                // paddingBottom: 300,
                // borderWidth: 3,
                // borderColor: '#000',
                // borderBottomWidth: 0,
                // borderTopWidth: 0
              }
            }
            showsVerticalScrollIndicator={false}
            scrollEnabled={this.state.scrollY >= 110 ? true : false}
          >
            <TrendingHomeFeed
              navigation={this.props.navigation}
              listType={"User"}
              data={this.props.userDetails.id}
              ViewMode={this.state.ViewMode}
              listScroll={this.listScroll}
              changeTabStatus={this.changeTabStatus}
              tabPost={this.state.tabPost}
              ActiveTab={this.state.routes[this.state.index].title}
              doScroll={this.doScroll}
              onLoadingComment={this.onLoadingComment}
            />
          </ScrollView>
        );
      default:
        return (
          <ScrollView
            style={
              {
                // borderWidth: 3,
                // borderColor: '#000',
                // borderBottomWidth: 0,
                // borderTopWidth: 0
              }
            }
            showsVerticalScrollIndicator={false}
            scrollEnabled={this.state.scrollY >= 110 ? true : false}
          >
            <NewHomeFeed
              navigation={this.props.navigation}
              listType={"User"}
              data={this.props.userDetails.id}
              ViewMode={this.state.ViewMode}
              listScroll={this.listScroll}
              changeTabStatus={this.changeTabStatus}
              tabPost={this.state.tabPost}
              ActiveTab={this.state.routes[this.state.index].title}
              doScroll={this.doScroll}
              onLoadingComment={this.onLoadingComment}
            />
          </ScrollView>
        );
    }
  };

  loginHandle = () => {
    this.props.loginModalStatus(true);
  };

  renderTabViewForMobile = () => {
    if (this.props.getTabView == "Bookmarks") {
      return (
        <View>
          {this.props.loginStatus == 1 ? (
            <DiscussionHomeFeed
              navigation={this.props.navigation}
              listType={"User"}
              data={this.props.navigation.getParam("username", "NO-ID")}
              ViewMode={this.state.ViewMode}
              listScroll={this.listScroll}
              ActiveTab={this.state.routes[this.state.index].title}
              doScroll={this.doScroll}
            />
          ) : (
            <View>
              <View
                style={{
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Icon
                  color={"#000"}
                  iconStyle={{
                    color: "#fff",
                    justifyContent: "center",
                    alignItems: "center",
                    alignSelf: "center",
                  }}
                  reverse
                  name="sticky-note"
                  type="font-awesome"
                  size={20}
                  containerStyle={{
                    alignSelf: "center",
                  }}
                />
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: ConstantFontFamily.defaultFont,
                    color: "#000",
                    alignSelf: "center",
                  }}
                >
                  <Text
                    onPress={() => this.loginHandle()}
                    style={{
                      textDecorationLine: "underline",
                      fontFamily: ConstantFontFamily.defaultFont,
                    }}
                  >
                    Login
                  </Text>{" "}
                  to see bookmarked posts
                </Text>
              </View>
            </View>
          )}
        </View>
      );
    } else if (this.props.getTabView == "New") {
      return (
        <NewHomeFeed
          navigation={this.props.navigation}
          listType={"User"}
          data={this.props.userDetails.id}
          ViewMode={this.state.ViewMode}
          listScroll={this.listScroll}
          ActiveTab={this.state.routes[this.state.index].title}
          doScroll={this.doScroll}
        />
      );
    } else if (this.props.getTabView == "Trending") {
      return (
        // <View >
        //   <TrendingHomeFeed
        //     navigation={this.props.navigation}
        //     listType={"User"}
        //     data={this.props.userDetails.id}
        //     ViewMode={this.state.ViewMode}
        //     listScroll={this.listScroll}
        //     // ActiveTab={this.state.routes[this.state.index].title}
        //     doScroll={this.doScroll}
        //   />
        // </View>
        <View>
          <TrendingHomeFeed
            navigation={this.props.navigation}
            listType={"User"}
            data={this.props.userDetails.id}
            ViewMode={this.state.ViewMode}
            // setPrevList={this.setPrevList}
            prevFeedList={this.state.prevFeedList}
            ActiveTab={""}
            // this.state.routes[this.state.index].title}
            doScroll={this.doScroll}
          />
        </View>
      );
    } else {
      return (
        <View style={{ flex: 1 }}>
          <TrendingHomeFeed
            navigation={this.props.navigation}
            listType={"User"}
            data={this.props.userDetails.id}
            ViewMode={this.state.ViewMode}
            listScroll={this.listScroll}
            ActiveTab={this.state.routes[this.state.index].title}
            doScroll={this.doScroll}
          />
        </View>
      );
    }
  };

  closeEditProfile = () => {
    this.setState({ showEditProfile: false });
  };
  setUpdateData = () => {
    this.setState({ showEditProfile: true });
  };

  reportUser = () => {
    this.props.showReportUserModal(true);
  };
  render() {
    // console.log(this.props.screenName, 'screenNamescreenName');

    return (
      <View style={styles.container}>
        {/* {Platform.OS == "web" ? (
          

            <SEOMetaData
              title={
                this.props.userDetails.getIn(["node", "full_name"])
                  ? this.props.userDetails.getIn(["node", "full_name"])
                  : ""
              }
              description={
                this.props.userDetails.getIn(["node", "description"])
                  ? this.props.userDetails.getIn(["node", "description"])
                  : ""
              }
              image={
                this.props.userDetails.getIn(["node", "profile_pic"])
                  ? this.props.userDetails.getIn(["node", "profile_pic"])
                  : ""
              }
            />
          
        ) : null} */}

        {/* {Dimensions.get('window').width <= 750 &&
          <Header
            title={"@" +
            this.props.userDetails.username}
            navigation={this.props.navigation}
          // leftPanelModalFunc={this.props.leftPanelModalFunc}
          />
        } */}
        {Dimensions.get("window").width < 750 &&
        this.props.screenName == "profileScreen" &&
        Platform.OS != "web" ? (
          <Animated.View
            style={{
              position: Platform.OS == "web" ? "sticky" : null,
              top: 0,
              left: 0,
              right: 0,
              zIndex: 10,
              overflow: "hidden",
              borderRadius: Dimensions.get("window").width <= 750 ? 0 : 6,
            }}
          >
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <View
                style={{
                  width: "100%",
                  flexDirection: "row",
                  marginBottom: Dimensions.get("window").width <= 750 ? 0 : 10,
                  backgroundColor: "#000",
                  borderRadius: Dimensions.get("window").width <= 750 ? 0 : 6,
                  height: 50,
                }}
              >
                <TouchableOpacity
                  style={[
                    ButtonStyle.headerBackStyle,
                    { marginRight: !this.props.getsearchBarStatus ? 10 : 0 },
                  ]}
                  onPress={() => {
                    // this.props.searchOpenBarStatus(false);
                    // let nav =
                    //   this.props.navigation.dangerouslyGetParent().state;
                    // if (nav.routes.length > 1) {
                    this.props.screen("home");
                    this.props.navigation.goBack();
                    //   return;
                    // } else {
                    // this.props.navigation.navigate("home");
                    // }
                  }}
                >
                  <Icon
                    color={"#fff"}
                    name="angle-left"
                    type="font-awesome"
                    size={40}
                  />
                </TouchableOpacity>
                {!this.props.getsearchBarStatus && (
                  <TouchableOpacity
                    style={{
                      flex: this.props.loginStatus
                        ? 1
                        : !this.props.loginStatus && Platform.OS == "web"
                        ? 1
                        : !this.props.loginStatus && Platform.OS != "web" && 0,
                      justifyContent: "center",
                    }}
                  >
                    <View
                      style={{
                        alignSelf: "flex-start",
                        paddingVertical: 5,
                        borderRadius: 6,
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Text
                        style={{
                          textAlign: "center",
                          alignSelf: "center",
                          color: "#fff",
                          fontWeight: "bold",
                          fontSize: 18,
                          fontFamily: ConstantFontFamily.defaultFont,
                        }}
                      >
                        {"@" + this.props.userDetails.username}
                      </Text>
                    </View>
                  </TouchableOpacity>
                )}
                <View
                  style={[
                    ButtonStyle.headerRightStyle,
                    { width: this.props.getsearchBarStatus ? "85%" : "20%" },
                  ]}
                >
                  <HeaderRightDashboard navigation={this.props.navigation} />
                </View>
              </View>
            </View>
          </Animated.View>
        ) : null}
        <ScrollView
          contentContainerStyle={
            Dimensions.get("window").width > 750
              ? ButtonStyle.threeColStruc
              : null
          }
          scrollEnabled={false}
        >
          {Dimensions.get("window").width > 750 ? (
            <LeftPanel
              // ref={navigatorRef => {
              //   NavigationService.setTopLevelNavigator(navigatorRef);
              // }}
              navigation={this.props.navigation}
            />
          ) : null}
          {/* {console.log(this.state.scrollY, 'event.nativeEvent.contentOffset.y')} */}
          {/* {Dimensions.get('window').width > 750  ? */}
          <ScrollView
            contentContainerStyle={{
              height: Dimensions.get("window").height - 50,
            }}
            ref={(ref) => {
              this.UserProfilescrollview = ref;
            }}
            showsVerticalScrollIndicator={false}
            onScroll={(event) => {
              // this.TopicsProfilescrollview.scrollTo({x: 100, y:200, animated:true})
              this.setState({
                scrollY: event.nativeEvent.contentOffset.y,
              });
            }}
            // scrollEnabled={this.state.scrollY <= 190 ? true : false}
            scrollEventThrottle={16}
          >
            <UserProfileCard
              item={this.props.userDetails}
              navigation={this.props.navigation}
              icon={this.showIcon}
              feedY={this.state.feedY}
              ProfileHeight={this.state.ProfileHeight}
              setOpen={this.setOpen}
              openPopUp={this.openPopUp}
              closeEditProfile={this.closeEditProfile}
              showEditProfile={this.state.showEditProfile}
              setUpdateData={this.setUpdateData}
              // fullname={this.state.fullname}
              username={this.state.username}
              reportUser={this.reportUser}
              // description={this.state.description}
              // profilepic={this.state.profilepic}
            />

            {/* </Animated.View> */}
            {/* : null} */}
            <View
              style={{
                flexDirection: "row",
                height: this.props.windowSize.height,
                backgroundColor: "#fff",
                // width: "100%",
                // flex: 1,
              }}
            >
              {Dimensions.get("window").width <= 750 ? (
                <ScrollView
                  style={{
                    flex: 1,
                    width: "100%",
                    height: "100%",
                  }}
                >
                  {this.renderTabViewForMobile()}
                </ScrollView>
              ) : (
                !this.props.show && (
                  <View
                    style={[
                      ButtonStyle.DashboardTabViewStyle,
                      {
                        // width:
                        //   Platform.OS == "web" &&
                        //     Dimensions.get("window").width >= 750 && Dimensions.get("window").width <= 1200
                        //     ? '100%' : Platform.OS == "web" && Dimensions.get("window").width >= 1200 ? '50%'
                        //       : "100%",
                        width:
                          Dimensions.get("window").width > 1200 &&
                          Dimensions.get("window").width < 1600
                            ? 450
                            : Dimensions.get("window").width > 1600
                            ? 600
                            : Dimensions.get("window").width - 310,
                        borderWidth: 0,
                        // marginLeft: Dimensions.get("window").width < 1200 && Dimensions.get("window").width >= 750 ? 0 : 0,
                        backgroundColor: Colors.whiteBackground,
                      },
                    ]}
                  >
                    <View style={ButtonStyle.TabbarBorderStyleForDashboard}>
                      {["Trending", "New", "Discussion"].map((e) => {
                        return (
                          <TouchableOpacity
                            style={{
                              backgroundColor: "transparent",
                            }}
                            onPress={() => {
                              this.setState({
                                focused: e,
                              });
                            }}
                          >
                            <Text
                              style={[
                                ButtonStyle.tabbarTitleStyle,
                                {
                                  color:
                                    this.state.focused == e
                                      ? Colors.blueColor
                                      : "#D3D3D3",
                                  // color: "#D3D3D3",
                                  flexWrap: "wrap",
                                  flex: 1,
                                  width: "100%",
                                  fontWeight:
                                    this.state.focused == e ? "bold" : "100",
                                  // fontWeight: '100'
                                },
                              ]}
                            >
                              {e}
                            </Text>
                          </TouchableOpacity>
                        );
                      })}
                    </View>
                    {this._renderScene()}
                  </View>
                )
              )}
              {this.props.windowSize.width > 1200 || this.props.show ? (
                <View
                  style={[
                    FeedCommentStyle.commentContainer,
                    {
                      width:
                        this.props.windowSize.width > 1200 &&
                        this.props.windowSize.width < 1600
                          ? 450
                          : this.props.windowSize.width > 1600
                          ? 600
                          : this.props.windowSize.width - 310,
                    },
                  ]}
                >
                  <View style={{ width: "92%", marginLeft: 20 }}>
                    {this.props.windowSize.width < 1200 && this.props.show ? (
                      <View
                        style={[
                          ButtonStyle.TabbarBorderStyleForDashboard,
                          {
                            height: 55,
                            alignItems: "center",
                            paddingHorizontal: 10,
                            paddingVertical: 10,
                            marginLeft: 0,
                            width: "100%",
                            justifyContent: this.props.show
                              ? "flex-start"
                              : "center",
                            alignItems: "center",
                            flexDirection: "row",
                            backgroundColor: "#fff",
                            marginBottom: 10,
                            borderBottomWidth: 1,

                            // borderTopWidth: 2,
                          },
                        ]}
                      >
                        {this.props.show == true ? (
                          <TouchableOpacity
                            style={[
                              ButtonStyle.headerBackStyle,
                              { width: "20%" },
                            ]}
                            onPress={() => this.props.showDiscussion(false)}
                          >
                            <Icon
                              color={"#000"}
                              name="angle-left"
                              type="font-awesome"
                              size={40}
                            />
                          </TouchableOpacity>
                        ) : null}
                        <Text
                          style={[
                            ButtonStyle.tabbarTitleStyle,
                            {
                              width: this.props.show ? "55%" : "100%",
                              textAlign: "center",
                            },
                          ]}
                        >
                          {" "}
                          Discussion
                        </Text>
                      </View>
                    ) : null}

                    <CommentDetailScreen
                      type="userProfile"
                      navigation={this.props.navigation}
                      postId={
                        this.props.PostDetails &&
                        this.props.PostDetails.node?.id
                      }
                      listScroll={this.listScroll}
                      ProfileHeight={this.state.ProfileHeight}
                      scrollY={this.state.scrollY}
                      ActiveTab={this.state.routes[this.state.index].title}
                    />
                  </View>
                </View>
              ) : null}
            </View>
          </ScrollView>
        </ScrollView>

        {/* {this.props.show == true || Dimensions.get("window").width >= 1200 ?
          <View style={{
            width: Platform.OS == "web" &&
              Dimensions.get("window").width >= 750 && Dimensions.get("window").width <= 1200
              ? '100%' : Platform.OS == "web" && Dimensions.get("window").width >= 1200 ? '50%'
                : "100%", marginLeft: Dimensions.get("window").width >= 1200 ? '50%' : 0, zIndex: 999, position: 'absolute', bottom: 10,
          }}>
            <CreateCommentCard
              onClose={this.onClose}
              parent_content_id={
                this.props.PostId &&
                this.props.PostId.replace("Trending", "")
                  .replace("New", "")
                  .replace("Discussion", "")
                  .replace("Search", "")
              }
              clickList={
                this.props.PostDetails
                  ? this.props.PostDetails
                  : null
              }
              initial="main"
              topComment={this.props.PostDetails && this.props.PostDetails.node}
              navigation={this.props.navigation}
              title={this.props.Title}
              type={this.props.type}
            />
          </View> : null
        } */}

        {this.state.opentool ? (
          <Overlay
            animationType="fadeInUp"
            visible={this.state.opentool}
            onClose={this.onClose}
            closeOnTouchOutside
            children={
              <View
                style={{
                  width: "100%",
                  backgroundColor: "#fff",
                  borderRadius: 8,
                }}
              >
                <View style={{ padding: 20 }}>
                  {this.props.loginStatus == 1 &&
                  this.props.userDetails?.id ==
                    this.props.profileData.data.id?.replace(
                      "Account:",
                      "User:"
                    ) ? (
                    <TouchableOpacity
                      optionsContainerStyle={{
                        borderRadius: 6,
                        borderWidth: 1,
                        borderColor: "#e1e1e1",
                        shadowColor: "transparent",
                      }}
                      customStyles={{
                        optionsContainer: {
                          minHeight: 50,
                          width: 150,
                          marginTop: Platform.OS != "web" ? -20 : 20,
                          // marginLeft: Dimensions.get('window').width <= 750 ? 0 : '-7%'
                          // marginLeft: Dimensions.get('window').width < 1350 ? -25 : -140,
                        },
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          this.onClose();
                          this.props.navigation.navigate("settings");
                        }}
                        style={{ marginTop: 10 }}
                      >
                        <Hoverable>
                          {(isHovered) => (
                            <Text
                              style={{
                                textAlign: "center",
                                color:
                                  isHovered == true
                                    ? ConstantColors.blueColor
                                    : "#000",
                                fontFamily: ConstantFontFamily.defaultFont,
                                fontSize: 20,
                              }}
                            >
                              Settings
                            </Text>
                          )}
                        </Hoverable>
                      </TouchableOpacity>

                      <TouchableOpacity
                        onPress={() => {
                          this.onClose();
                          this.props.navigation.navigate("Analytics");
                        }}
                        style={{ marginTop: 10 }}
                      >
                        <Hoverable>
                          {(isHovered) => (
                            <Text
                              style={{
                                textAlign: "center",
                                color:
                                  isHovered == true
                                    ? ConstantColors.blueColor
                                    : "#000",
                                fontFamily: ConstantFontFamily.defaultFont,
                                fontSize: 20,
                              }}
                            >
                              Analytics
                            </Text>
                          )}
                        </Hoverable>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() =>
                          this.setState({
                            showEditProfile: true,
                            opentool: false,
                          })
                        }
                        style={{ marginTop: 10 }}
                      >
                        <Hoverable>
                          {(isHovered) => (
                            <Text
                              style={{
                                textAlign: "center",
                                color:
                                  isHovered == true
                                    ? ConstantColors.blueColor
                                    : "#000",
                                fontFamily: ConstantFontFamily.defaultFont,
                                fontSize: 20,
                              }}
                            >
                              Edit Profile
                            </Text>
                          )}
                        </Hoverable>
                      </TouchableOpacity>
                      <TouchableOpacity
                        onPress={() => {
                          this.onClose();
                          this.logout();
                        }}
                        style={{ marginTop: 10 }}
                      >
                        <Hoverable>
                          {(isHovered) => (
                            <Text
                              style={{
                                textAlign: "center",
                                color:
                                  isHovered == true
                                    ? ConstantColors.blueColor
                                    : "#000",
                                fontFamily: ConstantFontFamily.defaultFont,
                                fontSize: 20,
                              }}
                            >
                              Sign Out
                            </Text>
                          )}
                        </Hoverable>
                      </TouchableOpacity>
                    </TouchableOpacity>
                  ) : this.props.loginStatus == 1 &&
                    this.props.userDetails?.id !=
                      this.props.profileData.data.id?.replace(
                        "Account:",
                        "User:"
                      ) ? (
                    <TouchableOpacity
                      optionsContainerStyle={{
                        borderRadius: 6,
                        borderWidth: 1,
                        borderColor: "#e1e1e1",
                        shadowColor: "transparent",
                      }}
                      customStyles={{
                        optionsContainer: {
                          minHeight: 25,
                          width: 150,
                          marginTop: Platform.OS != "web" ? -20 : 20,
                          // marginLeft: Dimensions.get('window').width < 1350 ? -25 : -140,
                        },
                      }}
                    >
                      <TouchableOpacity
                        onPress={() => {
                          this.props.showReportUserModal(
                            true,
                            this.props.userDetails?.id
                          ),
                            this.setState({ opentool: false });
                        }}
                      >
                        <Hoverable>
                          {(isHovered) => (
                            <Text
                              style={{
                                textAlign: "center",
                                color:
                                  isHovered == true
                                    ? ConstantColors.blueColor
                                    : "#000",
                                fontFamily: ConstantFontFamily.defaultFont,
                                fontSize: 20,
                              }}
                            >
                              Report
                            </Text>
                          )}
                        </Hoverable>
                      </TouchableOpacity>

                      {this.props.isAdmin && (
                        <TouchableOpacity>
                          <Hoverable>
                            {(isHovered) => (
                              <Text
                                style={{
                                  textAlign: "center",
                                  color:
                                    isHovered == true
                                      ? ConstantColors.blueColor
                                      : "#000",
                                  fontFamily: ConstantFontFamily.defaultFont,
                                  fontSize: 20,
                                }}
                              >
                                Delete
                              </Text>
                            )}
                          </Hoverable>
                        </TouchableOpacity>
                      )}
                    </TouchableOpacity>
                  ) : (
                    this.props.loginStatus == 0 && (
                      <TouchableOpacity
                        optionsContainerStyle={{
                          borderRadius: 6,
                          borderWidth: 1,
                          borderColor: "#e1e1e1",
                          shadowColor: "transparent",
                        }}
                        customStyles={{
                          optionsContainer: {
                            minHeight: 50,
                            width: 150,
                            marginTop: Platform.OS != "web" ? -20 : 20,
                            marginLeft:
                              Dimensions.get("window").width < 1350
                                ? -25
                                : -140,
                          },
                        }}
                      >
                        <TouchableOpacity
                          onSelect={() => {
                            return this.props.navigation.navigate("settings");
                          }}
                        >
                          <Hoverable>
                            {(isHovered) => (
                              <Text
                                style={{
                                  textAlign: "center",
                                  color:
                                    isHovered == true
                                      ? ConstantColors.blueColor
                                      : "#000",
                                  fontFamily: ConstantFontFamily.defaultFont,
                                  fontSize: 20,
                                }}
                              >
                                Settings
                              </Text>
                            )}
                          </Hoverable>
                        </TouchableOpacity>

                        <TouchableOpacity
                          onSelect={() =>
                            this.props.navigation.navigate("analytics")
                          }
                        >
                          <Hoverable>
                            {(isHovered) => (
                              <Text
                                style={{
                                  textAlign: "center",
                                  color:
                                    isHovered == true
                                      ? ConstantColors.blueColor
                                      : "#000",
                                  fontFamily: ConstantFontFamily.defaultFont,
                                  fontSize: 20,
                                }}
                              >
                                Analytics
                              </Text>
                            )}
                          </Hoverable>
                        </TouchableOpacity>
                      </TouchableOpacity>
                    )
                  )}
                </View>
              </View>
            }
            childrenWrapperStyle={{
              padding: 0,
              margin: 0,
              borderRadius: 6,
            }}
          />
        ) : null}

        {/* {Dimensions.get("window").width <= 750 ? (
          

            <BottomScreen navigation={NavigationService} />
          
        ) : null} */}
      </View>
    );
  }
}

const mapStateToprops = (state) => ({
  userDetails: state.UserProfileDetailsReducer.getCurrentUserProfileDetails,
  loginStatus: state.UserReducer.loginStatus,
  getTabView: state.AdminTrueFalseReducer.tabType,
  // getsearchBarStatus: state.AdminTrueFalseReducer.get("searchBarOpenStatus"),
  // TrendingUserHomeFeedList: state.HomeFeedReducer.get("TrendingUserHomeFeedList"),
  // PostId: state.PostCommentDetailsReducer.get("PostId"),
  // PostDetails: state.PostDetailsReducer.get("PostDetails"),
  // show: state.showDiscussionReducer.show,
  profileData: state.LoginUserDetailsReducer.userLoginDetails,
  windowSize: state.windowResizeReducer.windowResize,
  show: state.AdminTrueFalseReducer.showDiscussions,
  screenName: state.screenNameReducer.screen,
});

const mapDispatchToprops = (dispatch) => ({
  userId: (payload) => dispatch(getCurrentUserProfileDetails(payload)),
  loginModalStatus: (payload) => dispatch(setLOGINMODALACTION(payload)),
  setPostCommentDetails: (payload) => dispatch(setPostCommentDetails(payload)),

  // searchOpenBarStatus: (payload) =>
  //   dispatch({ type: "SEARCHBAR_STATUS", payload }),
  // feedFluctuation: payload => dispatch(feedFluctuation(payload)),
  // // setPostCommentReset: (payload) =>
  // //   dispatch({ type: "POSTCOMMENTDETAILS_RESET", payload }),
  // setPostCommentDetails: payload => dispatch(setPostCommentDetails(payload)),
  // showDiscussion: (payload) => dispatch(showDiscussion(payload)),
  // screen: (payload) => dispatch(screen(payload)),
  changeLoginStatus: (payload) => dispatch(setLoginStatus(payload)),
  changeAdminStatus: (payload) => dispatch(setAdminStatus(payload)),
  showDiscussion: (payload) => dispatch(showDiscussionReducer(payload)),
  screen: (payload) => dispatch(screen(payload)),
  showReportUserModal: (reportUserModal, userId) =>
    dispatch(
      showReportUserModal({
        type: "reportUser",
        payload: { reportUserModal, userId },
      })
    ),
  // resetLoginUserDetails: payload =>
  //   dispatch({ type: "LOGIN_USER_DETAILS_RESET", payload }),
  // resetUserProfileDetails: payload =>
  //   dispatch({ type: "USER_PROFILE_DETAILS_RESET", payload }),
});

export default compose(connect(mapStateToprops, mapDispatchToprops))(
  ProfileScreen
);

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.whiteBackground,
    height: "100%",
  },
});
