export const phrases = [
    "A Space for the Intellectually Curious.",
    "The platform made for the coolest, smartest people on the planet.",
    "Weclikd facilitates discourse. We cogently discuss ideas, and do not attack people, cultures, or events.",
    "Comments must be thoughtful and original.",
    "Our core values are quality, authenticity, and integrity.",
    "We hold the right to not support discourse of certain topics.",
    "Violation of principles results in forfeiture of content, earnings, and accounts.",
    "Privacy and security are utmost important to us.",
    "We do not sell ads or personal info",
    "We C. We Likd. #Weclikd."
];
//Format to fit space.
  