import React, { useEffect, useState } from "react";
import {
  Dimensions,
  Platform,
  Text,
  TouchableOpacity,
  View,
  Image
} from "react-native";
import { Icon } from "react-native-elements";
import Overlay from "react-native-modal-overlay";
import { Hoverable } from "react-native-web-hooks";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import { editClik } from "../reducers/EditClikReducer";
import { saveUserLoginDaitails } from "../reducers/LoginUserDetailsReducer";
import appolloClient from "../client";

import ConstantFontFamily from "../constants/FontFamily";
import { DeleteContentMutation } from "../graphqlSchema/FeedMutation"
import { ClikFollowingMutation } from "../graphqlSchema/UserMutation";
import ClikStar from "./ClikStar";
import ButtonStyle from "../constants/ButtonStyle";
import {
  Menu,
  MenuOption,
  MenuOptions,
  MenuTrigger,
} from "react-native-popup-menu";
import { getTrendingClicks } from "../actionCreator/TrendingCliksAction";
// import NavigationService from "../library/NavigationService";
import Colors from "../constants/Colors";


import ProifileCardStyle from "./Style/ProifileCardStyle";
import { ClikJoinMutation } from "../graphqlSchema/FollowandUnFollowMutation";
import { ClikJoinVariables } from "../graphqlSchema/FollowandUnfollowVariables";
import {setclikInviteModal} from '../reducers/AdminTrueFalseReducer';

const CliksProfileCard = props => {
  let [Height, setHeight] = useState(0);
  const cliks = props.item;
  const [followBtnActive, setfollowBtnActive] = useState(0);
  const [showRequestInviteModal, setshowRequestInviteModal] = useState(false);
  const [showLeaveCliksModal, setshowLeaveCliksModal] = useState(false);
  const [Badge, setBadge] = useState(require("../assets/image/YBadge.png"));
  const [showInviteUserModal, setshowInviteUserModal] = useState(false);
  const [ClikEdit, setClikEdit] = useState(false);
  const [userPermision, setuserPermision] = useState(false);
  const [userApprovePermision, setuserApprovePermision] = useState(false);
  let [MenuHover, setMenuHover] = useState(false);
  const [superAdmin, SetAdmin] = useState(false)
  const [star, setStar] = useState(false)

  let description = cliks && cliks.description;
  let cliksMembers = cliks && cliks.num_members;
  let website = cliks && cliks.swebsite;

  useEffect(() => {
    // console.log(props.getUserFollowCliksList, 'cliks')
    if (props.loginStatus == 1) {
      getUserPermision();
      getUserApprovePermision();
      Invite(
        cliks && cliks.name,
        Badge == "icon"
          ? cliks && cliks.invite_only == true
            ? "Apply"
            : "Join"
          : "Invite"
      );
      
      const index = props.getUserFollowCliksList.findIndex(
        i => i.node.clik.name.toLowerCase() == cliks.name.toLowerCase()
      );
      if (index != -1) {
        if (props.getUserFollowCliksList[index].node.follow_type == "FAVORITE"

        ) {
          setfollowBtnActive(2);
        }
        if (props.getUserFollowCliksList[index].node.follow_type == "FOLLOW"
        ) {
          setfollowBtnActive(1);
        }
        if (
          props.getUserFollowCliksList[index].node.member_type ==
          "SUPER_ADMIN"
        ) {
          setBadge(require("../assets/image/YBadge.png"));
          SetAdmin(true)
        } else if (
          props.getUserFollowCliksList[index].node.member_type == "ADMIN"
        ) {
          setBadge(require("../assets/image/SBadge.png"));
        } else if (
          props.getUserFollowCliksList[index].node.member_type == "MEMBER"
        ) {
          setBadge(require("../assets/image/badge.png"));
        } else {
          setBadge("icon");
        }
      } else {
        setfollowBtnActive(0);
        setBadge("icon");
      }
     
    }
  });



  function onClose() {
    setshowRequestInviteModal(false);
    setshowLeaveCliksModal(false);
    setshowInviteUserModal(false);
  }

  function Invite(cliksId, type) {
    const index = props.getUserFollowCliksList.findIndex(
      i => i.node.clik.name.toLowerCase() == cliksId?.toLowerCase()
    );
    if (
      index != -1 &&
      (props.getUserFollowCliksList[index]?.node?.member_type ==
        "SUPER_ADMIN" ||
        props.getUserFollowCliksList[index]?.node?.member_type == "ADMIN" ||
        props.getUserFollowCliksList[index]?.node?.member_type == "MEMBER")
    ) {
      props.members();
      props.showInviteLink(
        true,
        type,
        props.getUserFollowCliksList[index]?.node?.member_type
      );
    } else {
      props.members();
      props.showInviteLink(
        false,
        type,
        props.getUserFollowCliksList[index]?.node?.member_type
      );
    }
  }

  function MemberTab(cliksId, type) {
    props.showMembers(type);
    if (type == "Apply" || type == "Join") {
      ClikJoinVariables.variables.clik_id = "Clik:" + cliksId;
      ClikJoinVariables.variables.qualification = "";
      ClikJoinVariables.variables.known_members = [];
      try {
        appolloClient
          .query({
            query: ClikJoinMutation,
            ...ClikJoinVariables,
            fetchPolicy: "no-cache"
          })
          .then(async res => {
            console.log(res)
            if (res.status == 200) {
              alert('You have successfully joined this clik')
            }
          });
      } catch (e) {
        console.log(e);
      }
    } else if (type == "Invite" ){
      props.setclikInviteModal(true, cliksId )
    }
  }

  const openWindow = async link => {
    // await openBrowserAsync(link);
  };

  const showAlert = () => {
    if (Platform.OS === "web") {
      var result = confirm(
        "Are you sure you want to delete " +
        cliks
          .get("name")
          .replace("Clik%3A", "#")
          .replace("Clik:", "#")
      );
      if (result == true) {
        appolloClient
          .query({
            query: DeleteContentMutation,
            variables: {
              id: cliks.id
            },
            fetchPolicy: "no-cache"
          })
          .then(async res => {

            await appolloClient
              .query({
                query: ClikFollowingMutation,
                variables: {
                  id: "User:CurrentUser",
                  first: 50
                },
                fetchPolicy: "no-cache"
              }).then(e => {


                let userData = {
                  data: props.profileData,
                  externalfollow: props.getUserFollowFeedList,
                  clikfollow: e.data.node.cliks_following.edges,
                  topicfollow: props.getUserFollowTopicList,
                  userfollow: props.getUserFollowUserList

                }

                props.saveLoginUser(userData);
                props.clikDeleteUpdate(true);

                props.getTrendingClicks({
                  currentPage: 100
                });
                this.props.navigation.navigate("home")

              })
          });
      }
    } else {
      Alert.alert(
        "Are you sure you want to delete " +
        cliks
          .get("name")
          .replace("Clik%3A", "#")
          .replace("Clik:", "#")[
        ({
          text: "NO",
          onPress: () => console.warn("NO Pressed"),
          style: "cancel"
        },
        {
          text: "YES",
          onPress: () => {
            appolloClient
              .query({
                query: DeleteContentMutation,
                variables: {
                  id: cliks.id
                },
                fetchPolicy: "no-cache"
              })
              .then(async res => {
                await appolloClient
                  .query({
                    query: ClikFollowingMutation,
                    variables: {
                      id: "User:CurrentUser",
                      first: 50
                    },
                    fetchPolicy: "no-cache"
                  }).then(e => {


                    let userData = {
                      data: props.profileData,
                      externalfollow: props.getUserFollowFeedList,
                      clikfollow: e.data.node.cliks_following.edges,
                      topicfollow: props.getUserFollowTopicList,
                      userfollow: props.getUserFollowUserList

                    }
                    props.saveLoginUser(userData);
                  })
              });
          }
        })
        ]
      );
    }
  };
  const getUserPermision = () => {
   
  };

  const getUserApprovePermision = () => {
   
  };


  const leaveClik = async () => {
    setStar(true)
  };

  
  return (

    <TouchableOpacity
      style={[ProifileCardStyle.mainCardContainer,
      Dimensions.get('window').width > 750 ? null : ButtonStyle.profileShadowStyle,
      ]}
      onPress={() => props.openPopUp()}
      disabled={props.loginStatus == 1 && Platform.OS != 'web' ? false : true}
    >


      <View style={{ flexDirection: 'row', width: '100%', marginVertical: 15, }}
      >
        <View style={{ marginLeft: 15, }}>
          {/* {cliks && cliks.getIn(["profile_pic"]) && ( */}
          <Image
            source={cliks.profile_pic ? {
              uri: cliks.profile_pic
            } : require("../assets/image/logolastOne.png")}
            style={{
              height: 80,
              width: 80,
              padding: 0,
              margin: 0,
              borderRadius: 10,
              borderWidth: 1,
              borderColor: "#fff",
            }}
          />
          {/* )} */}

        </View>
        <View style={{ flexDirection: 'row', marginLeft: 15, width: (Dimensions.get('window').width <= 750 && cliks.profile_pic != null) ? '65%' : (Dimensions.get('window').width <= 750 && cliks.profile_pic == null) ? '90%' : Dimensions.get('window').width > 1600 ? '90%' : '80%', alignItems: 'flex-start' }}>
          <View style={{ width: '85%' }}>
            <View
              style={[ButtonStyle.clikNameBackgroundStyle, {
                marginTop: 0,
                marginLeft: 0,
                padding: 0,
                paddingVertical: 5,
                // backgroundColor: '#E8F5FA',
                alignSelf: 'flex-start'
              }]}
            >
              <Text
                style={[
                  ButtonStyle.clikNameTitleStyle,
                  {
                    color: Colors.blueColor
                  },
                ]}
              >

                #{cliks &&
                  cliks.name}
              </Text>


            </View>
            <View
              style={{
                // marginTop: 10,
                flexDirection: Dimensions.get('window').width <= 750 ? 'column' : "row",
                alignItems: 'flex-end',
                marginBottom: 10,

              }}
            >
              <View style={{
                flexDirection: 'row', flexWrap: Dimensions.get('window').width <= 750 ? 'wrap' : 'nowrap', alignSelf: Dimensions.get('window').width <= 750 ? 'flex-start' : 'auto',
                // marginBottom: Dimensions.get('window').width <= 750 ? 5 : 0
              }}>
                {cliks && cliks.invite_only == true && (
                  <Icon
                    name="lock"
                    type="font-awesome"
                    color={"grey"}
                    size={15}
                    containerStyle={{ alignItems: "center" }}
                    iconStyle={{
                      marginRight: 5,
                      alignSelf: "center"
                    }}
                  />
                )}
                <TouchableOpacity onPress={() => props.setTab('Members', cliks.id)}>
                  <Text
                    style={{
                      color: "grey",
                      fontSize: 12,
                      fontFamily: ConstantFontFamily.defaultFont
                    }}

                  >
                    {cliksMembers} Members{" "}{"/ "}

                  </Text>

                </TouchableOpacity>
                <Text
                  style={{
                    color: "grey",
                    fontSize: 12,
                    fontFamily: ConstantFontFamily.defaultFont
                  }}

                >
                  {props.cliksDetails.num_followers}{" "}
                  Followers
                </Text>
                {props.MemberType == 'ADMIN' || props.MemberType == 'SUPER_ADMIN' ||
                  props.MemberType == 'MEMBER' ?

                  <Text style={{
                    color: "grey",
                    fontSize: 12,
                    fontFamily: ConstantFontFamily.defaultFont
                  }}>{" / "}You are {props.MemberType == 'ADMIN' ? 'an ' : 'a '}
                    {props.MemberType == 'ADMIN' ? 'Admin' :
                      props.MemberType == 'SUPER_ADMIN' ? 'Super Admin' : props.MemberType == 'MEMBER' && 'member'}
                  </Text> : null}

              </View>
              <View style={{ flexDirection: 'row', alignSelf: 'flex-start' }}>
                {website != "" && website != null && (
                  <View style={{ marginLeft: Dimensions.get('window').width <= 750 ? 0 : 5 }}>
                    <Icon
                      name="link"
                      type="font-awesome"
                      color="grey"
                      size={14}
                    />
                  </View>
                )}
                <Hoverable>
                  {isHovered => (
                    <TouchableOpacity onPress={() => openWindow(website)}>
                      <Text
                        numberOfLines={1}
                        style={{
                          marginLeft: 5,
                          color: "grey",
                          fontSize: 12,
                          fontFamily: ConstantFontFamily.defaultFont,
                          textDecorationLine:
                            isHovered == true ? "underline" : "none",

                        }}
                      >
                        {
                          website != "" &&
                          website != null && website
                            .replace("http://", "")
                            .replace("https://", "")
                            .replace("www.", "")
                            .split(/[/?#]/)[0]
                        }
                      </Text>
                    </TouchableOpacity>
                  )}
                </Hoverable>
              </View>
            </View>
            <View
            // onLayout={event => {
            //   let { x, y, width, height } = event.nativeEvent.layout;
            //   setHeight(height);
            // }}
            >
              <Text
                style={{
                  // marginBottom: 20,
                  color: "#000",
                  fontSize: 14,
                  fontFamily: ConstantFontFamily.defaultFont,
                  textAlign: 'left',
                  // flexWrap:'wrap'
                }}
              >
                {description}
              </Text>
            </View>
          </View>

          <View style={{ alignItems: "flex-end", width: '15%', flexDirection: 'row', justifyContent: 'flex-end' }}>
            {props.loginStatus == 1 && Dimensions.get('window').width > 750 &&
              <TouchableOpacity
                onMouseEnter={() => setMenuHover(true)}
                onMouseLeave={() => setMenuHover(false)}
              >
                <Menu>
                  <MenuTrigger>
                    <Image
                      source={require("../assets/image/menu.png")}
                      style={{
                        height: 16,
                        width: 16,
                        marginTop: 5,
                        marginRight: 15,
                        transform: [{ rotate: "90deg" }]

                        // marginTop: Platform.OS != 'web' ? -20 : 20,
                      }}
                    />
                  </MenuTrigger>
                  <MenuOptions
                    optionsContainerStyle={{
                      borderRadius: 6,
                      borderWidth: 1,
                      borderColor: "#e1e1e1",
                      shadowColor: "transparent",
                    }}
                    customStyles={{
                      optionsContainer: {
                        minHeight: 50,
                        width: 150,
                        marginTop: Platform.OS != 'web' ? 5 : 20,
                        // marginLeft: Dimensions.get('window').width < 1350 ? -25 : -140,
                      }
                    }}
                  >
                    {/* {props.MemberType == null ? */}
                      <MenuOption
                        onSelect={() => {
                          MemberTab(cliks && cliks.name, Badge == "icon" ?
                            cliks && cliks.invite_only == true
                              ?
                              "Apply" : "Join" : "Invite")
                        }}
                      >
                        <Hoverable>
                          {isHovered => (
                            <Text
                              style={{
                                textAlign: "center",
                                color: isHovered == true ? Colors.blueColor : "#000",
                                fontFamily: ConstantFontFamily.defaultFont
                              }}
                            >
                              {Badge == "icon" ?
                                cliks && cliks.invite_only == true
                                  ?
                                  "Apply" : "Join" : "Invite"}

                            </Text>
                          )}
                        </Hoverable>
                      </MenuOption> 
                    {followBtnActive == 2 || followBtnActive == 1 ?
                      <MenuOption
                        onSelect={() => {
                          leaveClik()

                        }}
                      >
                        <Hoverable>
                          {isHovered => (
                            <Text
                              style={{
                                textAlign: "center",
                                color: isHovered == true ? Colors.blueColor : "#000",
                                fontFamily: ConstantFontFamily.defaultFont
                              }}
                            >
                              Leave

                            </Text>
                          )}
                        </Hoverable>
                      </MenuOption> : null}
                    {(props.isAdmin == true || superAdmin == true) &&
                      <MenuOption
                        onSelect={async () => {

                          await props.editClik({
                            clikId: cliks && cliks.id,
                            cliksName: cliks && cliks.name,
                            profile_pic: cliks && cliks.profile_pic,
                            description: cliks && cliks.description,
                            cliksMembers: cliks && cliks.max_members,
                            website: cliks && cliks.websites,
                            invite_only: cliks && cliks.invite_only,
                            qualifications:
                              cliks && cliks.qualifications
                          });
                          props.navigation.navigate("editclik");
                        }}
                      >
                        <Hoverable>
                          {isHovered => (
                            <Text
                              style={{
                                textAlign: "center",
                                color: isHovered == true ? Colors.blueColor : "#000",
                                fontFamily: ConstantFontFamily.defaultFont
                              }}
                            >
                              Edit
                            </Text>
                          )}
                        </Hoverable>
                      </MenuOption>
                    }
                    <MenuOption onSelect={() => props.setTab('Members', cliks.id)}>
                      <Hoverable>
                        {isHovered => (
                          <Text
                            style={{
                              textAlign: "center",
                              color: isHovered == true ? Colors.blueColor : "#000",
                              fontFamily: ConstantFontFamily.defaultFont
                            }}
                          >
                            Members
                          </Text>
                        )}
                      </Hoverable>
                    </MenuOption>
                    <MenuOption onSelect={() => props.setTab('Applications', cliks.id)}>
                      <Hoverable>
                        {isHovered => (
                          <Text
                            style={{
                              textAlign: "center",
                              color: isHovered == true ? Colors.blueColor : "#000",
                              fontFamily: ConstantFontFamily.defaultFont
                            }}
                          >
                            Applications
                          </Text>
                        )}
                      </Hoverable>
                    </MenuOption>
                    <MenuOption >
                      <Hoverable>
                        {isHovered => (
                          <Text
                            style={{
                              textAlign: "center",
                              color: isHovered == true ? Colors.blueColor : "#000",
                              fontFamily: ConstantFontFamily.defaultFont
                            }}
                          >
                            Report
                          </Text>
                        )}
                      </Hoverable>
                    </MenuOption>
                    {(props.isAdmin == true || superAdmin == true) &&
                      <MenuOption
                        onSelect={() => { showAlert() }}
                      >
                        <Hoverable>
                          {isHovered => (
                            <Text
                              style={{
                                textAlign: "center",
                                color: isHovered == true ? Colors.blueColor : "#000",
                                fontFamily: ConstantFontFamily.defaultFont
                              }}
                            >
                              Delete
                            </Text>
                          )}
                        </Hoverable>
                      </MenuOption>
                    }
                  </MenuOptions>
                </Menu>
              </TouchableOpacity>
            }

            <ClikStar
              MemberType={props.MemberType}
              ClikName={
                props.cliksDetails
                  ? props.cliksDetails.id
                  : ""
              }
              ContainerStyle={{ justifyContent: "center" }}
              ImageStyle={{
                height: 20,
                width: 20,
                alignSelf: "center",
                marginLeft: 15
              }}
              updateStar={props.updateStar}
              star={star}
            />
          </View>
        </View>

      </View>

     

    </TouchableOpacity>
  );
};

const mapStateToProps = state => ({
  cliksDetails: state.ClikProfileDetailsReducer.getTrendingCliksProfileDetails,
  loginStatus: state.UserReducer.loginStatus,
  getUserFollowCliksList: state.LoginUserDetailsReducer.userFollowCliksList
    ? state.LoginUserDetailsReducer.userFollowCliksList
    : [],
  
  profileData: state.LoginUserDetailsReducer.userLoginDetails,
  isAdmin: state.AdminTrueFalseReducer.isAdmin,

});

const mapDispatchToProps = dispatch => ({
  saveLoginUser: payload => dispatch(saveUserLoginDaitails(payload)),
  editClik: payload => dispatch(editClik(payload)),
  getTrendingClicks: payload => dispatch(getTrendingClicks(payload)),
  setLoginModalStatus: (payload) => dispatch(setLOGINMODALACTION(payload)),
  setclikInviteModal : (clikInviteModalStatus, clikId) => dispatch(setclikInviteModal({ type: "clikInviteModal",  payload: { clikInviteModalStatus, clikId }  }))

});



export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CliksProfileCard
);


