import React, { Component } from "react";
import {
    Image,
    Text,
    TouchableHighlight,
    TouchableOpacity,
    View,
    Dimensions,
    Platform,
} from 'react-native';
import { Icon } from "react-native-elements";
import { Hoverable } from "react-native-web-hooks";
import {
    Menu,
    MenuOption,
    MenuOptions,
    MenuTrigger
} from "react-native-popup-menu";
import moment from "moment";
// import NavigationService from "../library/NavigationService";
import ConstantFontFamily from "../constants/FontFamily";
// import ChangeIcon from "../components/ChangeIcon";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import { getCurrentUserProfileDetails } from "../actionCreator/UserProfileDetailsAction";
import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";
import ButtonStyle from "../constants/ButtonStyle";
import TooltipBox from './TooltipBox';
import { openToolkit } from '../reducers/action/Completed';
import { OpenWindow } from "../constants/CommonFunction";

moment.updateLocale("en", {
    relativeTime: {
        future: "in %s",
        past: "%s ago",
        s: "1m",
        ss: "%ds",
        m: "1m",
        mm: "%dm",
        h: "1h",
        hh: "%dh",
        d: "1d",
        dd: "%dd",
        w: "1w",
        ww: "%dw",
        M: "1M",
        MM: "%dM",
        y: "1y",
        yy: "%dy"
    }
});

const getColour = score => {
    return "#969faa";
};

class CommonBottomSection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            MenuHover: false,
            clikAdmin: false,
            showTooltip: false,
        }
        this.props1 = this.props
    }

    handleBugClick = () => {
        this.props.handleBugClick()
    }

    writeToClipboard = () => {
        this.props.writeToClipboard()
    }

    handleDeleteFeed = (id) => {
        this.props.handleDeleteFeed(id)
    }

    handleClickComment = () => {
        this.props.handleClickComment()
    }
    handleClick = (status) => {
        this.props.handleClick(status)
    }
    hearCountFunc = (value) => {
        this.props.hearCountFunc(value)
    }

    // openWindow = (data) => {
    //     this.props.openWindow(data)
    // }

    handleSharePost = (id) => {
        this.props.handleSharePost()
        this.props.sharePostId(id)
    }
    goToUserProfile = async (username) => {
        this.props.userId({
            username: username,
            type: "feed",
        });
        this.props.navigation.navigate("profile", {
            username: username,
            type: "feed",
            postId: ' '
        });
    };

    componentDidMount() {
        // this.checkClikAdmin()
        this.setState({ showTooltip: false })
    }

    checkClikAdmin = () => {
        if (this.props.props.listType == "Clik") {
            let index = this.props.getUserFollowCliksList.findIndex(e => e.node.clik.id.toLowerCase() == this.props?.cliksDetails?.getIn(["data", "node"])?.get("id")?.toLowerCase())
            if (index != -1 && (this.props.getUserFollowCliksList[index]?.node.member_type
                == "SUPER_ADMIN" ||
                this.props.getUserFollowCliksList[index]?.node?.member_type == "ADMIN")) {

                this.setState({
                    clikAdmin: true
                })
            } else {
                this.setState({
                    clikAdmin: false
                })
            }

            if (index == -1) {
                this.setState({
                    clikAdmin: false
                })
            }

        }
    }

    render() {

        const props = this.props1
        // console.log(this.props?.item?.item?.node?.link,'itttt=============');
        // if (props.screenName == "postDetail") {
        //     console.log('11111111111111111------------', props?.item?.item)
        // }


        return (
            <>

                <View
                    style={{
                        width: "100%",
                        flexDirection: "row",

                        alignItems: 'center',
                    }}
                >
                    <View
                        style={{

                            paddingRight: 10,
                            paddingLeft:0

                        }}
                    >

                        <>
                            <Hoverable>
                                {isHovered => (
                                    <View
                                        style={{
                                            width: '100%',
                                            flexDirection: "row",
                                        }}
                                    >
                                        {props?.item?.item?.node?.link != null &&
                                            props?.item?.item?.node?.link != "" ? (
                                            props?.item?.item?.node?.external_feed != null &&
                                                props?.item?.item?.node?.external_feed?.icon_url != null ? (
                                                <TouchableOpacity
                                                    style={{
                                                        alignSelf: "center"
                                                    }}
                                                    onPress={() =>
                                                        goToFeedProfile(
                                                            props?.item?.item?.node?.external_feed?.id.replace(
                                                                "ExternalFeed:",
                                                                ""
                                                            )
                                                        )
                                                    }
                                                >

                                                    <View style={{ width: 26, alignItems: 'flex-start', justifyContent: 'center', }}>
                                                        <Image
                                                            source={{
                                                                uri: props?.item?.item?.node?.external_feed?.icon_url
                                                            }}
                                                            style={{
                                                                width: 22,
                                                                height: 22,
                                                                borderRadius: 15,
                                                            }}
                                                        />
                                                    </View>
                                                </TouchableOpacity>
                                            ) : (
                                                null

                                            )
                                        ) : null}
                                        <TouchableOpacity
                                            style={{
                                                alignSelf: "center",

                                                flexDirection: 'row',
                                            }}
                                            onPress={() => OpenWindow(this.props?.item?.link)}
                                        >
                                            <Text
                                                numberOfLines={1}
                                                style={{
                                                    textAlign: "left",
                                                    color: "#6D757F",
                                                    fontSize: props.ViewMode == "Card" ? 14 : 14,
                                                    fontFamily: ConstantFontFamily.defaultFont,
                                                    textDecorationLine:
                                                        isHovered == true ? "underline" : "none",
                                                    flexWrap: 'wrap',
                                                    width: '100%'
                                                }}
                                            >
                                                {
                                                    this.props?.item?.link
                                                        ?.replace("http://", "")
                                                        ?.replace("https://", "")
                                                        ?.replace("www.", "")
                                                        ?.split(/[/?#]/)[0]
                                                }
                                            </Text>
                                        </TouchableOpacity>
                                    </View>
                                )}
                            </Hoverable>
                            <Hoverable>
                                {isHovered => (
                                    <View
                                        style={{
                                            width: '100%',
                                            flexDirection: "row",
                                        }}
                                    >
                                        {this.props?.item?.node?.link != null &&
                                            this.props?.item?.node?.link != "" ? (
                                            this.props?.item?.node?.external_feed != null &&
                                                this.props?.item?.node?.external_feed?.icon_url != null ? (
                                                <TouchableOpacity
                                                    style={{
                                                        alignSelf: "center"
                                                    }}
                                                    onPress={() =>
                                                        goToFeedProfile(
                                                            this.props?.item?.node?.external_feed?.id.replace(
                                                                "ExternalFeed:",
                                                                ""
                                                            )
                                                        )
                                                    }
                                                >

                                                    <View style={{ width: 26, alignItems: 'flex-start', justifyContent: 'center', }}>
                                                        <Image
                                                            source={{
                                                                uri: this.props?.item?.node?.external_feed?.icon_url
                                                            }}
                                                            style={{
                                                                width: 22,
                                                                height: 22,
                                                                borderRadius: 15,
                                                            }}
                                                        />
                                                    </View>
                                                </TouchableOpacity>
                                            ) : (
                                                null

                                            )
                                        ) : null}
                                        <TouchableOpacity
                                            style={{
                                                alignSelf: "center",

                                                flexDirection: 'row',
                                            }}
                                            onPress={() => OpenWindow(this.props?.item?.node?.link)}
                                        >
                                            <Text
                                                numberOfLines={1}
                                                style={{
                                                    textAlign: "left",
                                                    color: "#6D757F",
                                                    fontSize: props.ViewMode == "Card" ? 14 : 14,
                                                    fontFamily: ConstantFontFamily.defaultFont,
                                                    textDecorationLine:
                                                        isHovered == true ? "underline" : "none",
                                                    flexWrap: 'wrap',
                                                    width: '100%'
                                                }}
                                            >
                                                {
                                                    this.props?.item?.node?.link
                                                        ?.replace("http://", "")
                                                        ?.replace("https://", "")
                                                        ?.replace("www.", "")
                                                        ?.split(/[/?#]/)[0]
                                                }
                                            </Text>
                                        </TouchableOpacity>
                                    </View>
                                )}
                            </Hoverable>
                        </>


                    </View>
                    {props.screenName == "postDetail" ?
                        <Text style={{
                            color: "#6D757F",
                            fontSize: 14,
                            fontFamily: ConstantFontFamily.defaultFont,

                        }}>
                            {moment
                                .utc(this.props.item.created)
                                .local()
                                .fromNow()}
                        </Text>
                        : null}

                </View >

            </>
        )
    }
}

const mapStateToProps = (state) => ({

    profileData: state.LoginUserDetailsReducer.userLoginDetails,
    getUserFollowCliksList: state.LoginUserDetailsReducer.userFollowCliksList
        ? state.LoginUserDetailsReducer.userFollowCliksList
        : [],
    isAdmin: state.AdminTrueFalseReducer.isAdmin,
    cliksDetails: state.ClikProfileDetailsReducer.getTrendingCliksProfileDetails,
    // totalComment: state.TotalCommentReducer.totalComment,
    // openId: state.openTookitReducer.openId,
    // screenName: state.screenNameReducer.screen

});

const mapDispatchToProps = dispatch => ({
    userId: (payload) => dispatch(getCurrentUserProfileDetails(payload)),
    sharePostId: (payload) => dispatch({ type: "SHAREDPOSTID", payload }),
    setLoginModalStatus: (payload) => dispatch(setLOGINMODALACTION(payload)),
    openToolkit: (payload) => dispatch(openToolkit(payload))


});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
    CommonBottomSection
);