import { createSlice } from '@reduxjs/toolkit'

const initialState = { 
    HomefeedList: [],
    HomefeedListPagination: null,
    DiscussionHomeFeedList: [],
    TrendingHomeFeedList: [],
    NewHomeFeedList: [],
    TrendingTopicHomeFeedList: [],
    TrendingClikHomeFeedList: [],
    TrendingUserHomeFeedList: [],
    TrendingExternalFeedHomeFeedList: [],
    Trending_Topics_List:[]
 }

const HomefeedListSlice = createSlice({
  name: 'HomefeedList',
  initialState,
  reducers: {
    setTrendingHomeFeed(state,action) {
      state.TrendingHomeFeedList= action.payload
    },
    setNewHomeFeed(state,action) {        
      state.NewHomeFeedList = action.payload
    },
    setDiscussionHomeFeed(state, action) {
      state.DiscussionHomeFeedList = action.payload
    },
    trendingTopicList(state, action) {
      state.Trending_Topics_List = action.payload
    }
    
  },
})

export const { setTrendingHomeFeed, setNewHomeFeed, setDiscussionHomeFeed,trendingTopicList } = HomefeedListSlice.actions
export default HomefeedListSlice.reducer