
import React, { useState, useRef } from "react";
import {
    Text,
    TouchableOpacity,
    View,
    Dimensions,
    ScrollView, TextInput, Image
} from "react-native";
import { Icon, Button } from "react-native-elements";
import { Hoverable } from "react-native-web-hooks";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import CreateCommentStyle from './Style/CreateCommentStyle';
import ButtonStyle from "../constants/ButtonStyle";
import applloClient from "../client";
import { SearchUserVariables } from "../graphqlSchema/SearchVariables";
import { SearchUserMutation } from "../graphqlSchema/SearchMutation";
import { InviteToClikMutation } from "../graphqlSchema/FollowandUnFollowMutation";
import { devDebug } from "@apollo/client/invariantErrorCodes";


const ClikInviteModal = props => {

    let [userData, setUserData] = useState(props.clikMemberList)
    let [text, settext] = useState('')
    let [box, setBox] = useState(false)
    let [topic, settopic] = useState('')
    let [selectUser, setselectUser] = useState([])
    let [userIdArray, setuserIdArray] = useState([])


    let dummy = []

    const searchUser = (id) => {
        let usersearchData = [];
        ;
        SearchUserVariables.variables.prefix = id;
        applloClient
            .query({
                query: SearchUserMutation,
                ...SearchUserVariables,
                fetchPolicy: "no-cache"
            })
            .then(res => {
                console.log(res, 'res=======')
                res.data.search.users.forEach(async (element, index) => {
                    usersearchData.push({ node: { user: element } });

                });
                setUserData(usersearchData)
            });
    }
    const handleSelectUser = (user) => {
        selectUser = selectUser.concat([user.user])
        userIdArray = userIdArray.concat([{ 'user_id': user.user.id, 'member_type': user.member_type }])
        setuserIdArray(userIdArray)
        setselectUser(selectUser)
    }

    const handleUserDelete = (e) => {
        let user = selectUser
        user.splice(user.indexOf(e), 1)
        setselectUser([...user])
    }
    const submitInvite = () => {

        try {
            applloClient
                .query({
                    query: InviteToClikMutation,
                    variables: {
                        clik_id: 'Clik:' + props.clikId,
                        invited_users: userIdArray
                    },
                    fetchPolicy: "no-cache"
                })
                .then(async res => {
                    console.log(res)
                    if (res.status == 200) {
                        alert('You have successfully joined this clik')
                    }
                });
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <View
            style={{
                width: "100%",
                height: Dimensions.get('window').width >= 750 ? 480 : 500,
                backgroundColor: '#fff'
            }}
        >
            {/* <Hoverable>
                {isHovered => (
                    <TouchableOpacity
                        style={{
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            flex: 1,
                            position: "absolute",
                            zIndex: 999999,
                            left: 0,
                            top: 0
                        }}
                        onPress={props.onClose}
                    >
                        <Icon
                            color={isHovered == true ? "rgba(256,256,256,0.4)" : "#000"}
                            iconStyle={{
                                color: "#fff",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                            reverse
                            name="close"
                            type="antdesign"
                            size={16}
                        />
                    </TouchableOpacity>
                )}
            </Hoverable> */}
            <>

                {selectUser.length > 0 ?
                    <View style={{
                        padding: 10, alignSelf: 'center', width: '90%',
                        borderColor: '#d3d3d3', marginTop: 10, borderWidth: 1, borderRadius: 6,
                        flexDirection: 'row', flexWrap: 'wrap'
                    }}>
                        {selectUser.map(e => {
                            return (

                                <View style={[ButtonStyle.clikNameBackgroundStyle, {
                                    marginLeft: 10,
                                    flexDirection: "row",
                                    justifyContent: 'flex-start',
                                    alignItems: 'center'

                                }]}
                                    key={e.id}>

                                    <Text style={[ButtonStyle.clikNameTitleStyle,
                                    {
                                        paddingHorizontal: 5,
                                    }]}>{e.username}</Text>

                                    <Icon
                                        name="close" size={18} type="font-awesome"
                                        iconStyle={{ marginLeft: 5, }}
                                        onPress={() => {
                                            handleUserDelete(e)
                                        }}

                                    />
                                </View>

                            )
                        })}
                    </View> :

                    <View >
                        <TextInput placeholder="Tag @users"
                            style={{ borderColor: '#d3d3d3', borderWidth: 1, borderRadius: 6, padding: 10, marginTop: 20, alignSelf: 'center', width: '90%' }}
                            // style={{ outline: "none" }}
                            onFocus={() => setBox(true)}
                            value={text}
                            onChangeText={(e) => { settext(e), searchUser(e) }}
                        />
                    </View>}
                {box ?
                    <ScrollView
                        nestedScrollEnabled
                        contentContainerStyle={{ padding: 10, alignSelf: 'center', width: '90%', height: 200 }}
                        showsVerticalScrollIndicator={false}
                    >
                        {userData.map(e => {
                            console.log(e, '1111111111111')
                            return (
                                <TouchableOpacity
                                    key={e.node.user.id}
                                    style={{
                                        width: "100%",
                                        // paddingVertical: 5,
                                        flexDirection: 'row'
                                    }}
                                    onPress={() => {
                                        handleSelectUser(e.node)
                                    }
                                    }
                                >
                                    <Image
                                        source={e.node.user.profile_pic ? e.node.user.profile_pic : require("../assets/image/weclickd-logo.png")}
                                        style={{
                                            width: 30,
                                            height: 30,
                                            borderRadius: 15
                                        }}
                                    />
                                    <View style={[ButtonStyle.userNameBackgroundStyle, {
                                        marginLeft: 10,
                                    }]}>
                                        <Text style={[ButtonStyle.userNameTitleStyle,
                                        {
                                            paddingHorizontal: 5
                                        }]}>{e.node.user.username}</Text>
                                    </View>
                                </TouchableOpacity>
                            )
                        })
                        }
                    </ScrollView>



                    :
                    null
                }

                {/* {
                    close ?
                        <Icon
                            name="close" size={18} type="font-awesome"
                            iconStyle={{ marginHorizontal: 5, }}
                            onPress={() => {

                            }}

                        /> : null
                } */}
            </>

            <Button
                title="Submit"
                titleStyle={ButtonStyle.titleStyle}
                buttonStyle={ButtonStyle.backgroundStyle}
                containerStyle={[ButtonStyle.containerStyle,
                    //  { marginTop:userData.length>0 ? 200:0, marginBottom: 50 }
                ]}
                onPress={() => {
                    submitInvite()
                    props.onClose()

                }}
            />

        </View >
    );
};

const mapStateToProps = state => ({
    sharePostId: state.PostShareReducer.postShareId,
    getUserFollowUserList: state.LoginUserDetailsReducer.userFollowUserList
        ? state.LoginUserDetailsReducer.userFollowUserList
        : [],
    clikId: state.AdminTrueFalseReducer.clikId,
    clikMemberList: state.ClikMembersReducer.clikMemberList,


});

// const PostShareModelWrapper = graphql(PostShareMutation, {
//   name: "Post",
//   options: { fetchPolicy: "no-cache" }
// })(PostShareModel);



export default compose(connect(mapStateToProps, null))(
    ClikInviteModal
);