import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    userLoginDetails: {},
    userFollowUserList: [],
    userFollowTopicsList: [],
    userFollowFeedList: [],
    userFollowCliksList: []
};


const LoginUserDetailsReducer = createSlice({
    name: 'LoginModalReducer',
    initialState,
    reducers: {
        saveUserLoginDaitails(state, action) {
            console.log(action, 'action=======')
            return {
                ...state,
                userLoginDetails: action.payload,
                userFollowUserList: action.payload.userfollow,
                userFollowTopicsList: action.payload.topicfollow,
                userFollowFeedList: action.payload.externalfollow,
                userFollowCliksList: action.payload.clikfollow

            }
        },
        // loginModalFailure(state, action) {
        //     return { ...state, userLoginDetails: action.payload }
        // },

    },
})

export const { saveUserLoginDaitails } = LoginUserDetailsReducer.actions
export default LoginUserDetailsReducer.reducer