import React, { lazy, Component, Suspense } from "react";

import {
  Dimensions,
  Platform,
  ScrollView,
  Text,
  View,
  Image,
  TouchableOpacity,
} from "react-native";
import { Icon, Button } from "react-native-elements";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import ConstantFontFamily from "../constants/FontFamily";
import { heightPercentageToDP as hp } from "react-native-responsive-screen";
// import ShadowSkeletonComment from "../components/ShadowSkeletonComment";

import CreateCommentCardWeb from "../components/CreateCommentCardWeb";
import Colors from "../constants/Colors";
import ButtonStyle from "../constants/ButtonStyle";
import {
  getAuth,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import {
  UserLoginMutation,
  UserFollowingMutation,
  ExternalFeedFollowingMutation,
  TopicFollowingMutation,
  ClikFollowingMutation,
} from "../graphqlSchema/UserMutation";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { setUserApproachAction } from "../actionCreator/UserApproachAction";
import {
  saveUserLoginDaitails,
  setLoginStatus,
} from "../actionCreator/UserAction";
import { setUSERNAMEMODALACTION } from "../actionCreator/UsernameModalAction";
import applloClient from "../client";
import { getCurrentUserProfileDetails } from "../actionCreator/UserProfileDetailsAction";
// import NavigationService from "../library/NavigationService";
// import { showbox, showMoreId } from '../reducers/action/Completed'
import { showMoreId, showbox } from "../reducers/ShowCommentReducer";
import PostDetailsComment from "../components/PostDetailsComment";

// const PostDetailsComment = lazy(() => import("../components/PostDetailsComment"))

class CommentDetailScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listScroll: 0,
      showSkeleton: true,
      commentShow: true,
    };
    this.myScroll = React.createRef();
  }

  async componentDidMount() {
    this.props.showbox({ show: false, id: "", showmoreId: "" });
    this.props.showMoreId({ id: "", showmoreId: "" });
    setTimeout(async () => {
      // await this.props.setPostCommentReset({
      //   payload: [],
      //   postId: "",
      //   title: "",
      //   loading: true,
      // });
      if (this.props.Loading) {
        this.setState({ showSkeleton: true });
      } else {
        this.setState({ showSkeleton: false });
      }
    }, 500);

    if (this.props.PostCommentDetails) {
      setTimeout(() => {
        if (Platform.OS == "web" && Dimensions.get("window").width >= 1200) {
          this.handleCommentLoading();
        }
      }, 100);
    }
  }

  handleCommentLoading = async () => {
    this.setState({ commentShow: true });
    setTimeout(() => {
      this.setState({ commentShow: false });
    }, 200);
  };

  onClose = () => {
    this.setState({
      modalVisible: false,
    });
  };
  loginWithGoogle = async () => {
    if (Platform.OS == "web") {
      await this.loginWithGoogleWeb();
    }
    //  else {
    //   await this.props.leftPanelModalFunc(false)
    //   await this.loginWithGoogleMobile();
    // }
  };

  loginWithGoogleWeb = async () => {
    this.setState({
      buttonName: "Logging in...",
      UserEmail: "",
      UserPassword: "",
    });
    // await this.props.setLoginButtonText("Logging in...");
    await this.props.setGoogleLogin(true);
    await this.props.setUserApproachAction({ type: "login" });
    await this.props.setAnonymousUser({ value: false, token: "" });
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: "select_account",
    });
    const auth = getAuth();
    await signInWithPopup(auth, provider);

    // this.getNewFeedList()

    await applloClient
      .query({
        query: UserLoginMutation,
        variables: {
          id: "Account:" + "CurrentUser",
        },
        fetchPolicy: "no-cache",
      })
      .then(async (res) => {
        let userfollow = await applloClient
          .query({
            query: UserFollowingMutation,
            variables: {
              id: "User:CurrentUser",
              first: 20,
            },
            fetchPolicy: "no-cache",
          })
          .then((e) => {
            return e.data.node.users_following.edges;
          });

        let topicfollow = await applloClient
          .query({
            query: TopicFollowingMutation,
            variables: {
              id: "User:CurrentUser",
              first: 20,
            },
            fetchPolicy: "no-cache",
          })
          .then((e) => {
            return e.data.node.topics_following.edges;
          });
        let clikfollow = await applloClient
          .query({
            query: ClikFollowingMutation,
            variables: {
              id: "User:CurrentUser",
              first: 20,
            },
            fetchPolicy: "no-cache",
          })
          .then((e) => {
            return e.data.node.cliks_following.edges;
          });
        let externalfollow = await applloClient
          .query({
            query: ExternalFeedFollowingMutation,
            variables: {
              id: "User:CurrentUser",
              first: 20,
            },
            fetchPolicy: "no-cache",
          })
          .then((e) => {
            return e.data.node.external_feeds_following.edges;
          });
        let userData = {
          data: res.data.node,
          externalfollow: externalfollow,
          clikfollow: clikfollow,
          topicfollow: topicfollow,
          userfollow: userfollow,
        };
        await this.props.saveLoginUser(userData);
        // setTimeout(() => {
        //   this.setState({
        //     buttonName: "Login"
        //   });
        //   this.props.setLoginButtonText("Login");
        // }, 2000);

        this.props.userId({
          username: res.data.node.user.username,
          type: "feed",
        });
        await AsyncStorage.setItem("userLoginId", res.data.node.user.id);
        await AsyncStorage.setItem("MyUserUserId", res.data.node.user.id);
        await AsyncStorage.setItem("userIdTokenWeclikd", this.state.access_key);
        await AsyncStorage.setItem("UserId", res.data.node.user.id);
        await this.props.changeLoginStatus(1);
        // this.props.onClose()
      });
  };

  render() {
    // console.log(this.props?.PostId,'this.props.PostCommentDetails');
    return (
      <>
        <View
          style={[
            {
              width: "100%",
              backgroundColor: Colors.whiteBackground,
              height: "100%",
              // paddingBottom: this.props.PostCommentDetails.length > 0 ? 20 : 0,
            },
          ]}
        >
          {/* {this.props.loginStatus==0 ?
            <View
              style={{
                backgroundColor: "#fff",
                borderColor: "#c5c5c5",
                borderRadius: 6,
                width: '96%',
                marginLeft: '2%',
                height: Dimensions.get('window').width >= 750 ? 380 : 500,
                alignItems: 'center',
                justifyContent: 'center'

              }}>
              <View
                style={{
                  alignItems: "center",
                  marginVertical: 30,

                }}
              >
                <Text style={[ButtonStyle.profileTitleStyle, { fontSize: 16, marginBottom: 20 }]}>New to Weclikd?</Text>

                <Button
                  buttonStyle={[
                    styles.GbuttonStyle,
                    { borderColor: Colors.blueColor, marginTop: 0, width: 300, borderRadius: 30 }
                  ]}
                  title="Login with Google"
                  titleStyle={styles.Gbuttontextstyle}
                  onPress={this.loginWithGoogle}
                  icon={
                    <Image
                      source={require("../assets/image/gLogin.png")}
                      style={styles.Gicon}
                    />
                  }
                />

                <Button
                  buttonStyle={[
                    styles.GbuttonStyle,
                    { borderColor: Colors.blueColor, marginTop: 20, width: 300, borderRadius: 30 }
                  ]}
                  title="Create account"
                  titleStyle={styles.Gbuttontextstyle}
                  onPress={() => this.props.setUsernameModalStatus(true)}

                />
                <View
                  style={{
                    width: "100%",
                    marginTop: 20,
                    alignItems: 'flex-start',
                    flexDirection: 'row',
                    paddingLeft: 10
                  }}
                >
                  <Text
                    style={{
                      fontSize: 12,
                      color: "#49525D",
                      fontFamily: ConstantFontFamily.defaultFont,
                      textAlign: "center"
                    }}
                  >
                    By signing up, you agree to the{" "}
                  </Text>

                  <Text
                    style={{
                      fontSize: 12,
                      color: "#49525D",
                      fontFamily: ConstantFontFamily.defaultFont,
                      textAlign: "center"
                    }}
                  >
                    <Text
                      style={{
                        textDecorationLine: "underline"
                      }}
                      onPress={() => {
                        // this.props.onClose();
                        NavigationService.navigate("termsandconditions");
                      }}
                    >
                      Terms of service
                    </Text>
                    <Text>{" "} and {" "}</Text>
                    <Text
                      style={{
                        textDecorationLine: "underline"
                      }}
                      onPress={() => {
                        // this.props.onClose();
                        NavigationService.navigate("privacyPolicy");
                      }}
                    >
                      Privacy Policy.
                    </Text>{" "}

                  </Text>
                </View>
              </View>
            </View> : null
          } */}

          {Dimensions.get("window").width > 750 &&
          Platform.OS == "web" &&
          this.props.showId == this.props.PostId ? (
            <View
              style={{
                // position: 'absolute',
                marginBottom: 10,
                // width: '96%',
                // marginHorizontal: '2%',
              }}
            >
              <CreateCommentCardWeb
                onClose={this.onClose}
                parent_content_id={this.props.PostId}
                clickList={
                  this.props.PostDetails ? this.props.PostDetails : null
                }
                initial="main"
                topComment={
                  this.props.PostDetails && this.props.PostDetails.node
                }
                navigation={this.props.navigation}
                title={this.props.Title}
                type={this.props.type}
              />
            </View>
          ) : null}
          <Suspense fallback={null}>
            <ScrollView
              ref={(ref) => {
                this.myScroll = ref;
              }}
              showsVerticalScrollIndicator={false}
              style={{
                // minHeight: 10,
                height:
                  this.props.show == false && this.state.commentShow == false
                    ? Dimensions.get("window").height - 150
                    : Dimensions.get("window").height - 100,
                // location.pathname.startsWith("/clik") == true ? hp("62%") :
                //   location.pathname.startsWith("/topic") == true ? Dimensions.get('window').height - 480 :
                //     location.pathname.startsWith("/user") == true ? hp('68%') : location.pathname.startsWith("/feeds") == true ?
                //       hp('68%') : Dimensions.get('window').height - 340,
                paddingBottom: 100,
              }}
              onScroll={(event) => {
                this.props.listScroll(event.nativeEvent.contentOffset.y);
                this.setState({
                  listScroll: event.nativeEvent.contentOffset.y,
                  xscroll: event.nativeEvent.contentOffset.x,
                });
              }}
              scrollEventThrottle={16}
            >
              {this.props.showId != this.props.PostId ? (
                <View
                  style={[
                    ButtonStyle.normalFeedStyleForDashboard,
                    ButtonStyle.discussionBox,
                  ]}
                >
                  <TouchableOpacity
                    style={{
                      justifyContent: "center",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                    onPress={() =>
                      this.props.showbox({ show: true, id: this.props.PostId })
                    }
                  >
                    <Text
                      style={{
                        color: "gray",
                        marginRight: 10,
                        fontSize: 20,
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      Click here to start a new discussion
                    </Text>
                    <Image
                      style={{
                        width: 30,
                        height: 30,
                      }}
                      source={require("../assets/image/lightning.png")}
                    />
                  </TouchableOpacity>
                </View>
              ) : null}
              {this.state.showSkeleton &&
                Platform.OS == "web" &&
                Dimensions.get("window").width >= 750 &&
                this.props?.PostCommentDetails?.length > 0 &&
                null // <ShadowSkeletonComment />
              }

              {this.props.commentDelay &&
              this.props.commentDelay == true &&
              Platform.OS == "web" &&
              Dimensions.get("window").width >= 750
                ? null // <ShadowSkeletonComment />
                : null}

              {this.props?.PostCommentDetails?.length > 0 ? (
                <PostDetailsComment
                  left={this.state.listScroll}
                  top={this.state.xscroll}
                  right={true}
                  item={this.props.PostCommentDetails}
                  navigation={this.props.navigation}
                  closeModalhandalListMode={this.handalListMode}
                  clickList={
                    this.props.PostCommentDetails
                      ? this.props.PostCommentDetails
                      : null
                  }
                  PostId={
                    this.props.PostDetails
                      ? this.props.PostDetails.node?.id
                      : null
                  }
                  stopScrolling={() => {}}
                />
              ) : null}

              {this.props.loginStatus == 1 &&
              (this.props?.PostCommentDetails?.length == 0 ||
                this.props.loginStatus == 1) &&
              this.props?.PostCommentDetails?.payload?.length == 0 &&
              this.props.PostId != "" &&
              this.props.show == false ? (
                <View
                  style={{
                    flexDirection: "column",
                    zIndex: -1,
                  }}
                >
                  <View
                    style={{
                      width: "100%",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <Icon
                      color={"#000"}
                      iconStyle={{
                        color: "#fff",
                        justifyContent: "center",
                        alignItems: "center",
                        alignSelf: "center",
                      }}
                      reverse
                      name="comments"
                      type="font-awesome"
                      size={20}
                      containerStyle={{
                        alignSelf: "center",
                      }}
                    />
                  </View>
                  <View
                    style={{
                      flex: 1,
                      width: "100%",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    <Text
                      style={{
                        fontSize: 14,
                        fontWeight: "bold",
                        fontFamily: ConstantFontFamily.defaultFont,
                        color: "#000",
                        alignSelf: "center",
                      }}
                    >
                      No discussions
                    </Text>
                  </View>
                </View>
              ) : null}
            </ScrollView>
          </Suspense>
        </View>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  PostDetails: state.PostDetailsReducer.PostDetails,
  PostCommentDetails: state.PostCommentDetailsReducer.PostCommentDetails,
  PostId: state.PostCommentDetailsReducer.PostId,
  Title: state.PostCommentDetailsReducer.Title,
  Loading: state.PostCommentDetailsReducer.Loading,
  show: state.ShowCommentReducer.show,
  showId: state.ShowCommentReducer.id,
  // commentTitle: state.getCommentTitleReducer.getTitle,
  loginStatus: state.UserReducer.loginStatus,
  // windowSize: state.windowResizeReducer.windowResize,
});

const mapDispatchToProps = (dispatch) => ({
  // setPostCommentReset: (payload) =>
  //   dispatch({ type: "POSTCOMMENTDETAILS_RESET", payload }),
  // setGoogleLogin: payload => dispatch({ type: "SET_GOOGLE_LOGIN", payload }),
  // setAnonymousUser: payload =>
  //   dispatch({ type: "ANONYMOUS_USER", payload }),
  // setUserApproachAction: payload => dispatch(setUserApproachAction(payload)),
  // saveLoginUser: payload => dispatch(saveUserLoginDaitails(payload)),
  // changeLoginStatus: payload => dispatch(setLoginStatus(payload)),
  // setUsernameModalStatus: (payload) =>
  //   dispatch(setUSERNAMEMODALACTION(payload)),
  // userId: (payload) => dispatch(getCurrentUserProfileDetails(payload)),
  showbox: (payload) => dispatch(showbox(payload)),
  // setPostShareModel: (payload) =>
  //   dispatch({ type: "POSTSHARESTATUS", payload }),
  showMoreId: (payload) => dispatch(showMoreId(payload)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CommentDetailScreen
);

export const styles = {
  TextInputStyleClass: {
    marginBottom: 10,
    height: 45,
    paddingLeft: 10,
    borderWidth: 0,
    color: "#000",
    backgroundColor: "#fff",
    fontFamily: ConstantFontFamily.defaultFont,
    fontWeight: "bold",
  },

  forgotpasswordStyle: {
    fontSize: 12,
    color: "#49525D",
    textAlign: "right",
    fontFamily: ConstantFontFamily.defaultFont,
    marginTop: 10,
  },

  buttonStyle: {
    marginVertical: 25,
    backgroundColor: "#000",
    borderRadius: 6,
    height: 40,
    alignItems: "center",
    justifyContent: "center",
    padding: 5,
    width: "20%",
    alignSelf: "center",
  },
  GbuttonStyle: {
    backgroundColor: "#FFFFFF",
    borderRadius: 10,
    height: 50,
    alignItems: "center",
    padding: 5,
    borderWidth: 2,
    borderColor: "#000",
    marginTop: 10,
  },

  Gbuttontextstyle: {
    fontSize: 16,
    color: "#000",
    fontFamily: ConstantFontFamily.defaultFont,
    marginLeft: 5,
  },
  Gicon: {
    height: 25,
    width: 25,
  },
};
