import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loginStatus: 0
};

const UserReducer = createSlice({
  name: 'UserReducer',
  initialState,
  reducers: {
     setLoginStatus(state, action) {
      return { ...state, loginStatus: action.payload }
  },
    
  },
})

export const { setLoginStatus } = UserReducer.actions
export default UserReducer.reducer


// import { fromJS } from "immutable";
// import {
//   LOGIN_STATUS_SUCCESS,
//   LOGIN_STATUS_FAILURE
// } from "../constants/Action";

// const initialState = fromJS({
//   loginStatus: 0,
// });

// export default function UserReducer(state = initialState, action) {
//   const { type, payload } = action;
//   switch (type) {
//     case LOGIN_STATUS_SUCCESS:
//       return state.set("loginStatus", fromJS(payload));

//     case LOGIN_STATUS_FAILURE:
//       return initialState;
//   }
//   return state;
// }
