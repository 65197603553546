import { takeEvery, call, put } from "redux-saga/effects";
import {
  CLIKUSERREQUEST,
  CLIKUSERREQUEST_SUCCESS,
  CLIKUSERREQUEST_FAILURE
} from "../constants/Action";
import { clikApplicationSuccess } from '../reducers/TrendingCliksProfileReducer';
import appolloClient from "../client";
import { ClikUserApplications } from "../graphqlSchema/TrendingMutation";

const getResponse = req => {
  return appolloClient
    .query({
      query: ClikUserApplications,
      variables: {
        id:req.id,
        first: 20,
        // "first": null,
        last: null,
        before: null,
        after: null
      },
      fetchPolicy: "no-cache"
    })
    .then(res => res);
};

export function* ClikUserRequestMiddleware({ payload }) {
  try {
    const response = yield call(getResponse, payload);
    // yield put(clikUserRequestSuccess(response));
    yield put(clikApplicationSuccess(response));

  } catch (err) {
    yield put({
      type: CLIKUSERREQUEST_FAILURE,
      payload: err.message
    });
    console.log(err);
  }
}

export default function* ClikUserRequestMiddlewareWatcher() {
  yield takeEvery(CLIKUSERREQUEST, ClikUserRequestMiddleware);
}
