import { takeEvery, takeLatest, call, put } from 'redux-saga/effects';
import { LIKECONTENT, LIKECONTENT_SUCCESS, LIKECONTENT_FAILURE } from "../constants/Action";
import appolloClient from '../client';
import { LikeContentMutation } from '../graphqlSchema/LikeContentMutation';


const getResponse = (req) => {
    return appolloClient.mutate({
        mutation: LikeContentMutation,
        variables: {
            content_id: req.content_id,
            like_type: req.like_type
        },
        fetchPolicy: 'no-cache'
    }).then(res => res);
};

export function* LikeContentMiddleware({ payload }) {
    try {
        const response = yield call(getResponse, payload);
        yield put({
            type: LIKECONTENT_SUCCESS,
            payload: response
        });
    } catch (err) {
        yield put({
            type: LIKECONTENT_FAILURE,
            payload: err.message
        });
    }
}

export default function* LikeContentMiddlewareWatcher() {
    yield takeLatest(LIKECONTENT, LikeContentMiddleware);
}