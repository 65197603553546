
import { createSlice } from '@reduxjs/toolkit';
// import { CLIKS_PROFILE_DETAILS_FAILURE, CLIKS_PROFILE_DETAILS_SUCCESS } from "../constants/Action";




const initialState = {
    getFeedProfileDetails: {},
};


const FeedProfileReducer = createSlice({
    name: 'topicProfileDetails',
    initialState,
    reducers: {
        feedDetailsSuccess(state, action) {
            // console.log(action, 'actionnnnnnnnnnnn');
            return { ...state, getFeedProfileDetails: action.payload.data.node }
        },
        feedDetailsfailure(state, action) {
            // state.getTrendingCliksProfileDetails=action.payload.data.node
        },

    },
})

export const { feedDetailsSuccess, feedDetailsfailure } = FeedProfileReducer.actions
export default FeedProfileReducer.reducer