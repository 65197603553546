import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isAdmin: false,
  isAdminView: false,
  searchBarStatus: false,
  setKeyEvent: "",
  setDisplayType: "",
  inviteUserDetails: {},
  messageModalStatus: false,
  loginButtonText: "Login",
  googleLogin: false,
  earningModalStatus: false,
  isAnonomyousLogin: false,
  anonymousToken: "",
  tabType: "",
  openCreateCommentModal: false,
  leftPanelOpenStatus: false,
  searchBarOpenStatus: false,
  analyticsTabType: "",
  profileTabType: "",
  notificationCount: 0,
  commentLevel: 2,
  iconUpload: false,
  profileUpload: false,
  updatedPostId: 0,
  showComment: false,
  focusCreateComment: false,
  feedIconUpload: false,
  topicIconUpload: false,
  userIconUpload: false,
  setClikTabView: false,
  clikCardShow: false,
  cardDetails: {},
  setIndex: 3,
  isfeed: false,
  shareLinkModalStatus: false,
  repliedId: "",
  type: "Feeds",
  showDiscussions: false,
  isCompleted: false,
  getPostShareModelStatus: false,
  usernameModalStatus: false,
  linkStatus: false,
  clikInviteModal: {
    clikInviteModalStatus: false,
    clikId: "",
  },
  tabType: "Trending",
  reportUser: {
    reportUserModal: false,
    userId: "",
  },
  postDeleteStatus: false,
  signupmodalStatus: false,
  name: "",
  commentType: "",
  arrowkeys: false,
  searchQuery: false,
  commentSubmit: false,
};

const AdminTrueFalseReducer = createSlice({
  name: "AdminTrueFalseReducer",
  initialState,
  reducers: {
    setGoogleLogin(state, action) {
      return { ...state, googleLogin: action.payload };
    },
    setAdminStatus(state, action) {
      return { ...state, isAdmin: action.payload };
    },
    feedFluctuation(state, action) {
      return { ...state, isfeed: action.payload };
    },
    setSHARELINKMODALACTION(state, action) {
      return { ...state, shareLinkModalStatus: action.payload };
    },
    getRepliedId(state, action) {
      return { ...state, repliedId: action.payload };
    },
    setIndexes(state, action) {
      return { ...state, type: action.payload };
    },
    showDiscussionReducer(state, action) {
      return { ...state, showDiscussions: action.payload };
    },
    clikIconUpload(state, action) {
      return { ...state, iconUpload: action.payload };
    },
    topicIconUpload(state, action) {
      return { ...state, topicIconUpload: action.payload };
    },
    userIconUpload(state, action) {
      return { ...state, userIconUpload: action.payload };
    },
    feedIconUpload(state, action) {
      return { ...state, feedIconUpload: action.payload };
    },
    IsCompleted(state, action) {
      return { ...state, isCompleted: action.payload };
    },
    setDisplayType(state, action) {
      return { ...state, setDisplayType: action.payload };
    },
    setPostShareModel(state, action) {
      return { ...state, getPostShareModelStatus: action.payload };
    },
    leftPanelModalFunc(state, action) {
      return { ...state, leftPanelOpenStatus: action.payload };
    },
    setUSERNAMEMODALACTION(state, action) {
      return { ...state, usernameModalStatus: action.payload };
    },
    setInviteLinkStatus(state, action) {
      return { ...state, linkStatus: action.payload };
    },
    setclikInviteModal(state, action) {
      return {
        ...state,
        clikInviteModalStatus: action.payload.payload.clikInviteModalStatus,
        clikId: action.payload.payload.clikId,
      };
    },
    setTabView(state, action) {
      return { ...state, tabType: action.payload };
    },
    searchOpenBarStatus(state, action) {
      return { ...state, searchBarOpenStatus: action.payload };
    },
    showReportUserModal(state, action) {
      console.log(action, "action");
      return {
        ...state,
        reportUserModal: action.payload.payload.reportUserModal,
        userId: action.payload.payload.userId,
      };
    },
    deletePostAction(state, action) {
      return { ...state, postDeleteStatus: action.payload };
    },

    setSIGNUPMODALACTION(state, action) {
      return { ...state, signupmodalStatus: action.payload };
    },

    setMessageModalStatus(state, action) {
      return { ...state, messageModalStatus: action.payload };
    },
    userNameGet(state, action) {
      return { ...state, name: action.payload };
    },

    setCommentType(state, action) {
      return { ...state, commentType: action.payload };
    },
    setArrowkeys(state, action) {
      return { ...state, arrowkeys: action.payload };
    },
    setSearchQuery(state, action) {
      return { ...state, searchQuery: action.payload };
    },
    setCommentSubmit(state, action) {
      return { ...state, commentSubmit: action.payload };
    },
  },
});

export const {
  setDisplayType,
  IsCompleted,
  setGoogleLogin,
  setAdminStatus,
  feedFluctuation,
  setSHARELINKMODALACTION,
  getRepliedId,
  setIndexes,
  showDiscussionReducer,
  clikIconUpload,
  topicIconUpload,
  userIconUpload,
  feedIconUpload,
  setPostShareModel,
  leftPanelModalFunc,
  setUSERNAMEMODALACTION,
  setInviteLinkStatus,
  setclikInviteModal,
  setTabView,
  searchOpenBarStatus,
  showReportUserModal,
  deletePostAction,
  setMessageModalStatus,
  setSIGNUPMODALACTION,
  userNameGet,
  setCommentType,
  setArrowkeys,
  setSearchQuery,
  setCommentSubmit,
} = AdminTrueFalseReducer.actions;
export default AdminTrueFalseReducer.reducer;
