import React, { useEffect, useState, useRef } from "react";
import {
  // AsyncStorage,
  Dimensions,
  Image,
  Platform,
  Text,
  TouchableOpacity,
  View,
  Modal,
  TextInput,
} from "react-native";
import appolloClient from "../client";

import { connect } from "react-redux";
import { compose } from "react-recompose";
import { heightPercentageToDP as hp } from "react-native-responsive-screen";
import ConstantFontFamily from "../constants/FontFamily";
import { Button, Icon } from "react-native-elements";
import Colors from "../constants/Colors";
import ButtonStyle from "../constants/ButtonStyle";
import { showMoreId, showbox } from "../reducers/ShowCommentReducer";
import { CreateCommentVariables } from "../graphqlSchema/LikeContentVariables";
import { CreateCommentMutation } from "../graphqlSchema/LikeContentMutation";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { setPostCommentDetails } from "../actionCreator/PostCommentDetailsAction";
import SearchinputComment from "./SearchInputComponent";
import CreateCommentStyle from "./Style/CreateCommentStyle";
import {
  setArrowkeys,
  setCommentSubmit,
} from "../reducers/AdminTrueFalseReducer";
// import { indexOf } from "lodash";

const CreateCommentCardWeb = (props) => {
  const editorReference = useRef();
  const [title, settitle] = useState("");
  const [item, setitem] = useState([]);
  const [click, setclick] = useState(null);
  const [getBorderColor, setBorderColor] = useState("#e8e8e8");
  // const [editorState, onChange] = useState(EditorState.createEmpty());
  const [placeholderState, setPlaceholderState] = useState(true);
  const [titleContent, setTitleContent] = useState("char. required");
  const [getSubmitData, setSubmitData] = useState("");
  const [getLockStatus, SetLockStatus] = useState(false);
  const [getSelectedItem, setSelectedItem] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedCliks, setSelectedCliks] = useState({});
  const [opened, setOpened] = useState(false);
  const [getFeedItem, setFeedItem] = useState("");
  const [isReadOnly, setisReadOnly] = useState(false);
  const SearchComment = useRef(null);
  let [clikData, setClikData] = useState([]);
  let [userData, setUserData] = useState([]);
  let [cancel, setCancel] = useState(false);
  const [searchnput, setSearchInput] = useState(false);
  const [SubmitColor, setSubmitColor] = useState(false);
  const [ShowDummyText, setShowDummyText] = useState(true);
  const [displayBox, setdisplayBox] = useState(false);
  const [text, setText] = useState("");
  const [close, setClose] = useState(false);

  const comment = useRef(null);

  const submitComment = () => {
    props.setArrowkeys(false);
    props.setCommentSubmit(!props.commentSubmit);
    // props.showbox({ id: "", show: false })
    setisReadOnly(false);
    let trimmedData = "";

    trimmedData = title ? title.replace(/  +/g, " ") : title;

    CreateCommentVariables.variables.text = trimmedData;
    CreateCommentVariables.variables.parent_content_id =
      props.parent_content_id;

    if (props.initial != "main") {
      CreateCommentVariables.variables.clik = props.topComment.clik
        ? props.topComment.clik
        : null;
    } else {
      if (click == null) {
        CreateCommentVariables.variables.clik = null;
      } else if (click == "Everyone" || click == "all") {
        CreateCommentVariables.variables.clik = null;
      } else {
        CreateCommentVariables.variables.clik = click
          .toLowerCase()
          .replace("#", "");
      }
    }
    let clik = [];
    let user1 = [];
    clikData.map((e) => {
      clik.push(e.id);
    });

    userData.map((e) => {
      user1.push(e.id);
    });

    appolloClient
      .query({
        query: CreateCommentMutation,
        variables: {
          parent_id: props.parent_content_id,
          text: trimmedData,
          cliks: clik.length > 0 ? clik : null,
          users: user1.length > 0 ? user1 : null,
        },
        fetchPolicy: "no-cache",
      })
      .then(async (res) => {
        settitle("");
        setclick(null);

        let prevCommentList = props.PostCommentDetails;

        let data = {
          node: {
            cliks: res.data.comment_create.discussion.cliks,
            comment: {
              author: res.data.comment_create.comment.author,
              created: res.data.comment_create.comment.created,
              id: res.data.comment_create.comment.id,
              num_comments: res.data.comment_create.comment.num_comments,
              num_likes: res.data.comment_create.comment.num_likes,
              num_reports: res.data.comment_create.comment.num_reports,
              text: res.data.comment_create.comment.text,
              comments: { edges: [] },
              path: res.data.comment_create.comment.path,
            },
            users: res.data.comment_create.discussion.users,
            id: "Discussion:2767176656819853225",
            locked: res.data.comment_create.discussion.locked,
            private: res.data.comment_create.discussion.private,
            path: [],
          },
          // id: props.parent_content_id
        };
        // console.log(props.PostCommentDetails);
        // props.setCommentStatus(true)
        props.showbox({ show: false, id: "", showmoreId: "" });
        if (props.showMore == "no") {
          props.showMoreId({ id: "", showmoreId: "" });
        } else {
          props.showMoreId({ id: "", showmoreId: props.showMoreIdd });
        }

        if (props.parent_content_id.startsWith("Comment")) {
          let updatedArray = updateNestedArray1(
            prevCommentList,
            props.parent_content_id,
            data
          );

          console.log(updatedArray, "updatedArray");

          props.setPostCommentDetails([...updatedArray]);
          // props.onClose()
        } else {
          // props.setTotalComment(props.totalComment + 1)
          // props.parentCommentId(props.parent_content_id)
          props.setPostCommentDetails([data, ...prevCommentList]);
        }
      })
      .catch((e) => {
        console.log(
          e.message.toLowerCase().replace("CreateCommentMutation error: ", "")
        );
      });
    setPlaceholderState(true);
    setSelectedCliks({});
    setSelectedUser([]);
  };

  const showClose = () => {
    setClose(true);
  };

  const handleUserDelete = (e) => {
    // console.log(userData,'eeeeee');
    let user = userData;
    user.splice(user.indexOf(e), 1);

    // let user=[...userData]
    // console.log(user, 'iiii');
    setText("");
    setUserData([...user]);
  };

  const handleClikDelete = (e) => {
    // console.log(userData,'eeeeee');
    let clik = clikData;
    clik.splice(clik.indexOf(e), 1);
    setText("");

    // let user=[...userData]
    // console.log(user,'iiii');
    setClikData([...clik]);
  };

  const showDisplayBox = (e) => {
    setText(e);
    if (e.startsWith("@")) {
      setdisplayBox(true);
    } else {
      setdisplayBox(false);
    }
  };

  return (
    <>
      <View
        style={[
          // props.initial == "main" ? ButtonStyle.normalFeedStyleForDashboard : null,
          {
            backgroundColor: "#fff",
            marginTop: 15,
            marginLeft: props.initial == "child" ? 0 : 0,
            // marginRight:'2%',
            borderRadius: 15,
            borderColor: "#d3d3d3",
            // width: Dimensions.get("window").width >= 750 && props.initial == "main" ? '100%' :
            // Dimensions.get("window").width >= 750 && props.initial != "main" ? '90%' : '95%',
            borderWidth: props.initial == "main" ? 1 : 0,
            marginBottom: props.initial == "main" ? 0 : 10,
            width: "100%",
            paddingBottom: props.initial == "child" ? 0 : 10,
          },
        ]}
      >
        {props.initial == "main" ? (
          <View
            style={{
              width: "100%",
              flexDirection: "row",
              alignItems: "center",
              marginTop: 10,
              justifyContent: "space-evenly",
            }}
          >
            {getLockStatus ? (
              <View>
                <Icon
                  size={25}
                  name="lock"
                  type="font-awesome"
                  color="#000"
                  underlayColor="#fff"
                  onPress={() => {
                    !getLockStatus
                      ? alert(
                          "Discussion will be closed to users and cliks that were invited"
                        )
                      : alert("Discussion will be open to everyone");
                    SetLockStatus(false);
                  }}
                />
              </View>
            ) : null}
            {!getLockStatus ? (
              <View>
                <Icon
                  size={25}
                  name="unlock-alt"
                  type="font-awesome"
                  color="#000"
                  underlayColor="#fff"
                  onPress={() => {
                    !getLockStatus
                      ? alert(
                          "Discussion will be closed to users and cliks that were invited"
                        )
                      : alert("Discussion will be open to everyone");
                    SetLockStatus(true);
                  }}
                />
              </View>
            ) : null}

            <View style={CreateCommentStyle.tagsMainView}>
              {clikData.length > 0 || userData.length > 0 ? (
                <View style={CreateCommentStyle.tagWrapView}>
                  {clikData.map((e) => {
                    return (
                      <View
                        style={[
                          ButtonStyle.clikNameBackgroundStyle,
                          {
                            marginLeft: 5,
                            backgroundColor: Colors.clikNamebackgroundColor,
                            flexDirection: "row",
                            flexWrap: "wrap",
                          },
                        ]}
                        key={e.id}
                      >
                        <Text
                          style={[
                            ButtonStyle.clikNameTitleStyle,
                            {
                              paddingHorizontal: 5,
                              color: Colors.blueColor,
                            },
                          ]}
                        >
                          #{e.name}
                        </Text>
                        <Icon
                          name="close"
                          size={18}
                          type="font-awesome"
                          iconStyle={{ marginLeft: 5 }}
                          onPress={() => {
                            handleClikDelete(e);
                          }}
                        />
                      </View>
                    );
                  })}
                  {userData.map((e) => {
                    // console.log(e, 'eeeeeee');
                    return (
                      <View
                        style={[
                          ButtonStyle.clikNameBackgroundStyle,
                          {
                            marginLeft: 10,
                            flexDirection: "row",
                            borderWidth: 1,
                            alignItems: "center",
                          },
                        ]}
                        key={e.id}
                      >
                        <Text
                          style={[
                            ButtonStyle.clikNameTitleStyle,
                            {
                              paddingHorizontal: 5,
                            },
                          ]}
                        >
                          {e.username}
                        </Text>
                        <Icon
                          name="close"
                          size={18}
                          type="font-awesome"
                          iconStyle={{ marginLeft: 5 }}
                          onPress={() => {
                            handleUserDelete(e);
                          }}
                        />
                      </View>
                    );
                  })}
                </View>
              ) : (
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "90%",
                  }}
                >
                  <TextInput
                    placeholder="Tag @users"
                    style={{ outline: "none" }}
                    onFocus={() => showClose()}
                    value={text}
                    onChangeText={(e) => showDisplayBox(e)}
                  />
                </View>
              )}
              {close ? (
                <Icon
                  name="close"
                  size={18}
                  type="font-awesome"
                  iconStyle={{ marginHorizontal: 5 }}
                  onPress={() => {
                    setdisplayBox(false), setText("");
                  }}
                />
              ) : null}
            </View>

            <TouchableOpacity
              onPress={() => props.showbox({ id: "", show: false })}
            >
              <Icon
                size={20}
                name="trash-bin"
                type="ionicon"
                color="red"
                underlayColor="#fff"
              />
            </TouchableOpacity>
          </View>
        ) : null}
        <View
          style={{
            height: 220,
            position: "relative",
          }}
        >
          {displayBox ? (
            <View
              style={{
                height: 100,
                backgroundColor: "#fff",
                flex: 1,
                position: "absolute",
                zIndex: 5,
                width: "85%",
                marginLeft: "8%",
              }}
            >
              <SearchinputComment
                input={text}
                setClikData={setClikData}
                setUserData={setUserData}
                setdisplayBox={setdisplayBox}
              />
            </View>
          ) : null}
          {props.initial == "main" && title.length > 0 ? (
            <View
              style={{
                justifyContent: "flex-end",
                alignSelf: "flex-end",
                marginTop: 10,
                marginBottom: 10,
                marginRight: 30,
                height: 30,
              }}
            >
              {props.initial == "main" && title.length < 3000 && (
                <Text
                  style={{
                    color: title.length < 25 ? "#de5246" : "#009B1A",
                    fontSize: 16,
                    fontFamily: ConstantFontFamily.defaultFont,
                    alignSelf: "center",
                    marginRight: 10,
                  }}
                >
                  {title.length < 25
                    ? `${25 - title.length} ${titleContent}`
                    : title.length < 145
                    ? `${144 - title.length} char. left`
                    : null}
                </Text>
              )}
            </View>
          ) : null}

          {props.initial == "main" ? (
            <View
              style={{
                minHeight: 175,
                maxHeight: hp("55%"),
              }}
            >
              <TextInput
                style={[
                  CreateCommentStyle.textInputStyle,
                  {
                    marginTop: 75,
                    paddingLeft: 20,
                  },
                ]}
                value={title}
                onChangeText={(e) => {
                  if (e.length > 0) {
                    setShowDummyText(false);
                  } else {
                    setShowDummyText(true);
                  }
                  settitle(e);
                }}
                autoFocus={true}
                ref={comment}
                multiline={true}
                maxLength={props.initial == "main" ? 144 : 3000}
                placeholder={
                  props.initial == "main"
                    ? "Write a succinct discussion topic that is \n\u2022 debatable\n\u2022 thought provoking."
                    : "write one insightful comment"
                }
                placeholderTextColor="#d3d3d3"
              />
            </View>
          ) : (
            <View
              style={{
                marginTop: 20,
                width: "92%",
                alignSelf: "flex-end",
                marginRight: 15,
                borderColor: "#d3d3d3",
              }}
            >
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Image
                  source={
                    props.profileData.data.user.profile_pic
                      ? { uri: props.profileData.data.user.profile_pic }
                      : require("../assets/image/logolastOne.png")
                  }
                  style={{
                    width: 30,
                    height: 30,
                    borderRadius: 6,
                  }}
                />
                <Text style={{ marginLeft: 5 }}>
                  {props.profileData?.data?.user?.username}
                </Text>
              </View>
              <TextInput
                style={{
                  minHeight: 140,
                  maxHeight: hp("55%"),
                  fontSize: 15,
                  color: "#000",
                  marginTop: 2,
                  borderWidth: 1,
                  borderColor: "#d3d3d3",
                  marginTop: 10,
                  padding: 10,
                  fontStyle: ConstantFontFamily.defaultFont,
                  borderRadius: 15,
                  outline: "none",
                }}
                autoFocus={true}
                value={title}
                onChangeText={(e) => {
                  if (e.length > 0) {
                    setShowDummyText(false);
                  } else {
                    setShowDummyText(true);
                  }
                  settitle(e);
                }}
                ref={comment}
                multiline={true}
                maxLength={props.initial == "main" ? 144 : 3000}
              />
            </View>
          )}
          {props.initial != "main" ? (
            <View
              style={{ flexDirection: "row", alignItems: "center", height: 30 }}
            >
              {title.length < 3000 && (
                <Text
                  style={{
                    color: title.length < 140 ? "#de5246" : "#009B1A",
                    fontSize: 16,
                    fontFamily: ConstantFontFamily.defaultFont,
                    alignSelf: "center",
                    marginRight: 10,
                    marginLeft: Dimensions.get("window").width <= 750 ? 40 : 20,
                    marginBottom: 10,
                  }}
                >
                  {title
                    ? title.length < 144
                      ? `${144 - title.length} ${titleContent}`
                      : `${3000 - title.length} char. left`
                    : ""}
                </Text>
              )}
            </View>
          ) : null}
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TouchableOpacity
            style={{
              marginRight: 10,
              borderWidth: 1,
              width: 130,
              height: 30,
              borderRadius: 20,
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
              borderColor: Colors.blueColor,
              backgroundColor: "red",
              marginTop: props.initial == "main" ? 10 : 5,
            }}
            onPress={() => {
              props.showbox({ show: false, id: "", showmoreId: "" });
            }}
          >
            <Text style={[ButtonStyle.titleStyle, { color: "#fff" }]}>
              Cancel
            </Text>
          </TouchableOpacity>

          <TouchableOpacity
            style={{
              marginRight: 10,
              borderWidth: 1,
              width: 130,
              height: 30,
              borderRadius: 20,
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
              borderColor: Colors.blueColor,
              backgroundColor: "#D0DAF8",
              opacity:
                props.initial == "main" && title.length < 25
                  ? 0.3
                  : props.initial != "main" && title.length < 145
                  ? 0.3
                  : 1,
              marginTop: props.initial == "main" ? 10 : 5,
            }}
            disabled={
              props.initial == "main" && title.length < 25
                ? true
                : props.initial != "main" && title.length < 145
                ? true
                : false
            }
            onPress={() => {
              if (props.initial == "main") {
                submitComment();
              } else {
                submitComment();
                //   onChange(
                //     EditorState.push(
                //       editorState,
                //       ContentState.createFromText("")
                //     )
                //   );
              }
            }}
          >
            <Text style={[ButtonStyle.titleStyle, { color: Colors.blueColor }]}>
              Submit
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </>
  );
};

const mapStateToProps = (state) => ({
  profileData: state.LoginUserDetailsReducer.userLoginDetails,
  PostDetails: state.PostDetailsReducer.PostDetails,
  PostCommentDetails: state.PostCommentDetailsReducer.PostCommentDetails,
  PostId: state.PostCommentDetailsReducer.PostId,
  Title: state.PostCommentDetailsReducer.Title,
  Loading: state.PostCommentDetailsReducer.Loading,
  show: state.ShowCommentReducer.show,
  showId: state.ShowCommentReducer.id,
  loginStatus: state.UserReducer.loginStatus,
  windowSize: state.windowResizeReducer.windowResize,
  commentSubmit: state.AdminTrueFalseReducer.commentSubmit,
});

const mapDispatchToProps = (dispatch) => ({
  // userId: (payload) => dispatch(getCurrentUserProfileDetails(payload)),
  setPostCommentDetails: (payload) => dispatch(setPostCommentDetails(payload)),
  // showbox: (payload) => dispatch(showbox(payload)),
  // setLoginModalStatus: (payload) => dispatch(setLOGINMODALACTION(payload)),
  // focusCreateComment: (payload) =>
  //   dispatch({ type: "FOCUS_CREATE_COMMENT", payload }),
  // setTotalComment: (payload) => dispatch(setTotalComment(payload)),
  // setNestedTotalComment: (payload) => dispatch(setNestedTotalComment(payload)),
  // setCommentStatus: (payload) => dispatch(setCommentStatus(payload)),
  // showbox: (payload) => dispatch(showbox(payload)),
  // parentCommentId: (payload) => dispatch(parentCommentId(payload)),
  // setPostShareModel: (payload) =>
  //   dispatch({ type: "POSTSHARESTATUS", payload }),
  // showMoreComment: (payload) => dispatch(showMoreComment(payload)),
  // showMoreId: (payload) => dispatch(showMoreId(payload)),
  // setZindexZero: payload => dispatch({ type: "SET_ZINDEX", payload })

  setPostCommentReset: (payload) =>
    dispatch({ type: "POSTCOMMENTDETAILS_RESET", payload }),
  setGoogleLogin: (payload) => dispatch({ type: "SET_GOOGLE_LOGIN", payload }),
  setAnonymousUser: (payload) => dispatch({ type: "ANONYMOUS_USER", payload }),
  setUserApproachAction: (payload) => dispatch(setUserApproachAction(payload)),
  saveLoginUser: (payload) => dispatch(saveUserLoginDaitails(payload)),
  changeLoginStatus: (payload) => dispatch(setLoginStatus(payload)),
  setUsernameModalStatus: (payload) =>
    dispatch(setUSERNAMEMODALACTION(payload)),
  userId: (payload) => dispatch(getCurrentUserProfileDetails(payload)),
  showbox: (payload) => dispatch(showbox(payload)),
  setPostShareModel: (payload) =>
    dispatch({ type: "POSTSHARESTATUS", payload }),
  showMoreId: (payload) => dispatch(showMoreId(payload)),
  setArrowkeys: (payload) => dispatch(setArrowkeys(payload)),
  setCommentSubmit: (payload) => dispatch(setCommentSubmit(payload)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  CreateCommentCardWeb
);

// function updateNestedArray1(input, parentId, newData) {
//   console.log(input, parentId, newData, 'newwww');

//   input?.map(com=>{
//     if(com.node.comment.id==parentId){

//     } else{
//       setCommentArray(com.node.comment.comments.edges)
//     }
//   })

//   function setCommentArray(arr){
// console.log(arr,'arrarr');
//     // arr?.map(ncom=>{
//     //   console.log(ncom,'commm');
//     // })

//   }

// }

function addNestedSwipe(arr) {
  let AddSwipeArray = [...arr];
  AddSwipeArray.map((e) => {
    let newObj = {
      ...e,
      swipe: false,
    };
    AddSwipeArray.splice(AddSwipeArray.indexOf(e), 1, newObj);
  });
  return AddSwipeArray;
}

function addArray(nestArr, pid, ndata) {
  let newNestArray = [...nestArr];
  // console.log(newNestArray, 'nes');

  newNestArray.forEach((nval, nInd) => {
    if (nval.node.id == pid) {
      let newSwiped = addNestedSwipe(nval.node.comments.edges);
      // console.log(nval,'nvallllllllll');
      let nOb1 = {
        ...nval,
        swipe: true,
        node: {
          ...nval.node,
          num_comments: nval.node.num_comments + 1,
          comments: {
            ...nval.node.comments,
            edges: [
              ...[{ node: ndata.node.comment, swipe: true }, ...newSwiped],
            ],
          },
        },
      };

      newNestArray.splice(newNestArray.indexOf(nval), 1, nOb1);
    } else {
      newNestArray.splice(newNestArray.indexOf(nval), 1, {
        ...nval,
        swipe: false,
      });

      // console.log(nval, ndata.node.comment.path);
      ndata.node.comment.path.forEach((e, i) => {
        console.log(e == nval.node.id);
        if (nval.node.id == e) {
          console.log("coming inside condition");
          let cdata = addArray(nval.node.comments.edges, pid, ndata);
          // console.log(cdata, 'cdata');
          newNestArray.splice(newNestArray.indexOf(nval), 1, {
            ...nval,
            node: {
              ...nval.node,
              num_comments: nval.node.num_comments + 1,
              comments: {
                ...nval.node.comments,
                edges: [...cdata],
              },
            },
          });
        }
      });
    }
  });

  return newNestArray;
}

function updateNestedArray1(input, parentId, newData) {
  let parentArray = [...input];
  let newData2 = [];

  // console.log(newData, 'newData');

  parentArray.forEach((val, ind) => {
    if (val.node.comment.id == parentId) {
      let nObj = {
        ...val,
        node: {
          ...val.node,
          comment: {
            ...val.node.comment,
            num_comments: val.node.comment.num_comments + 1,
            comments: {
              ...val.node.comment.comments,
              edges: [
                ...[
                  { node: newData.node.comment },
                  ...val.node.comment.comments.edges,
                ],
              ],
            },
          },
        },
      };

      parentArray.splice(parentArray.indexOf(val), 1, nObj);
    } else {
      if (val.node.comment.id == newData.node.comment.path[1]) {
        // console.log(newData, 'newData======================')
        let newArray = addArray(
          val.node.comment.comments.edges,
          parentId,
          newData
        );
        console.log(newArray, "newArray");
        console.log(val, "val");
        parentArray.splice(parentArray.indexOf(val), 1, {
          ...val,
          node: {
            ...val.node,
            comment: {
              ...val.node.comment,
              num_comments: val.node.comment.num_comments + 1,
              comments: {
                ...val.node.comment.comment,
                edges: [...newArray],
              },
            },
          },
        });
      }
    }
  });

  console.log(parentArray, "parentArray");

  return parentArray;

  // input.forEach(e => {
  //   if (e.node.comment.id == parentId) {
  //     let arr = [{ node: newData.node.comment }, ...e.node.comment.comments.edges]

  //     let newArray = [...input]

  //     for (var obj of newArray) {
  //       if (obj.node.comment.id == parentId) {
  //         // console.log(obj,'objjjjj');
  //         let newObj = {
  //           ...obj, node: {
  //             ...obj.node, comment: {
  //               ...obj.node.comment, comments: {
  //                 ...obj.node.comment.comments, edges: [...arr]
  //               }
  //             }
  //           }
  //         }

  //         newArray.splice(newArray.indexOf(obj), 1, newObj)
  //         console.log(newArray, 'llllllllllllllllll');
  //         parentArray = [...newArray]
  //       }
  //     }

  //   } else {
  //     if (e.node.comment.id == newData.node.comment.path[1]) {
  //       let nest = nestArray(e.node.comment.comments.edges)

  //       console.log(nest, 'nest');
  //       let newArray2 = [...input]

  //       let newObj2 = {}
  //       for (var vl of newArray2) {
  //         for (var i of newData.node.comment.path) {
  //           if (vl.node.comment.id == i) {
  //             newObj2 = {
  //               ...vl, node: {
  //                 ...vl.node, comment: {
  //                   ...vl.node.comment, comments: {
  //                     ...vl.node.comment.comments, edges: [...nest]
  //                   }
  //                 }
  //               }
  //             }
  //             newArray2.splice(newArray2.indexOf(vl), 1, newObj2)
  //           }
  //         }
  //       }

  //       console.log(newArray2, 'newarray222');

  //       parentArray = [...newArray2]
  //     }

  //   }
  // });

  // function nestArray(nested, level) {

  //   console.log(nested, 'nested');
  //   nested.forEach(el => {
  //     if (el.node.id == parentId) {
  //       let arr1 = [{ node: newData.node.comment }, ...el.node.comments.edges]
  //       // console.log(arr1, 'arr11111');
  //       let newArray1 = [...nested]

  //       // console.log(newArray1, '1111111');

  //       for (var obj1 of newArray1) {
  //         if (obj1.node.id == parentId) {

  //           let newObj1 = {
  //             ...obj1, node: {
  //               ...obj1.node, comments: {
  //                 ...obj1.node.comments, edges: [...arr1]
  //               }
  //             }
  //           }

  //           newArray1.splice(newArray1.indexOf(obj1), 1, newObj1)

  //         }
  //       }
  //       newData2 = [...newArray1]
  //     } else {
  //       newData.node.comment.path.forEach(ele => {
  //         if (el.node.id == ele) {
  //           let nest1 = nestArray(el.node.comments.edges)
  //           // console.log(nest1, 'nest');

  //           let newA = [...nested]

  //           for (var obj1 of newA) {
  //             newData.node.comment.path.forEach(ele => {
  //               if (obj1.node.id == ele) {

  //                 let newObj1 = {
  //                   ...obj1, node: {
  //                     ...obj1.node, comments: {
  //                       ...obj1.node.comments, edges: [...nest1]
  //                     }
  //                   }
  //                 }

  //                 newA.splice(newA.indexOf(obj1), 1, newObj1)

  //               }
  //             })
  //           }

  //           newData2 = [...newA]

  //         }

  //       });

  //     }

  //   });

  //   return newData2

  // }

  // console.log(parentArray, 'parenttt array');

  // return parentArray;
}
