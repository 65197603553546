import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    PostCommentDetails: [],
    selectComment: {
        currentId: null,
        prevId: null
    },
    PostId: "Post:0",
    Title: "",
    Loading: true,
    item:null
}

const PostCommentDetailsReducer = createSlice({
    name: 'PostCommentDetails',
    initialState,
    reducers: {
        setPostCommentDetailSuccess(state, action) {
            return { ...state, PostId: action.payload.postId, Title: action.payload.title, Loading: action.payload.loading, PostCommentDetails: action.payload.payload }
            // .PostId = action.payload.id,
            // state.Title = action.payload.title,
            // state.Loading = action.payload.loading
        },
        setData(state, action) {
            return { ...state, item: action.payload }
            // .PostId = action.payload.id,
            // state.Title = action.payload.title,
            // state.Loading = action.payload.loading
        },
        setPostCommentDetailFailure(state, action) {
            return state
        }
    }
})

export const { setPostCommentDetailSuccess, setPostCommentDetailFailure,setData } = PostCommentDetailsReducer.actions
export default PostCommentDetailsReducer.reducer