// import "@expo/browser-polyfill";
import _ from "lodash";
// import { List } from "immutable";
import React, { Component } from "react";
// import { graphql } from "react-apollo";
import { Image, TouchableOpacity } from "react-native";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import { setLikeContent } from "../actionCreator/LikeContentAction";
import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";
import { getTrendingClicks } from "../actionCreator/TrendingCliksAction";
import { getTrendingExternalFeeds } from "../actionCreator/TrendingExternalFeedsAction";
import { getTrendingTopics } from "../actionCreator/TrendingTopicsAction";
import { getTrendingUsers } from "../actionCreator/TrendingUsersAction";
import { saveUserLoginDaitails } from "../reducers/LoginUserDetailsReducer";
import appolloClient from "../client";
// import AppHelper from "../constants/AppHelper";
import {
  FollowMutation,
  UnFollowMutation,
} from "../graphqlSchema/FollowandUnFollowMutation";
import { UserLoginMutation, ClikFollowingMutation, UserFollowingMutation, ExternalFeedFollowingMutation, TopicFollowingMutation } from "../graphqlSchema/UserMutation";
import {
  FollowVariables,
  UnFollowVariables,
} from "../graphqlSchema/FollowandUnfollowVariables";
import { getTrendingTopicsProfileDetails } from "../actionCreator/TrendingTopicsProfileAction";

class TopicStar extends Component {
  constructor(props) {
    super(props);
    const gstar = require("../assets/image/gstar.png");
    const ystar = require("../assets/image/ystar.png");
    const wstar = require("../assets/image/wstar.png");

    this.state = {
      index: 0,
      starList: [wstar, gstar, ystar],
      followList: ["TRENDING", "FOLLOW", "FAVORITE"],
    };
  }

  async componentDidMount() {
    await this.getTopicStar(this.props.TopicName);
  }

  componentDidUpdate = async (prevProps) => {
    if (prevProps.TopicName !== this.props.TopicName) {
      await this.getTopicStar(this.props.TopicName);
    }
    if (
      prevProps.getUserFollowTopicList !== this.props.getUserFollowTopicList
    ) {
      await this.getTopicStar(this.props.TopicName);
    }
  };

  getTopicStar = async (TopicName) => {
    if (this.props.loginStatus == 0) {
      await this.setState({
        index: 0,
      });
      return false;
    }

    let index = 0;
    index = this.props.getUserFollowTopicList.findIndex(
      (i) => i.node.topic.id.toLowerCase() == TopicName && TopicName.toLowerCase()
    );
    if (index == -1) {
      await this.setState({
        index: 0,
      });
    } else if (
      this.props.getUserFollowTopicList[index].node.follow_type == "FOLLOW"
    ) {
      await this.setState({
        index: 1,
      });
    } else {
      await this.setState({
        index: 2,
      });
    }
  };

  changeStar = async () => {
    if (this.props.loginStatus == 0) {
      this.props.setLoginModalStatus(true);
      return false;
    }
    if (this.state.index + 1 == this.state.starList.length) {
      await this.setState({
        index: 0,
      });
      await this.changeStarApi(this.state.followList[this.state.index]);
    } else {
      await this.setState({
        index: this.state.index + 1,
      });
      await this.changeStarApi(this.state.followList[this.state.index]);
    }
  };

  changeStarApi = _.debounce((typeIndex) => {
    typeIndex == "FOLLOW"
      ? this.followTopics(this.props.TopicName)
      : typeIndex == "FAVORITE"
        ? this.favroiteTopics(this.props.TopicName)
        : this.unfollowTopics(this.props.TopicName);
  }, 1000);

  followTopics = async (topicId) => {
    if (this.props.loginStatus == 0) {
      this.props.setLoginModalStatus(true);
      return false;
    }
    FollowVariables.variables.id = "Topic:" + topicId.replace("topic:", "");
    FollowVariables.variables.follow_type = "FOLLOW";
    appolloClient
      .query({
        query: FollowMutation,
        ...FollowVariables,
        fetchPolicy: "no-cache",
      })
      .then(async (res) => {
        await appolloClient
          .query({
            query: TopicFollowingMutation,
            variables: {
              id: "User:CurrentUser",
              first: 50
            },
            fetchPolicy: "no-cache"
          }).then(e => {


            let userData = {
              data: this.props.profileData,
              externalfollow: this.props.getUserFollowFeedList,
              clikfollow: this.props.getUserFollowCliksList,
              topicfollow: e.data.node.topics_following.edges,
              userfollow: this.props.getUserFollowUserList

            }
            this.props.saveLoginUser(userData);
            // let resDataLogin = await this.props.Login();
            // await this.props.saveLoginUser(resDataLogin.data.login);
            // setTimeout(async () => {
               this.props.updateStar();

            

          })
      });
  };

  favroiteTopics = async (topicId) => {
    if (this.props.loginStatus == 0) {
      this.props.setLoginModalStatus(true);
      return false;
    }
    FollowVariables.variables.id = "Topic:" + topicId.replace("topic:", "");
    FollowVariables.variables.follow_type = "FAVORITE";
    appolloClient
      .query({
        query: FollowMutation,
        ...FollowVariables,
        fetchPolicy: "no-cache",
      })
      .then(async (res) => {
        await appolloClient
          .query({
            query: TopicFollowingMutation,
            variables: {
              id: "User:CurrentUser",
              first: 50
            },
            fetchPolicy: "no-cache"
          }).then(e => {

            console.log(e, 'followtopics')
            let userData = {
              data: this.props.profileData,
              externalfollow: this.props.getUserFollowFeedList,
              clikfollow: this.props.getUserFollowCliksList,
              topicfollow: e.data.node.topics_following.edges,
              userfollow: this.props.getUserFollowUserList

            }
            this.props.saveLoginUser(userData);
            // let resDataLogin = await this.props.Login();
            // await this.props.saveLoginUser(resDataLogin.data.login);
            // setTimeout(async () => {
               this.props.updateStar();

            // }, 1);

          })
      });
  };

  unfollowTopics = async (topicId) => {
    if (this.props.loginStatus == 0) {
      this.props.setLoginModalStatus(true);
      return false;
    }
    UnFollowVariables.variables.id = "Topic:" + topicId.replace("topic:", "");
    appolloClient
      .query({
        query: UnFollowMutation,
        ...UnFollowVariables,
        fetchPolicy: "no-cache",
      })
      .then(async (res) => {
        await appolloClient
          .query({
            query: TopicFollowingMutation,
            variables: {
              id: "User:CurrentUser",
              first: 50
            },
            fetchPolicy: "no-cache"
          }).then(e => {


            let userData = {
              data: this.props.profileData,
              externalfollow: this.props.getUserFollowFeedList,
              clikfollow: this.props.getUserFollowCliksList,
              topicfollow: e.data.node.topics_following.edges,
              userfollow: this.props.getUserFollowUserList

            }
            this.props.saveLoginUser(userData);
            // let resDataLogin = await this.props.Login();
            // await this.props.saveLoginUser(resDataLogin.data.login);
            setTimeout(async () => {
              await this.props.updateStar();

            }, 1);

          })
      });
  };

  render() {
    return (
      <TouchableOpacity
        style={this.props.ContainerStyle}
        onPress={() => this.changeStar()}
      >
        <Image
          source={this.state.starList[this.state.index]}
          style={this.props.ImageStyle}
        />
      </TouchableOpacity>
    );
  }
}

const mapStateToProps = (state) => ({
  loginStatus: state.UserReducer.loginStatus,
  profileData: state.LoginUserDetailsReducer.userLoginDetails,
  isAdmin: state.AdminTrueFalseReducer.isAdmin,
  isAdminView: state.AdminTrueFalseReducer.isAdminView,
  // DiscussionHomeFeed: state.HomeFeedReducer.get("DiscussionHomeFeedList"),
  getUserFollowCliksList: state.LoginUserDetailsReducer.userFollowCliksList
    ? state.LoginUserDetailsReducer.userFollowCliksList
    : [],
  getUserFollowFeedList: state.LoginUserDetailsReducer.userFollowFeedList
    ? state.LoginUserDetailsReducer.userFollowFeedList
    : [],
  getUserFollowTopicList: state.LoginUserDetailsReducer.userFollowTopicsList
    ? state.LoginUserDetailsReducer.userFollowTopicsList
    : [],
  getUserFollowUserList: state.LoginUserDetailsReducer.userFollowUserList
    ? state.LoginUserDetailsReducer.userFollowUserList
    : [],
});

const mapDispatchToProps = (dispatch) => ({
  // LikeContent: (payload) => dispatch(setLikeContent(payload)),
  setLoginModalStatus: (payload) => dispatch(setLOGINMODALACTION(payload)),
  // setDiscussionHomeFeed: (payload) =>
  //   dispatch({ type: "SET_DISCUSSION_HOME_FEED", payload }),
  saveLoginUser: (payload) => dispatch(saveUserLoginDaitails(payload)),
  // getTrendingUsers: (payload) => dispatch(getTrendingUsers(payload)),
  // getTrendingTopics: (payload) => dispatch(getTrendingTopics(payload)),
  // getTrendingClicks: (payload) => dispatch(getTrendingClicks(payload)),
  // getTrendingExternalFeeds: (payload) =>
  //   dispatch(getTrendingExternalFeeds(payload)),
  // topicId: (payload) => dispatch(getTrendingTopicsProfileDetails(payload)),
});

// const TopicStarWrapper = graphql(UserLoginMutation, {
//   name: "Login",
//   options: { 
//     variables: {
//       id: "Account:" + "CurrentUser"
//     },
//     fetchPolicy: "no-cache" },
// })(TopicStar);

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  TopicStar
);
