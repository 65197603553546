import { createSlice } from '@reduxjs/toolkit';
// import { CLIKS_PROFILE_DETAILS_FAILURE, CLIKS_PROFILE_DETAILS_SUCCESS } from "../constants/Action";




const initialState = {
  termArr: "",
  termArray: [],
};

const TermArrayReducer = createSlice({
  name: 'TermArrayReducer',
  initialState,
  reducers: {
    AddTerm(state, action) {
      return { ...state, termArr: action.payload }
    },
    TermConcat(state, action) {
      return { ...state, termArray: action.payload }
    },

  },
 



})

export const { AddTerm,TermConcat } = TermArrayReducer.actions
export default TermArrayReducer.reducer


