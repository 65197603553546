import { Dimensions, Platform } from "react-native";
import * as Linking from "expo-linking";
import * as Clipboard from "expo-clipboard";

export const OpenWindow = (link) => {
  if (Platform.OS == "web") {
    window.open(link);
  } else {
    Linking.openURL(link);
  }
};

export const WriteToClipboard = async (item, postid, pageName) => {
  let Domain = window.location.href
    .replace("http://", "")
    .replace("https://", "")
    .replace("www.", "")
    .split(/[/?#]/)[0];

  if (pageName == "comment") {
    let commentId = `/post/${postid}/` + item.replace("Comment:", "");
    await Clipboard.setStringAsync(
      Domain.startsWith("localhost") == true
        ? "http://" + Domain + commentId
        : "https://" + Domain + commentId
    );
  } else if (pageName == "invite") {
    await Clipboard.setStringAsync(
      Domain.startsWith("localhost") == true
        ? "http://" + Domain + "/invite/" + item
        : "https://" + Domain + "/invite/" + item
    );
  } else {
    let PostId = "/post/" + item.replace("Post:", "");
    await Clipboard.setStringAsync(
      Domain.startsWith("localhost") == true
        ? "http://" + Domain + PostId
        : "https://" + Domain + PostId
    );
  }
};
