import { ElementsConsumer } from "@stripe/react-stripe-js";
// import Modal from "modal-enhanced-react-native-web";
import React from "react";
import {
  Dimensions,
  Linking,
  Platform,
  Text,
  TouchableOpacity,
  View,
  Modal
} from "react-native";
import { Button } from "react-native-elements";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import { saveUserLoginDaitails } from "../actionCreator/UserAction";
import applloClient from "../client";
// import ChangePaymentModal from "../components/ChangePaymentModal";
import ConstantFontFamily from "../constants/FontFamily";
import getEnvVars from "../environment";
import {
  GetPaymentInfoOuery,
  IsAccountHaveOuery
} from "../graphqlSchema/StripeMutation";
import {
  ChangeAccountSettingsMutation,
  UserLoginMutation, UserFollowingMutation, ExternalFeedFollowingMutation, TopicFollowingMutation, ClikFollowingMutation
} from "../graphqlSchema/UserMutation";
import { ChangeAccountSettingsVariables } from "../graphqlSchema/UserVariables";
// import {
//   getFirebaseToken,
//   getMyUserId,
//   getWeclikdToken
// } from "../library/Helper";
import ButtonStyle from "../constants/ButtonStyle";
import AsyncStorage from "@react-native-async-storage/async-storage";

const apiUrlMain = getEnvVars();

class PaymentInfo extends React.Component {
  state = {
    switchValue1: false,
   
    switchValue2: false,
   
    switchValue3: false,
   
    Email: null,
  
    showTextInput: false,
    cardInfoShow: false,
    infoCard: null,
    isAccountStripe: false,
    loading: false,
    showSubcriptionModal: false,
    conformModal: "",
    getCreditCardStatus: undefined
  };

  componentDidMount() {

    let __self = this;
    applloClient
      .query({
        query: IsAccountHaveOuery,
        variables: {
          id: "Account:" + "CurrentUser"
        },
        fetchPolicy: "no-cache"
      })
      .then(async res => {
        if (res) {
          __self.setState({
            isAccountStripe: res.data.node.payout_info.has_stripe_account
          });
        }
      });
  }
  getinfo = () => {
    let __self = this;
    applloClient
      .query({
        query: IsAccountHaveOuery,
        variables: {
          id: "Account:" + "CurrentUser"
        },
        fetchPolicy: "no-cache"
      })
      .then(async res => {
        if (res) {
          __self.setState({
            isAccountStripe: res.data.node.payout_info.has_stripe_account
          });
        }
      });
  };

  ChangeSubscription = () => {
    // ChangeAccountSettingsVariables.variables.privacy.dm_settings = "EVERYONE";
    ChangeAccountSettingsVariables.variables.email = this.state.Email;
    ChangeAccountSettingsVariables.variables.email_notification_monthly_earnings = this.state.switchValue1;
    ChangeAccountSettingsVariables.variables.email_notification_clik_invitations = this.state.switchValue3;
    ChangeAccountSettingsVariables.variables.email_notification_weclikd_updates = this.state.switchValue2;
    applloClient
      .query({
        query: ChangeAccountSettingsMutation,
        ...ChangeAccountSettingsVariables,
        fetchPolicy: "no-cache"
      })
      .then(async res => {
        let resDataLogin = await applloClient
          .query({
            query: UserLoginMutation,
            variables: {
              id: "Account:" + "CurrentUser"
            },
            fetchPolicy: "no-cache"
          }).then(res => {
            return res
          })

        let userfollow = await applloClient
          .query({
            query: UserFollowingMutation,
            variables: {
              id: "User:CurrentUser",
              first: 20
            },
            fetchPolicy: "no-cache"
          }).then(e => {
            return e.data.node.users_following.edges
          })

        let topicfollow = await applloClient
          .query({
            query: TopicFollowingMutation,
            variables: {
              id: "User:CurrentUser",
              first: 20
            },
            fetchPolicy: "no-cache"
          }).then(e => {
            return e.data.node.topics_following.edges
          })
        let clikfollow = await applloClient
          .query({
            query: ClikFollowingMutation,
            variables: {
              id: "User:CurrentUser",
              first: 20
            },
            fetchPolicy: "no-cache"
          }).then(e => {
            return e.data.node.cliks_following.edges
          })
        let externalfollow = await applloClient
          .query({
            query: ExternalFeedFollowingMutation,
            variables: {
              id: "User:CurrentUser",
              first: 20
            },
            fetchPolicy: "no-cache"
          }).then(e => {
            return e.data.node.external_feeds_following.edges
          })
        let userData = {
          data: resDataLogin.data.node,
          externalfollow: externalfollow,
          clikfollow: clikfollow,
          topicfollow: topicfollow,
          userfollow: userfollow

        }
        await this.props.saveLoginUser(userData);
      });
  };

  getCardInfo = () => {
    let __self = this;
    this.setState({
      loading: true
    });
    applloClient
      .query({
        query: GetPaymentInfoOuery,

        variables: {
          id: "Account:" + "CurrentUser"
        },
        fetchPolicy: "no-cache"

      })
      .then(async res => {
        
        __self.setState({
          infoCard: {
            last4: res.data.node.payment_info?.last4 ? res.data.node.payment_info?.last4 : "",
            brand: res.data.node.payment_info.brand ? res.data.node.payment_info.brand : "",
            exp_month:
              res.data.node.payment_info?.exp_month?.toString().length == 1
                ? "0" + res.data.node.payment_info.exp_month
                : res.data.node.payment_info.exp_month,
            exp_year: res.data.node.payment_info.exp_year ? res.data.node.payment_info.exp_year : "",
            exp_year: res.data.node.payment_info?.exp_year?.toString()
              .substring(
                res.data.node.payment_info?.exp_year?.toString().length - 2
              )
          },
          cardInfoShow: !__self.state.cardInfoShow,
          loading: false,
          getCreditCardStatus: true
        });
      })
      .catch(e => {
        console.log(e);
        __self.setState({
          loading: false,
          getCreditCardStatus: false
        });
      });
  };

  onClose = () => {
    this.getCardInfo();
    this.setState({
      showSubcriptionModal: false
    });
  };

  auth = async () => {
    const firebaseToken = await AsyncStorage.getItem("userIdTokenFirebase");
    const tempToken = await AsyncStorage.getItem("userIdTokenWeclikd");
    const tempCurrentUserId = await AsyncStorage.getItem("userLoginId");
    let weclikdToken = tempToken ? tempToken : null;
    let CurrentUserId = tempCurrentUserId ? tempCurrentUserId : null;
    return await {
      firebaseToken,
      weclikdToken,
      CurrentUserId
    };
  };

  openWindow = async () => {
    let apiUrl = apiUrlMain.API_URL + "plutus/payout/authorize";
    let apiUrlHeader = await this.auth();
    let headersSet = {
      // "Weclikd-Authorization": encodeURI("Bearer " + apiUrlHeader.weclikdToken),
      Authorization: encodeURI("Custom " + apiUrlHeader.firebaseToken)
    };
    let str = "";
    for (let key in headersSet) {
      if (str != "") {
        str += "&";
      }
      str += key + "=" + headersSet[key];
    }
    const uri = apiUrl + "?" + str;
    Linking.openURL(uri);
  };

  openWindowDashboard = async () => {
    let apiUrl = apiUrlMain.API_URL + "plutus/payout/dashboard";
    let apiUrlHeader = await this.auth();
    let headersSet = {
      // "Weclikd-Authorization": encodeURI("Bearer " + apiUrlHeader.weclikdToken),
      Authorization: encodeURI("Custom " + apiUrlHeader.firebaseToken)
    };
    let str = "";
    for (let key in headersSet) {
      if (str != "") {
        str += "&";
      }
      str += key + "=" + headersSet[key];
    }
    const uri = apiUrl + "?" + str;
    Linking.openURL(uri);
  };

  render() {
    const {
      cardInfoShow,
      conformModal,
      isAccountStripe,
      loading,
      showSubcriptionModal
    } = this.state;
    return (
      <View
        style={{
          // backgroundColor:'#f4f4f4',
          backgroundColor: "#fff",
          paddingHorizontal: 10,
          marginVertical: Dimensions.get("window").width >= 750 ? 10 : 0
        }}
      >
        {this.props.profileData.subscription == "GOLD" ? <>
          <Text
            style={{
              color: "#000",
              fontSize: 16,
              fontWeight: "bold",
              fontFamily: ConstantFontFamily.defaultFont,
              //marginVertical: 10
            }}
          >
            Credit Card
          </Text>

          <View style={{ flexDirection: "row", width: "100%", justifyContent: 'space-between', height: 45, alignItems: 'center' }}>
            <View
              style={{
                justifyContent: "flex-start",
                // width:cardInfoShow ? "70%" : '100%',
                alignSelf: "center"
              }}
            >
              {this.state.getCreditCardStatus == false && (
                <View
                  style={{
                    //marginVertical: 5,
                    //height: 45,
                    justifyContent: "center"
                  }}
                >
                  <Text
                    style={{
                      color: "#000",
                      //fontWeight: "bold",
                      fontFamily: ConstantFontFamily.defaultFont,
                      fontSize: 14
                    }}
                  >
                    No card on file
                  </Text>
                </View>
              )}

              {this.state.getCreditCardStatus == undefined && cardInfoShow == false ? (
                loading == false ? (
                  <TouchableOpacity
                    style={{
                      marginVertical: 10,
                      //height: 45,
                      justifyContent: "flex-start",
                      flexDirection: 'row'
                    }}
                    onPress={this.getCardInfo}
                  >
                    <Text
                      style={{
                        color: "#000",
                        //fontWeight: "bold",
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 14,
                        //textDecorationLine: loading == false && 'underline'
                      }}
                    >
                      Click {' '}
                    </Text>
                    <Text
                      style={{
                        color: "#000",
                        //fontWeight: "bold",
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 14,
                        textDecorationLine: loading == false && 'underline'
                      }}
                    >
                      here
                    </Text>
                    <Text
                      style={{
                        color: "#000",
                        //fontWeight: "bold",
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 14,
                        //textDecorationLine: loading == false && 'underline'
                      }}
                    >
                      {' '}  to view current card
                    </Text>
                  </TouchableOpacity>
                ) : (
                  <View
                    style={{
                      marginVertical: 5,
                      //height: 45,
                      justifyContent: "center"
                    }}
                  >
                    <Text
                      style={{
                        color: "#000",
                        fontWeight: "bold",
                        fontFamily: ConstantFontFamily.defaultFont,
                        fontSize: 14
                      }}
                    >
                      Loading...
                    </Text>
                  </View>
                )
              ) : (
                this.state.infoCard && (
                  <View>
                    <View
                      style={{
                        width: "100%",
                        flexDirection: "row",
                        alignItems: "center"
                      }}
                    >
                      <Text
                        style={{
                          color: "#000",
                          fontWeight: "bold",
                          fontFamily: ConstantFontFamily.defaultFont,
                          fontSize: 12,
                          marginLeft: 5
                        }}
                      >
                        {this.state.infoCard.brand.charAt(0).toUpperCase() +
                          this.state.infoCard.brand.slice(1)}{" "}
                        **** **** **** {this.state.infoCard.last4}
                        {"   "}
                        {this.state.infoCard.exp_month} /{" "}
                        {this.state.infoCard.exp_year}
                      </Text>
                    </View>
                  </View>
                )
              )}
            </View>

            <View
              style={{
                //height: 45,
                justifyContent: "flex-end",
                //width: "40%",
                alignItems: "flex-end"
              }}
            >
              <Button
                onPress={() => {
                  this.setState({
                    showSubcriptionModal: true
                  });
                }}
                color="#fff"
                title="Change"
                titleStyle={ButtonStyle.titleStyle}
                buttonStyle={[ButtonStyle.backgroundStyle, { paddingHorizontal: 10 }]}
                containerStyle={ButtonStyle.containerStyle}
              />
            </View>
          </View>
        </> : null}


        <Text
          style={{
            color: "#000",
            fontSize: 16,
            fontWeight: "bold",
            fontFamily: ConstantFontFamily.defaultFont,
            marginBottom: 10,
            marginTop: 5
          }}
        >
          Stripe Payouts
        </Text>

        <View style={{ flexDirection: "row", flexWrap: Dimensions.get("window").width <= 450 ? 'wrap' : 'nowrap', width: "100%", justifyContent: 'space-between', paddingBottom: 10 }}>
          <View
            style={{
              justifyContent: "flex-start",
              width: Dimensions.get("window").width >= 450 && isAccountStripe ? "70%" : '50%',
              alignSelf: "center"
            }}
          >
            <View
              style={{
                marginBottom: 5,
                height: 45,
                justifyContent: "center"
              }}
            >
              {isAccountStripe == false ? (
                <Text
                  style={{
                    color: "#000",
                    //fontWeight: "bold",
                    fontFamily: ConstantFontFamily.defaultFont,
                    fontSize: 14
                  }}
                >
                  Set up a Stripe account to receive money from Weclikd
                </Text>
              ) : (
                <Text
                  style={{
                    color: "#000",
                    fontWeight: "bold",
                    fontFamily: ConstantFontFamily.defaultFont,
                    fontSize: 14
                  }}
                >
                  Go to Stripe Dashboard to change settings and view payouts
                </Text>
              )}
            </View>
          </View>
          <View
            style={{
              //height: 45,
              justifyContent: "center",
              //width: "40%",
              alignItems: "flex-end"
            }}
          >
            {isAccountStripe == false ? (
              <Button
                onPress={this.openWindow}
                color="#fff"
                title="Setup Stripe"
                titleStyle={ButtonStyle.titleStyle}
                buttonStyle={[ButtonStyle.backgroundStyle, { paddingHorizontal: 10 }]}
                containerStyle={ButtonStyle.containerStyle}
              />
            ) : (
              <Button
                title="Go to Stripe"
                titleStyle={ButtonStyle.titleStyle}
                buttonStyle={[ButtonStyle.backgroundStyle, { paddingHorizontal: 10 }]}
                containerStyle={ButtonStyle.containerStyle}
                onPress={this.openWindowDashboard}
              />
            )}

            {showSubcriptionModal == true
              ? Platform.OS === "web" && (
                <Modal
                  isVisible={showSubcriptionModal}
                  onBackdropPress={this.onClose}
                  style={{
                    marginHorizontal:
                      Dimensions.get("window").width > 450 ? "30%" : 10,
                    padding: 0
                  }}
                >
                  {/* <ElementsConsumer>
                    {({ stripe, elements }) => (
                      <ChangePaymentModal
                        onClose={this.onClose}
                        {...this.props}
                        type={conformModal}
                        stripe={stripe}
                        elements={elements}
                      />
                    )}
                  </ElementsConsumer> */}
                </Modal>
              )
              : null}
          </View>
        </View>
      </View>
    );
  }
}

const mapStateToProps = state => ({
  profileData: state.LoginUserDetailsReducer.userLoginDetails
});

const mapDispatchToProps = dispatch => ({
  saveLoginUser: payload => dispatch(saveUserLoginDaitails(payload))
});



export default compose(connect(mapStateToProps, mapDispatchToProps))(
  PaymentInfo
);
