import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  windowResize: {
    width: '',
    height: ''
  },
}

const windowResizeReducer = createSlice({
  name: 'windowResizeReducer',
  initialState,
  reducers: {
    windowResize(state, action) {
      return { ...state, windowResize: action.payload }

      // state.windowResize = action.payload

    }
  }
})

export const { windowResize } = windowResizeReducer.actions
export default windowResizeReducer.reducer