import { createSlice } from '@reduxjs/toolkit'

const initialState ={
  hasScrollTop: false,
  setBrowserHeightWidth: { heigh: 0, width: 0 }
};

const HasScrolledReducer = createSlice({
  name: 'HasScrolledReducer',
  initialState,
  reducers: {
    hasscrolledAdctionSuccess(state,action) {
      state.hasScrollTop= action.payload
    },
    hasscrolledAdctionFailure(state,action) {        
      return state
    },
   
  },
})

export const { hasscrolledAdctionSuccess, hasscrolledAdctionFailure } = HasScrolledReducer.actions
export default HasScrolledReducer.reducer

// export default function HasScrolledReducer(state = initialState, action) {
//   const { type, payload } = action;
//   switch (type) {
//     case HASSCROLLEDACTION_SUCCESS:
//       return state.set("hasScrollTop", fromJS(payload));
//     case HASSCROLLEDACTION_FAILURE:
//       return initialState;
//     case "SET_BROWSER_HEIGHT_WIDTH":
//       return state.set("setBrowserHeightWidth", payload);
//   }
//   return state;
// }
