import React, { Component } from "react";
import { Animated, Dimensions, View } from "react-native";
import ButtonStyle from "../constants/ButtonStyle";




class ShadowSkeletonNotification extends React.Component {
  constructor(props) {
    super(props);
    this.Pagescrollview = null;
    this.circleAnimatedValue = new Animated.Value(0);
  }

  componentDidMount() {
    this.circleAnimated();
  }

  circleAnimated = () => {
    this.circleAnimatedValue.setValue(0);
    Animated.timing(this.circleAnimatedValue, {
      toValue: 1,
      duration: 1000
    }).start(() => {
      setTimeout(() => {
        this.circleAnimated();
      }, 1000);
    });
  };

  render() {
    const translateX = this.circleAnimatedValue.interpolate({
      inputRange: [0, 1],
      outputRange: [-10, Dimensions.get("window").width]
    });
    const translateTitle = this.circleAnimatedValue.interpolate({
      inputRange: [0, 1],
      outputRange: [-10, Dimensions.get("window").width]
    });
    return (
      <View>
        {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => {
          return (
            <View style={[ButtonStyle.normalFeedStyleForDashboard, {
              height: 150,
              backgroundColor:"rgba(0,0,0,0.03)",
    
            }]}></View>
            
          );
        })}
      </View>
    );
  }
}
export default ShadowSkeletonNotification;
