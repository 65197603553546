import { createSlice } from '@reduxjs/toolkit';
// import { CLIKS_PROFILE_DETAILS_FAILURE, CLIKS_PROFILE_DETAILS_SUCCESS } from "../constants/Action";




const initialState = {
    clik: "",
};

const EditClikReducer = createSlice({
    name: 'EditClikReducer',
    initialState,
    reducers: {
        editClik(state, action) {
            // console.log(action, 'actionnnnnnnnnnnn');
            return { ...state, clik: action.payload }
        },
        // feedDetailsfailure(state, action) {
        //     // state.getTrendingCliksProfileDetails=action.payload.data.node
        // },

    },
})

export const { editClik } = EditClikReducer.actions
export default EditClikReducer.reducer

// export default function EditClikReducer(state = initialState, action) {
//   const { type, payload } = action;
//   switch (type) {
//     case EDITCLIK_SUCCESS:
//       return state.set("clik", fromJS(payload));
//     case EDITCLIK_FAILURE:
//       return initialState;
//   }
//   return state;
// }
